"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnumNames = void 0;
const bank_details_model_1 = require("../models/bank-details.model");
const enums_1 = require("../models/enums/enums");
const parking_type_enum_1 = require("../models/enums/parking-type.enum");
class EnumNames {
    static getPayoutFrequency(enumValue) {
        switch (enumValue) {
            case bank_details_model_1.PayoutFrequencyEnum.MONTHLY:
                return 'Monthly on the 1st business day';
            case bank_details_model_1.PayoutFrequencyEnum.IMMEDIATE:
                return '1 - 3 business days after client payment';
        }
    }
    static getPaymentOnInvoice(enumValue) {
        switch (enumValue) {
            case bank_details_model_1.PaymentOnInvoiceEnum.NONE:
                return 'ParkUpp makes the payout on generation of the proforma';
            case bank_details_model_1.PaymentOnInvoiceEnum.INVOICE_REQUIRED:
                return 'ParkUpp waits for invoice before making the payout';
        }
    }
    static getAdminPaymentOnInvoice(enumValue) {
        switch (enumValue) {
            case bank_details_model_1.PaymentOnInvoiceEnum.NONE:
                return 'Pay on Generation of Proforma';
            case bank_details_model_1.PaymentOnInvoiceEnum.INVOICE_REQUIRED:
                return 'Wait for Landlord to Invoice';
        }
    }
    static getClassPaymentOnInvoice(enumValue) {
        switch (enumValue) {
            case bank_details_model_1.PaymentOnInvoiceEnum.NONE:
                return 'badge bg-success text-light';
            case bank_details_model_1.PaymentOnInvoiceEnum.INVOICE_REQUIRED:
                return 'badge bg-danger text-light';
        }
    }
    static getSubscriptionType(enumValue) {
        switch (enumValue) {
            case enums_1.SubscriptionType.PAYGATE:
                return 'PayGate';
            case enums_1.SubscriptionType.PAYFAST:
                return 'PayFast';
            case enums_1.SubscriptionType.EFT:
                return 'EFT';
            default:
                return '';
        }
    }
    static getSubscriptionTypeClass(enumValue) {
        switch (enumValue) {
            case enums_1.SubscriptionType.PAYGATE:
                return 'bg-paygate';
            case enums_1.SubscriptionType.PAYFAST:
                return 'bg-payfast';
            case enums_1.SubscriptionType.EFT:
                return 'bg-eft';
            default:
                return '';
        }
    }
    static getParkingTypeNiceName(enumValue) {
        switch (enumValue) {
            case parking_type_enum_1.ParkingType.STANDARD:
                return 'Standard';
            case parking_type_enum_1.ParkingType.PLANES_TRAINS_SHIPS:
                return 'Planes, Trains & Ships';
            case parking_type_enum_1.ParkingType.TRAILERS_CARAVANS_BOATS:
                return 'Trailers, Caravans & Boats';
            case parking_type_enum_1.ParkingType.PARKING_COLLECTOR:
                return 'Parking Collector';
            case parking_type_enum_1.ParkingType.PARKING_EVENTS:
                return 'Parking Events';
            case parking_type_enum_1.ParkingType.TRUCKS:
                return 'Trucks';
            case parking_type_enum_1.ParkingType.FLEX:
                return 'Flex';
            case parking_type_enum_1.ParkingType.MANUAL:
                return 'Manual';
            case parking_type_enum_1.ParkingType.BOOKING:
                return 'Booking';
        }
    }
    static getParkingTypeClass(enumValue) {
        switch (enumValue) {
            case parking_type_enum_1.ParkingType.STANDARD:
                return 'bg-light text-dark';
            default:
                return 'bg-warning text-dark';
        }
    }
}
exports.EnumNames = EnumNames;
