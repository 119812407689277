"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Term = void 0;
var Term;
(function (Term) {
    Term["MONTHLY"] = "MONTHLY";
    Term["DAILY"] = "DAILY";
    Term["ONCEOFF"] = "ONCEOFF";
    Term["FLEX_MONTH"] = "FLEX_MONTH";
    Term["FLEX_HALF_MONTH"] = "FLEX_HALF_MONTH";
})(Term = exports.Term || (exports.Term = {}));
