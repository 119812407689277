import { Component, OnInit } from '@angular/core'
import { AdminChangeLog, AngularRoutes, ChangeLogsEntityEnum, ContractStatus, Invoice, InvoiceStatus, Subscription, subscriptionsPath } from '@parkupp/core'
import { collection, collectionData, DocumentData, orderBy, query, Query, serverTimestamp, Timestamp, where } from '@angular/fire/firestore'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment/moment'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { ClientService } from 'src/app/services/client.service'
import Swal from 'sweetalert2'
import { AdminChangeLogService } from 'src/app/services/admin-change-log.service'
import { GlobalService } from 'src/app/services/global.service'
import { AdminService } from 'src/app/services/admin.service'
import { InvoiceService } from 'src/app/services/invoice.service'
import { Validators } from '@angular/forms'
import { FormBuilder } from '@angular/forms'
import { FormGroup } from '@angular/forms'

@Component({
    selector: 'app-invoices-list',
    templateUrl: './invoices-list.component.html',
    styleUrls: ['./invoices-list.component.scss'],
})
export class InvoicesListComponent implements OnInit {
    subscriptions: Subscription[] = []
    inactiveContractStatuses: ContractStatus[]
    ref: Query<DocumentData>
    // invoices$: Observable<Invoice[]>
    readonly AngularRoutes = AngularRoutes
    invoices: Invoice[] = []
    lastVisible: any
    showLoadMoreButton: boolean = false
    InvoiceStatus = InvoiceStatus
    invoiceStates: InvoiceStatus[] = Object.values(InvoiceStatus)

    today: number = moment().toDate().getTime()
    protected readonly Invoice = Invoice
    filteredInvoices: Invoice[] = []
    status: InvoiceStatus = InvoiceStatus.DUE
    selectedInvoice: Invoice | null = null
    thisYearStart: Date
    thisYearEnd: Date
    form: FormGroup

    constructor(
        public globalService: GlobalService,
        private authService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private adminService: AdminService,
        private invoiceService: InvoiceService,
        private clientService: ClientService,
        private adminChangeLogService: AdminChangeLogService,
        private formBuilder: FormBuilder
    ) {}

    ngOnInit() {
        this.thisYearStart = new Date(2024, 2, 1)
        this.thisYearEnd = new Date(2025, 1, 28)

        // Format dates to 'YYYY-MM-DD' for input type 'date'
        const formattedStartDate = this.thisYearStart.toISOString().split('T')[0]
        const formattedEndDate = this.thisYearEnd.toISOString().split('T')[0]

        this.form = this.formBuilder.group({
            fromDate: [formattedStartDate, Validators.required],
            toDate: [formattedEndDate, Validators.required],
        })

        this.route.queryParams.subscribe((params) => {
            if (params['status']) {
                this.status = params['status'] as InvoiceStatus
            }
        })
        const activeContractStatuses: ContractStatus[] = [ContractStatus.paymentInitiated, ContractStatus.active, ContractStatus.bouncing, ContractStatus.renewing]
        const ref = query(collection(this.adminService.firestore, subscriptionsPath()), orderBy('createdAt', 'desc'), where('status', 'in', activeContractStatuses))
        collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.subscriptions = []
            documents.forEach((documentData) => {
                let subscription = new Subscription(documentData, documentData.documentId)
                this.subscriptions.push(subscription)
            })
            this.fetchInvoices()
        })
    }

    fetchInvoices() {
        this.invoices = []
        this.invoiceService.listNoLimit().subscribe((data) => {
            this.invoices = data.map((doc) => {
                const invoice = new Invoice(doc.data())
                invoice.$key = doc.id
                invoice.$subscription = this.subscriptions.find((sub) => sub.$key === invoice.subscriptionRef?.id)
                return invoice
            })
        })
    }
}
