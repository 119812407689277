import { Inject, Injectable } from '@angular/core'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { environment } from '../../environments/environment'
import { connectFunctionsEmulator, Functions } from '@angular/fire/functions'
import { DOCUMENT } from '@angular/common'
import { GlobalService } from './global.service'
import {
    Client,
    merchantParkingsPath,
    OmnisendAddToCartRequest,
    OmnisendAddToCartResponse,
    OmnisendCreateCartRequest,
    OmnisendCreateCartResponse,
    OmnisendCreateOrderRequest,
    OmnisendCreateProductRequest,
    OmnisendCreateProductResponse,
    OmnisendImage,
    OmnisendProduct,
    OmnisendVariant,
    Parking,
} from '@parkupp/core'
import { ClientService } from './client.service'
import { AuthenticationService } from './authentication.service'
import * as moment from 'moment'
import { doc, updateDoc } from '@angular/fire/firestore'

@Injectable({
    providedIn: 'root',
})
export class OmnisendService {
    scriptLoaded = false
    functions: Functions

    omnisendCookieName = 'omnisendContactID'
    private omnisend: any
    private window: any

    constructor(@Inject(DOCUMENT) private document: Document, private globalService: GlobalService, private authService: AuthenticationService, private clientService: ClientService) {
        this.functions = getFunctions()
        if (environment.useEmulators) {
            connectFunctionsEmulator(this.functions, 'localhost', 5001)
        }
        this.window = this.document.defaultView

        this.loadScript()
        // this.viewPage()

        // if (!this.window.omnisend) {
        this.window.omnisend = []
        this.window.omnisend.push(['accountID', '631b85c048c164e108e0c276'])
        this.window.omnisend.push(['track', '$pageViewed'])
        // }

        this.clientService.activeClientReady.subscribe((client) => {
            this.updateUserCookie(client as Client)
        })

        this.authService.onSignOut.subscribe(() => {
            this.globalService.deleteCookie(this.omnisendCookieName)
        })
    }

    productView(parking: Parking) {
        if (!parking.omnisendProductId) {
            this.createProduct(parking)

            const update = { omnisendProductId: parking.$key }
            updateDoc(doc(this.clientService.firestore, merchantParkingsPath(parking.merchantRef.id), parking.$key!), update)
        }

        // console.log('Viewed product: ' + parking.title)
        // console.log(this.window.omnisend)
        // this.isProductView = true
        this.window.omnisend.push([
            'track',
            '$productViewed',
            {
                $productID: parking.$key,
                $currency: 'ZAR',
                $price: parking.defaultPriceInCents,
                $title: parking.title,
                $variantID: parking.$key,
                $imageUrl: parking.defaultImage,
                $productUrl: `${environment.host}/parking/${parking.$key}`,
            },
        ])
    }

    loadScript() {
        if (!this.scriptLoaded) {
            this.globalService.addScript('https://omnisnippet1.com/inshop/launcher-v2.js', 'omnisend-script')
            this.getOmnisend()
            this.scriptLoaded = true
        }
    }

    getOmnisend() {
        return new Promise<void>((resolve) => {
            const interval = setInterval(() => {
                // console.log(typeof this.window.omnisend.push === 'function')
                if (this.window.omnisend) {
                    this.omnisend = this.window.omnisend as any
                    clearInterval(interval)
                    resolve(this.omnisend)
                }
                // else {
                // console.log(this.window.omnisend)
                // console.log(this.window.omnisend.length)
                // }
            }, 100)
        })
    }

    updateUserCookie(client: Client) {
        if (client.omnisendContactKey) {
            this.globalService.setCookie({
                name: this.omnisendCookieName,
                value: client.omnisendContactKey,
                expireDays: 30,
            })
        } else {
            this.createContact({
                firstName: client.firstName,
                lastName: client.lastName,
                phone: client.$phoneNumber,
                email: client.$email,
                clientKey: client.$key!,
            })
        }
    }

    createContact(data: { firstName: any; lastName: any; phone: string; email: string; clientKey: string }) {
        return new Promise<any>((resolve, reject) => {
            const createContact = httpsCallable(this.functions, 'omnisend-createContact')
            createContact(data).then((result: any) => {
                if (result.data.contactID) {
                    this.globalService.setCookie({
                        name: this.omnisendCookieName,
                        value: result.data.contactID,
                        expireDays: 30,
                    })

                    this.clientService.updateClient({ omnisendContactKey: result.data.contactID }, data.clientKey)
                }
                resolve('done')
            })
        })
    }

    getSetCart(req: { activeClient: Client; subscriptionKey: string; parking: Parking; quantity: number; priceInCents: number; orderTotal: number }) {
        if (req.activeClient.omnisendCartKey) {
            const deleteCart = httpsCallable(this.functions, 'omnisend-deleteCart')
            deleteCart({ cartId: req.activeClient.omnisendCartKey }).then((result: any) => {
                this.createCart(req)
            })
        } else {
            this.createCart(req)
        }
    }

    createCart(req: { activeClient: Client; subscriptionKey: string; parking: Parking; quantity: number; priceInCents: number; orderTotal: number }) {
        // create
        const data = new OmnisendCreateCartRequest({
            productID: req.parking.$key,
            variantID: req.parking.$key,
            title: req.parking.title,
            description: req.parking.description?.substring(0, 300),
            productUrl: `${environment.host}/parking/${req.parking.$key}`,
            cartID: req.subscriptionKey,
            email: req.activeClient.$email,
            phone: req.activeClient.$phoneNumber,
            contactID: req.activeClient.omnisendContactKey,
            cartRecoveryUrl: `${environment.host}/cart/${req.subscriptionKey}`,
            subscriptionKey: req.subscriptionKey,
            quantity: req.quantity,
            priceInCents: req.parking.defaultPriceInCents,
            imageUrl: req.parking.defaultImage,
        })

        const createCart = httpsCallable(this.functions, 'omnisend-createCart')
        createCart(data).then((result: OmnisendCreateCartResponse) => {
            this.clientService.updateClient({ omnisendCartKey: req.subscriptionKey }, req.activeClient.$key!)
            this.addToCart(req)
        })
    }

    addToCart(req: { activeClient: Client; subscriptionKey: string; parking: Parking; quantity: number; priceInCents: number; orderTotal: number }) {
        const omnisendAddToCartRequest = new OmnisendAddToCartRequest({
            currency: 'ZAR',
            cartProductID: req.parking.$key,
            productID: req.parking.$key,
            variantID: req.parking.$key,
            title: req.parking.title,
            description: req.parking.description?.substring(0, 300),
            quantity: req.quantity,
            price: req.priceInCents,
            productUrl: `${environment.host}/parking/${req.parking.$key}`,
            imageUrl: req.parking.defaultImage,
        })

        const addToCart = httpsCallable(this.functions, 'omnisend-addToCart')
        addToCart({ cartId: req.subscriptionKey, omnisendAddToCartRequest }).then((result: OmnisendAddToCartResponse) => {
            // console.log('Added to cart')
            // console.log(result)
        })
    }

    createProduct(parking: Parking) {
        const omnisendImage = new OmnisendImage({
            imageID: 'Image001',
            url: parking.defaultImage,
            isDefault: true,
            variants: [parking.$key!],
        })
        const omnisendVariant = new OmnisendVariant({
            variantID: parking.$key,
            title: parking.title,
            status: 'inStock',
            price: parking.defaultPriceInCents,
            productUrl: `${environment.host}/parking/${parking.$key}`,
            imageID: 'Image001',
        })

        const omnisendCreateProductRequest = new OmnisendCreateProductRequest({
            productID: parking.$key,
            title: parking.title,
            status: 'inStock',
            description: parking.description?.substring(0, 300),
            currency: 'ZAR',
            productUrl: `${environment.host}/parking/${parking.$key}`,
            type: parking.niceType,
            createdAt: moment().toISOString(),
            images: [omnisendImage],
            variants: [omnisendVariant],
        })

        const createProduct = httpsCallable(this.functions, 'omnisend-createProduct')
        createProduct(omnisendCreateProductRequest).then((result: OmnisendCreateProductResponse) => {
            // console.log('Added product')
            // console.log(result)
        })
    }

    createOrder(req: { activeClient: Client; subscriptionKey: string; parking: Parking; quantity: number; priceInCents: number; orderTotal: number }) {
        // console.log(req)

        const product = new OmnisendProduct({
            cartProductID: req.parking.$key,
            productID: req.parking.$key,
            variantID: req.parking.$key,
            title: req.parking.title,
            description: req.parking.description?.substring(0, 300),
            quantity: req.quantity,
            price: req.priceInCents,
            productUrl: `${environment.host}/parking/${req.parking.$key}`,
            imageUrl: req.parking.defaultImage,
        })

        const requestData = new OmnisendCreateOrderRequest({
            orderID: req.subscriptionKey,
            email: req.activeClient.$email,
            phone: req.activeClient.$phoneNumber,
            firstName: req.activeClient.firstName,
            lastName: req.activeClient.lastName,
            cartID: req.subscriptionKey,
            orderUrl: `${environment.host}/client/${req.activeClient.$key}/subscription/${req.subscriptionKey}`,
            currency: 'ZAR',
            orderSum: req.orderTotal,
            createdAt: moment().toISOString(),
            updatedAt: moment().toISOString(),
            paymentMethod: 'Credit card',
            paymentStatus: 'paid',
            fulfillmentStatus: 'fulfilled',
            products: [product],
        })

        const createOrder = httpsCallable(this.functions, 'omnisend-createOrder')
        createOrder(requestData).then((result: OmnisendAddToCartResponse) => {
            // console.log('Completed order')
            // console.log(result)
        })
    }
}
