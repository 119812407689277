import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MerchantService } from '../../../services/merchant.service'
import { Client, Merchant, MerchantMember, merchantMembersPath, MerchantRole, merchantsPath } from '@parkupp/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import * as firestore from 'firebase/firestore'
import * as uuid from 'uuid'
import { collection, collectionSnapshots } from '@angular/fire/firestore'
import { Subscription } from 'rxjs'
import { ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { ClientService } from '../../../services/client.service'
import { AuthenticationService } from '../../../services/authentication.service'

@Component({
    selector: 'app-merchant-members-list',
    templateUrl: './merchant-members-list.component.html',
    styleUrls: ['./merchant-members-list.component.scss'],
})
export class MerchantMembersListComponent implements OnInit, OnDestroy {
    @Input() merchantKey: string
    @Input() showHeading = true

    uid: string

    emailFormGroup: FormGroup
    permissionsFormGroup: FormGroup
    loading = false
    error: string
    merchantRoles = MerchantRole
    isOwner = false
    canEditRoles = false

    merchant: Merchant
    merchantMembers: MerchantMember[]
    valid: boolean = true
    modalRef?: BsModalRef
    membersSubscription: Subscription
    selectedMerchantMember: MerchantMember
    clientMemberMap: { [id: string]: Client } = {}
    showDeletedMembers: boolean = false

    constructor(
        private auth: AuthenticationService,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private modalService: BsModalService,
        private toastrService: ToastrService,
        private clientService: ClientService,
        private merchantService: MerchantService
    ) {
        this.uid = this.auth.uid
        this.emailFormGroup = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        })
        this.permissionsFormGroup = this.formBuilder.group({
            emailMember: ['', [Validators.required, Validators.email]],
            active: [true, [Validators.required]],
            token: [''],
            manageListings: [true, [Validators.required]],
            manageMembers: [true, [Validators.required]],
            manageBilling: [true, [Validators.required]],
            manageProfile: [true, [Validators.required]],
        })
    }

    ngOnInit(): void {
        this.getMerchant()
        this.getMerchantMembersList(this.merchantKey)
    }

    ngOnDestroy(): void {
        if (this.membersSubscription) {
            this.membersSubscription.unsubscribe()
        }
    }

    getMerchant() {
        this.merchantService.subscribeToMerchant(this.merchantKey).subscribe((document) => {
            this.merchant = new Merchant(document)
        })
    }

    getMerchantMembersList(uid: string) {
        if (this.membersSubscription) {
            this.membersSubscription.unsubscribe()
        }
        this.membersSubscription = collectionSnapshots(collection(this.merchantService.firestore, merchantMembersPath(uid))).subscribe((documents) => {
            let merchantMembers: MerchantMember[] = []
            documents.forEach((documentData) => {
                let merchantMember: MerchantMember = documentData.data() as MerchantMember
                merchantMember.$key = documentData.id
                merchantMembers.push(merchantMember)
                if (merchantMember.uid && !this.clientMemberMap[merchantMember.uid]) {
                    this.getClient(merchantMember.uid)
                }
                this.isOwner = !!(documents.length === 1 || (merchantMember.uid && merchantMember.uid === this.uid))
                this.checkPermissions()
            })
            this.merchantMembers = merchantMembers
        })
    }

    getClient(uid: string) {
        const clientSubscription = this.clientService.subscribeToClient(uid).subscribe((document) => {
            clientSubscription.unsubscribe()
            this.clientMemberMap[uid] = document as Client
        })
    }

    save() {
        const memberEmail = this.emailFormGroup.value.email
        for (const member of this.merchantMembers) {
            if (memberEmail == member.email) {
                if (!member.active) {
                    member.active = true
                    this.updateMember(member)
                }
                return
            }
        }
        if (this.emailFormGroup.valid) {
            this.loading = true
            const merchantMember = new MerchantMember()
            merchantMember.email = this.emailFormGroup.value.email
            merchantMember.token = uuid.v4()
            merchantMember.merchantRef = firestore.doc(this.merchantService.firestore, merchantsPath(), this.merchantKey) as any
            merchantMember.roles = [MerchantRole.PORTFOLIO_MANAGER, MerchantRole.BUILDING_MANAGER]

            this.merchantService
                .addMerchantMember(merchantMember, this.merchantKey)
                .then(() => {
                    this.emailFormGroup.get('email')?.setValue('')
                    this.loading = false
                    this.toastrService.success(`Invite sent to ${memberEmail}`, 'Successful')
                })
                .catch((error) => {
                    this.error = error['message']
                    this.loading = false
                })
        } else {
            this.valid = false
        }
    }

    updateMember(merchantMember: MerchantMember) {
        this.selectedMerchantMember = merchantMember
        this.loading = true
        if (merchantMember.$key) {
            this.merchantService
                .updateMerchantMember(merchantMember, this.merchantKey, merchantMember.$key)
                .then(() => {
                    this.loading = false
                    this.closeSaveFormModal()
                })
                .catch((error) => {
                    this.error = error['message']
                    this.loading = false
                })
        }
    }

    closeSaveFormModal() {
        this.modalRef?.hide()
    }

    openModal(template: any) {
        this.modalRef = this.modalService.show(template)
        return false
    }

    deleteMember(merchantMember: MerchantMember) {
        if (confirm('Are you sure you want to delete this user. This cannot be undone.')) {
            this.selectedMerchantMember = merchantMember
            this.selectedMerchantMember.active = false
            this.updateMember(this.selectedMerchantMember)
        }
        return false
    }

    restoreMember(merchantMember: MerchantMember) {
        this.selectedMerchantMember = merchantMember
        this.selectedMerchantMember.active = true
        this.updateMember(this.selectedMerchantMember)
        return false
    }

    resendInvite(merchantMember: MerchantMember) {
        merchantMember.token = uuid.v4()
        this.updateMember(merchantMember)
        this.toastrService.success(`Invite sent to ${merchantMember.email}`, 'Successful')
        return false
    }

    transferOwnership(merchantMember: MerchantMember) {
        if (confirm('Are you sure you want to transfer ownership to this user.')) {
            this.loading = true
            this.merchantService
                .updateMerchant(
                    {
                        owner: merchantMember.uid,
                    },
                    this.merchantKey
                )
                .then(() => {
                    this.loading = false
                    this.getMerchantMembersList(this.merchantKey)
                })
                .catch((error) => {
                    this.error = error['message']
                    this.loading = false
                })
        }
        return false
    }

    updateRoles(merchantMember: MerchantMember, merchantRole: MerchantRole) {
        const index = merchantMember.roles.indexOf(merchantRole)
        if (index === -1) {
            merchantMember.roles.push(merchantRole)
        } else {
            merchantMember.roles.splice(index, 1)
        }
        this.merchantService
            .updateMerchantMember({ roles: merchantMember.roles }, this.merchantKey, merchantMember.$key!)
            .then(() => {
                if (merchantMember.uid === this.auth.uid) {
                    this.auth.merchantMemberRoles[this.merchantKey].roles = merchantMember.roles
                }
                this.loading = false
                this.toastrService.success(`Merchant with email ${merchantMember.email} was updated`, 'Successful')
                this.getMerchantMembersList(this.merchantKey)
            })
            .catch((error) => {
                this.error = error['message']
                this.loading = false
            })
        return false
    }

    checkPermissions() {
        this.canEditRoles = false
        if (this.isOwner) {
            this.canEditRoles = true
        }
        if (this.auth.merchantMemberRoles[this.merchantKey] && this.auth.merchantMemberRoles[this.merchantKey].roles) {
            if (
                this.auth.merchantMemberRoles[this.merchantKey].roles.indexOf(MerchantRole.ACCOUNT_KEY) ||
                this.auth.merchantMemberRoles[this.merchantKey].roles.indexOf(MerchantRole.PORTFOLIO_MANAGER)
            ) {
                this.canEditRoles = true
            }
        }
    }

    toggleShowDeletedMembers() {
        this.showDeletedMembers = !this.showDeletedMembers
    }
}
