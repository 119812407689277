<admin-app-header></admin-app-header>
<br />
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Upload Image</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-image-cropper [uid]="blogKey" [fileName]="blogKey" [filePath]="blogFilePath" [aspectRatio]="4 / 3" (completed)="onUploadComplete()"></app-image-cropper>
    </div>
</ng-template>
<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="mb-5">Create Blog Page</h1>
        </div>
    </div>
    <div class="row g-3">
        <form [formGroup]="formGroup" (ngSubmit)="save()">
            <div class="row">
                <div class="form-group col-md-6 mb-4">
                    <label>Title</label>
                    <input type="text" name="blogTitle" id="blogTitle" formControlName="blogTitle" placeholder="" class="form-control" required />
                    <div invalidMessage="blogTitle" class="invalid-feedback">{{ formGroup.get('blogTitle') | validationErrors }}</div>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-6 mb-4">
                    <label>Body</label>
                    <textarea type="text" autosize name="blogBody" id="blogBody" formControlName="blogBody" placeholder="" class="form-control" required rows="4"></textarea>
                    <div invalidMessage="blogBody" class="invalid-feedback">{{ formGroup.get('blogBody') | validationErrors }}</div>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-6 mb-4">
                    <label>Link</label>
                    <input type="text" name="blogLink" id="blogLink" formControlName="blogLink" placeholder="" class="form-control" />
                    <div invalidMessage="blogLink" class="invalid-feedback">{{ formGroup.get('blogLink') | validationErrors }}</div>
                </div>
            </div>

            <div *ngIf="!valid && formGroup.invalid" class="alert alert-info">Please complete required fields</div>
            <div *ngIf="error" class="alert alert-warning">
                {{ error }}
            </div>
            <div class="row">
                <div class="col-xl-2 col-lg-3 col-md-4 col-6">
                    <div class="dropzone-wrapper" (click)="openImageModal(template)">
                        <div class="dropzone-desc">
                            <i class="fa fa-upload"></i>
                            Upload Image
                        </div>
                    </div>
                </div>
                <div *ngIf="uploading" class="col-xl-2 col-lg-3 col-md-4 col-6 position-relative">
                    <img src="/assets/images/parking/no_image.png" class="w-100 rounded-image mb-3" alt="Profile" />
                    <div class="image-load-container">
                        <div><small>Processing</small></div>
                        <div><i class="fa fa-circle-notch fa-spin mt-2"></i></div>
                    </div>
                </div>
                <div *ngIf="!uploading" class="col-xl-2 col-lg-3 col-md-4 col-6 position-relative">
                    <ng-container *ngIf="blogImage && blogImage.smallUrl">
                        <img src="{{ blogImage.smallUrl }}" class="w-100 rounded-image mb-3" alt="Profile" />
                        <!--                        <div class="icon-button delete-image-button float-end"><i class="fa fa-times"-->
                        <!--                                                                                  (click)="deleteImage(image, removeImage)"></i>-->
                        <!--                        </div>-->
                    </ng-container>
                </div>
            </div>
            <button [disabled]="loading" type="submit" class="btn btn-primary my-3"><i *ngIf="loading" class="fa fa-circle-notch fa-spin"></i><span *ngIf="!loading">Save</span></button>
        </form>
    </div>
</div>
