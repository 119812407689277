"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OmnisendCreateCartResponse = void 0;
class OmnisendCreateCartResponse {
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
}
exports.OmnisendCreateCartResponse = OmnisendCreateCartResponse;
