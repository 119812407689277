"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Booking = void 0;
const client_model_1 = require("./client.model");
class Booking {
    constructor(source) {
        this.startDate = null;
        if (source) {
            Object.assign(this, source);
        }
        if (source?.$client) {
            this.$client = new client_model_1.Client(source?.$client);
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        delete clone.$merchant;
        delete clone.$client;
        delete clone.$parking;
        delete clone.$occupants;
        delete clone.$payments;
        const subscription = JSON.parse(JSON.stringify(clone));
        if (this.merchantRef) {
            subscription.merchantRef = this.merchantRef;
        }
        if (this.clientRef) {
            subscription.clientRef = this.clientRef;
        }
        if (this.parkingRef) {
            subscription.parkingRef = this.parkingRef;
        }
        if (this.occupantRefs) {
            subscription.occupantRefs = this.occupantRefs;
        }
        subscription.createdAt = this.createdAt;
        subscription.updatedAt = this.updatedAt;
        if (this.startDate) {
            subscription.startDate = this.startDate;
        }
        else {
            subscription.startDate = null;
        }
        return subscription;
    }
}
exports.Booking = Booking;
