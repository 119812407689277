<!--<h5>Landlord Profile Menu</h5>-->

<div class="row">
    <div class="col-12">
        <div *ngIf="authService.merchants.length > 0 && authService.activeMerchant" class="dropdown d-grid">
            <button
                [ngClass]="{ 'dropdown-toggle': authService.merchants.length > 1 }"
                aria-expanded="false"
                class="btn btn-outline-info text-start"
                data-bs-toggle="dropdown"
                id="merchantDropdown"
                type="button"
            >
                <strong><i class="fa fa-user-circle me-2"></i> {{ this.merchant?.companyName | slice: 0:32 }}</strong>
            </button>

            <ul *ngIf="authService.merchants.length > 1" aria-labelledby="merchantDropdown" class="dropdown-menu">
                <ng-container *ngFor="let merchantClaim of authService.merchants">
                    <li *ngIf="merchantClaim.$key != merchant?.$key" class="d-grid">
                        <a (click)="authService.setActiveMerchant(merchantClaim)" class="dropdown-item">{{ merchantClaim.companyName }}</a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</div>

<nav *ngIf="merchant" class="menu">
    <ul class="menu-list">
        <li class="menu-item">
            <a [routerLink]="'/merchant/' + merchantKey" class="menu-link">
                <span class="menu-icon"><i class="fas fa-tachometer-alt"></i></span>
                Dashboard
            </a>
        </li>
        <li class="menu-item">
            <a [routerLink]="'/merchant/' + merchantKey + '/overview'" class="menu-link">
                <span class="menu-icon"><i class="fas fa-user"></i></span>
                Portfolio Consolidation
            </a>
        </li>
        <li class="menu-item">
            <a *ngIf="!merchant.bookuppEnabled" [routerLink]="'/merchant/' + merchantKey + '/bays'" class="menu-link">
                <span class="menu-icon"><i class="fas fa-parking"></i></span>
                Parking Bays
            </a>
        </li>
        <li *ngIf="!merchant.bookuppEnabled" class="menu-item">
            <a [routerLink]="'/merchant/' + merchantKey + '/active'" class="menu-link">
                <span class="menu-icon"><i class="fas fa-car"></i></span>
                Active Tenants
            </a>
        </li>
        <li *ngIf="!merchant.bookuppEnabled" class="menu-item">
            <a [routerLink]="'/merchant/' + merchantKey + '/expired'" class="menu-link">
                <span class="menu-icon"><i class="fas fa-times"></i></span>
                Expired Tenants
            </a>
        </li>
        <li *ngIf="!merchant.bookuppEnabled" class="menu-item">
            <a [routerLink]="'/merchant/' + merchantKey + '/invoices'" class="menu-link">
                <span class="menu-icon"><i class="fas fa-coins"></i></span>
                Payout History
            </a>
        </li>
        <li *ngIf="!merchant.bookuppEnabled" class="menu-item">
            <a [routerLink]="'/merchant/' + merchantKey + '/proforma-invoices'" class="menu-link">
                <span class="menu-icon"><i class="fas fa-file-invoice"></i></span>
                Proforma Invoices <span class="new-promoted">New</span>
            </a>
        </li>
        <li>
            <a class="menu-link" [routerLink]="['/merchant/', merchantKey, 'emails']">
                <span class="menu-icon"><i class="fas fa-envelope"></i></span>
                Email History <span class="new-promoted">New</span>
            </a>
        </li>
        <!--        <li class="menu-item">-->
        <!--            <a class="menu-link" [routerLink]="'/merchant/'+merchantKey+'/bookupp'"  *ngIf="!merchant.bookuppEnabled">-->
        <!--                <span class="menu-icon"><i class="fas fa-calendar-alt"></i></span>-->
        <!--                BookUpp-->
        <!--                <span class="new-promoted">New</span>-->
        <!--            </a>-->
        <!--        </li>-->
        <li class="menu-item">
            <a *ngIf="merchant.bookuppEnabled" [routerLink]="'/merchant/' + merchantKey + '/bookupp-parkings'" class="menu-link">
                <span class="menu-icon"><i class="fas fa-parking"></i></span>
                Parking Bays
            </a>
        </li>
        <li class="menu-item">
            <a *ngIf="merchant.bookuppEnabled" [routerLink]="'/merchant/' + merchantKey + '/bookupp-users'" class="menu-link">
                <span class="menu-icon"><i class="fas fa-user-friends"></i></span>
                Authorized parkers
            </a>
        </li>
        <li class="menu-item">
            <a *ngIf="merchant.bookuppEnabled" [routerLink]="'/merchant/' + merchantKey + '/bookupp-bookings'" class="menu-link">
                <span class="menu-icon"><i class="fas fa-calendar-alt"></i></span>
                Bookings
            </a>
        </li>
        <li class="menu-item">
            <a [routerLink]="'/merchant/' + merchantKey + '/profile'" class="menu-link">
                <span class="menu-icon"><i class="fas fa-cog"></i></span>
                Settings
            </a>
        </li>
        <li class="menu-item">
            <a *ngIf="!merchant.bookuppEnabled" class="menu-link" data-bs-target="#howModal" data-bs-toggle="modal">
                <span class="menu-icon"><i class="fas fa-question"></i></span>
                Learn how ParkUpp works
            </a>
        </li>
        <!--        <li class="menu-item">-->
        <!--            <a class="menu-link" [routerLink]="'/merchant/'+merchantKey+'/changelog'">-->
        <!--                <span class="menu-icon"><i class="fas fa-rss-square"></i></span>-->
        <!--                Latest Dashboard Features-->
        <!--            </a>-->
        <!--        </li>-->
    </ul>
</nav>

<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" id="howModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">How ParkUpp works</h5>
                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
            </div>
            <div class="modal-body">
                <img
                    alt=""
                    class="img-fluid"
                    src="https://firebasestorage.googleapis.com/v0/b/parkupp-test.appspot.com/o/public%2Fwebsite%2FHow%20to%20use%20ParkUpp%20-%20Company.png?alt=media&token=00a28145-05eb-410b-bf70-a0adaf1ff526"
                />
            </div>
        </div>
    </div>
</div>
