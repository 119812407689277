import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { MerchantService } from '../../../services/merchant.service'
import { BankDetails, bankingDetailsPath } from '@parkupp/core'
import { ActivatedRoute, Router } from '@angular/router'
import { collection, collectionSnapshots } from '@angular/fire/firestore'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-merchant-banking-details-list',
    templateUrl: './merchant-banking-details-list.component.html',
    styleUrls: ['./merchant-banking-details-list.component.scss'],
})
export class MerchantBankingDetailsListComponent implements OnInit, OnDestroy {
    @Input() merchantKey: string
    @Input() returnUrl: string
    @Input() bankDetailsModal: boolean
    @Output() onBankDetailsSelected = new EventEmitter<BankDetails>()
    @Output() onBankDetailsFormModal = new EventEmitter<BankDetails>()
    bankDetailsList: BankDetails[]
    bankDetailsSubscription: Subscription

    constructor(private merchantService: MerchantService, private router: Router, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.getMerchantBankDetailsList(this.merchantKey)
    }

    ngOnDestroy(): void {
        if (this.bankDetailsSubscription) {
            this.bankDetailsSubscription.unsubscribe()
        }
    }

    getMerchantBankDetailsList(merchantKey: string) {
        if (this.bankDetailsSubscription) {
            this.bankDetailsSubscription.unsubscribe()
        }
        this.bankDetailsSubscription = collectionSnapshots(collection(this.merchantService.firestore, bankingDetailsPath(merchantKey))).subscribe((documents) => {
            let bankDetailsList: BankDetails[] = []
            documents.forEach((documentData) => {
                let bankDetails: BankDetails = documentData.data() as BankDetails
                bankDetails.$key = documentData.id
                bankDetailsList.push(bankDetails)
            })
            this.bankDetailsList = bankDetailsList
        })
    }

    editBankDetails(bankDetails: BankDetails) {
        this.router.navigate([`/merchant/${this.merchantKey}/bankDetails`], { queryParams: { bankDetailsKey: bankDetails.$key!, returnUrl: this.returnUrl } })
    }

    selectBankDetails(bankDetails: BankDetails) {
        this.onBankDetailsSelected.emit(bankDetails)
    }

    editBankDetailsModal(bankDetail: BankDetails) {
        this.onBankDetailsFormModal.emit(bankDetail)
    }
}
