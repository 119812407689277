<admin-app-header></admin-app-header>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Change Client Password</h4>
        <button (click)="modalRef?.hide()" aria-label="Close" class="btn-close close pull-right" type="button">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form (submit)="onSubmit()" [formGroup]="formGroup">
            <div class="form-group mb-4">
                <label class="form-label">Password</label>

                <input class="form-control" formControlName="password" id="firstName" name="password" placeholder="Password" required type="password" />
                <div class="invalid-feedback" invalidMessage="password">{{ formGroup.get('password') | validationErrors }}</div>
            </div>
            <button [disabled]="loading" class="btn btn-primary mb-3" type="submit"><i *ngIf="loading" class="fa fa-circle-notch fa-spin"></i><span *ngIf="!loading">Save</span></button>
        </form>
        Latest 2000 Parkers
    </div>
</ng-template>

<div class="container-fluid">
    <button (click)="globalService.exportcsv('all-clients')" class="btn btn-info btn-sm text-white float-end px-3 py-1 my-2"><i class="fas fa-file-csv"></i> Export to CSV</button>
    <div class="row header">
        <div class="col-4">
            <h2 class="">Latest 2000 Parkers</h2>
        </div>
    </div>
    <div class="content col-lg-12">
        <table class="table table-striped table-hover table-sm" id="all-clients">
            <thead>
                <tr>
                    <th scope="col">Updated</th>
                    <th scope="col">Company</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Location</th>
                    <th scope="col">Last Login</th>
                    <th scope="col">Functions</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let client of clients">
                    <tr *ngIf="client.$email || client.$phoneNumber">
                        <td>
                            {{ client.updatedAt | daysAgo }}
                        </td>
                        <td>
                            {{ client.companyName }}
                        </td>
                        <td>
                            <a [routerLink]="['/client/', client.$key]">
                                <span [ngClass]="{ undefined: !client.firstName }">{{ client.firstName || 'Not set' }}</span>
                            </a>
                            <a [routerLink]="['/client/', client.$key]">
                                {{ client.lastName }}
                            </a>
                            &nbsp;
                            <span *ngIf="lastLoginwithin60Days(client)" class="badge bg-success">Recently Active</span>
                        </td>
                        <td>
                            {{ client.$email }}
                        </td>
                        <td>
                            {{ client.$phoneNumber }}
                        </td>
                        <td>
                            {{ client.broadLocation }}
                        </td>
                        <td>
                            {{ client.lastLogin | formatDate }}
                        </td>
                        <td>
                            <a (click)="openModal(template, client.$key!)"> Change Password </a>
                        </td>
                        <!--                <td>-->
                        <!--                    <a (click)="deputize(client)">-->
                        <!--                        Deputize-->
                        <!--                    </a>-->
                        <!--                </td>-->
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
