"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminClient = void 0;
const enums_1 = require("./enums/enums");
class AdminClient {
    constructor(source, key) {
        this.leadStatus = enums_1.LeadStatus.NEW;
        if (source) {
            Object.assign(this, source);
        }
        if (key) {
            this.$key = key;
        }
    }
    get fullName() {
        return this.firstName + ' ' + this.lastName;
    }
    get companyOrFullName() {
        if (this.companyName) {
            return this.companyName;
        }
        else {
            return this.fullName;
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        const client = JSON.parse(JSON.stringify(clone));
        client.createdAt = this.createdAt;
        client.updatedAt = this.updatedAt;
        client.authUserCreatedAt = this.authUserCreatedAt;
        client.clientProfileCreatedAt = this.clientProfileCreatedAt;
        if (this.activeSubscriptionExpiry !== undefined) {
            client.activeSubscriptionExpiry = this.activeSubscriptionExpiry;
        }
        return client;
    }
    getLeadStatusClass() {
        switch (this.leadStatus) {
            case enums_1.LeadStatus.COLD:
                return 'bg-info';
            case enums_1.LeadStatus.IN_PROGRESS:
                return 'bg-warning';
            case enums_1.LeadStatus.HOT:
                return 'bg-primary';
            case enums_1.LeadStatus.NEW:
                return 'bg-secondary';
            case enums_1.LeadStatus.CONVERTED:
                return 'bg-success';
            case enums_1.LeadStatus.CLOSED:
                return 'bg-danger';
            default:
                return 'bg-secondary';
        }
    }
}
exports.AdminClient = AdminClient;
