<admin-app-header></admin-app-header>
<div class="container-fluid">
    <h2>Payments possibly pending settlement</h2>

    <div class="content col-lg-12">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Paygate Reference</th>
                    <th scope="col">Transaction ID</th>
                    <th scope="col">Status</th>
                    <th scope="col">Failed</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Subscription</th>
                    <th scope="col">Client</th>
                    <!-- <th scope="col">Parking</th> -->
                    <!-- <th scope="col">Merchant</th> -->
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let payment of payments">
                    <td>
                        {{ payment.createdAt.toDate() | date: 'dd/MM/yyyy HH:mm' }}
                    </td>
                    <td>
                        <a [routerLink]="['/admin/payment', payment.$key]">
                            {{ payment.$key }}
                        </a>
                    </td>
                    <td>
                        {{ payment.transactionId }}
                    </td>
                    <td>
                        <span [ngClass]="payment.statusBadgeColour" class="badge">
                            {{ payment.status }}
                        </span>
                    </td>
                    <td></td>
                    <td>R{{ payment.amountInCents | formatCents }}</td>
                    <td>
                        <a [routerLink]="['/admin/subscription', payment.subscriptionRef.id]">
                            {{ payment.subscriptionRef.id }}
                        </a>
                    </td>
                    <td>
                        <a [routerLink]="['/client/', payment.$client?.$key]">
                            {{ payment.$client?.firstName || '' }}
                            {{ payment.$client?.lastName || '' }}
                        </a>
                    </td>
                    <!-- <td>
                        <a [routerLink]="['/parking', payment.$parking?.$key]">
                            {{ payment.$parking?.title || '' }}
                        </a>
                    </td>
                    <td>
                        <a [routerLink]="['/merchant/', payment.$merchant?.$key]">
                            {{ payment.$merchant?.companyName || '' }}
                            <span *ngIf="!payment.$merchant?.companyName" class="undefined">Undefined</span>
                        </a>
                    </td> -->
                    <td>
                        <a class="badge bg-danger text-white" (click)="voidPayment(payment.$key!)">Void Payment</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
