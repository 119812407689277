import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'prettyJson',
})
export class PrettyJsonPipe implements PipeTransform {
    transform(value: any): string {
        if (!value) return ''
        // Check if the value is a string and try to parse it as JSON
        if (typeof value === 'string') {
            try {
                // Attempt to parse the string as JSON
                const obj = JSON.parse(value)
                // Format the parsed object
                return JSON.stringify(obj, null, 2)
            } catch (error) {
                // If parsing fails, return the original string
                console.error('PrettyJsonPipe error parsing string:', error)
                return value
            }
        }
        // If the value is already an object, format it directly
        try {
            return JSON.stringify(value, null, 2)
        } catch (error) {
            console.error('PrettyJsonPipe error:', error)
            return ''
        }
    }
}
