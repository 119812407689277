import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AdminClient, LeadClosedReason, LeadStatus, LeadUser } from '@parkupp/core'
import { AdminClientService } from 'src/app/services/admin-client.service'
import { GlobalService } from 'src/app/services/global.service'
import Swal from 'sweetalert2'
@Component({
    selector: 'app-parker-table',
    templateUrl: './parker-table.component.html',
    styleUrls: ['./parker-table.component.scss'],
})
export class ParkerTableComponent implements OnInit {
    @Input() filteredAdminClients: AdminClient[]

    leadStatuses = Object.values(LeadStatus)
    leadUsers = Object.values(LeadUser)
    sortColumn: string = ''
    sortDirection: 'asc' | 'desc' = 'asc'

    constructor(public globalService: GlobalService, private adminClientService: AdminClientService, private router: Router) {}

    ngOnInit(): void {}

    sortData(column: string) {
        if (this.sortColumn === column) {
            this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
        } else {
            this.sortColumn = column
            this.sortDirection = 'asc'
        }

        this.filteredAdminClients.sort((a: any, b: any) => {
            const valueA = a[column]
            const valueB = b[column]

            if (valueA < valueB) {
                return this.sortDirection === 'asc' ? -1 : 1
            } else if (valueA > valueB) {
                return this.sortDirection === 'asc' ? 1 : -1
            } else {
                return 0
            }
        })
    }
    async onContextMenu(event: MouseEvent, adminClient: AdminClient) {
        this.globalService.contextMenuItems = []
        this.globalService.showContextMenu(event)

        this.globalService.contextMenuItems.push({
            label: 'Edit',
            action: () => this.router.navigate(['/admin/clients-private', adminClient.$key]),
        })

        const leadSubMenuItems: any = this.leadStatuses.map((status) => {
            return {
                label: status,
                action: () => this.updateLeadStatus(adminClient, status as LeadStatus),
            }
        })

        this.globalService.contextMenuItems.push({
            label: 'Update Lead Status',
            subMenuItems: leadSubMenuItems,
        })

        const leadUserSubMenuItems: any = this.leadUsers.map((user) => {
            return {
                label: user,
                action: () => this.updateLeadUser(adminClient, user),
            }
        })
        leadUserSubMenuItems.push({
            label: 'None',
            action: () => this.updateLeadUser(adminClient, null),
        })
        this.globalService.contextMenuItems.push({
            label: 'Update Lead User',
            subMenuItems: leadUserSubMenuItems,
        })
    }

    async updateLeadStatus(adminClient: AdminClient, status: LeadStatus) {
        const leadClosedReasons = Object.values(LeadClosedReason)

        if (status === LeadStatus.CLOSED) {
            const { value: reason } = await Swal.fire({
                title: 'Select a reason for closing',
                input: 'select',
                inputOptions: leadClosedReasons.reduce((options: any, reason: any) => {
                    options[reason] = reason
                    return options
                }, {}),
                inputPlaceholder: 'Select a reason',
                showCancelButton: true,
            })

            if (reason) {
                adminClient.leadClosedReason = reason
                await this.adminClientService.update(adminClient)
            }
        } else if ((status === LeadStatus.IN_PROGRESS || status === LeadStatus.COLD || status === LeadStatus.HOT) && (!adminClient.userResponsible || !adminClient.numberOfBays)) {
            const { value: formValues } = await Swal.fire({
                title: 'Update Details',
                html: `
                <div class="form-group">
                    <label for="user-select">Select a user responsible:</label>
                    <select id="user-select" class="form-control">
                        ${this.leadUsers.map((user) => `<option value="${user}">${user}</option>`).join('')}
                    </select>
                </div>
                <div class="form-group mt-3">
                    <label for="bays-input">Enter number of bays:</label>
                    <input id="bays-input" type="number" class="form-control" placeholder="Number of bays" value="${adminClient.numberOfBays}">
                </div>
            `,
                focusConfirm: false,
                showCancelButton: true,
                preConfirm: () => {
                    const user = (document.getElementById('user-select') as HTMLSelectElement).value
                    const bays = (document.getElementById('bays-input') as HTMLInputElement).value
                    return { user, bays }
                },
            })

            if (formValues) {
                if (formValues.user) {
                    adminClient.userResponsible = formValues.user
                }
                if (formValues.bays) {
                    adminClient.numberOfBays = parseInt(formValues.bays, 10)
                }
            }
        }

        adminClient.leadStatus = status
        await this.adminClientService.update(adminClient)
    }

    async updateLeadUser(adminClient: AdminClient, user: any) {
        adminClient.userResponsible = user
        await this.adminClientService.update(adminClient)
    }

    callPhone(phoneNumber: string) {
        window.open(`tel:${phoneNumber.replace(/\s+/g, '')}`, '_blank')
    }

    sendWhatsApp(phoneNumber: string) {
        window.open(`https://wa.me/${phoneNumber.replace(/\s+/g, '')}`, '_blank')
    }

    sendEmail(email: string) {
        window.open(`mailto:${email}`, '_blank')
    }
}
