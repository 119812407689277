"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceStatus = void 0;
var InvoiceStatus;
(function (InvoiceStatus) {
    InvoiceStatus["DUE"] = "due";
    InvoiceStatus["PAID"] = "paid";
    InvoiceStatus["REFUNDED"] = "refunded";
    InvoiceStatus["CANCELLED"] = "cancelled";
})(InvoiceStatus = exports.InvoiceStatus || (exports.InvoiceStatus = {}));
