import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AdminClient, LeadCity, LeadClosedReason, LeadStatus, LeadUser } from '@parkupp/core'
import { AdminClientService } from 'src/app/services/admin-client.service'

@Component({
    selector: 'app-edit-parker',
    templateUrl: './edit-parker.component.html',
    styleUrls: ['./edit-parker.component.scss'],
})
export class EditParkerComponent {
    LeadStatus = LeadStatus
    editParkerForm: FormGroup
    adminClientKey: string
    adminClient: AdminClient
    southAfricanCities: string[] = Object.values(LeadCity)
    leadStatuses: string[] = Object.values(LeadStatus)
    leadClosedReasons: string[] = Object.values(LeadClosedReason)
    leadUsers: string[] = Object.values(LeadUser)

    constructor(private adminClientService: AdminClientService, private activeRoute: ActivatedRoute, private router: Router) {
        this.adminClientKey = this.activeRoute.snapshot.params['adminClientKey']

        this.editParkerForm = new FormGroup({
            numberOfBays: new FormControl(''),
            city: new FormControl(''),
            userResponsible: new FormControl(''),
            leadStatus: new FormControl(''),
            leadClosedReason: new FormControl(''),
        })

        if (this.adminClientKey) {
            this.adminClientService.get(this.adminClientKey).subscribe((client) => {
                this.adminClient = new AdminClient(client)
                console.log(this.adminClient)

                this.editParkerForm.patchValue(this.adminClient)
            })
        }
    }

    onSubmit(): void {
        Object.assign(this.adminClient, this.editParkerForm.value)

        this.adminClientService.update(this.adminClient).then(() => {
            this.router.navigate(['/admin/parker-crm'])
        })
    }
}
