import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { LogSearch } from '@parkupp/core'

@Component({
    selector: 'app-admin-searches-table',
    templateUrl: './admin-searches-table.component.html',
    styleUrls: ['./admin-searches-table.component.scss'],
})
export class AdminSearchesTableComponent implements OnInit {
    cityData: any[]
    SA_PROVINCES = ['Eastern Cape', 'Free State', 'Gauteng', 'KwaZulu-Natal', 'Limpopo', 'Mpumalanga', 'North West', 'Northern Cape', 'Western Cape']

    constructor(private adminService: AdminService) {}

    ngOnInit(): void {
        this.adminService.getSearchLogs().then((searches: LogSearch[]) => {
            this.cityData = this.processData(searches)
        })
    }

    private processData(searches: any[]): any[] {
        const cityDataMap = new Map<string, number>()

        searches.forEach((search) => {
            const city = search.city
            const count = cityDataMap.get(city) || 0
            cityDataMap.set(city, count + 1)
        })

        return Array.from(cityDataMap.entries())
            .map(([name, count]) => ({ name, count }))
            .sort((a, b) => b.count - a.count)
    }
}
