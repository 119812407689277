import { Component, OnInit } from '@angular/core'
import { GlobalService } from '../../../services/global.service'

@Component({
    selector: 'app-bookupp-footer',
    templateUrl: './bookupp-footer.component.html',
    styleUrls: ['./bookupp-footer.component.scss'],
})
export class BookuppFooterComponent implements OnInit {
    bgClass = 'bg-dark text-light'

    constructor(public globalService: GlobalService) {
        if (this.globalService.whitelabeled) {
            document.documentElement.style.setProperty('--whitelabel-color', this.globalService.whitelabelColour)
        } else {
            document.documentElement.style.setProperty('--whitelabel-color', '#383838')
        }

        if (this.globalService.subdomain == 'redefine') {
            this.bgClass = 'd-none'
        }
    }

    ngOnInit(): void {}
}
