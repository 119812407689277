"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogEmail = void 0;
class LogEmail {
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
    parse() {
        // Deep clone the object
        const clone = JSON.parse(JSON.stringify(this));
        // Manually reassign the properties that should not be stringified
        clone.createdAt = this.createdAt;
        if (this.clientRef) {
            clone.clientRef = this.clientRef;
        }
        return clone;
    }
}
exports.LogEmail = LogEmail;
