import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { serverTimestamp } from '@angular/fire/firestore'
import { Client, ContractStatus, Merchant, Parking, Plan, Subscription, SubscriptionType, Term } from '@parkupp/core'
import { AdminService } from '../../../services/admin.service'
import * as moment from 'moment'
import * as firestore from 'firebase/firestore'
import { ActivatedRoute, Router } from '@angular/router'
import { MerchantService } from '../../../services/merchant.service'
import { ParkingService } from '../../../services/parking.service'
import { ClientService } from '../../../services/client.service'
import { SubscriptionService } from '../../../services/subscription.service'

@Component({
    selector: 'app-admin-subscription-create',
    templateUrl: './admin-subscription-create.component.html',
    styleUrls: ['./admin-subscription-create.component.scss'],
})
export class AdminSubscriptionCreateComponent implements OnInit {
    @Input() clientKeyInput: string
    subscriptionKey: string
    formData: FormGroup
    formLoading: boolean = false
    subscription: Subscription
    merchants: Merchant[]
    parkingLookup: Parking[]
    clientLookup: Client[]
    parkingKey: string
    merchantKey: string
    clientKey: string

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private adminService: AdminService,
        private activatedRoute: ActivatedRoute,
        private merchantService: MerchantService,
        private parkingService: ParkingService,
        private clientService: ClientService,
        private subscriptionService: SubscriptionService
    ) {
        this.subscriptionKey = this.activatedRoute.snapshot.params['subscriptionKey']

        this.formData = this.formBuilder.group({
            startDate: [moment().add(1, 'M').startOf('month').format('yyyy-MM-DD'), []],
            endDate: [moment().add(1, 'M').endOf('month').format('yyyy-MM-DD'), []],
            baysBooked: [1, Validators.required],
            feeInRands: [null, Validators.required],
            contractLength: [1, Validators.required],
            parkingName: [''],
            clientName: [''],
            confirmMerchantCompanyName: [''],
            confirmClientEmail: [''],
            confirmClientPhone: [''],
            confirmMonthlyCost: [''],
        })

        this.formData.controls['contractLength'].valueChanges.subscribe((value) => this.formChanges({ contractLength: value }))
        this.formData.controls['startDate'].valueChanges.subscribe((value) => this.formChanges({ startDate: value }))

        this.subscription = new Subscription()

        this.merchantService.list().subscribe((merchants) => {
            this.merchants = merchants
        })
        this.parkingService.list().subscribe((parkings) => {
            this.parkingLookup = parkings
        })
        this.clientService.list().subscribe((clients) => {
            this.clientLookup = clients
        })
    }

    ngOnInit(): void {}

    formChanges(changes: { contractLength?: number; startDate?: number; baysBooked?: number; feeInCents?: number }) {
        let contractLength = changes.contractLength ? changes.contractLength : this.formData.value.contractLength
        let startDate = changes.startDate ? changes.startDate : this.formData.value.startDate

        if (changes.contractLength || changes.startDate) {
            this.updateEndDate(Term.MONTHLY, startDate, contractLength)
        }

        const bays = this.formData.value.baysBooked
        const overrideCost = this.formData.value.feeInCents
        this.formData.controls['confirmMonthlyCost'].setValue(bays * overrideCost)
    }

    updateEndDate(term: string, startDate: Date, contractLength: any) {
        let lengthUnit = 'M'
        if (term == Term.MONTHLY) {
            lengthUnit = 'M'
        }
        if (term == Term.DAILY) {
            lengthUnit = 'd'
        }
        if (term == Term.ONCEOFF) {
            lengthUnit = 'd'
        }

        const newEndDate = moment(startDate).add(contractLength, lengthUnit).subtract(1, 'day')
        this.formData.controls['endDate'].setValue(newEndDate.format('yyyy-MM-DD'))
    }

    onParkingSelect(selectedParking: Parking): void {
        this.parkingKey = selectedParking.$key!
        this.merchantKey = selectedParking.merchantRef.id

        this.formData.controls['confirmMerchantCompanyName'].setValue(selectedParking.denormMerchantCompanyName)
        if (selectedParking.plans && selectedParking.plans[0]) {
            this.formData.controls['feeInRands'].setValue(selectedParking.plans[0].feeInCents / 100)
        }

        this.subscription.parkingRef = this.parkingService.getDocRef(this.parkingKey) as any
        this.subscription.denormParkingTitle = selectedParking.title

        this.subscription.merchantRef = this.merchantService.getDocRef(this.merchantKey) as any
        this.subscription.denormMerchantCompanyName = selectedParking.denormMerchantCompanyName
    }

    onClientSelect(selectedClient: Client): void {
        this.clientService.adminGetEmailFromClientKey(selectedClient.$key!).subscribe((privateClient) => {
            this.formData.controls['confirmClientEmail'].setValue(privateClient.email)
            this.formData.controls['confirmClientPhone'].setValue(privateClient.phoneNumber)
        })

        this.subscription.clientRef = this.clientService.getDocRef(selectedClient.$key!) as any
        this.subscription.denormClientFirstName = selectedClient.firstName
        this.subscription.denormClientLastName = selectedClient.lastName
    }

    onSubmit() {
        this.formLoading = true

        this.subscription.type = SubscriptionType.PAYFAST
        this.subscription.createdAt = serverTimestamp() as firestore.Timestamp
        this.subscription.updatedAt = serverTimestamp() as firestore.Timestamp
        this.subscription.baysBooked = this.formData.value.baysBooked
        this.subscription.startDate = firestore.Timestamp.fromDate(new Date(this.formData.value.startDate))
        this.subscription.endDate = firestore.Timestamp.fromDate(new Date(this.formData.value.endDate))
        this.subscription.status = ContractStatus.pendingClientApproval
        this.subscription.period = this.formData.value.contractLength

        if (this.subscription.period > 1) {
            let startDate = new Date(this.formData.value.startDate)
            // startDate.setDate(startDate.getDate() + 30)
            // this.subscription.nextDebitDate = firestore.Timestamp.fromDate(startDate)
            this.subscription.nextDebitDate = firestore.Timestamp.fromDate(startDate)
        }

        this.subscription.plan = new Plan({
            feeInCents: this.formData.value.feeInRands * 100,
            term: Term.MONTHLY,
        })

        this.subscriptionService.create(this.subscription).then(async () => {
            this.formLoading = false
            await this.router.navigate([`/admin/subscriptions`])
            return
        })
    }
}
