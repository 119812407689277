import { Pipe, PipeTransform } from '@angular/core'
import { Image } from '@parkupp/core'

@Pipe({ name: 'sortImagesByPrimaryImage' })
export class SortImagesByPrimaryImagePipe implements PipeTransform {
    transform(value: Image[], imagePrimaryUrl: string): Image[] {
        return SortImagesByPrimaryImagePipe.sortImages(value, imagePrimaryUrl)
    }

    static sortImages(images: Image[], imagePrimaryUrl: string): Image[] {
        return images.sort((a, b) => {
            let first = imagePrimaryUrl
            return a.originalUrl == first ? -1 : b.originalUrl == first ? 1 : 0
        })
    }
}
