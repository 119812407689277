import { Component, OnInit } from '@angular/core'
import { GlobalService } from 'src/app/services/global.service'

@Component({
    selector: 'admin-app-header',
    templateUrl: './admin-header.component.html',
    styleUrls: ['./admin-header.component.scss'],
})
export class AdminHeaderComponent implements OnInit {
    constructor(public globalService: GlobalService) {}

    ngAfterViewInit() {
        document.addEventListener('click', this.closeContextMenu.bind(this))
    }

    ngOnInit(): void {}

    closeContextMenu(event: MouseEvent) {
        this.globalService.hideContextMenu()
    }
}
