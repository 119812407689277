import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { AccountType, Bank, BankDetails, Blog, blogsPath, blogImagePath, Country, Merchant, Image } from '@parkupp/core'
import { Subscription } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { AdminService } from '../../../services/admin.service'
import { addDoc, collection, doc, docData, serverTimestamp, updateDoc } from '@angular/fire/firestore'
import * as firestore from 'firebase/firestore'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import * as uuid from 'uuid'

@Component({
    selector: 'admin-blog-create',
    templateUrl: './admin-blog-create.component.html',
    styleUrls: ['./admin-blog-create.component.scss'],
})
export class AdminBlogCreateComponent implements OnInit {
    @Input() bankDetails: BankDetails
    @Input() bankDetailsModal: boolean
    @Output() onSaved = new EventEmitter<BankDetails>()
    merchantKey: string
    bankDetailsKey: string
    formGroup: FormGroup
    valid: boolean = true
    merchant: Merchant
    loading: boolean = false
    error: string | null
    accountTypes: AccountType[]
    banks: Bank[]
    countries: Country[]
    bankDetailsSubscription: Subscription
    returnUrl: string
    blogKey: string
    blog: Blog
    uploading: boolean
    blogImage: Image | null
    oldBlogImage: Image | null
    modalRef?: BsModalRef
    blogFilePath: string

    constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private adminService: AdminService, private activatedRoute: ActivatedRoute, private router: Router) {
        this.formGroup = this.formBuilder.group({
            blogTitle: [this.blog?.title, [Validators.required]],
            blogBody: [this.blog?.body, [Validators.required]],
            blogLink: [this.blog?.link, []],
        })

        this.blogKey = this.activatedRoute.snapshot.params['blogKey']

        if (!this.blogKey) {
            this.blog = new Blog()
            this.save()
        } else {
            this.getBlog()
        }
    }

    getBlog() {
        docData(doc(this.adminService.firestore, blogsPath(), this.blogKey)).subscribe((documentData: any) => {
            this.blog = new Blog(documentData)
            this.blog.$key = this.blogKey

            if (this.blog.title && this.blog.body) {
                this.formGroup.patchValue({
                    blogTitle: this.blog.title,
                    blogBody: this.blog.body,
                    blogLink: this.blog.link,
                })
            }
        })
        this.subscribeToBlogImage()
    }

    subscribeToBlogImage() {
        docData(doc(this.adminService.firestore, blogsPath(), this.blogKey)).subscribe((documentData: any) => {
            const blog = new Blog(documentData)
            this.blogImage = blog.blogImage
            if (this.blogImage && this.blogImage.smallUrl) {
                if (!this.oldBlogImage || this.oldBlogImage.smallUrl != this.blogImage.smallUrl) {
                    this.uploading = false
                }
            }
        })
    }

    ngOnInit(): void {
        if (this.blogKey) {
            this.blogFilePath = blogImagePath(this.blogKey)
        }
    }

    save() {
        if (!this.blogKey) {
            this.blog.createdAt = serverTimestamp() as firestore.Timestamp
            this.blog.updatedAt = serverTimestamp() as firestore.Timestamp

            addDoc(collection(this.adminService.firestore, blogsPath()), this.blog.parse())
                .then((reference) => {
                    this.blogKey = reference.id
                    this.getBlog()
                })
                .catch((error) => {
                    this.error = error['message']
                    this.loading = false
                })
        } else {
            if (this.formGroup.valid) {
                this.loading = true

                this.blog.updatedAt = serverTimestamp() as firestore.Timestamp
                console.log(this.formGroup.value)
                this.blog.title = this.formGroup.value.blogTitle
                this.blog.body = this.formGroup.value.blogBody
                this.blog.link = this.formGroup.value.blogLink

                // IF exists
                if (this.blog.$key) {
                    updateDoc(doc(this.adminService.firestore, blogsPath(), this.blog.$key), this.blog.parse())
                        .then(() => {
                            this.router.navigate([`/admin/blog`])
                        })
                        .catch((error) => {
                            this.error = error['message']
                            this.loading = false
                        })
                }
            } else {
                this.valid = false
                Object.keys(this.formGroup.controls).forEach((field) => {
                    const control = this.formGroup.controls[field]
                    if (!control.valid) {
                        control.markAsTouched()
                        control.markAsDirty()
                    }
                })
                this.formGroup.disable()
                this.formGroup.enable()
            }
        }
    }

    onUploadComplete() {
        this.uploading = true
        this.oldBlogImage = this.blogImage
        this.blogImage = null
        this.closeSaveFormModal()
    }

    closeSaveFormModal() {
        this.modalRef?.hide()
    }

    openImageModal(template: TemplateRef<any>) {
        // this.blogKey = uuid.v4()
        this.blogFilePath = blogImagePath(this.blogKey)
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' })
    }
}
