import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { HttpClient } from '@angular/common/http'
import { collection, collectionData, docData, orderBy, query, where } from '@angular/fire/firestore'
import { BankDetails, Client, Merchant, Parking, Payment, paymentsPath, Subscription } from '@parkupp/core'
import * as moment from 'moment'

@Component({
    selector: 'app-admin-list-settlements-page',
    templateUrl: './admin-list-settlements-page.component.html',
    styleUrls: ['./admin-list-settlements-page.component.scss'],
})
export class AdminListSettlementsPageComponent implements OnInit {
    payments: Payment[]
    firstDay: Date
    lastDay: Date

    constructor(private adminSvc: AdminService, private http: HttpClient) {
        this.firstDay = moment().subtract(1, 'months').startOf('month').toDate()
        this.lastDay = moment().startOf('month').toDate()

        const ref = query(
            collection(this.adminSvc.firestore, paymentsPath()),
            orderBy('createdAt', 'asc'),
            where('settled', '==', true),
            where('createdAt', '>=', this.firstDay),
            where('createdAt', '<', this.lastDay)
        )

        collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
            let payments: Payment[] = []
            documents.forEach((documentData) => {
                let payment = new Payment(documentData)
                payment.$key = documentData.documentId

                if (documentData.clientRef) {
                    docData(documentData.clientRef).subscribe((data) => {
                        payment.$client = new Client(data)
                        payment.$client.$key = documentData.clientRef.id
                    })
                }
                if (documentData.subscriptionRef) {
                    docData(documentData.subscriptionRef).subscribe((data) => {
                        payment.$subscription = new Subscription(data)
                        payment.$subscription.$key = documentData.subscriptionRef.id
                    })
                }
                if (documentData.parkingRef) {
                    docData(documentData.parkingRef).subscribe((parkingDocData) => {
                        payment.$parking = new Parking(parkingDocData)
                        payment.$parking.$key = documentData.parkingRef.id

                        if (payment.$parking && parkingDocData['bankDetailsRef']) {
                            docData(parkingDocData['bankDetailsRef']).subscribe((bankDetailsDocData) => {
                                payment.$parking!.$bankDetails = new BankDetails(bankDetailsDocData)
                                payment.$parking!.$bankDetails.$key = parkingDocData['bankDetailsRef']
                            })
                        }
                    })
                }
                if (documentData.merchantRef) {
                    docData(documentData.merchantRef).subscribe((data) => {
                        payment.$merchant = new Merchant(data)
                        payment.$merchant.$key = documentData.merchantRef.id
                    })
                }
                // if (documentData.settlementRefs) {
                //     docData(documentData.merchantRef, { idField: 'documentKey' }).subscribe((data) => {
                //         payment.$merchant = data as Merchant
                //         payment.$merchant.$key = data['documentKey']
                //     })
                // }

                payments.push(payment)
            })
            this.payments = payments
        })
    }

    ngOnInit(): void {}
}
