import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { collectionSnapshots, docSnapshots } from '@angular/fire/firestore'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BankDetails, Merchant, AccountType, Bank, Country, bankingDetailsPath } from '@parkupp/core'
import { collection, doc } from 'firebase/firestore'
import { Subscription } from 'rxjs'
import { MerchantService } from 'src/app/services/merchant.service'

@Component({
    selector: 'app-merchant-banking-details-old',
    templateUrl: './merchant-banking-details-old.component.html',
    styleUrls: ['./merchant-banking-details-old.component.scss'],
})
export class MerchantBankingDetailsOldComponent implements OnInit {
    @Input() merchantKey: string
    @Output() onBankDetailsSelected = new EventEmitter<BankDetails>()
    @Output() onBankDetailsFormModal = new EventEmitter<BankDetails>()
    bankDetailsList: BankDetails[]
    bankDetailsSubscription: Subscription

    constructor(private merchantService: MerchantService, private router: Router, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.getMerchantBankDetailsList(this.merchantKey)
    }

    ngOnDestroy(): void {
        if (this.bankDetailsSubscription) {
            this.bankDetailsSubscription.unsubscribe()
        }
    }

    getMerchantBankDetailsList(merchantKey: string) {
        if (this.bankDetailsSubscription) {
            this.bankDetailsSubscription.unsubscribe()
        }
        this.bankDetailsSubscription = collectionSnapshots(collection(this.merchantService.firestore, bankingDetailsPath(merchantKey))).subscribe((documents) => {
            let bankDetailsList: BankDetails[] = []
            documents.forEach((documentData) => {
                let bankDetails: BankDetails = documentData.data() as BankDetails
                bankDetails.$key = documentData.id
                bankDetailsList.push(bankDetails)
            })
            this.bankDetailsList = bankDetailsList
        })
    }
}
