"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingEntry = void 0;
class TrackingEntry {
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        const json = JSON.parse(JSON.stringify(clone));
        if (this.parkingRef)
            json.parkingRef = this.parkingRef;
        json.createdAt = this.createdAt;
        return json;
    }
}
exports.TrackingEntry = TrackingEntry;
