import { Pipe, PipeTransform } from '@angular/core'
import { AbstractControl } from '@angular/forms'

@Pipe({ name: 'validationErrors' })
export class ValidationErrorsPipe implements PipeTransform {
    transform(formControl: AbstractControl | null): string {
        return formControl?.hasError('required')
            ? 'Required'
            : formControl?.hasError('email')
            ? 'Not a valid email'
            : formControl?.hasError('maxlength')
            ? 'Exceeds maximum length'
            : formControl?.hasError('max')
            ? 'Exceeds the maximum value'
            : formControl?.hasError('min')
            ? 'Does not meet the minimum value'
            : formControl?.hasError('password')
            ? 'Password must contain at least 6 alpha numeric mixed case characters.'
            : ''
    }
}
