<admin-app-header></admin-app-header>

<div class="container mt-2">
    <div class="row">
        <div class="col-12">
            <h1 class="mb-3">Create Once Off Invoice</h1>
        </div>
    </div>
    <div class="col-12">
        <form (ngSubmit)="onSubmit()" [formGroup]="formData" autocomplete="off" novalidate>
            <div class="row">
                <div class="form-group col-4">
                    <label>Type</label>
                    <select class="form-control" formControlName="paymentMethodType">
                        <option value="">Select Payment Method</option>
                        <option [value]="SubscriptionType.PAYFAST">PayFast</option>
                        <option [value]="SubscriptionType.EFT">EFT</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="form-group col-4">
                    <label>Client Name </label>
                    <input
                        (typeaheadOnSelect)="onClientSelect($event.item)"
                        [typeaheadItemTemplate]="clientTemplate"
                        [typeaheadOptionField]="'firstName'"
                        [typeahead]="clientLookup"
                        class="form-control"
                        formControlName="clientName"
                        placeholder="Search for a Client"
                        type="text"
                    />
                    <ng-template #clientTemplate let-index="index" let-model="item">
                        {{ model.firstName }} {{ model.lastName }}
                        <ng-container *ngIf="model.companyName"> - {{ model.companyName }} </ng-container>
                    </ng-template>
                </div>

                <div class="form-group col-4">
                    <label>Confirm Client Email</label>
                    <input DISABLED class="form-control" formControlName="confirmClientEmail" name="confirmClientEmail" type="text" />
                </div>
                <div class="form-group col-4">
                    <label>Confirm Client Phone</label>
                    <input DISABLED class="form-control" formControlName="confirmClientPhone" name="confirmClientPhone" type="text" />
                </div>
            </div>

            <div class="row mt-3">
                <div class="form-group col-6">
                    <label>Parking Name </label>
                    <input
                        (typeaheadOnSelect)="onParkingSelect($event.item)"
                        [typeaheadItemTemplate]="parkingTemplate"
                        [typeaheadOptionField]="'title'"
                        [typeahead]="parkingLookup"
                        class="form-control"
                        formControlName="parkingName"
                        placeholder="Search for a Parking"
                        type="text"
                    />
                    <ng-template #parkingTemplate let-index="index" let-model="item">
                        {{ model.title }}
                    </ng-template>
                </div>

                <div class="form-group col-6">
                    <label>Portfolio</label>
                    <input DISABLED class="form-control" formControlName="confirmMerchantCompanyName" name="confirmMerchantCompanyName" type="text" />
                </div>
            </div>

            <div class="row mt-3">
                <div class="form-group col-6">
                    <label>Line Item Description<span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="lineItemDescription" name="lineItemDescription" type="text" />
                </div>

                <div class="form-group col-6">
                    <label>Once Off Price (Including VAT)<span class="text-danger">*</span></label>
                    <div class="input-group">
                        <div class="input-group-text">
                            <span>R</span>
                        </div>
                        <input class="form-control" formControlName="feeInRands" name="feeInRands" type="number" />
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="form-group col-6">
                    <button [disabled]="!formData.valid || formLoading" class="btn btn-primary my-3" type="submit">Create Invoice</button>
                </div>
            </div>
        </form>
    </div>
</div>
