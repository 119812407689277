<admin-app-header></admin-app-header>
<div class="container-fluid">
    <h1>Settlements</h1>
    <small>From: {{ firstDay.toDateString() }} to {{ lastDay.toDateString() }}</small>

    <div class="content col-lg-12">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">Key</th>
                    <th scope="col">Paygate Reference</th>
                    <th scope="col">Total</th>
                    <th scope="col">Payout</th>
                    <th scope="col">Client</th>
                    <th scope="col">Parking</th>
                    <th scope="col">Merchant</th>
                    <th scope="col">Bank</th>
                    <th scope="col">Type</th>
                    <th scope="col">Account Name</th>
                    <th scope="col">Account Number</th>
                    <!--                <th scope="col">billingAddressCity</th>-->
                    <!--                <th scope="col">billingAddressSuburb</th>-->
                    <!--                <th scope="col">billingAddressStreetNumber</th>-->
                    <!--                <th scope="col">billingAddressStreetAddress</th>-->
                    <!--                <th scope="col">billingAddressProvince</th>-->
                    <!--                <th scope="col">billingAddressPostalCode</th>-->
                    <!--                <th scope="col">billingAddressCountry</th>-->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let payment of payments">
                    <td>
                        {{ payment.createdAt | formatDate }}
                    </td>
                    <td>
                        {{ payment.$key }}
                    </td>
                    <td>R{{ payment.amountInCents | formatCents }}</td>
                    <td>R{{ payment.amountMinusCommission | formatCents }}</td>
                    <td>
                        <a [routerLink]="['/client/', payment.$client?.$key]">
                            {{ payment.$client?.firstName || '' }}
                            {{ payment.$client?.lastName || '' }}
                        </a>
                    </td>
                    <td>
                        <a [routerLink]="['/parking', payment.$parking?.$key]">
                            {{ payment.$parking?.title || '' }}
                        </a>
                    </td>
                    <td>
                        <a [routerLink]="['/merchant/', payment.$merchant?.$key]">
                            {{ payment.$merchant?.companyName || '' }}
                            <span *ngIf="!payment.$merchant?.companyName" class="undefined">Undefined</span>
                        </a>
                    </td>
                    <td>{{ payment.$parking?.$bankDetails?.bank }}</td>
                    <td>{{ payment.$parking?.$bankDetails?.accountType }}</td>
                    <td>{{ payment.$parking?.$bankDetails?.accountHolderName }}</td>
                    <td>{{ payment.$parking?.$bankDetails?.accountNumber }}</td>
                    <!--                <td>{{payment.$parking?.$bankDetails?.billingAddressCity}}</td>-->
                    <!--                <td>{{payment.$parking?.$bankDetails?.billingAddressSuburb}}</td>-->
                    <!--                <td>{{payment.$parking?.$bankDetails?.billingAddressStreetNumber}}</td>-->
                    <!--                <td>{{payment.$parking?.$bankDetails?.billingAddressStreetAddress}}</td>-->
                    <!--                <td>{{payment.$parking?.$bankDetails?.billingAddressProvince}}</td>-->
                    <!--                <td>{{payment.$parking?.$bankDetails?.billingAddressPostalCode}}</td>-->
                    <!--                <td>{{payment.$parking?.$bankDetails?.billingAddressCountry}}</td>-->
                </tr>
            </tbody>
        </table>
    </div>
</div>
