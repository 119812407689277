import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { AbstractControl, ControlContainer, FormGroupDirective } from '@angular/forms'
import { Observable, Subscription } from 'rxjs'

@Directive({
    selector: '[invalidMessage]',
})
export class InvalidMessageDirective implements OnInit, OnDestroy {
    @Input() invalidMessage: string
    control: AbstractControl | null | undefined
    controlValue$: Observable<any>
    controlSubscription: Subscription | undefined

    constructor(private _fg: ControlContainer, private _el: ElementRef, private render: Renderer2) {}

    ngOnInit() {
        this.control = this.form?.get(this.invalidMessage)
        this.setVisible()
        this.controlSubscription = this.control?.valueChanges.subscribe(() => {
            this.setVisible()
        })
    }

    private setVisible() {
        if (this.control?.invalid && this.control.dirty) {
            this.render.removeStyle(this._el.nativeElement, 'display')
        } else {
            this.render.setStyle(this._el.nativeElement, 'display', 'none')
        }
    }

    get form() {
        return this._fg.formDirective ? (this._fg.formDirective as FormGroupDirective).form : null
    }

    ngOnDestroy() {
        this.controlSubscription?.unsubscribe()
    }
}
