<admin-app-header></admin-app-header>

<div *ngIf="exceptionAlerts">
    <div class="container-fluid">
        <div class="row py-2">
            <div class="col-lg-4">
                <h2 class="float-start d-inline-block">Exception Alerts</h2>
                <select class="form-select float-end" (change)="onReviewedChange($event)" [ngModel]="reviewed" style="width: 200px">
                    <option value="true">Reviewed</option>
                    <option value="false">Unreviewed</option>
                </select>
            </div>
            <div class="col-lg-8">
                <button (click)="markAllAsReviewed()" class="btn btn-success btn-sm text-white float-end px-3 py-1 my-2"><i class="fas fa-check-double"></i> Mark All as Reviewed</button>
            </div>
        </div>

        <div class="content col-lg-12">
            <table class="table table-striped table-hover table-sm" id="all-exception-alerts">
                <thead>
                    <tr>
                        <th scope="col">Timestamp</th>
                        <th scope="col">Severity</th>
                        <th scope="col">Message</th>
                        <th scope="col">Function</th>
                        <th scope="col">File</th>
                        <th scope="col">Line</th>
                        <th scope="col">Reviewed</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let alert of exceptionAlerts">
                        <td>{{ alert.timestamp | date: 'medium' }}</td>
                        <td>
                            <span [ngClass]="getSeverityClass(alert.alertSeverity)" class="badge">
                                {{ alert.alertSeverity }}
                            </span>
                        </td>
                        <td>
                            <a [routerLink]="['/admin/exception', alert.$key]"> {{ alert.comment }} {{ alert.message }} </a>
                            <ng-container *ngIf="alert.json">
                                <i class="fas fa-database" title="{{ alert.json }}"></i>
                            </ng-container>
                        </td>
                        <td>{{ alert.functionName }}</td>
                        <td>{{ alert.fileName }}</td>
                        <td>{{ alert.lineNumber }}</td>
                        <td>
                            <span [ngClass]="{ 'text-success': alert.reviewed, 'text-danger': !alert.reviewed }">
                                {{ alert.reviewed ? 'Yes' : 'No' }}
                            </span>
                        </td>
                        <td>
                            <a *ngIf="!alert.reviewed" (click)="markAsReviewed(alert.$key!)" class="badge bg-success text-white px-3 py-1 my-2"><i class="fas fa-check-double"></i> Mark as Reviewed</a>
                            <a *ngIf="alert.reviewed" (click)="markAsUnreviewed(alert.$key!)" class="badge bg-danger text-white px-3 py-1 my-2"
                                ><i class="fas fa-times-circle"></i> Mark as Unreviewed</a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
