import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { KeyValuePair, ParkingType } from '@parkupp/core'

@Directive({
    selector: '[amenity]',
})
export class AmenityDirective implements OnInit, OnDestroy {
    @Input() key: string
    @Input() amenities: KeyValuePair[] | undefined

    constructor(private _el: ElementRef, private render: Renderer2) {}

    ngOnInit() {
        if (this.amenities) {
            for (const amenity of this.amenities) {
                if (this.key == amenity.key && amenity.value) {
                    this.render.addClass(this._el.nativeElement, 'active')
                    return
                }
            }
        }
    }

    ngOnDestroy(): void {}

    static amenities(parkingType: ParkingType): any {
        switch (parkingType) {
            case ParkingType.STANDARD:
                return {
                    nightLighting: 'Night Lighting',
                    onSiteGuard: 'On Site Guard',
                    ccTv: 'CCTV',
                    controlledAccess: 'Controlled Access',
                    coveredParking: 'Covered Parking',
                    underground: 'Underground',
                    maxSize: 'Max Size',
                }

            case ParkingType.FLEX:
                return {
                    nightLighting: 'Night Lighting',
                    onSiteGuard: 'On Site Guard',
                    ccTv: 'CCTV',
                    controlledAccess: 'Controlled Access',
                    coveredParking: 'Covered Parking',
                    underground: 'Underground',
                    maxSize: 'Max Size',
                }

            case ParkingType.PARKING_COLLECTOR:
                return {
                    managedCollectorFacility: 'Managed Collector Facility',
                    dedicatedCollectorArea: 'Dedicated Collector Area',
                    twentyFourSevenAccess: '24/7 Access',
                    onSiteSecurity: 'On Site Security',
                    basementParking: 'Basement Parking',
                    onSiteCarwash: 'On Site Carwash',
                }

            case ParkingType.PARKING_EVENTS:
                return {
                    nightLighting: 'Night Lighting',
                    onSiteGuard: 'On Site Guard',
                    ccTv: 'CCTV',
                    controlledAccess: 'Controlled Access',
                    coveredParking: 'Covered Parking',
                    underground: 'Underground',
                    maxSize: 'Max Size',
                }
        }
        return {}
    }
}
