<admin-app-header></admin-app-header>
<div class="container-fluid">
    <h1>Searches</h1>

    <div class="content col-lg-12">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">Created</th>
                    <th scope="col">Parking Type</th>
                    <th scope="col">City</th>
                    <th scope="col">Province</th>
                    <th scope="col">Country</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="logSearches">
                    <tr *ngFor="let logSearch of logSearches">
                        <td>
                            {{ logSearch.createdAt | daysAgo }}
                        </td>

                        <td>
                            {{ logSearch.parkingType }}
                        </td>
                        <td>
                            {{ logSearch.city }}
                        </td>
                        <td>
                            {{ logSearch.province }}
                        </td>
                        <td>
                            {{ logSearch.country }}
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
