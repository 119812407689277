"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AngularRoutes = void 0;
class AngularRoutes {
}
exports.AngularRoutes = AngularRoutes;
AngularRoutes.parking = 'parking';
AngularRoutes.client = 'client';
AngularRoutes.MERCHANT = 'merchant';
AngularRoutes.INVOICE = 'invoice';
