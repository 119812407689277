import { Component, Input, OnInit } from '@angular/core'
import * as moment from 'moment'
import { Timestamp } from '@firebase/firestore-types'

@Component({
    selector: 'app-countdown-timer',
    templateUrl: './countdown-timer.component.html',
    styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent implements OnInit {
    @Input() createdAt: Timestamp
    @Input() scale: string

    contractTimeleftMinutes: string
    contractTimeleftSeconds: string
    contractTimeleftHours: string
    private timeFontSize: string
    private descriptionFontSize: string

    constructor() {
        setInterval(() => {
            var now = moment(new Date())
            var end = moment(this.createdAt.toDate()).add(1, 'd') // another date
            let diff = end.diff(now)
            if (diff > 0) {
                // this.contractTimeleft = moment.utc(diff).format('HH [hours] mm [minutes] ss [seconds]')
                this.contractTimeleftHours = moment.utc(diff).format('HH')
                this.contractTimeleftMinutes = moment.utc(diff).format('mm')
                this.contractTimeleftSeconds = moment.utc(diff).format('ss')
            }
        }, 1000)
    }

    ngOnInit(): void {}
}
