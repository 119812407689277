import { Injectable } from '@angular/core'
import { doc, DocumentReference, Firestore, getDoc, setDoc } from '@angular/fire/firestore'
import { FirestoreStructure, VariableStructure } from '@parkupp/core'

@Injectable({
    providedIn: 'root',
})
export class VariableService {
    constructor(public firestore: Firestore) {}

    async getVariableByName(name: string): Promise<any> {
        const snapshot = await getDoc(doc(this.firestore, `${FirestoreStructure.VARIABLES}/${name}`))
        return snapshot.data()?.['value'] ?? null
    }

    updateVariable(name: string, value: any): Promise<void> {
        return setDoc(doc(this.firestore, `${FirestoreStructure.VARIABLES}/${name}`), { value })
    }
    async getNewPayoutNumber(): Promise<number> {
        const newPayoutNumber = (await this.getVariableByName(VariableStructure.PAYOUT_NUMBER)) + 1 ?? 738
        await this.updateVariable(VariableStructure.PAYOUT_NUMBER, newPayoutNumber)
        return newPayoutNumber
    }
    async getNewPayoutBatchNumber(): Promise<number> {
        const newPayoutBatchNumber = (await this.getVariableByName(VariableStructure.PAYOUT_BATCH_NUMBER)) + 1 ?? 1
        await this.updateVariable(VariableStructure.PAYOUT_BATCH_NUMBER, newPayoutBatchNumber)
        return newPayoutBatchNumber
    }
}
