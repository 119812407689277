import { Component, Input, OnInit } from '@angular/core'
import { AuthenticationService } from '../../../services/authentication.service'
import { Auth } from '@angular/fire/auth'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Client } from '@parkupp/core'
import { ClientService } from '../../../services/client.service'
import { GlobalService } from '../../../services/global.service'

@Component({
    selector: 'app-authenticator',
    templateUrl: './authenticator.component.html',
    styleUrls: ['./authenticator.component.scss'],
})
export class AuthenticatorComponent extends FormBuilder implements OnInit {
    @Input() showAsRegistration = false

    success: (auth: Auth) => void
    failure: (auth: Auth) => void

    returnUrl: string | null
    error: string | null
    loading: boolean = false
    withPassword = false
    formGroup: FormGroup
    valid: boolean = true

    constructor(
        public authService: AuthenticationService,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private router: Router,
        private clientService: ClientService,
        public globalService: GlobalService
    ) {
        super()

        this.activatedRoute.queryParams.forEach((queryParams) => {
            if (queryParams['returnUrl']) {
                this.returnUrl = queryParams['returnUrl']
            }
        })

        this.formGroup = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
        })

        this.success = (auth) => {
            this.loading = false
            this.onSignIn()
        }

        this.success = (auth) => {
            this.authService.user.reload().then(() => {
                // if (!this.authService.auth.currentUser?.emailVerified) {
                //     this.authService.auth.currentUser?.getIdToken().then((idToken) => {
                //         this.authService.sendEmailVerification(idToken).subscribe(() => {
                //             this.loading = false
                //             this.onSignIn()
                //         })
                //     })
                // }
                this.loading = false
                this.onSignIn()
            })
        }

        this.failure = (error: any) => {
            if (error.code === 'auth/user-not-found' && this.withPassword) {
                this.authService.createUserWithEmailAndPassword(this.formGroup.value.email, this.formGroup.value.password).then(this.success)
            } else {
                this.loading = false
                this.error = error.message
            }
        }
    }

    ngOnInit(): void {}

    onSignIn() {
        const clientSubscription = this.clientService.subscribeToClient(this.authService.uid).subscribe((document) => {
            const client = document as Client
            if (!client || !client.firstName) {
                if (this.returnUrl) {
                    this.router.navigate([`client/create`], { queryParams: { returnUrl: this.returnUrl } })
                } else {
                    this.router.navigate([`client/create`])
                }
            } else {
                if (this.returnUrl) {
                    this.router.navigate([this.returnUrl])
                } else {
                    this.router.navigate([`client/${this.authService.uid}`])
                }
            }
            clientSubscription.unsubscribe()
            this.loading = false
        })
    }

    signUpWithGoogle(): void {
        this.loading = true
        this.authService.signInWithGoogle().then(this.success).catch(this.failure)
    }

    signUpWithFacebook(): void {
        this.loading = true
        this.authService.signInWithFacebook().then(this.success).catch(this.failure)
    }

    setSignUpWithPassword(password: boolean): void {
        this.withPassword = password
    }

    signUpWithPassword(): void {
        if (this.formGroup.valid) {
            this.loading = true
            this.authService.signInWithPassword(this.formGroup.value.email, this.formGroup.value.password).then(this.success).catch(this.failure)
        } else {
            this.valid = false
        }
    }

    forgotPassword() {
        this.router.navigate(['/forgot-password'])
    }
}
