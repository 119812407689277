import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CoreModule } from '../../shared/core/core.module'
import { RouterModule } from '@angular/router'
import { SubscriptionsModule } from '../../shared/subscriptions/subscriptions.module'

@NgModule({
    declarations: [],
    imports: [CommonModule, CoreModule, RouterModule, SubscriptionsModule],
    exports: [],
})
export class ClientSubscriptionsModule {}
