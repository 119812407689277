"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientPrivate = void 0;
class ClientPrivate {
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        const client = JSON.parse(JSON.stringify(clone));
        client.createdAt = this.createdAt;
        client.updatedAt = this.updatedAt;
        return client;
    }
}
exports.ClientPrivate = ClientPrivate;
