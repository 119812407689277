<admin-app-header></admin-app-header>
<div class="container-fluid">
    <button (click)="globalService.exportcsv('manual-subscriptions')" class="btn btn-info btn-sm text-white float-end px-3 py-1 my-2"><i class="fas fa-file-csv"></i> Export to CSV</button>
    <h1 class="float-start">Manual Subscriptions</h1>

    <div class="content col-lg-12">
        <table class="table table-striped table-hover" id="manual-subscriptions">
            <thead>
                <tr>
                    <th scope="col">Created</th>
                    <th scope="col">Status</th>
                    <th scope="col">Client</th>
                    <th scope="col">Bays</th>
                    <th scope="col">Merchant</th>
                    <th scope="col">Parking</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Months</th>
                    <th scope="col">Period Fee</th>
                    <th scope="col">Total Fee</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let subscription of subscriptions">
                    <td>
                        <a [routerLink]="['/admin/subscription', subscription.$key]">
                            {{ subscription.createdAt | formatDate }}
                        </a>
                    </td>
                    <td>
                        <span [ngClass]="subscription.statusBadgeColour" class="badge">
                            {{ subscription.statusNiceName }}
                        </span>
                    </td>

                    <td>
                        {{ subscription.manualClientName }}
                    </td>

                    <td>
                        {{ subscription.baysBooked }}
                    </td>

                    <td>
                        <a *ngIf="subscription.$merchant" [routerLink]="['/merchant/', subscription.$merchant?.$key]">
                            {{ subscription.$merchant?.companyName | slice: 0:15 }}
                            <span *ngIf="!subscription.$merchant?.companyName" class="undefined">Merchant</span>
                        </a>
                    </td>

                    <td>
                        {{ subscription.manualParkingName }}
                    </td>

                    <td>
                        {{ subscription.startDate | customDate: 'D MMM YYYY' }}
                    </td>

                    <td>
                        {{ subscription.endDate | customDate: 'D MMM YYYY' }}
                    </td>
                    <td>
                        {{ subscription.period }}
                    </td>
                    <td>
                        {{ subscription.periodFee / 100 }}
                    </td>
                    <td>
                        {{ subscription.totalContractValue / 100 }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
