import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MerchantMembersListComponent } from './merchant-members-list/merchant-members-list.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ButtonsModule } from 'ngx-bootstrap/buttons'

@NgModule({
    declarations: [MerchantMembersListComponent],
    exports: [MerchantMembersListComponent],
    imports: [CommonModule, ReactiveFormsModule, FormsModule, ButtonsModule],
})
export class MerchantMembersModule {}
