"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentMethodStatus = void 0;
var PaymentMethodStatus;
(function (PaymentMethodStatus) {
    PaymentMethodStatus["created"] = "Created";
    PaymentMethodStatus["active"] = "Active";
    PaymentMethodStatus["expired"] = "Expired";
    PaymentMethodStatus["deleted"] = "Deleted";
})(PaymentMethodStatus = exports.PaymentMethodStatus || (exports.PaymentMethodStatus = {}));
