import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper'
import { ImageService } from '../../../services/image.service'
import * as uuid from 'uuid'

@Component({
    selector: 'app-image-cropper',
    templateUrl: './parkupp-image-cropper.component.html',
    styleUrls: ['./parkupp-image-cropper.component.scss'],
})
export class ParkuppImageCropperComponent implements AfterViewInit {
    @Input() loading = false
    @Input() uid: string
    @Input() imageType: string
    @Input() filePath: string
    @Input() fileName: string
    @Input() aspectRatio: number = 1
    @Output() completed: EventEmitter<boolean> = new EventEmitter<boolean>()
    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent | undefined
    imageChangedEvent: any = null
    croppedImage: any = ''
    imageSet: boolean = false
    imageSelected: boolean = false
    cropSelected: boolean = false
    error: any

    constructor(public imageService: ImageService) {}

    ngAfterViewInit(): void {}

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event
    }

    imageCropped(event: ImageCroppedEvent) {
        this.cropSelected = true
        this.croppedImage = event.base64
        this.imageSet = true
    }

    imageLoaded() {
        this.imageSelected = true
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        alert('Please choose correct format')
    }

    onSubmit() {
        this.loading = true
        this.imageService
            .uploadImage(this.croppedImage, this.filePath, this.fileName)
            .then((result) => {
                this.loading = false
                this.completed.emit(true)
            })
            .catch((error) => {
                this.error = error['message']
                this.loading = false
                console.log(this.error)
            })
    }

    onReset() {
        this.imageChangedEvent = null
        this.imageSet = false
        this.imageSelected = false
        this.cropSelected = false
    }

    reset() {}

    rotate() {}

    onLoad($event: any) {
        this.fileChangeEvent($event)
    }

    onDone() {
        this.fileChangeEvent(this.imageCropper?.crop())
    }
}
