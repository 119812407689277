<div aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" id="manager-roles-dialog" tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Manager Roles Explained</h5>
                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
            </div>
            <div class="modal-body text-center">
                <!-- https://docs.google.com/spreadsheets/d/1rz8X-UUb5wxxmDPrrH5s5DlJtyv2gJffpRqvWqW9_NA/edit#gid=0 -->
                <img class="modal-image" src="assets/images/home/manager-roles.png" />
            </div>
        </div>
    </div>
</div>

<div *ngIf="merchant" class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <h2 *ngIf="showHeading" class="mb-4">Managers</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <form (submit)="save()" [formGroup]="emailFormGroup" class="needs-validation" novalidate>
                <label>Email Address</label>
                <div class="input-group mb-3">
                    <input
                        aria-describedby="email-button"
                        aria-label="Email Address"
                        autocomplete="off"
                        class="form-control"
                        formControlName="email"
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        required
                        type="email"
                    />
                    <button class="btn btn-outline-primary" id="email-button" type="submit">Invite</button>
                </div>
            </form>
        </div>
    </div>

    <div class="alert alert-info">
        <a (click)="toggleShowDeletedMembers()" class="float-end">Show deleted members</a>
        <a data-bs-target="#manager-roles-dialog" data-bs-toggle="modal" href=""><strong>Learn more</strong></a> about each member role
    </div>

    <div *ngFor="let merchantMember of merchantMembers" class="row">
        <div *ngIf="showDeletedMembers && !merchantMember.active || !showDeletedMembers && merchantMember.active" class="col-md-12" id="">
            <div class="card corner-radius mt-3 w-100">
                <div class="card-body">
                    <span *ngIf="merchantMember.active && merchantMember.uid && merchant.owner == merchantMember.uid" class="badge bg-dark text-light ms-2 float-end">Owner</span>
                    <div class="card-title d-inline-block email-address mb-0">
                        <strong *ngIf="!merchantMember.uid || !clientMemberMap[merchantMember.uid] || !clientMemberMap[merchantMember.uid].firstName">{{ merchantMember.email }}</strong>
                        <strong *ngIf="merchantMember.uid && clientMemberMap[merchantMember.uid] && clientMemberMap[merchantMember.uid].firstName"
                        >{{ clientMemberMap[merchantMember.uid].firstName }} {{ clientMemberMap[merchantMember.uid].lastName }}</strong
                        >
                        &nbsp;
                        <span *ngIf="!merchantMember.uid" class="badge bg-danger">Not registered</span>
                    </div>
                    <div *ngIf="merchantMember.uid && clientMemberMap[merchantMember.uid] && clientMemberMap[merchantMember.uid].firstName" class="email-address">
                        {{ merchantMember.email }}
                    </div>
                    <div [ngClass]="{ 'can-edit': canEditRoles }" class="mb-3 mt-1">
                        <span
                            (click)="updateRoles(merchantMember, merchantRoles.ACCOUNT_KEY)"
                            [ngClass]="{ active: merchantMember.active && merchantMember.roles && merchantMember.roles.indexOf(merchantRoles.ACCOUNT_KEY) > -1 }"
                            class="badge badge-role bg-grey me-2"
                        >Account Key</span
                        >
                        <span
                            (click)="updateRoles(merchantMember, merchantRoles.PORTFOLIO_MANAGER)"
                            [ngClass]="{ active: merchantMember.active && merchantMember.roles && merchantMember.roles.indexOf(merchantRoles.PORTFOLIO_MANAGER) > -1 }"
                            class="badge badge-role bg-grey me-2"
                        >Portfolio Manager</span
                        >
                        <span
                            (click)="updateRoles(merchantMember, merchantRoles.BUILDING_MANAGER)"
                            [ngClass]="{ active: merchantMember.active && merchantMember.roles && merchantMember.roles.indexOf(merchantRoles.BUILDING_MANAGER) > -1 }"
                            class="badge badge-role bg-grey me-2"
                        >Building Manager</span
                        >
                    </div>
                    <div>
                        <div *ngIf="!merchantMember.active" class="text-danger small d-inline-block">
                            Agent deleted. Click <a (click)="restoreMember(merchantMember)" class="text-decoration-underline text-danger" href="">here</a> to restore.
                        </div>
                        <div *ngIf="merchantMember.active && (!merchantMember.uid || merchant.owner != merchantMember.uid) && uid != merchantMember.uid" class="d-inline-block">
                            <a (click)="deleteMember(merchantMember)" class="text-danger small" href="">Delete Agent</a>
                        </div>
                        <div *ngIf="merchantMember.active && merchantMember.uid && merchant.owner == uid && merchant.owner != merchantMember.uid" class="d-inline-block ms-3">
                            <a (click)="transferOwnership(merchantMember)" class="text-info small" href="">Transfer Ownership</a>
                        </div>
                        <div *ngIf="!merchantMember.uid" class="d-inline-block ms-3"><a (click)="resendInvite(merchantMember)" class="text-info small" href="">Resend Invite</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
