import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../../services/admin.service'
import { collection, getDocs, query, where } from '@angular/fire/firestore'
import { clientsPath } from '@parkupp/core'
import * as moment from 'moment'

@Component({
    selector: 'app-companies-by-client',
    templateUrl: './companies-by-client.component.html',
    styleUrls: ['./companies-by-client.component.scss'],
})
export class CompaniesByClientComponent implements OnInit {
    // Add properties for month selection and formatting
    public monthSelection: string[] = []
    public selectedMonth: string

    // Other properties...
    companyNameOccurrences: any = {}
    companyNameArray: { name: string; count: any }[]

    constructor(private adminService: AdminService) {}

    ngOnInit() {
        this.initializeMonthSelection()
        this.fetchClients() // Call fetchClients here without parameters initially
    }

    initializeMonthSelection() {
        // Populate monthSelection with the last 12 months including the current month
        for (let i = 0; i < 12; i++) {
            const month = moment().subtract(i, 'months').format('YYYY-MM')
            this.monthSelection.push(month)
        }
        this.selectedMonth = this.monthSelection[0] // Default to the current month
    }

    async fetchClients(month?: string) {
        this.companyNameOccurrences = {} // Reset occurrences to ensure fresh counting
        console.log(month)

        let clientsQuery

        if (month) {
            // Parse the year and month for querying
            const startDate = moment(month, 'YYYY-MM').startOf('month').toDate()
            const endDate = moment(month, 'YYYY-MM').endOf('month').toDate()

            // Query documents within the selected month
            clientsQuery = query(collection(this.adminService.firestore, clientsPath()), where('createdAt', '>=', startDate), where('createdAt', '<=', endDate))
        } else {
            // If no month is provided, fetch all clients without date filtering
            clientsQuery = query(collection(this.adminService.firestore, clientsPath()))
        }

        const clientsSnapshot = await getDocs(clientsQuery)
        // Iterate over each client document
        clientsSnapshot.forEach((doc) => {
            console.log(doc.data())
            const clientData: any = doc.data()
            // Get the company name from the client document
            let companyName = clientData.companyName?.trim()

            // If the company name exists, increment its count, otherwise initialize it to 1
            if (companyName) {
                companyName = this.toTitleCase(companyName)
                if (this.companyNameOccurrences[companyName]) {
                    this.companyNameOccurrences[companyName] += 1
                } else {
                    this.companyNameOccurrences[companyName] = 1
                }
            }
        })

        // Convert object to array of { name: string, count: number } objects
        this.companyNameArray = Object.keys(this.companyNameOccurrences)
            .map((name) => ({
                name,
                count: this.companyNameOccurrences[name],
            }))
            .sort((a, b) => b.count - a.count) // Sorting moved here for clarity

        // Sort the array by count in descending order
        this.companyNameArray.sort((a, b) => b.count - a.count)
    }

    toTitleCase(str: string): string {
        return str
            .toLowerCase()
            .split(' ')
            .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1)
            })
            .join(' ')
    }

    onMonthChange(event: any) {
        const newMonth = event.target.value
        this.companyNameOccurrences = {}
        this.companyNameArray = []
        this.fetchClients(newMonth) // Fetch clients based on the newly selected month
    }
}
