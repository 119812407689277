"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Occupant = void 0;
class Occupant {
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        const occupant = JSON.parse(JSON.stringify(clone));
        occupant.createdAt = this.createdAt;
        occupant.updatedAt = this.updatedAt;
        return occupant;
    }
    niceName() {
        let output = '';
        if (this.colour) {
            output += this.colour + ' ';
        }
        output += this.model;
        if (this.registration) {
            output += ` (${this.registration})`;
        }
        return output;
    }
}
exports.Occupant = Occupant;
