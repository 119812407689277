import { DocumentReference, Timestamp } from '@firebase/firestore-types'
import { Merchant } from '../merchant.model'

export class PayoutBatch {
    $key?: string
    status: PayoutBatchStatusEnum = PayoutBatchStatusEnum.DUE
    payoutRefs: DocumentReference[] = []
    merchantRefs: DocumentReference[] = []
    invoiceRefs: DocumentReference[] = []
    totalAmount: number
    payoutAmount: number
    payoutDate: Timestamp
    createdAt: Timestamp
    updatedAt: Timestamp
    payoutBatchNumber: number

    constructor(source?: Partial<PayoutBatch>, key?: string) {
        if (source) {
            Object.assign(this, source)
        }

        if (key) {
            this.$key = key
        }
    }

    parse() {
        const clone = JSON.parse(JSON.stringify(this))
        delete clone.$key
        delete clone.$client
        delete clone.$merchant
        delete clone.$parking
        const output = JSON.parse(JSON.stringify(clone))
        if (this.payoutRefs) {
            output.payoutRefs = this.payoutRefs
        }
        if (this.merchantRefs) {
            output.merchantRefs = this.merchantRefs
        }
        if (this.invoiceRefs) {
            output.invoiceRefs = this.invoiceRefs
        }

        output.createdAt = this.createdAt
        output.updatedAt = this.updatedAt
        if (this.payoutDate) {
            output.payoutDate = this.payoutDate
        }
        return output
    }
}

export enum PayoutBatchStatusEnum {
    DUE = 'due',
    PAID = 'paid',
}
