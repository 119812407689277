"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayGateMpiResponse = void 0;
const transaction_status_enum_1 = require("./transaction-status.enum");
class PayGateMpiResponse {
    constructor(input) {
        this.checksumChecked = true; // FIXME: THis shouldn't be hard coded
        if (input.payRequestId) {
            this.payRequestId = input.payRequestId;
        }
        if (input.transactionStatus) {
            this.transactionStatus = transaction_status_enum_1.PayGateTransactionStatus[input.transactionStatus];
        }
        if (input.checksum) {
            this.checksum = input.checksum;
        }
    }
}
exports.PayGateMpiResponse = PayGateMpiResponse;
