import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MerchantBankingDetailsFormComponent } from './merchant-banking-details-form/merchant-banking-details-form.component'
import { MerchantBankingDetailsListComponent } from './merchant-banking-details-list/merchant-banking-details-list.component'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { NgSelectModule } from '@ng-select/ng-select'
import { CoreModule } from '../../shared/core/core.module'

@NgModule({
    declarations: [MerchantBankingDetailsFormComponent, MerchantBankingDetailsListComponent],
    exports: [MerchantBankingDetailsFormComponent, MerchantBankingDetailsListComponent],
    imports: [CommonModule, ReactiveFormsModule, RouterModule, NgSelectModule, CoreModule],
})
export class MerchantBankingDetailsModule {}
