"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentMethod = void 0;
const payment_method_status_enum_1 = require("./enums/payment-method-status.enum");
class PaymentMethod {
    constructor(source) {
        this.primary = false;
        if (source) {
            Object.assign(this, source);
        }
        // TODO: Data migrations required
        if (!this.status) {
            this.status = payment_method_status_enum_1.PaymentMethodStatus.created;
        }
    }
    get cardNoOnlyNumbers() {
        return this.cardNo.replace(/x/gi, '');
    }
    get icon() {
        if (this.cardType == 'Visa') {
            return 'fa-cc-visa';
        }
        if (this.cardType == 'MasterCard') {
            return 'fa-cc-mastercard';
        }
        return 'fa-cc-visa';
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        delete clone.$client;
        const paymentMethods = JSON.parse(JSON.stringify(clone));
        paymentMethods.createdAt = this.createdAt;
        paymentMethods.updatedAt = this.updatedAt;
        return paymentMethods;
    }
    getCardName() {
        return this.cardNo.substring(12);
    }
}
exports.PaymentMethod = PaymentMethod;
