"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Plan = void 0;
class Plan {
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
    get totalFeeInCentsExVat() {
        return this.feeInCents / 1.15;
    }
}
exports.Plan = Plan;
