import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FirestoreStructure, LogEmail } from '@parkupp/core'
import { doc, Firestore } from 'firebase/firestore'
import { LogEmailService } from 'src/app/services/log-email.service'

@Component({
    selector: 'app-admin-email-details',
    templateUrl: './admin-email-details.component.html',
    styleUrls: ['./admin-email-details.component.scss'],
})
export class AdminEmailDetailsComponent implements OnInit {
    emailKey: string
    logEmail: LogEmail

    constructor(private logEmailService: LogEmailService, private route: ActivatedRoute) {}

    async ngOnInit(): Promise<void> {
        this.emailKey = this.route.snapshot.params['emailKey']
        console.log(this.emailKey)
        const docRef = doc(this.logEmailService.firestore, FirestoreStructure.LOG_EMAILS_OVERVIEW, this.emailKey)
        this.logEmail = await this.logEmailService.getLogEmail(docRef as any)
        console.log(this.logEmail)
    }
}
