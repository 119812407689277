import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../../services/admin.service'
import { ActivatedRoute, Router } from '@angular/router'
import { SubscriptionService } from '../../../../services/subscription.service'
import { GlobalService } from 'src/app/services/global.service'

@Component({
    selector: 'app-cart-page',
    templateUrl: './cart-page.component.html',
    styleUrls: ['./cart-page.component.scss'],
})
export class CartPageComponent implements OnInit {
    subscriptionKey: string

    constructor(private subscriptionService: SubscriptionService, private activatedRoute: ActivatedRoute, private router: Router, private globalService: GlobalService) {
        this.subscriptionKey = this.activatedRoute.snapshot.params['subscriptionKey']

        this.subscriptionService.getSubscription(this.subscriptionKey).then((subscription) => {
            // TODO: Add more data from the subscription to populate the subscript / cart
            this.router.navigate([`${this.globalService.routes.parking}${subscription.parkingRef.id}`])
        })
    }

    ngOnInit(): void {}
}
