"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParkingType = void 0;
var ParkingType;
(function (ParkingType) {
    ParkingType["STANDARD"] = "STANDARD";
    ParkingType["PLANES_TRAINS_SHIPS"] = "PLANES_TRAINS_SHIPS";
    ParkingType["TRAILERS_CARAVANS_BOATS"] = "TRAILERS_CARAVANS_BOATS";
    ParkingType["PARKING_COLLECTOR"] = "PARKING_COLLECTOR";
    ParkingType["PARKING_EVENTS"] = "PARKING_EVENTS";
    ParkingType["TRUCKS"] = "TRUCKS";
    ParkingType["FLEX"] = "FLEX";
    ParkingType["MANUAL"] = "MANUAL";
    ParkingType["BOOKING"] = "BOOKING";
})(ParkingType = exports.ParkingType || (exports.ParkingType = {}));
