<div [ngClass]="headerPaddingClass" class="header-padding"></div>
<nav [ngClass]="headerClass" class="navbar navbar-expand-lg">
    <div class="container-fluid px-0">
        <div (click)="onSideMenu.emit()" *ngIf="sideMenuButton" class="d-inline-block d-lg-none side-menu-button"><i class="fa fa-bars text-light"></i></div>
        <a (click)="goHome()" class="navbar-brand"> <img class="logo-image" src="{{ this.logoUrl }}" /></a>
        <button
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            class="navbar-toggler"
            data-bs-target="#navbarSupportedContent"
            data-bs-toggle="collapse"
            type="button"
        >
            <span class="fa fa-chevron-down"></span>
        </button>

        <div *ngIf="user" class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
                <li *ngIf="authService.admin" class="nav-item">
                    <a [routerLink]="['/admin']" aria-current="page" class="nav-link" routerLinkActive="active">Admin</a>
                </li>

                <li class="nav-item">
                    <a [routerLink]="['/bookupp-faqs']" aria-current="page" class="nav-link">How it Works</a>
                </li>

                <li class="nav-item">
                    <a [routerLink]="['/contact-bookupp']" aria-current="page" class="nav-link">Contact</a>
                </li>

                <li class="nav-item">
                    <a [routerLink]="['/']" aria-current="page" class="nav-link">Back to ParkUpp</a>
                </li>

                <li *ngIf="user.isAnonymous" class="nav-item dropdown">
                    <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"> Login / Register </a>
                    <ul aria-labelledby="navbarDropdown" class="dropdown-menu">
                        <li>
                            <a (click)="onSignIn()" aria-current="page" class="nav-link" routerLinkActive="active"> Login </a>
                        </li>
                        <li>
                            <a (click)="onSignUp()" aria-current="page" class="nav-link" routerLinkActive="active"> Register </a>
                        </li>
                    </ul>
                </li>

                <li *ngIf="!user.isAnonymous && authService.merchantsLoaded" class="nav-item dropdown">
                    <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" id="navbarDropdownProfile" role="button">
                        <i *ngIf="!clientService.activeClient || !clientService.activeClient.profileImage || !clientService.activeClient.profileImage.smallUrl" class="fa fa-user-circle"></i>
                        <img
                            *ngIf="clientService.activeClient && clientService.activeClient.profileImage && clientService.activeClient.profileImage.smallUrl"
                            [src]="clientService.activeClient.profileImage.smallUrl"
                            class="header-profile-image"
                        />
                        <ng-container *ngIf="clientService.activeClient && clientService.activeClient.firstName">{{ clientService.activeClient.firstName }}</ng-container>
                        <ng-container *ngIf="!clientService.activeClient || !clientService.activeClient.firstName"> Account</ng-container>
                    </a>
                    <ul aria-labelledby="navbarDropdownProfile" class="dropdown-menu dropdown-menu-end">
                        <li><a (click)="onClientProfile()" class="dropdown-item" href="">Parker Profile</a></li>

                        <li>
                            <a (click)="onMerchantPortal()" class="dropdown-item" routerLinkActive="active"> Landlord Profile </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider" />
                        </li>
                        <li><a (click)="signOut()" class="dropdown-item" href="">Logout</a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>

<div *ngIf="showWhiteLabelBackButton" class="container py-2">
    <a (click)="goBack()">
        <i class="fas fa-angle-double-left"></i>
        Back to property selection
    </a>
</div>
