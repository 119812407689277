<admin-app-header></admin-app-header>
<br />

<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="mb-2">Manual Subscription Form</h1>
        </div>
    </div>
    <div class="col-12">
        <form (ngSubmit)="onSubmit()" [formGroup]="formData" autocomplete="off" novalidate>
            <div class="row mt-3">
                <div class="form-group col-4">
                    <label>Start Date <span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="startDate" name="startDate" type="date" />
                </div>

                <div class="form-group col-4">
                    <label for="input-contractLength">Contract Duration <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <input class="form-control" formControlName="contractLength" id="input-contractLength" min="1" name="contractLength" type="number" />
                        <div class="input-group-text">
                            <span>Months</span>
                        </div>
                    </div>
                </div>

                <div class="form-group col-4">
                    <label>End Date <span class="text-danger">*</span></label>
                    <input DISABLED class="form-control" formControlName="endDate" name="endDate" type="date" />
                </div>
            </div>

            <div class="row mt-3">
                <div class="form-group col-4">
                    <label>Bays <span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="baysBooked" name="baysBooked" type="number" />
                </div>

                <div class="form-group col-4">
                    <label>Fee <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <div class="input-group-text">
                            <span>R</span>
                        </div>
                        <input class="form-control" formControlName="feeInRands" inputCurrency name="feeInRands" placeholder="R" type="text" />
                    </div>
                </div>

                <div class="form-group col-4">
                    <label>Period <span class="text-danger">*</span></label>
                    <input DISABLED class="form-control" type="text" value="Monthly" />
                </div>
            </div>

            <div class="row mt-3" *ngIf="clientLookup && clientLookup.length > 0">
                <div class="form-group col-6">
                    <label>Search for Client Name</label>
                    <input
                        (typeaheadOnSelect)="onClientSelect($event.item)"
                        [typeaheadItemTemplate]="clientTemplate"
                        [typeaheadOptionField]="'searchField'"
                        [typeahead]="clientLookup"
                        class="form-control"
                        formControlName="clientName"
                        placeholder="Search for a Client"
                    />
                </div>
                <ng-template #clientTemplate let-index="index" let-model="item"> {{ model.firstName }} {{ model.lastName }} ({{ model.companyName }}) </ng-template>
                <div class="form-group col-6">
                    <label>Client Ref <span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="clientKey" name="clientKey" type="text" readonly />
                    <small class="text-muted" *ngIf="formData.value.clientKey">
                        <a href="/client/{{ formData.value.clientKey }}" target="_blank">View Client</a>
                    </small>
                </div>
            </div>

            <div class="row mt-3" *ngIf="merchantLookup && merchantLookup.length > 0">
                <div class="form-group col-6">
                    <label>Search for Merchant Name </label>
                    <input
                        (typeaheadOnSelect)="onMerchantSelect($event.item)"
                        [typeaheadItemTemplate]="merchantTemplate"
                        [typeaheadOptionField]="'companyName'"
                        [typeahead]="merchantLookup"
                        class="form-control"
                        formControlName="merchantName"
                        placeholder="Search for a Merchant"
                        type="text"
                    />
                    <ng-template #merchantTemplate let-index="index" let-model="item">
                        {{ model.companyName }}
                    </ng-template>
                </div>
                <div class="form-group col-6">
                    <label>Merchant Ref <span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="merchantKey" name="merchantKey" type="text" readonly />
                    <small class="text-muted" *ngIf="formData.value.merchantKey">
                        <a href="/merchant/{{ formData.value.merchantKey }}" target="_blank">View Merchant</a>
                    </small>
                </div>
            </div>

            <div class="row mt-3" *ngIf="isMerchantSelected()">
                <div class="form-group col-6">
                    <label>Search for Parking Name </label>
                    <select class="form-control" formControlName="parkingName" (change)="onParkingSelect($event)">
                        <option value="">Select a Parking</option>
                        <option *ngFor="let parking of parkingLookup" [value]="parking.$key">
                            {{ parking.title }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-6">
                    <label>Parking Ref <span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="parkingKey" name="parkingKey" type="text" readonly />
                    <small class="text-muted" *ngIf="formData.value.parkingKey">
                        <a href="/parking/{{ formData.value.parkingKey }}" target="_blank">View Parking</a>
                    </small>
                </div>
            </div>

            <div class="row mt-3">
                <div class="form-group col-6">
                    <button [disabled]="!formData.valid || formLoading == true" class="btn btn-primary my-3" type="submit">Save</button>
                </div>
            </div>
        </form>
    </div>
</div>
