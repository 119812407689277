import { Pipe, PipeTransform } from '@angular/core'
import { Image } from '@parkupp/core'

@Pipe({ name: 'sortImagesByDate' })
export class SortImagesByDatePipe implements PipeTransform {
    transform(value: Image[]): Image[] {
        return SortImagesByDatePipe.sortImages(value)
    }

    static sortImages(images: Image[]): Image[] {
        return images.sort((a, b) => {
            const timestampA = parseInt(this.getParameterByName('modified', a.originalUrl), 10)
            const timestampB = parseInt(this.getParameterByName('modified', b.originalUrl), 10)
            return timestampA - timestampB
        })
    }

    static getParameterByName(name: string, url: string): string {
        name = name.replace(/[\[\]]/g, '\\$&')
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url)
        if (!results) return ''
        if (!results[2]) return ''
        return decodeURIComponent(results[2].replace(/\+/g, ' '))
    }
}
