<admin-app-header></admin-app-header>
<div class="container-fluid">
    <ng-container *ngIf="payment">
        <br />
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="content-box">
                        <h1>Payment Details</h1>
                        <div>Key: {{ payment.$key }}</div>
                        <p>CreatedAt: {{ payment.createdAt | formatDate }}</p>
                        <p>
                            <span [ngClass]="payment.statusBadgeColour" class="badge">
                                {{ payment.status }}
                            </span>
                        </p>
                        <p>Reference: {{ payment.reference }}</p>
                        <p>TransactionId: {{ payment.transactionId }}</p>
                        <p>AmountInCents: {{ payment.amountInCents | formatCents }}</p>
                        <p>Client: {{ payment.$client?.firstName || '' }}</p>
                        <p>Client: {{ payment.$client?.lastName || '' }}</p>
                        <p>Parking: {{ payment.$parking?.title || '' }}</p>
                        <p>Merchant: {{ payment.$merchant?.companyName || '' }}</p>
                        <div *ngIf="payment.payGateErrorResponse">
                            <div>ERROR:</div>
                            <pre><code>{{ payment.payGateErrorResponse | prettyJson }}</code></pre>
                        </div>


                        <div>
                            <div>All details:</div>
                            <pre><code>{{ payment.parse() | prettyJson }}</code></pre>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
