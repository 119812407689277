<div class="container mt-5">
    <div class="row">
        <div class="col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <h1 class="mb-4">Reset Password</h1>
            <form [formGroup]="formGroup" class="mb-3" (ngSubmit)="resetPassword()">
                <div class="form-group mb-3">
                    <label>Email</label>
                    <input type="email" name="email" id="email" formControlName="email" placeholder="Email" class="form-control" />
                    <div invalidMessage="email" class="invalid-feedback">{{ formGroup.get('email') | validationErrors }}</div>
                </div>
                <button type="submit" [disabled]="loading" class="btn btn-primary"><i *ngIf="loading" class="fa fa-circle-notch fa-spin"></i><span *ngIf="!loading">Reset Password</span></button>
            </form>
            <div class="divider"></div>
        </div>
    </div>
</div>
