"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Settlement = void 0;
class Settlement {
    constructor(source) {
        this.settlementFail = false;
        if (source) {
            Object.assign(this, source);
        }
        // if (!this.createdAt) {
        //     this.createdAt = Timestamp.now()
        // }
        // this.updatedAt = Timestamp.now()
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        delete clone.$merchant;
        delete clone.$subscription;
        delete clone.$payment;
        delete clone.$client;
        const settlement = JSON.parse(JSON.stringify(clone));
        settlement.createdAt = this.createdAt;
        settlement.updatedAt = this.updatedAt;
        if (this.merchantRef)
            settlement.merchantRef = this.merchantRef;
        if (this.subscriptionRef)
            settlement.subscriptionRef = this.subscriptionRef;
        if (this.clientRef)
            settlement.clientRef = this.clientRef;
        return settlement;
    }
}
exports.Settlement = Settlement;
