import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { ActivatedRoute } from '@angular/router'
import { doc, docData } from '@angular/fire/firestore'
import { Client, Merchant, Parking, Payment, paymentsPath, Subscription } from '@parkupp/core'

@Component({
    selector: 'app-admin-view-payment-page',
    templateUrl: './admin-view-payment-page.component.html',
    styleUrls: ['./admin-view-payment-page.component.scss'],
})
export class AdminViewPaymentPageComponent implements OnInit {
    paymentKey: string
    payment: Payment
    protected readonly JSON = JSON

    constructor(private adminSvc: AdminService, private activatedRoute: ActivatedRoute) {
        this.paymentKey = this.activatedRoute.snapshot.params['paymentKey']

        docData(doc(this.adminSvc.firestore, paymentsPath(), this.paymentKey)).subscribe((documentData: any) => {
            let payment = new Payment(documentData)
            payment.$key = this.paymentKey

            docData(documentData.clientRef).subscribe((data) => {
                payment.$client = new Client(data)
                payment.$client.$key = documentData.clientRef.id
            })
            docData(documentData.parkingRef).subscribe((data) => {
                payment.$parking = new Parking(data)
                payment.$parking.$key = documentData.parkingRef.id
            })
            docData(documentData.subscriptionRef).subscribe((data) => {
                payment.$subscription = new Subscription(data)
                payment.$subscription.$key = documentData.parkingRef.id
            })
            docData(documentData.merchantRef).subscribe((data) => {
                payment.$merchant = new Merchant(data)
                payment.$merchant.$key = documentData.parkingRef.id
            })

            this.payment = payment
        })
    }

    ngOnInit(): void {}
}
