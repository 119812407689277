import { Pipe, PipeTransform } from '@angular/core'
import { Term } from '@parkupp/core'

@Pipe({ name: 'parkingPlanTerm' })
export class ParkingPlanTermPipe implements PipeTransform {
    transform(value: Term): string {
        switch (value) {
            case Term.DAILY:
                return 'daily'
            case Term.MONTHLY:
                return 'p/m'
            case Term.ONCEOFF:
                return 'once off'
            case Term.FLEX_MONTH:
                return 'Full Month'
            case Term.FLEX_HALF_MONTH:
                return 'Half Month'
        }
        return ''
    }
}
