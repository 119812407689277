<admin-app-header></admin-app-header>
<br />
<div class="container">
    <h2>Searches by City</h2>
    <table class="table table-striped">
        <thead>
            <tr>
                <th>City</th>
                <th>Count</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let city of cityData">
                <td>{{ city.name }}</td>
                <td>{{ city.count }}</td>
            </tr>
        </tbody>
    </table>
</div>
