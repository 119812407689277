"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bank = void 0;
var Bank;
(function (Bank) {
    Bank["ABSA"] = "ABSA";
    Bank["BIDVEST"] = "BIDVEST";
    Bank["CAPITEC"] = "CAPITEC";
    Bank["DISCOVERY"] = "DISCOVERY";
    Bank["FNB"] = "FNB";
    Bank["INVESTEC"] = "INVESTEC";
    Bank["NEDBANK"] = "NEDBANK";
    Bank["STANDARD"] = "STANDARD";
    Bank["TYME"] = "TYME";
})(Bank = exports.Bank || (exports.Bank = {}));
