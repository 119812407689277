import { AfterViewInit, Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core'
import { AdminClient } from '@parkupp/core'

@Component({
    selector: 'app-parker-stats',
    templateUrl: './parker-stats.component.html',
    styleUrls: ['./parker-stats.component.scss'],
})
export class ParkerStatsComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() filteredAdminClients: AdminClient[]

    leadStatusSummary: any
    citySummary: any
    closedReasonSummary: any
    userResponsibleSummary: any

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.summarizeData()
        console.log(this.filteredAdminClients)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['filteredAdminClients']) {
            this.summarizeData()
        }
    }

    summarizeData() {
        this.leadStatusSummary = this.groupBy(this.filteredAdminClients, 'leadStatus')
        this.citySummary = this.groupBy(this.filteredAdminClients, 'city')
        this.closedReasonSummary = this.groupBy(this.filteredAdminClients, 'leadClosedReason')
        this.userResponsibleSummary = this.groupBy(this.filteredAdminClients, 'userResponsible')

        console.log('Lead Status Summary:', this.leadStatusSummary)
        console.log('City Summary:', this.citySummary)
        console.log('Closed Reason Summary:', this.closedReasonSummary)
        console.log('User Responsible Summary:', this.userResponsibleSummary)
    }

    groupBy(array: AdminClient[], key: string) {
        return array.reduce((result: any, client: any) => {
            const keyValue = client[key] || 0
            if (!result[keyValue]) {
                result[keyValue] = { totalBays: 0, totalLeads: 0 }
            }
            result[keyValue].totalBays += client.numberOfBays || 0
            result[keyValue].totalLeads += 1
            return result
        }, {})
    }
}
