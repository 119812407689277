import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Merchant, Payout } from '@parkupp/core'
import { MerchantService } from 'src/app/services/merchant.service'
import { PayoutService } from 'src/app/services/payout.service'

@Component({
    selector: 'app-merchant-proforma-invoices',
    templateUrl: './merchant-proforma-invoices.component.html',
    styleUrls: ['./merchant-proforma-invoices.component.scss'],
})
export class MerchantProformaInvoicesComponent implements OnInit {
    payouts: Payout[] = []
    merchantKey: string
    merchant: Merchant
    constructor(private payoutService: PayoutService, private activatedRoute: ActivatedRoute, private merchantService: MerchantService) {}

    async ngOnInit(): Promise<void> {
        this.merchantKey = this.activatedRoute.snapshot.params['merchantKey']
        this.merchant = await this.merchantService.getMerchant(this.merchantKey)

        const merchantRef = this.merchantService.getDocRef(this.merchantKey)
        this.payouts = await this.payoutService.getPayoutsByMerchant(merchantRef)
        console.log(this.payouts, this.merchantKey)
    }
}
