<admin-app-header></admin-app-header>
<div class="container mt-5">
    <div class="row">
        <div class="col-md-12">
            <h1>Force Void Payment Manually</h1>

            <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="paymentKey">Payment Key</label>
                    <input type="text" class="form-control" id="paymentKey" formControlName="paymentKey" />
                </div>
                <button type="submit" [disabled]="formGroup.invalid || loading" class="btn btn-primary">Force Void Payment</button>
            </form>

            <div *ngIf="response">
                <h2>Response</h2>
                <p>
                    <ng-container *ngIf="response.error">
                        <span>Error</span>
                        <pre>{{ response | json }}</pre>
                    </ng-container>

                    <ng-container *ngIf="!response.error">
                        <span>Success</span>
                    </ng-container>
                </p>
            </div>
        </div>
    </div>
</div>
