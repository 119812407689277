"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayGateQueryTypes = void 0;
var PayGateQueryTypes;
(function (PayGateQueryTypes) {
    PayGateQueryTypes["QueryResponse"] = "QueryResponse";
    PayGateQueryTypes["CardPaymentResponse"] = "CardPaymentResponse";
    PayGateQueryTypes["SettlementResponse"] = "SettlementResponse";
    PayGateQueryTypes["VoidResponse"] = "VoidResponse";
})(PayGateQueryTypes = exports.PayGateQueryTypes || (exports.PayGateQueryTypes = {}));
