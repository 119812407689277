import { Component, OnInit } from '@angular/core'
import { PayoutBatchService } from '../../../services/payout-batch.service'
import { ActivatedRoute } from '@angular/router'
import { PayoutService } from 'src/app/services/payout.service'
import { FirestorePaths, InvoiceStatus, Merchant, Payout, PayoutBatch, PayoutBatchStatusEnum, PayoutFrequencyEnum, PayoutStatusEnum } from '@parkupp/core'
import { MerchantService } from 'src/app/services/merchant.service'
import { serverTimestamp } from '@angular/fire/firestore'
import { doc, writeBatch } from '@angular/fire/firestore'
import { InvoiceService } from 'src/app/services/invoice.service'
import * as firestore from 'firebase/firestore'

@Component({
    selector: 'app-admin-payout-batch-details',
    templateUrl: './admin-payout-batch-details.component.html',
    styleUrls: ['./admin-payout-batch-details.component.scss'],
})
export class AdminPayoutBatchDetailsComponent implements OnInit {
    PayoutFrequencyEnum = PayoutFrequencyEnum
    PayoutStatusEnum = PayoutStatusEnum
    PayoutBatchStatusEnum = PayoutBatchStatusEnum
    payoutBatch: PayoutBatch
    payouts: Payout[] = []

    constructor(
        private payoutBatchService: PayoutBatchService,
        private route: ActivatedRoute,
        private payoutService: PayoutService,
        private merchantService: MerchantService,
        private invoiceService: InvoiceService
    ) {}

    async ngOnInit() {
        this.payoutBatch = await this.payoutBatchService.getPayoutBatch(this.route.snapshot.params['payoutBatchKey'])

        for (const payoutRef of this.payoutBatch.payoutRefs) {
            const payout = await this.payoutService.getPayout(payoutRef.id)
            if (payout.merchantRef) {
                payout.$merchant = await this.merchantService.getMerchant(payout.merchantRef.id)
            }
            this.payouts.push(payout)
        }
    }
    async completePayoutBatch() {
        const updatePayoutsBatch = writeBatch(this.payoutService.firestore)
        for (const payoutRef of this.payoutBatch.payoutRefs) {
            const payoutDocRef = this.payoutService.getDocRef(payoutRef.id)
            updatePayoutsBatch.update(payoutDocRef, { status: PayoutStatusEnum.PAID, payoutDate: serverTimestamp() })
        }
        await updatePayoutsBatch.commit()

        const invoiceBatch = writeBatch(this.invoiceService.firestore)
        for (const invoiceRef of this.payoutBatch.invoiceRefs) {
            const invoiceDocRef = this.invoiceService.getDocRef(invoiceRef.id)
            invoiceBatch.update(invoiceDocRef, { paidOut: true })
        }
        await invoiceBatch.commit()

        this.payoutBatch.status = PayoutBatchStatusEnum.PAID
        this.payoutBatch.payoutDate = serverTimestamp() as firestore.Timestamp
        await this.payoutBatchService.createOrUpdate(this.payoutBatch)
        location.reload()
    }
}
