import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core'

// declare const jQuery: any;

@Component({
    selector: 'app-drag-drop',
    templateUrl: './drag-drop.component.html',
    styleUrls: ['./drag-drop.component.scss'],
})
export class DragDropComponent implements OnInit {
    @Input() subtitle: string = ''
    @Input() accept = ''
    @Output() onLoad = new EventEmitter<any>()

    constructor(private el: ElementRef) {}

    ngOnInit() {}

    onChange($event: any) {
        this.onLoad.emit($event)
    }

    onDragOver() {
        // this.el.nativeElement.querySelector(".dropzone-wrapper").addClass('dragover')
    }

    onDragLeave() {
        // this.el.nativeElement.querySelector("dropzone-wrapper").removeClass('dragover')
    }

    readFile(input: any): void {
        if (input.files && input.files[0]) {
            const reader = new FileReader()

            const that = this
            reader.onload = function () {
                that.onLoad.emit(this)
            }

            reader.readAsDataURL(input.files[0])
        }
    }

    reset(e: any): void {
        e.wrap('<form>').closest('form').get(0).reset()
        e.unwrap()
    }
}
