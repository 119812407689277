import { AfterViewInit, Directive, ElementRef, EventEmitter, Host, Input, OnDestroy, Output } from '@angular/core'

@Directive({
    selector: '[appInViewport]',
})
export class InViewportDirective implements AfterViewInit, OnDestroy {
    @Input() threshold = 0.0
    @Output() visibilityChange = new EventEmitter<boolean>()
    private _observer: IntersectionObserver
    private _timeout: any

    constructor(@Host() private _elementRef: ElementRef) {}

    ngAfterViewInit(): void {
        this._observer = new IntersectionObserver(this._callback, {
            root: null,
            rootMargin: '0px',
            threshold: this.threshold,
        })
        this._observer.observe(this._elementRef.nativeElement)
    }

    ngOnDestroy() {
        this._observer.disconnect()
        if (this._timeout) {
            clearTimeout(this._timeout)
        }
    }

    private _callback = (entries: any[], _: any) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                // If the element is visible, start a timer for 2 seconds
                this._timeout = setTimeout(() => {
                    this.visibilityChange.emit(true)
                }, 500)
            } else {
                // If the element is not visible, clear the timer
                if (this._timeout) {
                    clearTimeout(this._timeout)
                }
            }
        })
    }
}
