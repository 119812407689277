import { Component, OnInit } from '@angular/core'
import { GlobalService } from '../../../services/global.service'

@Component({
    selector: 'app-footer-whitelabel',
    templateUrl: './footer-whitelabel.component.html',
    styleUrls: ['./footer-whitelabel.component.scss'],
})
export class FooterWhitelabelComponent implements OnInit {
    bgClass = 'bg-dark text-light'

    constructor(public globalService: GlobalService) {}

    ngOnInit(): void {
        if (this.globalService.whitelabeled) {
            document.documentElement.style.setProperty('--whitelabel-color', this.globalService.whitelabelColour)
            // if (this.globalService.whitelabelHeaderLight) {
            //     this.bgClass = 'bg-light text-dark'
            // }
        } else {
            document.documentElement.style.setProperty('--whitelabel-color', '#001533')
        }

        if (this.globalService.subdomain == 'redefine') {
            this.bgClass = 'd-none'
        }
    }
}
