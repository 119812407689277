import { Component, Input, OnInit } from '@angular/core'
import { AuthenticationService } from '../../../services/authentication.service'

@Component({
    selector: 'app-merchant-sidebar',
    templateUrl: './merchant-sidebar.component.html',
    styleUrls: ['./merchant-sidebar.component.scss'],
})
export class MerchantSidebarComponent implements OnInit {
    @Input() merchantKey: string
    dashboardView: boolean

    constructor(public authService: AuthenticationService) {
        this.dashboardView = true
    }

    ngOnInit(): void {}
}
