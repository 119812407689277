import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'formatCents' })
export class FormatCentsPipe implements PipeTransform {
    static formatCurrency(x: any, fractionDigits = 0) {
        if (isNaN(parseFloat(x))) {
            return null
        }
        x = x / 100
        x = parseFloat(x).toFixed(fractionDigits)
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }

    transform(value: any, fractionDigits = 0): string {
        return FormatCentsPipe.formatCurrency(Number(value), fractionDigits)
    }

    parse(value: string): number {
        if (isNaN(parseFloat(value.replace(/ /g, '')) * 100)) {
            return 0
        }
        return parseFloat(value.replace(/ /g, '')) * 100
    }
}
