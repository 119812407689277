"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Subscription = void 0;
const client_model_1 = require("./client.model");
const plan_model_1 = require("./plan.model");
const contract_status_enum_1 = require("./enums/contract-status.enum");
const subscription_renewal_status_enum_1 = require("./enums/subscription-renewal-status.enum");
const enums_1 = require("./enums/enums");
class Subscription {
    constructor(source, key) {
        this.type = enums_1.SubscriptionType.PAYGATE;
        // active = true // This refers to whether the merchant has approved it
        // paymentInitiated: boolean = false // This is false while the subscription is being created
        // clientCancelled: boolean = false
        this.status = contract_status_enum_1.ContractStatus.new;
        this.rejectionReason = '';
        this.attempts = 0;
        this.paymentsMade = 0;
        if (source) {
            Object.assign(this, source);
        }
        if (key) {
            this.$key = key;
        }
        if (source?.$client) {
            this.$client = new client_model_1.Client(source?.$client);
        }
        if (source?.plan) {
            this.plan = new plan_model_1.Plan(source?.plan);
        }
        this.$payments = [];
    }
    get commission() {
        if (this.$merchant && typeof this.$merchant?.commissionPercentage !== 'undefined') {
            return this.$merchant.commissionPercentage;
        }
        return 15;
    }
    get commissionException() {
        if (this.$merchant && typeof this.$merchant?.commissionPercentage !== 'undefined') {
            return true;
        }
        return false;
    }
    get statusNiceName() {
        if (this.status == contract_status_enum_1.ContractStatus.new) {
            return 'Draft';
        }
        if (this.status == contract_status_enum_1.ContractStatus.pendingClientApproval) {
            return 'Client Approval';
        }
        if (this.status == contract_status_enum_1.ContractStatus.incomplete) {
            return 'Incomplete';
        }
        if (this.status == contract_status_enum_1.ContractStatus.paymentInitiated) {
            return 'Payment Initiated';
        }
        if (this.status == contract_status_enum_1.ContractStatus.paymentFailed) {
            return 'Payment Failed';
        }
        if (this.status == contract_status_enum_1.ContractStatus.clientCancelled) {
            return 'Client Cancelled';
        }
        if (this.status == contract_status_enum_1.ContractStatus.merchantRejected) {
            return 'Merchant Rejected';
        }
        if (this.status == contract_status_enum_1.ContractStatus.expired) {
            return 'Expired';
        }
        if (this.status == contract_status_enum_1.ContractStatus.active) {
            return 'Active';
        }
        if (this.status == contract_status_enum_1.ContractStatus.completed) {
            return 'Completed';
        }
        if (this.status == contract_status_enum_1.ContractStatus.bouncing) {
            return 'Bouncing';
        }
        if (this.status == contract_status_enum_1.ContractStatus.clientBreachedNonPayment) {
            return 'Client Breached Non Payment';
        }
        if (this.status == contract_status_enum_1.ContractStatus.merchantBreached) {
            return 'Merchant Breached';
        }
        if (this.status == contract_status_enum_1.ContractStatus.mutuallyTerminated) {
            return 'Mutually Terminated';
        }
        if (this.status == contract_status_enum_1.ContractStatus.renewing) {
            return 'Renewing';
        }
        return 'Draft';
    }
    get showTimer() {
        if ([contract_status_enum_1.ContractStatus.paymentInitiated].includes(this.status)) {
            return true;
        }
        return false;
    }
    get statusBadgeColour() {
        if ([contract_status_enum_1.ContractStatus.active].includes(this.status)) {
            return 'bg-success text-dark';
        }
        if ([contract_status_enum_1.ContractStatus.new, contract_status_enum_1.ContractStatus.paymentInitiated, contract_status_enum_1.ContractStatus.renewing].includes(this.status)) {
            return 'bg-warning text-dark';
        }
        if ([
            contract_status_enum_1.ContractStatus.bouncing,
            contract_status_enum_1.ContractStatus.clientCancelled,
            contract_status_enum_1.ContractStatus.merchantRejected,
            contract_status_enum_1.ContractStatus.expired,
            contract_status_enum_1.ContractStatus.incomplete,
        ].includes(this.status)) {
            return 'bg-danger';
        }
        if ([contract_status_enum_1.ContractStatus.merchantBreached, contract_status_enum_1.ContractStatus.clientBreachedNonPayment, contract_status_enum_1.ContractStatus.mutuallyTerminated].includes(this.status)) {
            return 'bg-dark';
        }
        if ([contract_status_enum_1.ContractStatus.completed].includes(this.status)) {
            return 'bg-light text-dark';
        }
        return 'bg-warning text-dark';
    }
    get termPeriodName() {
        if (this.plan.term == 'MONTHLY') {
            return 'month';
        }
        if (this.plan.term == 'DAILY') {
            return 'day';
        }
        return 'month';
    }
    get momentJsUnit() {
        if (this.plan.term == 'MONTHLY') {
            return 'M';
        }
        if (this.plan.term == 'DAILY') {
            return 'd';
        }
        return 'M';
    }
    get termNamePlural() {
        if (this.period > 1) {
            return this.termPeriodName + 's';
        }
        else {
            return this.termPeriodName;
        }
    }
    get baysNamePlural() {
        if (this.baysBooked > 1) {
            return 'bays';
        }
        else {
            return 'bay';
        }
    }
    get periodFee() {
        return this.plan.feeInCents * this.baysBooked;
    }
    get periodFeeExVat() {
        return (this.plan.feeInCents * this.baysBooked) / 1.15;
    }
    get periodVat() {
        return this.periodFee - this.periodFeeExVat;
    }
    get periodFeeFormatted() {
        return 'R' + (this.plan.feeInCents * this.baysBooked) / 100;
    }
    get processingFee() {
        // return this.plan.feeInCents * (this.commission / 100)
        return this.plan.feeInCents * this.baysBooked * (this.commission / 100);
    }
    get processingFeeExVat() {
        return (this.plan.feeInCents * (this.commission / 100)) / 1.15;
        // return this.plan.totalFeeInCentsExVat * 0.15
    }
    get periodFinalPayout() {
        return this.periodFee - this.processingFee;
    }
    get totalContractPayoutValue() {
        return this.periodFinalPayout * this.period;
    }
    get totalContractPayoutValueExVAT() {
        return this.totalContractPayoutValue / 1.15;
    }
    get totalContractVAT() {
        return this.periodFinalPayout * this.period * 0.15;
    }
    get totalContractValue() {
        return this.periodFee * this.period;
    }
    // get denormClientName() {
    //     return this.denormClientFirstName + ' ' + this.denormClientLastName
    // }
    loadAll(firestore) {
        const promises = [];
        promises.push(this.loadClient(firestore));
        return Promise.all(promises);
    }
    loadClient(firestore) {
        return firestore.getDoc(this.clientRef).then((doc) => {
            this.$client = new client_model_1.Client(doc.data());
            this.$client.$key = doc.id;
            return Promise.resolve();
        });
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        delete clone.$merchant;
        delete clone.$client;
        delete clone.$parking;
        delete clone.$occupants;
        delete clone.$payments;
        const subscription = JSON.parse(JSON.stringify(clone));
        if (this.merchantRef) {
            subscription.merchantRef = this.merchantRef;
        }
        if (this.paymentMethodRef) {
            subscription.paymentMethodRef = this.paymentMethodRef;
        }
        if (this.clientRef) {
            subscription.clientRef = this.clientRef;
        }
        if (this.parkingRef) {
            subscription.parkingRef = this.parkingRef;
        }
        if (this.occupantRefs) {
            subscription.occupantRefs = this.occupantRefs;
        }
        if (this.paymentRefs) {
            subscription.paymentRefs = this.paymentRefs;
        }
        subscription.createdAt = this.createdAt;
        subscription.updatedAt = this.updatedAt;
        subscription.startDate = this.startDate;
        subscription.endDate = this.endDate;
        if (this.contractCreationLog) {
            subscription.contractCreationLog.date = this.contractCreationLog.date;
        }
        if (this.nextDebitDate) {
            subscription.nextDebitDate = this.nextDebitDate;
        }
        return subscription;
    }
    getStatus() {
        return 'test';
    }
    notRenewed() {
        if (this.renewalStatus == subscription_renewal_status_enum_1.SubscriptionRenewalStatus.notRenewed || this.renewalStatus == undefined) {
            return true;
        }
        else {
            return false;
        }
    }
}
exports.Subscription = Subscription;
