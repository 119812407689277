<admin-app-header></admin-app-header>

<div class="container-fluid">
    <button (click)="globalService.exportcsv('all-clients')" class="btn btn-info btn-sm text-white float-end px-3 py-1 my-2"><i class="fas fa-file-csv"></i> Export to CSV</button>
    <div class="row header">
        <div class="col-4 p-0">
            <h2 class="mb-1">Leads</h2>
            <p class="text-muted">Latest 200 clients who don't have an active or completed subscription</p>
        </div>
    </div>
    <div class="content col-lg-12">
        <table class="table table-striped table-hover table-sm" id="all-clients">
            <thead>
                <tr>
                    <th scope="col">Updated</th>
                    <th scope="col">Company</th>
                    <th scope="col">Name</th>
                    <th scope="col">Surname</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let client of clients">
                    <tr *ngIf="client.$email || client.$phoneNumber">
                        <td>
                            {{ client.updatedAt | daysAgo }}
                        </td>
                        <td>
                            {{ client.companyName }}
                        </td>
                        <td>
                            <a [routerLink]="['/client/', client.$key]">
                                <span [ngClass]="{ undefined: !client.firstName }">{{ client.firstName || 'Not set' }}</span>
                            </a>
                        </td>
                        <td>
                            <a [routerLink]="['/client/', client.$key]">
                                {{ client.lastName }}
                            </a>
                        </td>
                        <td>
                            {{ client.$email }}
                        </td>
                        <td>
                            {{ client.$phoneNumber }}
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
