import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-admin-generateqr-page',
    templateUrl: './admin-generateqr-page.component.html',
    styleUrls: ['./admin-generateqr-page.component.scss'],
})
export class AdminGenerateqrPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
