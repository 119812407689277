import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'formatRands' })
export class FormatRandsPipe implements PipeTransform {
    static formatCurrency(x: any, fractionDigits = 2) {
        if (isNaN(parseFloat(x))) {
            return null
        }
        x = parseFloat(x).toFixed(fractionDigits)
        return 'R ' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }

    transform(value: any, fractionDigits = 2): string {
        return FormatRandsPipe.formatCurrency(Number(value), fractionDigits) as string
    }

    parse(value: string): number {
        if (isNaN(parseFloat(value.replace(/ /g, '')) * 100)) {
            return 0
        }
        return parseFloat(value.replace(/ /g, '')) * 100
    }
}
