import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { AuthenticationService } from '../../../services/authentication.service'
import * as firebase from 'firebase/auth'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ClientService } from '../../../services/client.service'
import { GoogleTagManagerService } from 'angular-google-tag-manager'
import { GlobalService } from '../../../services/global.service'
import { Location } from '@angular/common'
import User = firebase.User

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input() showFindParking = true
    @Input() sideMenuButton = false
    @Output() onSideMenu = new EventEmitter<void>()

    user: User | null
    returnUrl: string | null
    activeMerchant: any
    subdomain: string
    logoUrl: string
    headerClass: string
    headerPaddingClass = ''
    showWhiteLabelBackButton = false
    GlobalService = GlobalService

    constructor(
        public globalService: GlobalService,
        public authService: AuthenticationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public clientService: ClientService,
        private gtmService: GoogleTagManagerService,
        private location: Location
    ) {
        this.activatedRoute.queryParams.forEach((queryParams) => {
            if (queryParams['returnUrl']) {
                this.returnUrl = queryParams['returnUrl']
            } else {
                this.returnUrl = null
            }
        })

        // Track each page view event
        this.router.events.forEach((item) => {
            if (item instanceof NavigationEnd) {
                const gtmTag = {
                    event: 'page',
                    pageName: item.url,
                }
                this.gtmService.pushTag(gtmTag)
            }
        })

        if (globalService.whitelabeled) {
            this.logoUrl = globalService.whitelabelLogoUrl
            document.documentElement.style.setProperty('--whitelabel-header-color', this.globalService.whitelabelHeaderColour)
            this.headerClass = 'navbar-dark bg-dark'
            if (globalService.whitelabelHeaderLight) {
                this.headerClass = 'navbar-light bg-light'
            }

            if (this.globalService.subdomain == 'redefine') {
                this.headerClass = 'd-none'
                this.headerPaddingClass = 'd-none'
                this.showWhiteLabelBackButton = true
                if (this.activatedRoute.snapshot.url && this.activatedRoute.snapshot.url[0]) {
                    if (this.activatedRoute.snapshot.url[0].path == 'showcase') {
                        // Override the back button on the home page.
                        this.showWhiteLabelBackButton = false
                    }
                }
            }
        } else {
            this.logoUrl = 'assets/images/branding/logo.svg'
            document.documentElement.style.setProperty('--whitelabel-header-color', '#001533')
            this.headerClass = 'navbar-dark bg-dark'
        }
    }

    ngOnInit(): void {
        this.authService.auth.onAuthStateChanged((user) => {
            this.user = user
            if (this.user && !this.user?.isAnonymous && (!this.user?.email || !this.user.phoneNumber)) {
                this.router.navigate(['client/create'])
            }
        })
    }

    ngOnDestroy(): void {}

    signOut() {
        this.authService
            .signOut()
            .then(() => {
                this.router.navigate([''])
            })
            .catch((e) => {
                console.log(e.message)
            })
        return false
    }

    onMerchantPortal() {
        if (this.authService.activeMerchant && this.authService.activeMerchant.companyName) {
            this.router.navigate([`merchant/${this.authService.activeMerchant.$key}`])
        } else {
            if (this.authService.user.isAnonymous) {
                this.router.navigate([`/corporate`])
            } else {
                this.router.navigate([`/merchant/create`])
            }
        }
        return false
    }

    onClientProfile() {
        if (this.clientService.activeClient && this.clientService.activeClient.firstName) {
            this.router.navigate([`client/${this.authService.uid}`])
        } else {
            this.router.navigate([`client/create`])
        }
        return false
    }

    onSignIn() {
        if (this.returnUrl) {
            this.router.navigate(['sign-in'], { queryParams: { returnUrl: this.returnUrl } })
        } else {
            this.router.navigate(['sign-in'])
        }
        return false
    }

    onSignUp() {
        if (this.returnUrl) {
            this.router.navigate(['sign-up'], { queryParams: { returnUrl: this.returnUrl } })
        } else {
            this.router.navigate(['sign-up'])
        }
        return false
    }

    onFindParking() {
        if (this.globalService.whitelabeled) {
            this.router.navigate(['/'])
        } else {
            this.router.navigate(['/map-search'])
        }
    }

    goBack() {
        this.location.back()
    }
}
