"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Enquiry = void 0;
class Enquiry {
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        clone.createdAt = this.createdAt;
        return clone;
    }
    patch(source) {
        Object.assign(this, source);
    }
}
exports.Enquiry = Enquiry;
