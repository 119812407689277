<admin-app-header></admin-app-header>
<div class="container-fluid">
    <h1 class="float-start">Blog Pages</h1>
    <a [routerLink]="['/admin/blog/create']" class="dropdown-item">Create blog article</a>
    <div class="content col-lg-12">
        <table class="table table-striped table-hover">
            <thead>
            <tr>
                <th scope="col">Created</th>
                <th scope="col">Title</th>
                <th scope="col">Link</th>
                <th scope="col">Functions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let blog of blogs">
                <td>
                    {{ blog.createdAt | daysAgo }}
                </td>
                <td>
                    <a [routerLink]="['/admin/blog/', blog.$key]">
                        {{ blog.title || 'No Title' }}
                    </a>
                </td>
                <td>
                    {{ blog.link || '' }}
                </td>
                <td>
                    <i (click)="deleteBlog(blog)" class="fa fa-trash text-danger"></i>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
