import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'

@Pipe({ name: 'formatDuration' })
export class FormatDurationPipe implements PipeTransform {
    transform(value: any, unit: string): number {
        const diffTime = value - moment().valueOf()
        let duration = moment.duration(diffTime, 'milliseconds')

        duration = moment.duration(duration, 'milliseconds')
        switch (unit) {
            case 'days':
                return Math.max(Math.floor(diffTime / 1000 / 60 / 60 / 24), 0)
            case 'hours':
                return Math.max(duration.hours(), 0)
            case 'minutes':
                return Math.max(duration.minutes(), 0)
        }
        return Math.max(duration.milliseconds(), 0)
    }
}
