import { AfterViewInit, Component, OnInit } from '@angular/core'
import { GlobalService } from '../../../services/global.service'

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, AfterViewInit {
    showFooter = false

    constructor(public globalService: GlobalService) {}

    ngOnInit(): void {
        if (this.globalService.whitelabeled) {
            document.documentElement.style.setProperty('--whitelabel-color', this.globalService.whitelabelColour)
        } else {
            document.documentElement.style.setProperty('--whitelabel-color', '#001533')
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.showFooter = true
        }, 1000) // Delay of 1 second
    }
}
