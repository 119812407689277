import { Component, OnInit } from '@angular/core'
import { ExceptionAlert } from '@parkupp/core'
import { GlobalService } from '../../../services/global.service'
import { ActivatedRoute, Router } from '@angular/router'
import { AdminService } from '../../../services/admin.service'
import { ExceptionAlertService } from '../../../services/exception-alert.service'

@Component({
    selector: 'app-admin-view-exceptions',
    templateUrl: './admin-view-exceptions.component.html',
    styleUrls: ['./admin-view-exceptions.component.scss'],
})
export class AdminViewExceptionsComponent implements OnInit {
    exceptionAlertKey: string
    exceptionAlert: ExceptionAlert

    constructor(
        public globalService: GlobalService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private adminService: AdminService,
        private exceptionAlertService: ExceptionAlertService
    ) {}

    ngOnInit() {
        this.exceptionAlertKey = this.activatedRoute.snapshot.params['exceptionAlertKey']
        this.exceptionAlertService.get(this.exceptionAlertKey).subscribe((exceptionAlert) => {
            this.exceptionAlert = new ExceptionAlert(exceptionAlert)
        })
    }
}
