<div class="container-fluid">
    <div *ngFor="let bankDetail of bankDetailsList" class="row">
        <div class="col-md-12">
            <div class="card mt-3 w-100">
                <div class="card-body">
                    <h5 class="card-title d-inline-block">{{ bankDetail.accountHolderName }}</h5>
                    <div *ngIf="!bankDetailsModal" class="icon-button float-end"><i class="fa fa-edit" (click)="editBankDetails(bankDetail)"></i></div>
                    <div *ngIf="bankDetailsModal" class="icon-button float-end"><i class="fa fa-edit" (click)="editBankDetailsModal(bankDetail)"></i></div>
                    <h6 class="card-subtitle mb-2 text-muted">{{ bankDetail.bank }} {{ bankDetail.accountNumber }}</h6>
                    <p class="card-text text-muted">{{ bankDetail.billingAddressStreetNumber }} {{ bankDetail.billingAddressStreetAddress }}</p>
                    <button *ngIf="bankDetailsModal" class="btn btn-primary" (click)="selectBankDetails(bankDetail)">Select</button>
                </div>
            </div>
        </div>
    </div>
</div>
