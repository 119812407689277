"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayGateResponse = void 0;
const paygate_query_types_enum_1 = require("./paygate-query-types.enum");
class PayGateResponse {
    constructor(type, result) {
        let queryName = '';
        let responseName = '';
        if (type == paygate_query_types_enum_1.PayGateQueryTypes.QueryResponse) {
            queryName = 'ns2:SingleFollowUpResponse';
            responseName = 'ns2:QueryResponse';
        }
        if (type == paygate_query_types_enum_1.PayGateQueryTypes.CardPaymentResponse) {
            queryName = 'ns2:SinglePaymentResponse';
            responseName = 'ns2:CardPaymentResponse';
        }
        if (type == paygate_query_types_enum_1.PayGateQueryTypes.SettlementResponse) {
            queryName = 'ns2:SingleFollowUpResponse';
            responseName = 'ns2:SettlementResponse';
        }
        if (type == paygate_query_types_enum_1.PayGateQueryTypes.VoidResponse) {
            queryName = 'ns2:SingleFollowUpResponse';
            responseName = 'ns2:VoidResponse';
        }
        const body = result['SOAP-ENV:Envelope']['SOAP-ENV:Body'];
        // console.log(body)
        // console.log(body['ns2:SinglePaymentResponse'])
        // console.log(body[queryName])
        if (body['SOAP-ENV:Fault']) {
            // Error
            if (body['SOAP-ENV:Fault']['detail']['payhost:error']) {
                this.error = body['SOAP-ENV:Fault']['detail']['payhost:error'];
            }
        }
        else {
            if (body[queryName][responseName]) {
                if (body[queryName][responseName]['ns2:Redirect']) {
                    // Only applies to payment method create
                    this.updatePayGateRedirectResponse(body[queryName][responseName]['ns2:Redirect']);
                }
                else if (body[queryName][responseName]['ns2:Status']) {
                    this.updatePayGateStatusResponse(body[queryName][responseName]['ns2:Status']);
                }
                else {
                    console.log('This is weird');
                }
            }
            else {
                console.log('no reponse data');
            }
        }
        console.log(this);
    }
    parse() {
        return JSON.parse(JSON.stringify(this));
    }
    updatePayGateRedirectResponse(redirect) {
        if (redirect['ns2:RedirectUrl'])
            this.redirectUrl = redirect['ns2:RedirectUrl']['_text'];
        if (redirect['ns2:UrlParams']) {
            if (redirect['ns2:UrlParams'][0]['ns2:value'])
                this.payRequestId = redirect['ns2:UrlParams'][0]['ns2:value']['_text'];
            if (redirect['ns2:UrlParams'][1]['ns2:value'])
                this.paygateId = redirect['ns2:UrlParams'][1]['ns2:value']['_text'];
            if (redirect['ns2:UrlParams'][2]['ns2:value'])
                this.checksum = redirect['ns2:UrlParams'][2]['ns2:value']['_text'];
        }
    }
    updatePayGateStatusResponse(status) {
        // TODO change this to ENUM
        if (status['ns2:Reference'])
            this.reference = status['ns2:Reference']['_text']; // ParkUpp Payment Key
        if (status['ns2:ResultCode'])
            this.resultCode = status['ns2:ResultCode']['_text'];
        if (status['ns2:TransactionId'])
            this.transactionId = status['ns2:TransactionId']['_text'];
        if (status['ns2:AcquirerCode'])
            this.acquirerCode = status['ns2:AcquirerCode']['_text'];
        if (status['ns2:StatusName'])
            this.statusName = status['ns2:StatusName']['_text'];
        if (status['ns2:AuthCode'])
            this.authCode = status['ns2:AuthCode']['_text'];
        if (status['ns2:PayRequestId'])
            this.payRequestId = status['ns2:PayRequestId']['_text'];
        if (status['ns2:VaultId'])
            this.vaultId = status['ns2:VaultId']['_text'];
        if (status['ns2:TransactionStatusDescription'])
            this.transactionStatusDescription = status['ns2:TransactionStatusDescription']['_text'];
        if (status['ns2:ResultDescription'])
            this.resultDescription = status['ns2:ResultDescription']['_text'];
        if (status['ns2:Currency'])
            this.currency = status['ns2:Currency']['_text'];
        if (status['ns2:Amount'])
            this.amount = status['ns2:Amount']['_text'];
        if (status['ns2:RiskIndicator'])
            this.riskIndicator = status['ns2:RiskIndicator']['_text'];
        if (status['ns2:PaymentType'] && status['ns2:PaymentType']['ns2:Method']) {
            if (status['ns2:PaymentType']['ns2:Method'])
                this.PaymentTypeMethod = status['ns2:PaymentType']['ns2:Method']['_text'];
            if (status['ns2:PaymentType']['ns2:Detail'])
                this.PaymentTypeDetail = status['ns2:PaymentType']['ns2:Detail']['_text'];
        }
        if (status['ns2:TransactionStatusCode'])
            this.transactionStatusCode = status['ns2:TransactionStatusCode']['_text'];
        if (status['ns2:PayVaultData']) {
            if (status['ns2:PayVaultData'][0]['ns2:value'])
                this.cardNumber = status['ns2:PayVaultData'][0]['ns2:value']['_text'];
            if (status['ns2:PayVaultData'][1]['ns2:value'])
                this.expiryDate = status['ns2:PayVaultData'][1]['ns2:value']['_text'];
        }
    }
}
exports.PayGateResponse = PayGateResponse;
