import { Component, OnInit } from '@angular/core'
import { AuthenticationService } from '../../../services/authentication.service'
import { ActivatedRoute, NavigationStart, Router } from '@angular/router'
import { MerchantService } from '../../../services/merchant.service'
import { Merchant } from '@parkupp/core'
import { filter } from 'rxjs/operators'

class AuthService {}

@Component({
    selector: 'app-merchant-vertical-menu',
    templateUrl: './merchant-vertical-menu.component.html',
    styleUrls: ['./merchant-vertical-menu.component.scss'],
})
export class MerchantVerticalMenuComponent implements OnInit {
    merchantKey: string
    merchant: Merchant

    constructor(private router: Router, private activatedRoute: ActivatedRoute, public authService: AuthenticationService, private merchantService: MerchantService) {
        this.merchantKey = this.authService.uid
        this.merchantKey = this.activatedRoute.snapshot.params['merchantKey']

        this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe((e) => {
            this.merchantKey = this.authService.uid
            this.merchantKey = this.activatedRoute.snapshot.params['merchantKey']
        })

        this.merchantService.subscribeToMerchant(this.merchantKey).subscribe((merchant) => {
            this.merchant = merchant as Merchant
            this.merchant.$key = this.merchantKey
        })
    }

    ngOnInit(): void {}
}
