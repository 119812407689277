"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MerchantMember = void 0;
class MerchantMember {
    constructor() {
        this.active = true;
        this.roles = [];
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        delete clone.$client;
        const merchantMember = JSON.parse(JSON.stringify(clone));
        if (this.merchantRef)
            merchantMember.merchantRef = this.merchantRef;
        merchantMember.createdAt = this.createdAt;
        merchantMember.updatedAt = this.updatedAt;
        return merchantMember;
    }
}
exports.MerchantMember = MerchantMember;
