import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HeaderComponent } from './header/header.component'
import { FooterComponent } from './footer/footer.component'
import { FormatDateFromNowPipe } from './pipes/format-date-from-now.pipe'
import { FormatCentsPipe } from './pipes/format-cents.pipe'
import { FormatCurrencyPipe } from './pipes/format-currency.pipe'
import { FormatDateFullPipe } from './pipes/format-date-full.pipe'
import { FormatDateTimePipe } from './pipes/format-date-time.pipe'
import { FormatDatePipe } from './pipes/format-date.pipe'
import { FormatCreditCardDatePipe } from './pipes/format-credit-card-date.pipe'
import { FormatNumberWithPaddedZerosPipe } from './pipes/format-number-with-padded-zeros.pipe'
import { FormatYearPipe } from './pipes/format-year.pipe'
import { FormatDurationPipe } from './pipes/format-duration.pipe'
import { RouterModule } from '@angular/router'
import { ValidationErrorsPipe } from './pipes/validation-errors.pipe'
import { InvalidMessageDirective } from './directives/invalid-message.directive'
import { GoogleMapComponent } from './google-map/google-map.component'
import { AgmCoreModule } from '@agm/core'
import { environment } from '../../../environments/environment'
import { InputCurrencyDirective } from './directives/currency.directive'
import { SafePipe } from './pipes/safe.pipe'
import { ParkingPlanTermPipe } from './pipes/parking-plan-term.pipe'
import { AmenityDirective } from './directives/amenity.directive'
import { SortImagesByDatePipe } from './pipes/sort-images-by-date.pipe'
import { SortImagesByPrimaryImagePipe } from './pipes/sort-images-by-primary-image.pipe'
import { DaysAgoPipe } from './pipes/days-ago.pipe'
import { TruncatePipe } from './pipes/string-truncate.pipe'
import { StringAddUrls } from './pipes/string-add-urls.pipe'
import { CustomDatePipe } from './pipes/custom-date.pipe'
import { CartPageComponent } from './shared-pages/cart-page/cart-page.component'
import { PhoneWithSpacesPipe } from './pipes/phone-with-spaces'
import { TickerTapeComponent } from './ticker-tape/ticker-tape.component'
import { FooterWhitelabelComponent } from './footer-whitelabel/footer-whitelabel.component'
import { InViewportDirective } from './directives/inViewport.directive'
import { BookuppHeaderComponent } from './bookupp-header/bookupp-header.component'
import { BookuppFooterComponent } from './bookupp-footer/bookupp-footer.component'
import { TestimonialsBlockComponent } from './testimonials-block/testimonials-block.component'
import { PayfastPaymentFormComponent } from './payfast/payfast-payment-form/payfast-payment-form.component'
import { FormatRandsPipe } from './pipes/format-rands.pipe'
import { PrettyJsonPipe } from './pipes/pretty-json.pipe'
import { StripHtmlPipe } from './pipes/strip-html.pipe'
import { DaysUntilPipe } from './pipes/days-until.pipe'
import { LogoSliderComponent } from './logo-slider/logo-slider.component'
import { SimpleTimePipe } from './pipes/simple-time'

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        HeaderComponent,
        FooterComponent,
        FooterWhitelabelComponent,
        InvalidMessageDirective,
        InputCurrencyDirective,
        FormatCentsPipe,
        FormatRandsPipe,
        FormatCurrencyPipe,
        FormatCreditCardDatePipe,
        FormatDateFromNowPipe,
        FormatDateFullPipe,
        FormatDateTimePipe,
        CustomDatePipe,
        PrettyJsonPipe,
        FormatDatePipe,
        FormatDurationPipe,
        FormatNumberWithPaddedZerosPipe,
        FormatYearPipe,
        ValidationErrorsPipe,
        GoogleMapComponent,
        SafePipe,
        SimpleTimePipe,
        PhoneWithSpacesPipe,
        ParkingPlanTermPipe,
        AmenityDirective,
        SortImagesByDatePipe,
        SortImagesByPrimaryImagePipe,
        DaysAgoPipe,
        DaysUntilPipe,
        TruncatePipe,
        StripHtmlPipe,
        StringAddUrls,
        CartPageComponent,
        TickerTapeComponent,
        InViewportDirective,
        BookuppHeaderComponent,
        BookuppFooterComponent,
        TestimonialsBlockComponent,
        PayfastPaymentFormComponent,
        LogoSliderComponent,
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        FooterWhitelabelComponent,
        InvalidMessageDirective,
        InputCurrencyDirective,
        FormatCentsPipe,
        FormatRandsPipe,
        FormatCurrencyPipe,
        FormatCreditCardDatePipe,
        FormatDateFromNowPipe,
        FormatDateFullPipe,
        FormatDateTimePipe,
        FormatDatePipe,
        CustomDatePipe,
        PrettyJsonPipe,
        FormatDurationPipe,
        FormatNumberWithPaddedZerosPipe,
        FormatYearPipe,
        ValidationErrorsPipe,
        GoogleMapComponent,
        SafePipe,
        SimpleTimePipe,
        PhoneWithSpacesPipe,
        ParkingPlanTermPipe,
        AmenityDirective,
        SortImagesByDatePipe,
        DaysAgoPipe,
        DaysUntilPipe,
        TruncatePipe,
        StripHtmlPipe,
        StringAddUrls,
        TickerTapeComponent,
        InViewportDirective,
        BookuppHeaderComponent,
        BookuppFooterComponent,
        TestimonialsBlockComponent,
        PayfastPaymentFormComponent,
        LogoSliderComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        AgmCoreModule.forRoot({
            apiKey: environment.googleMapsApiKey,
            libraries: ['places'],
        }),
    ],
})
export class CoreModule {}
