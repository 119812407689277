<div class="row">
    <div class="col-md-12">
        <div class="col-md-12 mb-2">
            <div *ngIf="imageSet" class="btn-group float-right ml-2">
                <label [ngClass]="{ 'btn-loading disabled': loading }" title="Upload" id="download" class="btn btn-outline-primary" (click)="onSubmit()">
                    <i *ngIf="loading" class="fa fa-circle-notch fa-spin"></i><span *ngIf="!loading">Upload</span>
                </label>
            </div>
            <div *ngIf="!cropSelected && imageSelected" class="btn-group float-right ml-2">
                <button [ngClass]="{ 'btn-loading disabled': loading }" (click)="onDone()" type="button" class="btn btn-outline-primary">Done</button>
            </div>
            <div *ngIf="(imageSet || imageSelected) && !loading" class="btn-group float-right ml-2">
                <button [ngClass]="{ 'btn-loading disabled': loading }" (click)="onReset()" type="button" class="btn btn-outline-primary">Cancel</button>
            </div>
        </div>
        <div *ngIf="!imageSet" class="mt-3">
            <div *ngIf="!imageSelected">
                <app-drag-drop [subtitle]="'Supported formats: jpeg, png, bmp'" [accept]="'image/bmp, image/jpeg, image/png'" (onLoad)="onLoad($event)"></app-drag-drop>
            </div>
            <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="aspectRatio"
                format="png"
                (imageLoaded)="imageLoaded()"
                (imageCropped)="imageCropped($event)"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
                [autoCrop]="false"
            ></image-cropper>
        </div>
        <div [ngClass]="{ disabled: loading || !imageSet, 'd-none': !imageSet }" id="image-crop" class="image-crop m-t-md">
            <img class="img-fluid" *ngIf="imageSelected" src="{{ croppedImage }}" alt="Image Cropper" />
        </div>
        <div class="text-center"></div>
    </div>
</div>
