import { Component, OnInit } from '@angular/core'
import { PayoutBatchService } from '../../../services/payout-batch.service'
import { PayoutBatch } from '@parkupp/core'
@Component({
    selector: 'app-admin-list-payout-batches',
    templateUrl: './admin-list-payout-batches.component.html',
    styleUrls: ['./admin-list-payout-batches.component.scss'],
})
export class AdminListPayoutBatchesComponent implements OnInit {
    payoutBatches: PayoutBatch[] = []
    constructor(private payoutBatchService: PayoutBatchService) {}

    async ngOnInit() {
        this.payoutBatches = await this.payoutBatchService.getPayoutBatches()
    }
}
