import { Component, OnInit } from '@angular/core'
import { AngularRoutes, ContractStatus, ExceptionAlert, Subscription } from '@parkupp/core'
import { DocumentData, Query } from '@angular/fire/firestore'
import { GlobalService } from '../../../services/global.service'
import { ActivatedRoute, Router } from '@angular/router'
import { AdminService } from '../../../services/admin.service'
import { ExceptionAlertService } from '../../../services/exception-alert.service'

@Component({
    selector: 'app-admin-list-exceptions',
    templateUrl: './admin-list-exceptions.component.html',
    styleUrls: ['./admin-list-exceptions.component.scss'],
})
export class AdminListExceptionsComponent implements OnInit {
    subscriptions: Subscription[]
    inactiveContractStatuses: ContractStatus[]
    ref: Query<DocumentData>
    status: string
    // exceptionAlerts$: Observable<ExceptionAlert[]>
    readonly AngularRoutes = AngularRoutes
    exceptionAlerts: ExceptionAlert[] = []
    lastVisible: any
    showLoadMoreButton: boolean = true
    reviewed: boolean = false

    constructor(public globalService: GlobalService, private route: ActivatedRoute, private router: Router, private adminService: AdminService, private exceptionAlertService: ExceptionAlertService) {}

    ngOnInit() {
        this.reviewed = false
        this.loadMore()
    }

    async loadMore() {
        this.exceptionAlerts = await this.exceptionAlertService.list({ reviewed: this.reviewed })
    }

    async markAllAsReviewed() {
        if (confirm('Are you sure you want to mark all alerts as reviewed?')) {
            await this.exceptionAlertService.markAllAsReviewed()
            this.loadMore()
        }
    }

    onReviewedChange(event: Event) {
        this.exceptionAlerts = []
        const value = (event.target as HTMLSelectElement).value
        this.reviewed = value === 'true'
        this.loadMore()
    }

    async markAsReviewed(key: string) {
        await this.exceptionAlertService.markAsReviewed(key)
        this.loadMore()
    }

    async markAsUnreviewed(key: string) {
        await this.exceptionAlertService.markAsUnreviewed(key)
        this.loadMore()
    }

    getSeverityClass(severity: string) {
        switch (severity) {
            case 'critical':
                return 'bg-danger'
            case 'high':
                return 'bg-warning'
            case 'info':
                return 'bg-info'
            case 'low':
                return 'bg-secondary'
            default:
                return 'bg-secondary'
        }
    }
}
