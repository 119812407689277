import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MerchantProfileFormComponent } from './merchant-profile-form/merchant-profile-form.component'
import { AuthenticationModule } from '../../shared/authentication/authentication.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'
import { MediaModule } from '../../shared/media/media.module'
import { ModalModule } from 'ngx-bootstrap/modal'
import { CoreModule } from '../../shared/core/core.module'
import { MerchantSidebarComponent } from './merchant-sidebar/merchant-sidebar.component'
import { TabsModule } from 'ngx-bootstrap/tabs'
import { MerchantMembersModule } from '../merchant-members/merchant-members.module'
import { MerchantBankingDetailsModule } from '../merchant-banking-details/merchant-banking-details.module'
import { RouterModule } from '@angular/router'
import { MerchantProfileTabsComponent } from './merchant-profile-tabs/merchant-profile-tabs.component'
import { MerchantVerticalMenuComponent } from './merchant-vertical-menu/merchant-vertical-menu.component'
import { MerchantBankingDetailsOldComponent } from '../merchant-banking-details-old/merchant-banking-details-old.component'
import { MerchantProformaInvoicesComponent } from '../merchant-proforma-invoices/merchant-proforma-invoices.component'
import { FormatDatePipe } from 'src/app/shared/core/pipes/format-date.pipe'
import { MerchantProformaInvoiceDetailComponent } from '../merchant-proforma-invoice-detail/merchant-proforma-invoice-detail.component'

@NgModule({
    declarations: [
        MerchantProfileFormComponent,
        MerchantSidebarComponent,
        MerchantProfileTabsComponent,
        MerchantVerticalMenuComponent,
        MerchantBankingDetailsOldComponent,
        MerchantProformaInvoicesComponent,
        MerchantProformaInvoiceDetailComponent,
    ],
    exports: [
        MerchantProfileFormComponent,
        MerchantSidebarComponent,
        MerchantProfileTabsComponent,
        MerchantVerticalMenuComponent,
        MerchantBankingDetailsOldComponent,
        MerchantProformaInvoicesComponent,
        MerchantProformaInvoiceDetailComponent,
    ],
    imports: [
        CommonModule,
        AuthenticationModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        MediaModule,
        ModalModule,
        FormsModule,
        CoreModule,
        TabsModule,
        MerchantMembersModule,
        MerchantBankingDetailsModule,
        RouterModule,
    ],
})
export class MerchantProfileModule {}
