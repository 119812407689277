"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogEmailType = void 0;
var LogEmailType;
(function (LogEmailType) {
    LogEmailType["admin"] = "Admin";
    LogEmailType["merchant"] = "Merchant";
    LogEmailType["client"] = "Client";
    LogEmailType["other"] = "Other";
})(LogEmailType = exports.LogEmailType || (exports.LogEmailType = {}));
