"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcessTokenPaymentResponse = void 0;
class ProcessTokenPaymentResponse {
    constructor(source) {
        this.error = false;
        if (source) {
            Object.assign(this, source);
        }
    }
}
exports.ProcessTokenPaymentResponse = ProcessTokenPaymentResponse;
