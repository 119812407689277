"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OmnisendVariant = void 0;
class OmnisendVariant {
    // customFields: {protectionClass: 'IP99'}
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
}
exports.OmnisendVariant = OmnisendVariant;
