"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Blog = void 0;
class Blog {
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        const blog = JSON.parse(JSON.stringify(clone));
        blog.createdAt = this.createdAt;
        blog.updatedAt = this.updatedAt;
        return blog;
    }
}
exports.Blog = Blog;
