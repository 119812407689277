<admin-app-header></admin-app-header>
<br />

<div class="container">

    <h1>{{ exceptionAlert.message }}</h1>

    <div>{{ exceptionAlert.timestamp | date: 'medium' }}</div>
    <div>
        <span
            [ngClass]="{'bg-danger': exceptionAlert.alertSeverity === 'critical', 'bg-warning': exceptionAlert.alertSeverity === 'high', 'bg-info': exceptionAlert.alertSeverity === 'info'}"
            class="badge">
            {{ exceptionAlert.alertSeverity }}
        </span>
    </div>
    <div>
        Comments: {{ exceptionAlert.comment }}
    </div>
    <div>
        File: {{ exceptionAlert.fileName }}<span>:{{ exceptionAlert.lineNumber }}</span>
    </div>
    <div>Function:  {{ exceptionAlert.functionName }}</div>
    
    <div *ngIf="exceptionAlert.json">
        <pre><code>{{ exceptionAlert.json | prettyJson }}</code></pre>
    </div>

</div>
