import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import {
    Client,
    clientsPrivatePath,
    ContractStatus,
    FirestoreStructure,
    Invoice,
    InvoiceStatus,
    Merchant,
    Parking,
    ParkingStatus,
    PaymentMethod,
    Payout,
    Subscription,
    SubscriptionRenewalStatus,
    subscriptionsPath,
    SubscriptionType,
    Term,
} from '@parkupp/core'
import { collection, collectionData, doc, docData, orderBy, query, where } from '@angular/fire/firestore'
import * as moment from 'moment'
import { Moment } from 'moment'
import { ParkingService } from '../../../services/parking.service'
import { async, firstValueFrom, of, take } from 'rxjs'
import { InvoiceService } from 'src/app/services/invoice.service'
import Swal from 'sweetalert2'
import { PayoutService } from 'src/app/services/payout.service'

@Component({
    selector: 'app-admin-dashboard-page',
    templateUrl: './admin-dashboard-page.component.html',
    styleUrls: ['./admin-dashboard-page.component.scss'],
})
export class AdminDashboardPageComponent implements OnInit {
    chartSalesData: any
    chartSalesColorScheme: any = { domain: [] }
    chartMonthlyData: any
    chartMonthlyColorScheme: any = { domain: [] }
    chartBaysData: any
    chartBaysColorScheme: any = { domain: [] }
    subscriptions: Subscription[]
    inactiveSubscriptions: Subscription[]
    activeBays: number
    activeSubscriptions: number = 0
    activeSubscriptionsPayfast: number = 0
    activeSubscriptionsPaygate: number = 0
    activeSubscriptionsEft: number = 0
    dates: { start: Moment; end: Moment }[]

    fullContractValueLoaded: Boolean = false
    baysLoaded: boolean = false
    monthlyTurnOverLoaded: Boolean = false
    totalParkings: number = 0
    totalBuildings: number = 0
    expiringSubscriptions: Subscription[] = []
    subscriptionRenewalStatus = Object.keys(SubscriptionRenewalStatus)
    users: number
    usersWithProfile: number
    activeUsers: number
    invoicesNotPaidOut: Invoice[]

    invoiceNotPaidOutTotalAmount: number = 0
    invoiceNotPaidOutTotalCount: number = 0

    invoiceNotPaidOutAmount: number = 0
    invoiceNotPaidOutCount: number = 0

    invoiceNotPaidOutProformaAmount: number = 0
    invoiceNotPaidOutProformaCount: number = 0

    overdueInvoicesCount: number = 0
    overdueInvoicesTotal: number = 0
    overdueInvoicesPayfast: number = 0
    overdueInvoicesPaygate: number = 0
    overdueInvoicesEft: number = 0

    dueInvoicesCount: number = 0
    dueInvoicesTotal: number = 0

    unpaidInvoices: number
    totalUnpublishedBuildings: number
    invoicesWithoutBankDetails: Invoice[] = []
    invoicesWithoutBankDetailsTotalValue: number = 0
    noBankMerchantRefs: any = []
    totalContractValue: number = 0

    constructor(private adminService: AdminService, private parkingService: ParkingService, private invoiceService: InvoiceService, private payoutService: PayoutService) {
        const inactiveContractStatuses = [ContractStatus.active, ContractStatus.bouncing]

        // get total parkings
        this.parkingService.list().subscribe((parkings: Parking[]) => {
            this.totalBuildings = 0
            this.totalParkings = 0
            this.totalUnpublishedBuildings = 0

            parkings.forEach((parking) => {
                if (parking.numberOfBays && parking.parkingStatus == ParkingStatus.PUBLISHED) {
                    this.totalParkings = this.totalParkings + Number(parking.numberOfBays)
                    this.totalBuildings = this.totalBuildings + 1
                } else {
                    this.totalUnpublishedBuildings = this.totalUnpublishedBuildings + 1
                }
            })
        })

        const inactiveRef = query(collection(this.adminService.firestore, subscriptionsPath()), orderBy('createdAt', 'desc'), where('status', 'in', [ContractStatus.paymentInitiated]))
        collectionData(inactiveRef, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.inactiveSubscriptions = []
            documents.forEach((documentData) => {
                let subscription = new Subscription(documentData)
                subscription.$key = documentData.documentId

                docData(documentData.parkingRef).subscribe((data) => {
                    subscription.$parking = new Parking(data)
                    subscription.$parking.$key = documentData.parkingRef.id
                })
                docData(documentData.clientRef).subscribe((data) => {
                    subscription.$client = new Client(data)
                    subscription.$client.$key = documentData.clientRef.id
                })
                docData(documentData.merchantRef).subscribe((data) => {
                    subscription.$merchant = new Merchant(data)
                    subscription.$merchant.$key = documentData.merchantRef.id
                })
                this.inactiveSubscriptions.push(subscription)
            })
        })

        const usersRef = collection(this.adminService.firestore, 'clients')
        collectionData(usersRef, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.users = documents.length
            this.usersWithProfile = documents.filter((doc) => doc.firstName).length

            const currentDate = new Date()
            this.activeUsers = documents.filter((doc) => {
                if (doc.lastLogin && doc.lastLogin.seconds) {
                    const lastLoginDate = new Date(doc.lastLogin.seconds * 1000)
                    const diffTime = Math.abs(currentDate.getTime() - lastLoginDate.getTime())
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                    return diffDays <= 60
                }
                return false
            }).length
        })

        this.invoiceService.listOverdueInvoices().subscribe((invoices) => {
            this.overdueInvoicesCount = invoices.length
            this.overdueInvoicesTotal = 0
            this.overdueInvoicesPayfast = 0
            this.overdueInvoicesPaygate = 0
            this.overdueInvoicesEft = 0
            invoices.forEach((invoiceData) => {
                const invoice = new Invoice(invoiceData)
                this.overdueInvoicesTotal += invoice.amountTotalDue
                if (invoice.paymentMethodType == SubscriptionType.PAYFAST) {
                    this.overdueInvoicesPayfast += invoice.amountTotalDue
                } else if (invoice.paymentMethodType == SubscriptionType.PAYGATE) {
                    this.overdueInvoicesPaygate += invoice.amountTotalDue
                } else if (invoice.paymentMethodType == SubscriptionType.EFT) {
                    this.overdueInvoicesEft += invoice.amountTotalDue
                }
            })
        })

        this.invoiceService.listDueInvoices().subscribe((invoices) => {
            this.dueInvoicesCount = invoices.length
            this.dueInvoicesTotal = 0
            invoices.forEach((invoiceData) => {
                const invoice = new Invoice(invoiceData)
                this.dueInvoicesTotal += invoice.amountTotalDue
            })
        })

        this.invoiceService.listInvoicesNotPaidOut().subscribe(async (invoices) => {
            // TODO: Get all proforma invoices and check if these ivoices are ina proforma also bank details
            // const proformaInvoices = await this.payoutService.getAllPayouts()

            this.invoicesNotPaidOut = invoices

            this.invoicesNotPaidOut.forEach(async (invoiceData) => {
                if (invoiceData.payoutRef) {
                    this.invoiceNotPaidOutProformaAmount += invoiceData.periodFinalPayout
                    this.invoiceNotPaidOutProformaCount += 1
                } else {
                    const invoice = new Invoice(invoiceData)
                    this.invoiceNotPaidOutAmount += invoice.periodFinalPayout
                    this.invoiceNotPaidOutCount += 1
                }

                this.invoiceNotPaidOutTotalAmount += invoiceData.periodFinalPayout
                this.invoiceNotPaidOutTotalCount += 1
            })
        })

        // Get active count
        const activeRef = query(collection(this.adminService.firestore, subscriptionsPath()), where('status', 'in', [ContractStatus.active]))
        collectionData(activeRef, { idField: 'documentId' })
            .pipe(take(1))
            .subscribe((documents: any[]) => {
                this.activeBays = 0
                documents.forEach((documentData) => {
                    let subscription = new Subscription(documentData)
                    subscription.$key = documentData.documentId

                    if (subscription.plan.term == Term.MONTHLY) {
                        this.activeBays = this.activeBays + subscription.baysBooked
                    }

                    this.activeSubscriptions = this.activeSubscriptions + 1

                    if (subscription.type == SubscriptionType.PAYFAST) {
                        this.activeSubscriptionsPayfast = this.activeSubscriptionsPayfast + 1
                    } else if (subscription.type == SubscriptionType.PAYGATE) {
                        this.activeSubscriptionsPaygate = this.activeSubscriptionsPaygate + 1
                    } else if (subscription.type == SubscriptionType.EFT) {
                        this.activeSubscriptionsEft = this.activeSubscriptionsEft + 1
                    }

                    const monthsLeft = moment(subscription.endDate.toDate()).diff(moment(), 'months')
                    const monthlyFee = subscription.periodFee
                    const totalContractValue = (monthlyFee / 100) * monthsLeft

                    this.totalContractValue = this.totalContractValue + totalContractValue
                })
            })

        // Get Expiring Soon
        const expiringRef = query(collection(this.adminService.firestore, subscriptionsPath()), orderBy('endDate'), where('status', 'in', [ContractStatus.active]))
        collectionData(expiringRef, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.expiringSubscriptions = []
            documents.forEach((documentData) => {
                let subscription = new Subscription(documentData)
                subscription.$key = documentData.documentId

                if (documentData.clientRef) {
                    docData(documentData.clientRef).subscribe((data) => {
                        subscription.$client = new Client(data)
                        subscription.$client.$key = documentData.clientRef.id
                    })
                }

                if (documentData.parkingRef) {
                    docData(documentData.parkingRef).subscribe((data) => {
                        subscription.$parking = new Parking(data)
                        subscription.$parking.$key = documentData.parkingRef.id
                    })
                }

                if (subscription.endDate) {
                    const expiryDate = moment(subscription.endDate.toDate().toISOString())
                    const tenDaysFromNow = moment().add(12, 'days')

                    if (expiryDate.isSameOrBefore(tenDaysFromNow, 'day') && expiryDate.isSameOrAfter(moment(), 'day') && !subscription.nextDebitDate) {
                        this.expiringSubscriptions.push(subscription)
                    }
                }
            })

            this.findAndAppendClientPrivate()
        })

        const ref = query(collection(this.adminService.firestore, subscriptionsPath()), where('status', 'in', inactiveContractStatuses))
        collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.subscriptions = []
            documents.forEach((documentData) => {
                let subscription = new Subscription(documentData)
                subscription.$key = documentData.documentId
                this.subscriptions.push(subscription)
            })

            this.loadMonthlyTurnOver()

            this.loadBays()

            this.loadContractRevenue()
        })

        const invoicesRef = query(collection(this.adminService.firestore, FirestoreStructure.INVOICES), where('status', '==', InvoiceStatus.PAID), where('paidOut', '==', false))
        collectionData(invoicesRef, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.invoicesWithoutBankDetails = []
            documents.forEach((documentData) => {
                let invoice: Invoice = new Invoice(documentData)
                invoice.$key = documentData.documentId

                if (documentData.merchantRef) {
                    docData(documentData.merchantRef).subscribe((data) => {
                        invoice.$merchant = new Merchant(data)
                        invoice.$merchant.$key = documentData.merchantRef.id

                        if (!invoice.$merchant.payoutDetails || !invoice.$merchant.payoutDetails.accountNumber) {
                            this.invoicesWithoutBankDetails.push(invoice)

                            if (!this.noBankMerchantRefs.some((ref: any) => ref.denormMerchantCompanyName === invoice.denormMerchantCompanyName)) {
                                this.noBankMerchantRefs.push({
                                    id: documentData.merchantRef.id,
                                    denormMerchantCompanyName: invoice.denormMerchantCompanyName,
                                })
                            }
                            this.invoicesWithoutBankDetailsTotalValue += invoice.periodFinalPayout
                        }
                    })
                }
            })
        })

        // this.colorScheme = {
        //     domain: [
        //         '#ac9aff', // Draft
        //         '#6796ff', // Recurring
        //         '#dbdbdb', // amountPaid
        //         '#8affb7', // Amount due
        //         '#e3b649', // Quotes accepted
        //         '#ffecbf', // Quotes sent
        //         '#ffabb9', // Timelogs
        //     ],
        // }
    }

    async findAndAppendClientPrivate() {
        const clientsPrivateRef = query(collection(this.adminService.firestore, clientsPrivatePath()))

        const clientsPrivateData = await firstValueFrom(collectionData(clientsPrivateRef, { idField: 'documentId' }))

        if (clientsPrivateData) {
            const clientsPrivateMap = new Map(clientsPrivateData.map((clientPrivate: any) => [clientPrivate.documentId, clientPrivate]))

            this.expiringSubscriptions.forEach((subscription) => {
                if (subscription.$client) {
                    // Add this check to prevent accessing properties of undefined
                    const clientPrivate = clientsPrivateMap.get(subscription.$client.$key)

                    if (clientPrivate) {
                        const updatedClient = {
                            ...subscription.$client,
                            $email: clientPrivate.email, // Only include the email property
                            $phoneNumber: clientPrivate.phoneNumber, // Only include the phoneNumber property
                        }

                        subscription.$client = Object.assign(new Client(), updatedClient)
                    }
                }
            })
        }
    }

    loadMonthlyTurnOver() {
        if (!this.monthlyTurnOverLoaded) {
            // ---------------------------------------------------
            // monthly
            // ---------------------------------------------------
            this.chartMonthlyColorScheme = {
                domain: ['#ac9aff'],
            }
            this.chartMonthlyData = []
            this.dates = []

            this.dates.push({
                start: moment().startOf('month'),
                end: moment().endOf('month'),
            })
            for (let i = 1; i < 12; i++) {
                this.dates.push({
                    start: moment().add(i, 'months').startOf('month'),
                    end: moment().add(i, 'months').endOf('month'),
                })
            }

            this.dates.forEach((chartDate) => {
                let total = 0
                const monthStartDate = chartDate.start
                const monthEndDate = chartDate.end

                this.subscriptions.forEach((subscription: Subscription) => {
                    // if (subscription.endDate.toDate() > date) {
                    // if (moment(subscription.endDate.toDate()).format() < moment(date.end).format() && moment(subscription.startDate.toDate()).format() > moment(date.start).format()) {
                    //     total = total + subscription.plan.feeInCents / 100
                    // }

                    const subStartDate = moment(subscription.startDate.toDate())
                    const subEndDate = moment(subscription.endDate.toDate())

                    let include = false
                    if (monthStartDate.add(1, 'day').isSameOrAfter(subStartDate)) {
                        include = true
                    }
                    if (subEndDate.isBefore(monthStartDate)) {
                        include = false
                    }
                    if (include) {
                        total = total + (subscription.plan.feeInCents * subscription.baysBooked) / 100
                    }
                })

                this.chartMonthlyData.push({
                    name: moment(chartDate.start).format('MMM-YY'),
                    series: [
                        {
                            name: 'Total',
                            value: total,
                        },
                    ],
                })
            })
        }
    }

    loadBays() {
        if (!this.baysLoaded) {
            // ---------------------------------------------------
            // Bays
            // ---------------------------------------------------
            this.chartBaysColorScheme = {
                domain: ['#6796ff'],
            }
            this.chartBaysData = []
            this.dates = []

            this.dates.push({
                start: moment().startOf('month'),
                end: moment().endOf('month'),
            })
            for (let i = 0; i < 12; i++) {
                // this.dates.push(moment().endOf('month').add(i, 'months'))
                this.dates.push({
                    start: moment().add(i, 'months').startOf('month'),
                    end: moment().add(i, 'months').endOf('month'),
                })
            }

            this.dates.forEach((chartDate) => {
                let bays = 0
                const monthStartDate = chartDate.start
                const monthEndDate = chartDate.end

                this.subscriptions.forEach((subscription: Subscription) => {
                    // if (moment(subscription.endDate.toDate()).format() < moment(date.end).format() && moment(subscription.startDate.toDate()).format() > moment(date.start).format()) {
                    // if (moment(subscription.startDate.toDate()).format() > moment(date.start).format() && !(moment(subscription.endDate.toDate()).format() < moment(date.end).format())) {
                    const subStartDate = moment(subscription.startDate.toDate())
                    const subEndDate = moment(subscription.endDate.toDate())

                    let include = false
                    // if (chartDate.start.isSameOrAfter(subStartDate) && !chartDate.end.isAfter(subEndDate)) {
                    if (monthStartDate.add(1, 'day').isSameOrAfter(subStartDate)) {
                        include = true
                    }
                    if (subEndDate.isBefore(monthStartDate)) {
                        include = false
                    }
                    if (include) {
                        bays = bays + subscription.baysBooked
                    }
                })

                this.chartBaysData.push({
                    name: moment(chartDate.start).format('MMM-YY'),
                    series: [
                        {
                            name: 'Bays',
                            value: bays,
                        },
                    ],
                })
            })
            this.baysLoaded = true
        }
    }

    loadContractRevenue() {
        if (!this.fullContractValueLoaded) {
            // ---------------------------------------------------
            // Monthly Sales
            // ---------------------------------------------------
            this.chartSalesColorScheme = {
                domain: ['#8affb7'],
            }
            this.chartSalesData = []
            this.dates = []

            for (let i = 11; i > 0; i--) {
                // this.dates.push(moment().endOf('month').subtract(i, 'months').toDate())
                this.dates.push({
                    start: moment().subtract(i, 'months').startOf('month'),
                    end: moment().subtract(i, 'months').endOf('month'),
                })
            }

            this.dates.push({
                start: moment().startOf('month'),
                end: moment().endOf('month'),
            })

            for (let i = 1; i <= 3; i++) {
                this.dates.push({
                    start: moment().add(i, 'months').startOf('month'),
                    end: moment().add(i, 'months').endOf('month'),
                })
            }

            this.dates.forEach((date) => {
                let total = 0

                this.subscriptions.forEach((subscription: Subscription) => {
                    //     subDate: moment(subscription.startDate.toDate()).format('MMM-YY'),
                    // })
                    if (moment(subscription.createdAt.toDate()).format() < moment(date.end).format() && moment(subscription.createdAt.toDate()).format() > moment(date.start).format()) {
                        total = total + subscription.totalContractValue
                    }
                })

                this.chartSalesData.push({
                    name: moment(date.end).format('MMM-YY'),
                    series: [
                        {
                            name: 'Total',
                            value: Math.round(total / 100),
                        },
                    ],
                })
            })
        }
    }

    async checkIfInvoiceIsInProforma(invoice: Invoice, proformaInvoices: Payout[]) {
        const invoiceRef: any = this.invoiceService.getDocRef(invoice.$key!)
        return proformaInvoices.some((proformaInvoice) => proformaInvoice.invoiceRefs.includes(invoiceRef))
    }

    // TODO: DYLAN: Need admin auth guards here
    ngOnInit(): void {}

    helpFutureContractValue() {
        Swal.fire(
            'Future Contract Value',
            'The totalContractValue is calculated by looking at each active subscription. For each subscription, it uses the periodFee (monthly fee) and calculates how many months (monthsLeft) are remaining until the endDate of the subscription. It multiplies the monthly fee by the number of months left to get the total value for that subscription. This value is then added to totalContractValue, which represents the total expected revenue from all active subscriptions.'
        )
    }

    helpOverdueInvoices() {
        Swal.fire(
            'Overdue Invoices',
            "This is the list of invoices where we've invoiced the parker, but the parker hasn't paid. Right now, this only includes payfast and manual invoices, because paygate invoices will automatically get marked as paid. In future, this will only include manual invoices."
        )
    }

    helpInvoicesNotPaidOut() {
        Swal.fire(
            'Invoices Not Paid Out',
            "This is all invoices that have been paid to us, but we haven't paid the landlord yet. In the future, this list should only contain the landlords who want to be paid at the end of the month. But this may also include landlords that are missing bank details."
        )
    }

    helpInvoicesMissingBankDetails() {
        Swal.fire('Invoices Missing Bank Details', "This is invoices that Natasha can't pay out because the landlord doesn't have bank details.")
    }

    helpUsers() {
        Swal.fire('Users', 'This is everybody that authenticated with the system, not necessarily everybody that created a parking, and also not everybody that created a profile.')
    }

    helpSalesByMonth() {
        Swal.fire(
            'Sales by month',
            'This value shows the full amount that the parker would pay if they were to pay the whole amount on the creation date of the subscription. This does not take into account the length of the parking subscription, but assumes that parker pays the whole amount in the first month.'
        )
    }
    helpAnticipatedTurnover() {
        Swal.fire('Anticipated Turnover', 'This is the month-on-month turnover that we will receive if no new contracts come in and no contracts are cancelled.')
    }
    formatToK(value: number) {
        if (value >= 1000000) {
            return 'R' + (value / 1000000).toFixed(1) + 'M'
        } else if (value >= 1000) {
            return 'R' + (value / 1000).toFixed(0) + 'K'
        }
        return 'R' + value.toString()
    }
}
