"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Invoice = void 0;
const invoice_status_enum_1 = require("../enums/invoice-status.enum");
const invoice_line_item_model_1 = require("./invoice-line-item.model");
class Invoice {
    constructor(source, key) {
        this.VAT_RATE = 0.15;
        this.status = invoice_status_enum_1.InvoiceStatus.DUE;
        this.lineItems = [];
        this.paygateAttempts = 0;
        this.paidOut = false;
        if (source) {
            Object.assign(this, source);
            if (source.lineItems) {
                // Convert lineItems from a plain object array to LineItem class instances
                this.lineItems = source.lineItems.map((item) => new invoice_line_item_model_1.InvoiceLineItem(item));
            }
        }
        if (key) {
            this.$key = key;
        }
    }
    // }
    // serialize(): any {
    //     const clone = { ...this } // Shallow copy of the object
    //
    //     // Iterate over the properties of the object
    //     for (const key in clone) {
    //         // Remove properties that start with '$'
    //         if (clone.hasOwnProperty(key) && key.startsWith('$')) {
    //             delete clone[key]
    //             continue
    //         }
    //
    //         // Check if the property is a Firestore Timestamp and handle it appropriately
    //         if (clone[key] instanceof Timestamp) {
    //             clone[key] = this[key]
    //             continue
    //         }
    //
    //         // Check if the property is empty or undefined
    //         if (clone[key] === undefined) {
    //             delete clone[key]
    //             continue
    //         }
    //
    //         // Preserve properties that end with 'ref' or 'refs' and are not empty
    //         if (key.endsWith('ref') || key.endsWith('refs') || (this[key] !== undefined && this[key] !== null && this[key] !== '')) {
    //             clone[key] = this[key]
    //         }
    //     }
    //
    //     console.log('Serialized', clone)
    //
    //     return clone
    // Calculate total excluding VAT
    get totalExcludingVAT() {
        return this.amountTotalDue / (1 + this.VAT_RATE);
    }
    // Calculate total VAT
    get totalVAT() {
        return this.amountTotalDue - this.totalExcludingVAT;
    }
    get description() {
        if (this.lineItems.length > 0) {
            return this.lineItems[0].description;
        }
        return '';
    }
    get formatTotal() {
        const rand = this.amountTotalDue.toFixed(2);
        return `R${rand}`;
    }
    get processingFee() {
        // return this.plan.feeInCents * (this.commission / 100)
        return this.amountTotalDue * (this.commission / 100);
    }
    get commission() {
        if (this.$merchant && typeof this.$merchant?.commissionPercentage !== 'undefined') {
            return this.$merchant.commissionPercentage;
        }
        return 15;
    }
    get periodFinalPayout() {
        return this.amountTotalDue - this.processingFee;
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        delete clone.$client;
        delete clone.$merchant;
        delete clone.$parking;
        const output = JSON.parse(JSON.stringify(clone));
        if (this.clientRef) {
            output.clientRef = this.clientRef;
        }
        if (this.paymentMethodRef) {
            output.paymentMethodRef = this.paymentMethodRef;
        }
        if (this.merchantRef) {
            output.merchantRef = this.merchantRef;
        }
        if (this.parkingRef) {
            output.parkingRef = this.parkingRef;
        }
        if (this.subscriptionRef) {
            output.subscriptionRef = this.subscriptionRef;
        }
        output.createdAt = this.createdAt;
        output.updatedAt = this.updatedAt;
        output.dueDate = this.dueDate;
        if (this.paidOutDate) {
            output.paidOutDate = this.paidOutDate;
        }
        return output;
    }
    getStatusClass() {
        const today = new Date().getTime();
        if (this.status === invoice_status_enum_1.InvoiceStatus.PAID) {
            return 'bg-success';
        }
        else if (this.dueDate && this.dueDate.toDate().getTime() < today) {
            return 'bg-danger';
        }
        else if (this.status === invoice_status_enum_1.InvoiceStatus.DUE) {
            return 'bg-warning';
        }
        else {
            return '';
        }
    }
}
exports.Invoice = Invoice;
