import { Component, OnInit } from '@angular/core'
import { collectionData } from '@angular/fire/firestore'
import { collection, orderBy, where } from '@angular/fire/firestore'
import { query } from '@angular/fire/firestore'
import { FirestoreStructure, MerchantUserPrivate } from '@parkupp/core'
import { map } from 'rxjs/operators'
import { AdminService } from 'src/app/services/admin.service'
import { GlobalService } from 'src/app/services/global.service'

@Component({
    selector: 'app-admin-list-merchants-member-invites',
    templateUrl: './admin-list-merchants-member-invites.component.html',
    styleUrls: ['./admin-list-merchants-member-invites.component.scss'],
})
export class AdminListMerchantsMemberInvitesComponent implements OnInit {
    invites: any[]

    constructor(private adminSvc: AdminService, public globalService: GlobalService) {}

    ngOnInit(): void {
        this.getMerchantMemberInvites()
    }

    getMerchantMemberInvites() {
        let merchantMemberInvitesRef = query(collection(this.adminSvc.firestore, FirestoreStructure.MERCHANT_MEMBER_INVITES), orderBy('email', 'asc'))
        let merchantsRef = query(collection(this.adminSvc.firestore, FirestoreStructure.MERCHANTS))

        collectionData(merchantMemberInvitesRef, { idField: 'documentId' })
            .pipe(
                map((invitesData: any[]) => {
                    return invitesData.map((inviteData: any) => {
                        return {
                            ...inviteData,
                        }
                    })
                })
            )
            .subscribe((invites: any[]) => {
                this.invites = invites

                collectionData(merchantsRef, { idField: 'documentId' })
                    .pipe(
                        map((merchantsData: any[]) => {
                            return merchantsData.map((merchantData: any) => {
                                return {
                                    ...merchantData,
                                    $key: merchantData.documentId,
                                }
                            })
                        })
                    )
                    .subscribe((merchants: any[]) => {
                        this.invites = this.invites.map((invite) => {
                            invite.merchant = merchants.find((merchant) => merchant.$key === invite.merchantRef?.id)
                            return invite
                        })
                    })
            })
    }
}
