import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import {
    Auth,
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    signInAnonymously,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    signInWithPopup,
} from '@angular/fire/auth'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { BehaviorSubject, filter, map, Observable, Subject, Subscription } from 'rxjs'
import * as auth from 'firebase/auth'
import { IEmailVerificationToken, Merchant, MerchantMemberMap, MerchantRole } from '@parkupp/core'
import { MerchantService } from './merchant.service'
import { AdminService } from './admin.service'
import User = auth.User

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    user: User
    merchants: Merchant[] = []
    merchantMemberRoles: { [id: string]: MerchantMemberMap } = {}
    activeMerchant: Merchant | null
    merchantMapSubscription: Subscription
    merchantsLoaded = false
    isAdmin = false
    onSignOut = new Subject()
    authenticatedUser$: Observable<User>
    private userSubject = new BehaviorSubject<User | null>(null)
    user$ = this.userSubject.asObservable()

    constructor(public auth: Auth, public router: Router, private http: HttpClient, private merchantService: MerchantService, private adminService: AdminService) {
        this.auth.onAuthStateChanged((user: any) => {
            if (user) {
                this.user = user
                this.userSubject.next(user)
                this.merchantsLoaded = false
                if (!this.user.isAnonymous) {
                    this.checkAdmin(user).then(() => {})
                    this.setMerchantMap()
                    this.subscribeToMerchants()
                } else {
                    this.merchants = []
                    this.isAdmin = false
                    this.activeMerchant = null
                    this.merchantMemberRoles = {}
                    if (this.merchantMapSubscription) {
                        this.merchantMapSubscription.unsubscribe()
                    }
                }
            }
        })
        this.authenticatedUser$ = this.user$.pipe(
            filter((user): user is User => !!user && !user.isAnonymous),
            map((user) => user as User)
        )
    }

    get uid(): string {
        return <string>this.auth.currentUser?.uid
    }

    get email(): string | null {
        if (this.auth.currentUser && this.auth.currentUser.email) {
            return this.auth.currentUser.email
        }
        return null
    }

    get phoneNumber(): string | null {
        if (this.auth.currentUser && this.auth.currentUser.phoneNumber) {
            return this.auth.currentUser.phoneNumber
        }
        return null
    }

    get isAnonymous(): boolean {
        return this.user !== null && this.user.isAnonymous
    }

    get admin(): boolean {
        return this.isAdmin
    }

    static getHeaders(idToken: string) {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}`,
            }),
        }
    }

    checkAdmin(user: User) {
        return new Promise<void>((resolve) => {
            this.adminService
                .getAdmins(user.uid)
                .then((admin) => {
                    this.isAdmin = admin.active
                    resolve()
                })
                .catch(() => {
                    this.isAdmin = false
                    resolve()
                })
        })
    }

    reloadUser(): Promise<void> {
        return this.auth.currentUser!.reload()
    }

    subscribeToMerchants() {
        if (this.merchantMapSubscription) {
            this.merchantMapSubscription.unsubscribe()
        }
        this.merchantMapSubscription = this.merchantService.subscribeToMerchantMap(this.user.uid).subscribe((documents) => {
            const promises: Promise<Merchant>[] = []
            documents.forEach((data) => {
                this.merchantMemberRoles[(data as any).documentId] = data as any
                promises.push(this.getMerchant((data as any).documentId))
            })
            Promise.all(promises).then((merchants) => {
                this.merchants = merchants.filter((merchant) => {
                    return merchant.deletedAt == null
                })
                if (this.merchants.length > 0 && !this.activeMerchant) {
                    const cookieClaimName = this.getCookie('ActiveMerchantClaim')
                    if (cookieClaimName) {
                        const foundClaim = this.merchants.find((i) => i.companyName === cookieClaimName)
                        if (foundClaim) {
                            this.activeMerchant = foundClaim
                        } else {
                            this.activeMerchant = this.merchants[0]
                        }
                    } else {
                        this.activeMerchant = this.merchants[0]
                    }
                }
                this.merchantsLoaded = true
            })
        })
    }

    getMerchants(): Promise<Merchant[]> {
        return new Promise<Merchant[]>((resolve) => {
            const merchantMapSubscription = this.merchantService.subscribeToMerchantMap(this.user.uid).subscribe((documents) => {
                const promises: Promise<Merchant>[] = []
                documents.forEach((data) => {
                    this.merchantMemberRoles[(data as any).documentId] = data as any
                    promises.push(this.getMerchant((data as any).documentId))
                })
                Promise.all(promises).then((merchants) => {
                    resolve(merchants)
                    merchantMapSubscription.unsubscribe()
                })
            })
        })
    }

    getMerchant(id: string): Promise<Merchant> {
        return new Promise<Merchant>((resolve) => {
            this.merchantService.subscribeToMerchant(id).subscribe((data: any) => {
                const merchant = new Merchant(data)
                merchant.$key = id
                resolve(merchant)
            })
        })
    }

    setMerchantMap() {
        return new Promise<void>((resolve) => {
            this.getMerchants().then((merchants) => {
                this.merchants = merchants.filter((merchant) => {
                    return merchant.deletedAt == null
                })
                if (this.merchants.length > 0) {
                    const cookieClaimName = this.getCookie('ActiveMerchantClaim')
                    if (cookieClaimName) {
                        const foundClaim = this.merchants.find((i) => i.companyName === cookieClaimName)
                        if (foundClaim) {
                            this.activeMerchant = foundClaim
                        } else {
                            this.activeMerchant = this.merchants[0]
                        }
                    } else {
                        this.activeMerchant = this.merchants[0]
                    }
                }
                this.merchantsLoaded = true
                resolve()
            })
        })
    }

    setActiveMerchant(merchant: Merchant) {
        this.activeMerchant = merchant

        this.setCookie({
            name: 'ActiveMerchantClaim',
            value: this.activeMerchant.companyName,
            expireDays: 7,
        })

        this.router.navigate([`merchant/${this.activeMerchant.$key}`]).then(() => {
            window.location.reload()
        })
    }

    //TODO: @Dylan  I need this function Maybe we can change it to a subscription?
    waitForUid(): Promise<string> {
        return new Promise<any>((resolve) => {
            if (this.auth.currentUser?.uid) {
                resolve(<string>this.auth.currentUser?.uid)
            } else {
                setTimeout(() => {
                    resolve(this.waitForUid())
                }, 100)
            }
        })
    }

    updatePhoneNumber(phoneNumber: string, idToken: string): Observable<any> {
        return this.http.post<any>(`${environment.functionsUrl}/authentication-updatePhoneNumber`, { phoneNumber: phoneNumber }, AuthenticationService.getHeaders(idToken))
    }

    updateEmail(email: string, idToken: string): Observable<any> {
        return this.http.post<any>(`${environment.functionsUrl}/authentication-updateEmail`, { email: email }, AuthenticationService.getHeaders(idToken))
    }

    createCustomToken(email: string, idToken: string): Observable<any> {
        return this.http.post<any>(`${environment.functionsUrl}/authentication-createCustomToken`, { email: email }, AuthenticationService.getHeaders(idToken))
    }

    signInWithCustomToken(token: string): Promise<auth.UserCredential> {
        return signInWithCustomToken(this.auth, token) as any
    }

    signInWithPassword(email: string, password: string): Promise<any> {
        return signInWithEmailAndPassword(this.auth, email, password)
    }

    createUserWithEmailAndPassword(email: string, password: string): Promise<any> {
        return createUserWithEmailAndPassword(this.auth, email, password)
    }

    signInWithGoogle(): Promise<any> {
        return signInWithPopup(this.auth, new GoogleAuthProvider())
    }

    signInWithFacebook(): Promise<any> {
        return signInWithPopup(this.auth, new FacebookAuthProvider())
    }

    signInAnonymously(): Promise<any> {
        return signInAnonymously(this.auth)
    }

    signOut(): Promise<any> {
        this.onSignOut.next(true)
        this.isAdmin = false
        this.merchants = []
        this.merchantMemberRoles = {}
        if (this.merchantMapSubscription) {
            this.merchantMapSubscription.unsubscribe()
        }
        return this.auth.signOut()
    }

    sendPasswordResetEmail(email: string): Promise<any> {
        return sendPasswordResetEmail(this.auth, email, { url: 'https://parkupp.co.za/sign-in' })
    }

    sendEmailVerification(idToken: string): Observable<any> {
        return this.http.post<any>(`${environment.functionsUrl}/authentication-resendVerificationEmail`, {}, AuthenticationService.getHeaders(idToken))
    }

    verifyEmail(emailVerificationToken: IEmailVerificationToken): Observable<any> {
        return this.http.post<any>(`${environment.functionsUrl}/authentication-verifyEmail`, emailVerificationToken, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
    }

    testPermissions(merchantKey: string) {
        for (const key in this.merchantMemberRoles) {
            console.log(key) // merchantKey
        }
        if ((this.merchantMemberRoles && this.merchantMemberRoles[merchantKey] && this.merchantMemberRoles[merchantKey].roles.indexOf(MerchantRole.PORTFOLIO_MANAGER)) || this.isAdmin) {
            console.log('access granted')
        }
    }

    changeClientPassword(clientKey: string, password: string, idToken: string): Observable<any> {
        return this.http.post<any>(`${environment.functionsUrl}/authentication-changeClientPassword`, { uid: clientKey, password: password }, AuthenticationService.getHeaders(idToken))
    }

    getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';')
        let caLen: number = ca.length
        let cookieName = `${name}=`
        let c: string

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '')
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length)
            }
        }
        return ''
    }

    setCookie(params: any) {
        let d: Date = new Date()
        d.setTime(d.getTime() + (params.expireDays ? params.expireDays : 1) * 24 * 60 * 60 * 1000)
        document.cookie =
            (params.name ? params.name : '') +
            '=' +
            (params.value ? params.value : '') +
            ';' +
            (params.session && params.session == true ? '' : 'expires=' + d.toUTCString() + ';') +
            'path=' +
            (params.path && params.path.length > 0 ? params.path : '/') +
            ';' +
            (location.protocol === 'https:' && params.secure && params.secure == true ? 'secure' : '')
    }
}
