"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParkingStatus = void 0;
/**
 *
 */
var ParkingStatus;
(function (ParkingStatus) {
    ParkingStatus["NEW"] = "NEW";
    ParkingStatus["PENDING"] = "PENDING";
    ParkingStatus["PUBLISHED"] = "PUBLISHED";
    ParkingStatus["DISABLED"] = "DISABLED";
})(ParkingStatus = exports.ParkingStatus || (exports.ParkingStatus = {}));
