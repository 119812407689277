"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceLineItem = void 0;
class InvoiceLineItem {
    // taxType = 'Standard Rate'
    // accountCode = 200
    // itemCode: "ABC123",
    constructor(source) {
        this.VAT_RATE = 0.15;
        if (source) {
            Object.assign(this, source);
        }
    }
    get vatRateName() {
        return this.VAT_RATE * 100 + '%';
    }
    get lineTotal() {
        return this.unitPrice * this.quantity;
    }
    // Calculate total VAT
    get totalVAT() {
        return this.lineTotal - this.totalExcludingVAT;
    }
    // Calculate total excluding VAT
    get totalExcludingVAT() {
        return this.lineTotal / (1 + this.VAT_RATE);
    }
}
exports.InvoiceLineItem = InvoiceLineItem;
