import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { collection, collectionData, query } from '@angular/fire/firestore'
import { LogSearch, logSearchesPath } from '@parkupp/core'

@Component({
    selector: 'app-admin-list-searches-page',
    templateUrl: './admin-list-searches-page.component.html',
    styleUrls: ['./admin-list-searches-page.component.scss'],
})
export class AdminListSearchesPageComponent implements OnInit {
    logSearches: LogSearch[]

    constructor(private adminSvc: AdminService) {
        collectionData(query(collection(this.adminSvc.firestore, logSearchesPath())), { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.logSearches = []
            documents.forEach((documentData) => {
                let logSearch = new LogSearch(documentData)
                this.logSearches.push(logSearch)
            })
        })
    }

    ngOnInit(): void {}
}
