<admin-app-header></admin-app-header>
<div class="container-fluid">
    <h2>Line Charts</h2>

    <div class="chart-toggles">
        <span *ngFor="let metric of dashboardMetrics" (click)="toggleChart(metric)" class="badge me-1 mb-1" [ngClass]="{ 'bg-success': isVisible(metric), 'bg-danger': !isVisible(metric) }">
            {{ getChartTitle(metric) }}
        </span>
    </div>

    <div class="row mt-3">
        <ng-container *ngFor="let chart of chartData">
            <div class="col-3" *ngIf="isVisible(chart.metric)">
                <div style="height: 200px">
                    <h5 class="px-3">{{ chart.chartTitle }}</h5>
                    <ngx-charts-line-chart
                        [results]="chart.chartData"
                        [xAxis]="true"
                        [yAxis]="true"
                        [legend]="false"
                        [showXAxisLabel]="false"
                        [showYAxisLabel]="false"
                        [xAxisLabel]="chart.xAxisLabel"
                        [yAxisLabel]="chart.yAxisLabel"
                        [autoScale]="true"
                        [timeline]="true"
                        [scheme]="chart.colourScheme"
                        [yAxisTickFormatting]="formatToK"
                    >
                    </ngx-charts-line-chart>
                </div></div
        ></ng-container>
    </div>
</div>
