<admin-app-header></admin-app-header>
<div class="container-fluid">
    <br />
    <div class="row mb-5">
        <div class="col-md-2">
            <div class="top-stats-card" style="background-color: #f7e7a9">
                <h5 class="card-title">Future Contract Value <i (click)="helpFutureContractValue()" class="fa fa-info-circle"></i></h5>
                Value: {{ this.totalContractValue | formatRands }}
            </div>
        </div>

        <div class="col-md-2">
            <div class="top-stats-card" style="background-color: #d3d3d3">
                <h5 class="card-title">Buildings / Parkings</h5>
                Active: {{ this.totalBuildings }}
                <br />
                Drafts: {{ this.totalUnpublishedBuildings }}
            </div>
        </div>

        <div class="col-md-2">
            <div class="top-stats-card" style="background-color: #a8d5ba">
                <h5 class="card-title">Parking Bays</h5>
                Active: {{ this.activeBays }}
                <br />
                Total: {{ this.totalParkings }}
                <br />
                Capacity: {{ (this.activeBays / this.totalParkings) * 100 | number: '1.2-2' }}%
            </div>
        </div>

        <div class="col-md-2">
            <div class="top-stats-card" style="background-color: #e5d0fb">
                <h5 class="card-title">Subscriptions / Lease Agreements</h5>
                Total Overdue: {{ this.activeSubscriptions }}
                <br />
                Payfast Overdue: {{ this.activeSubscriptionsPayfast }}
                <br />
                Paygate Overdue: {{ this.activeSubscriptionsPaygate }}
                <br />
                EFT Overdue: {{ this.activeSubscriptionsEft }}
            </div>
        </div>

        <div *ngIf="this.overdueInvoicesCount" class="col-md-2">
            <div class="top-stats-card" style="background-color: #f5a9a9">
                <h5 class="card-title">Parker Invoices <i (click)="helpOverdueInvoices()" class="fa fa-info-circle"></i></h5>
                <a class="inline-stat-link" routerLink="/admin/invoices">Overdue:</a>
                {{ this.overdueInvoicesTotal | formatRands }} ({{ this.overdueInvoicesCount }})
                <br />
                Payfast: {{ this.overdueInvoicesPayfast | formatRands }}
                <br />
                Paygate: {{ this.overdueInvoicesPaygate | formatRands }}
                <br />
                EFT: {{ this.overdueInvoicesEft | formatRands }}
            </div>
        </div>

        <div *ngIf="this.invoiceNotPaidOutCount || this.invoiceNotPaidOutProformaCount" class="col-md-2">
            <div class="top-stats-card" style="background-color: #d4a5c9">
                <h5 class="card-title">Merchant Payouts <i (click)="helpInvoicesNotPaidOut()" class="fa fa-info-circle"></i></h5>
                Total Not Paid Out Invoices: {{ this.invoiceNotPaidOutTotalAmount | formatRands }} ({{ this.invoiceNotPaidOutTotalCount }})
                <br />
                <a class="inline-stat-link" routerLink="/admin/invoice-payouts">Missing Bank Details:</a>
                {{ this.invoicesWithoutBankDetailsTotalValue | formatRands }} ({{ this.invoicesWithoutBankDetails.length }})
                <br />
                <a class="inline-stat-link" routerLink="/admin/invoice-payouts">Invoices not Paid Out:</a>
                {{ this.invoiceNotPaidOutAmount | formatRands }} ({{ this.invoiceNotPaidOutCount }})
                <br />
                <a class="inline-stat-link" routerLink="/admin/payouts">Invoices in Proforma:</a>
                {{ this.invoiceNotPaidOutProformaAmount | formatRands }} ({{ this.invoiceNotPaidOutProformaCount }})
            </div>
        </div>

        <div *ngIf="this.users" class="col-md-2">
            <div class="top-stats-card" style="background-color: #a9d3f5">
                <h5 class="card-title">Users <i (click)="helpUsers()" class="fa fa-info-circle"></i></h5>
                Users: {{ this.users }}
                <br />
                With Profile: {{ this.usersWithProfile }}
                <br />
                60 Day Active: {{ this.activeUsers }}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="d-none d-sm-block col-lg-4 mb-5 mr-5">
            <div style="height: 200px">
                <h5 class="px-3">Sales by month <i (click)="helpSalesByMonth()" class="fa fa-info-circle"></i></h5>
                <ngx-charts-bar-vertical-stacked
                    [animations]="true"
                    [gradient]="false"
                    [legend]="false"
                    [noBarWhenZero]="true"
                    [results]="chartSalesData"
                    [scheme]="chartSalesColorScheme"
                    [showDataLabel]="true"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="true"
                    [dataLabelFormatting]="formatToK"
                >
                </ngx-charts-bar-vertical-stacked>
            </div>
        </div>
        <div class="d-none d-sm-block col-lg-4 mb-5 mr-5">
            <div style="height: 200px">
                <h5 class="px-3">Anticipated Turn over <i (click)="helpAnticipatedTurnover()" class="fa fa-info-circle"></i></h5>
                <ngx-charts-bar-vertical-stacked
                    [animations]="true"
                    [gradient]="false"
                    [legend]="false"
                    [noBarWhenZero]="true"
                    [results]="chartMonthlyData"
                    [scheme]="chartMonthlyColorScheme"
                    [showDataLabel]="true"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="false"
                    [dataLabelFormatting]="formatToK"
                >
                </ngx-charts-bar-vertical-stacked>
            </div>
        </div>
        <div class="d-none d-sm-block col-lg-4 mb-5 mr-5">
            <div style="height: 200px">
                <h5 class="px-3 mt-3">Bays Booked</h5>
                <ngx-charts-bar-vertical-stacked
                    [animations]="true"
                    [gradient]="false"
                    [legend]="false"
                    [noBarWhenZero]="true"
                    [results]="chartBaysData"
                    [scheme]="chartBaysColorScheme"
                    [showDataLabel]="true"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="false"
                >
                </ngx-charts-bar-vertical-stacked>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="content col-lg-3">
            <h5 class="px-3">Subscriptions pending approval</h5>
            <table class="table table-striped table-hover ms-2">
                <thead>
                    <tr>
                        <th scope="col">Created</th>
                        <th scope="col">Status</th>
                        <th scope="col">Merchant</th>
                        <th scope="col">Parking</th>
                        <th scope="col">Client</th>
                        <th scope="col">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let subscription of inactiveSubscriptions">
                        <td>
                            <a [routerLink]="['/admin/subscription', subscription.$key]">
                                {{ subscription.createdAt | daysAgo }}
                            </a>
                        </td>
                        <td>
                            <span [ngClass]="subscription.statusBadgeColour" class="badge"> Paid </span>
                        </td>
                        <td>
                            <a [routerLink]="['/merchant/', subscription.$merchant?.$key]">
                                {{ subscription.$merchant?.companyName | slice: 0:20 }}
                                <span *ngIf="!subscription.$merchant?.companyName" class="undefined">??</span>
                            </a>
                        </td>
                        <td>
                            <a [routerLink]="['/parking/', subscription.$parking?.$key]">
                                {{ subscription.$parking?.title || '' }}
                            </a>
                        </td>
                        <td>
                            <a [routerLink]="['/client/', subscription.$client?.$key]">
                                {{ subscription.$client?.firstName || '' }}
                            </a>
                        </td>
                        <td>R{{ subscription.totalContractValue | formatCents }}</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <br />
            <h5 class="px-3">Invoices missing bank details</h5>
            <table class="table table-striped table-hover ms-2">
                <thead>
                    <tr>
                        <th scope="col">Merchant</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let merchant of this.noBankMerchantRefs">
                        <td>
                            <a [routerLink]="['/merchant/', merchant.id, 'profile', 'payout']">{{ merchant.denormMerchantCompanyName }}</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="content col-lg-9">
            <h5 class="px-3">Subscriptions expiring within 10 days</h5>
            <table class="table table-striped table-hover ms-2">
                <thead>
                    <tr>
                        <th scope="col">Status</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Client</th>
                        <th scope="col">Parking</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let subscription of expiringSubscriptions">
                        <tr *ngIf="subscription.$client">
                            <td>
                                <ng-container *ngIf="subscription.renewalStatus == 'notRenewed'">
                                    <span class="badge bg-danger">
                                        {{ subscription.renewalStatus }}
                                    </span>
                                </ng-container>

                                <ng-container *ngIf="['renewedSameParking', 'renewedOtherParking'].includes(subscription.renewalStatus)">
                                    <span
                                        [ngClass]="{
                                            'bg-success': subscription.renewalStatus === 'renewedSameParking',
                                            'bg-warning': subscription.renewalStatus === 'renewedOtherParking'
                                        }"
                                        class="badge cursor"
                                    >
                                        <a [routerLink]="['/admin/subscription/', subscription.renewalSubscriptionRef.id]">
                                            {{ subscription.renewalStatus }}
                                        </a>
                                    </span>
                                </ng-container>
                            </td>
                            <td>
                                <a [routerLink]="['/admin/subscription/', subscription.$key]">
                                    {{ subscription.endDate | formatDate }}
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/client/', subscription.$client.$key]">
                                    {{ subscription.$client.firstName || 'Unknown' }}
                                    {{ subscription.$client.lastName || '' }}
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/parking/', subscription.$parking?.$key]">
                                    {{ subscription.$parking?.title || '' }}
                                </a>
                            </td>
                            <td>
                                <ng-container *ngIf="subscription.$client.$email">
                                    {{ subscription.$client.$email }}
                                    <a class="icon-wrapper email" href="mailto:{{ subscription.$client.$email }}" target="_blank">
                                        <i class="far fa-envelope"></i>
                                    </a>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngIf="subscription.$client.$phoneNumber">
                                    {{ subscription.$client.$phoneNumber }}
                                    <a class="icon-wrapper whatsapp" href="https://wa.me/{{ subscription.$client.$phoneNumber }}" target="_blank">
                                        <i class="fab fa-whatsapp"></i>
                                    </a>
                                    <a class="icon-wrapper tel" href="tel:{{ subscription.$client.$phoneNumber }}" target="_blank">
                                        <i class="fas fa-phone"></i>
                                    </a>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
