"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Parking = void 0;
const parking_type_enum_1 = require("./enums/parking-type.enum");
const parking_location_model_1 = require("./parking-location.model");
const bank_details_model_1 = require("./bank-details.model");
const parking_status_enum_1 = require("./enums/parking-status.enum");
const term_enum_1 = require("./enums/term.enum");
class Parking {
    constructor(source, key) {
        this.parkingStatus = parking_status_enum_1.ParkingStatus.NEW;
        this.parkingType = parking_type_enum_1.ParkingType.STANDARD;
        if (source) {
            Object.assign(this, source);
        }
        if (key) {
            this.$key = key;
        }
        if (source?.parkingLocation) {
            this.parkingLocation = new parking_location_model_1.ParkingLocation(source?.parkingLocation);
        }
    }
    get niceType() {
        if (this.parkingType == parking_type_enum_1.ParkingType.STANDARD) {
            return 'Monthly';
        }
        else if (this.parkingType == parking_type_enum_1.ParkingType.PARKING_COLLECTOR) {
            return 'Collector';
        }
        else if (this.parkingType == parking_type_enum_1.ParkingType.PARKING_EVENTS) {
            return 'Events';
        }
        else if (this.parkingType == parking_type_enum_1.ParkingType.FLEX) {
            return 'Flex';
        }
        else {
            return 'Monthly';
        }
    }
    get metaDescription() {
        return this.description?.substring(0, 170);
    }
    // get dailySavingsPercentage() {
    //     if (this.plans) {
    //         let plan
    //         if (this.parkingType == ParkingType.FLEX) {
    //             plan = this.plans?.find((i) => i.term === Term.FLEX_MONTH) as Plan
    //         } else {
    //             plan = this.plans?.find((i) => i.term === Term.MONTHLY) as Plan
    //         }
    //         let monthlyFee = plan.feeInCents
    //         if (monthlyFee) {
    //             const dailyTotal = this.dailySavings * 30.5
    //             return Math.round(((dailyTotal - monthlyFee) / dailyTotal) * 100)
    //         } else {
    //             return ''
    //         }
    //     } else {
    //         return ''
    //     }
    // }
    get defaultPriceInCents() {
        let productPriceInCents = 0;
        if (this.plans?.find((i) => i.term === term_enum_1.Term.MONTHLY)) {
            const monthlyPlan = this.plans?.find((i) => i.term === term_enum_1.Term.MONTHLY);
            productPriceInCents = monthlyPlan.feeInCents;
        }
        if (this.plans && this.plans[0]) {
            productPriceInCents = this.plans[0].feeInCents;
        }
        return productPriceInCents;
    }
    get defaultImage() {
        // TODO: this is using the wrong image
        if (this.images) {
            for (let key in this.images) {
                return this.images[key].largeUrl;
            }
        }
        return '';
    }
    get defaultImageSmall() {
        // TODO: this is using the wrong image
        if (this.images) {
            for (let key in this.images) {
                return this.images[key].smallUrl;
            }
        }
        return '';
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        delete clone.$subscriptions;
        delete clone.$merchant;
        delete clone.$bankDetails;
        const parking = JSON.parse(JSON.stringify(clone));
        if (this.bankDetailsRef) {
            parking.bankDetailsRef = this.bankDetailsRef;
        }
        if (this.merchantRef) {
            parking.merchantRef = this.merchantRef;
        }
        parking.createdAt = this.createdAt;
        parking.updatedAt = this.updatedAt;
        if (parking.startDate) {
            parking.startDate = this.startDate;
        }
        return parking;
    }
    loadBankDetails(firestore) {
        return firestore.getDoc(this.bankDetailsRef).then((doc) => {
            this.$bankDetails = Object.assign(new bank_details_model_1.BankDetails(), doc.data());
            this.$bankDetails.$key = doc.id;
            return Promise.resolve();
        });
    }
}
exports.Parking = Parking;
