<admin-app-header></admin-app-header>

<div class="container-fluid">
    <button (click)="globalService.exportcsv('all-clients')" class="btn btn-info btn-sm text-white float-end px-3 py-1 my-2"><i class="fas fa-file-csv"></i> Export to CSV</button>
    <div class="row header">
        <div class="col-4 p-0">
            <h2 class="mb-0">Parker CRM</h2>
            <!-- <small>Last synced at {{ lastSyncedAt | date: 'dd MMMM HH:mm' }}</small> -->
        </div>
        <!-- <div class="col-8">
            <input type="text" class="form-control float-end" placeholder="Search..." (input)="onSearch($event)" />
        </div> -->
    </div>
    <div class="row mb-3">
        <form class="row" (ngSubmit)="onFilter()" [formGroup]="filterForm">
            <div class="col-md-2">
                <label for="search">Search</label>
                <input type="text" class="form-control" placeholder="Search..." formControlName="search" />
            </div>

            <div class="col-md-2">
                <label for="filterUserResponsible">Responsible User</label>
                <select class="form-select" placeholder="Responsible User" formControlName="filterUserResponsible">
                    <option value="">All</option>
                    <option *ngFor="let user of leadUsers" [value]="user">{{ user }}</option>
                </select>
            </div>

            <div class="col-md-2">
                <label for="filterLeadStatus">Lead Status</label>
                <select class="form-select" placeholder="Lead Status" formControlName="filterLeadStatus">
                    <option value="">All</option>
                    <option *ngFor="let leadStatus of leadStatuses" [value]="leadStatus">{{ leadStatus }}</option>
                </select>
            </div>

            <div class="col-md-2">
                <label for="filterEverParked">Ever Parked</label>
                <select class="form-select" placeholder="Ever Parked" formControlName="filterEverParked">
                    <option value="">All</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            </div>

            <div class="col-md-2">
                <label for="filterCurrentSub">Current Subscription</label>
                <select class="form-select" placeholder="Current Subscription" formControlName="filterCurrentSub">
                    <option value="">All</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="filterFutureSub">Future Subscription</label>
                <select class="form-select" placeholder="Future Subscription" formControlName="filterFutureSub">
                    <option value="">All</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            </div>
            <div class="col-md-2">
                <button type="submit" class="btn btn-primary btn-sm">Search</button>
            </div>
        </form>
    </div>
</div>
<ng-container *ngIf="searchReady">
    <app-parker-table *ngIf="view === 'table'" [filteredAdminClients]="filteredAdminClients"></app-parker-table>

    <app-parker-stats *ngIf="view === 'stats'" [filteredAdminClients]="filteredAdminClients"></app-parker-stats>
</ng-container>
