<admin-app-header></admin-app-header>
<div class="container-fluid">
    <h1>Unique Emails by Subject Line</h1>

    <div class="content col-lg-12">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th scope="col">Created</th>
                    <th scope="col">Subject Line</th>
                    <th scope="col">Content</th>
                    <th scope="col">Merchant</th>
                    <th scope="col">Client</th>
                    <th scope="col">Tools</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="logEmails">
                    <tr *ngFor="let logEmail of logEmails" [ngClass]="{ 'needs-review': logEmail.needsReview }">
                        <td>
                            {{ logEmail.createdAt | daysAgo }}
                        </td>
                        <td>
                            <a [routerLink]="['/admin/email', logEmail.$key]" class="text-decoration-underline">
                                {{ logEmail.subject }}
                            </a>
                        </td>
                        <td>
                            <a title="{{ logEmail.content }}">
                                {{ logEmail.content | stripHtml | truncate: [150] }}
                            </a>
                        </td>
                        <td>
                            <span *ngIf="logEmail.merchantRef; else noMerchant">
                                <span class="badge bg-success">Yes</span>
                            </span>
                            <ng-template #noMerchant>
                                <span>No</span>
                            </ng-template>
                        </td>
                        <td>
                            <span *ngIf="logEmail.clientRef; else noClient">
                                <span class="badge bg-success">Yes</span>
                            </span>
                            <ng-template #noClient>
                                <span>No</span>
                            </ng-template>
                        </td>
                        <td>
                            <button class="btn btn-primary" (click)="onNeedsReview(logEmail)">Needs Review</button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
