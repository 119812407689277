import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { HttpClient } from '@angular/common/http'
import { collection, collectionData, docData, DocumentData, orderBy, Query, query, where } from '@angular/fire/firestore'
import { Client, Merchant, Parking, Payment, paymentsPath, Subscription } from '@parkupp/core'
import { firstValueFrom } from 'rxjs'
import { GlobalService } from '../../../services/global.service'

@Component({
    selector: 'app-admin-recon-page',
    templateUrl: './admin-recon-page.component.html',
    styleUrls: ['./admin-recon-page.component.scss'],
})
export class AdminReconPageComponent implements OnInit {
    payments: Payment[]
    selectedPayments: [] = []
    mouseDown = false
    showType: string = 'unpaid'
    private refPaid: Query<DocumentData>

    constructor(public globalService: GlobalService, private adminService: AdminService, private http: HttpClient) {
        const startDate = new Date(2022, 2, 1) // March 1, 2022
        // const endDate = new Date(2022, 5, 28) // February 28, 2023
        const endDate = new Date(2023, 1, 28) // February 28, 2023

        this.refPaid = query(
            collection(this.adminService.firestore, paymentsPath()),
            where('createdAt', '>=', startDate),
            where('createdAt', '<=', endDate),
            orderBy('createdAt', 'asc'),
            where('settled', '==', true)
        )

        this.getPayments(this.refPaid)
    }

    async getPayments(ref: Query<DocumentData>) {
        const documents = await firstValueFrom(collectionData(ref, { idField: 'documentId' }))
        let payments: Payment[] = []

        for (const documentData of documents) {
            let payment = new Payment(documentData)
            payment.$key = documentData['documentId']

            const refs = []
            const refTypes: any[] = [] // To keep track of reference types

            if (payment.clientRef) {
                refs.push(docData(payment.clientRef as any))
                refTypes.push('client')
            }
            if (payment.subscriptionRef) {
                refs.push(docData(payment.subscriptionRef as any))
                refTypes.push('subscription')
            }
            if (payment.parkingRef) {
                refs.push(docData(payment.parkingRef as any))
                refTypes.push('parking')
            }
            if (payment.merchantRef) {
                refs.push(docData(payment.merchantRef as any))
                refTypes.push('merchant')
            }

            const results = await Promise.all(refs.map((ref) => firstValueFrom(ref)))

            // Assign the results to the respective properties of payment
            results.forEach((result, index) => {
                switch (refTypes[index]) {
                    case 'client':
                        payment.$client = new Client(result) // Assuming result is directly assignable
                        payment.$client.$key = payment.clientRef.id
                        break
                    case 'subscription':
                        payment.$subscription = new Subscription(result) // Assuming result is directly assignable
                        payment.$subscription.$key = payment.subscriptionRef.id
                        break
                    case 'parking':
                        payment.$parking = new Parking(result) // Assuming result is directly assignable
                        payment.$parking.$key = payment.parkingRef.id
                        break
                    case 'merchant':
                        payment.$merchant = new Merchant(result) // Assuming result is directly assignable
                        payment.$merchant.$key = payment.merchantRef.id
                        break
                    default:
                        // Handle unexpected case
                        break
                }
            })

            payments.push(payment)
        }
        this.payments = payments
        console.log(this.payments)
    }

    ngOnInit(): void {}

    info(payment: Payment) {
        console.log(payment)
    }
}
