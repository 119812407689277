<admin-app-header></admin-app-header>
<div class="container-fluid">
    <div class="content col-lg-12">
        <button (click)="globalService.exportcsv('income-recon')" class="btn btn-info btn-sm text-white float-end px-3 py-1 my-2"><i class="fas fa-file-csv"></i> Export to CSV</button>
        <h1>Income Recon</h1>
        <div *ngIf="!payments">LOADING...</div>
        <table *ngIf="payments" class="table table-striped table-hover" id="income-recon">
            <thead>
                <tr>
                    <th scope="col">Info</th>
                    <th scope="col">Payment Date</th>
                    <th scope="col">Subscription ID</th>
                    <th scope="col">Income</th>
                    <th scope="col">Client</th>
                    <th scope="col">Merchant</th>
                    <th scope="col">Parking</th>
                    <th scope="col">Transaction ID</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let payment of payments">
                    <td>
                        <i (click)="info(payment)" class="fas fa-info-circle"></i>
                    </td>
                    <td>
                        <a [routerLink]="['/merchant/', payment.$merchant?.$key, 'payment', payment.$key]">
                            {{ payment.createdAt | formatDate }}
                        </a>
                    </td>
                    <td>
                        {{ payment.$subscription?.$key }}
                    </td>
                    <td>
                        {{ payment.amountInCents / 100 }}
                    </td>
                    <td>
                        <a [routerLink]="['/client/', payment.$client?.$key]">
                            {{ payment.$client?.firstName || '' }}
                            {{ payment.$client?.lastName || '' }}
                        </a>
                    </td>
                    <td>
                        <a [routerLink]="['/merchant/', payment.$merchant?.$key, 'payment', payment.$key]">
                            {{ payment.$merchant?.companyName | slice: 0:25 }}
                        </a>
                    </td>
                    <td>
                        <a [routerLink]="['/parking/', payment.$parking?.$key]" [title]="payment.$parking?.title">
                            {{ payment.$parking?.title | slice: 0:15 }}
                        </a>
                    </td>
                    <td>
                        {{ payment.transactionId }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
