import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'formatNumberWithPaddedZeros' })
export class FormatNumberWithPaddedZerosPipe implements PipeTransform {
    transform(value: any, size: number): string {
        let s = String(value)
        while (s.length < (size || 2)) {
            s = '0' + s
        }
        return s
    }
}
