<admin-app-header></admin-app-header>
<br />
<div class="container">
    <h2>Companies by Client Over Time</h2>
    <br />
    <!-- Add this inside your template -->
    <select (change)="onMonthChange($event)">
        <option value="">All Dates</option>
        <!-- Add this line -->
        <option *ngFor="let month of monthSelection" [value]="month">
            {{ month | date: 'MMMM yyyy' }}
        </option>
    </select>
    <br />
    <br />

    <table>
        <thead>
            <tr>
                <th scope="col">Company</th>
                <th scope="col">Count</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let company of companyNameArray">
                <td>{{ company.name }}</td>
                <td>{{ company.count }}</td>
            </tr>
        </tbody>
    </table>
</div>
