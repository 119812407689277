<div *ngIf="contractTimeleftSeconds || contractTimeleftMinutes || contractTimeleftHours" class="timer {{ scale }}">
    <div class="unit">
        <div class="time">{{ contractTimeleftHours }}</div>
        <div class="description">Hours</div>
    </div>
    <div class="unit">
        <div class="time">{{ contractTimeleftMinutes }}</div>
        <div class="description">Minutes</div>
    </div>
    <div class="unit">
        <div class="time">{{ contractTimeleftSeconds }}</div>
        <div class="description">Seconds</div>
    </div>
</div>
