import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'daysUntil',
    pure: true,
})
export class DaysUntilPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        try {
            value = value.toDate()
        } catch (e) {
            if (value._seconds) {
                value = new Date(value._seconds * 1000)
            }
            if (value.seconds) {
                value = new Date(value.seconds * 1000)
            }
        }

        if (value) {
            const days = Math.floor((+new Date(value) - +new Date()) / 86400000) // 86400000 ms in a day
            if (days < 1) {
                return '0 days'
            } else if (days === 1) {
                return '1 day'
            } else {
                return days + ' days'
            }
        }
        return value
    }
}
