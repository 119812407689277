import { Injectable } from '@angular/core'
import { collection, doc, docData, DocumentData, Firestore, getDocs, limit, orderBy, query, QueryDocumentSnapshot, startAfter, updateDoc, where, writeBatch } from '@angular/fire/firestore'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { AuthenticationService } from './authentication.service'
import { from, map, Observable } from 'rxjs'
import { ExceptionAlert, FirestoreStructure } from '@parkupp/core'
import { ClientService } from './client.service'
import { MerchantService } from './merchant.service'
import { ParkingService } from './parking.service'
import { SubscriptionService } from './subscription.service'

@Injectable({
    providedIn: 'root',
})
export class ExceptionAlertService {
    collection = collection(this.firestore, FirestoreStructure.EXCEPTION_ALERTS)

    constructor(public firestore: Firestore) {}

    get(id: string): Observable<any> {
        const docRef = doc(this.firestore, `${FirestoreStructure.EXCEPTION_ALERTS}/${id}`)
        return docData(docRef, { idField: '$key' })
    }
    async list(filter?: { reviewed?: boolean }): Promise<ExceptionAlert[]> {
        let exceptionsQuery = query(this.collection, orderBy('timestamp', 'desc'), limit(1000))

        if (filter?.reviewed !== undefined) {
            exceptionsQuery = query(exceptionsQuery, where('reviewed', '==', filter.reviewed))
        }
        const snapshot = await getDocs(exceptionsQuery)
        const docs = snapshot.docs.map((doc) => {
            const data = doc.data()
            return {
                ...data,
                $key: doc.id,
            }
        })
        return docs as ExceptionAlert[]
    }

    async markAllAsReviewed() {
        const batch = writeBatch(this.firestore)
        const querySnapshot = await getDocs(query(this.collection, where('reviewed', '==', false)))
        querySnapshot.forEach((docSnapshot) => {
            const docRef = doc(this.collection, docSnapshot.id)
            batch.update(docRef, { reviewed: true })
        })
        await batch.commit()
    }

    async markAsReviewed(key: string) {
        const docRef = doc(this.collection, key)
        await updateDoc(docRef, { reviewed: true })
    }

    async markAsUnreviewed(key: string) {
        const docRef = doc(this.collection, key)
        await updateDoc(docRef, { reviewed: false })
    }
}
