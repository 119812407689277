import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Pipe({
    name: 'stringReplaceUrls',
})
export class StringAddUrls implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    transform(value: string): SafeHtml {
        var exp_match = /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
        var element_content = value.replace(exp_match, "<a href='$1'>$1</a>")
        var new_exp_match = /(^|[^\/])(www\.[\S]+(\b|$))/gim
        var new_content = element_content.replace(new_exp_match, '$1<a target="_blank" href="http://$2">$2</a>')

        return this.sanitizer.bypassSecurityTrustHtml(new_content)
    }
}
