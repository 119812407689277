import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AuthenticatorComponent } from './authenticator/authenticator.component'
import { CoreModule } from '../core/core.module'
import { AuthModule } from '@angular/fire/auth'
import { ReactiveFormsModule } from '@angular/forms'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { RouterModule } from '@angular/router'

@NgModule({
    declarations: [AuthenticatorComponent, ForgotPasswordComponent],
    exports: [AuthenticatorComponent, ForgotPasswordComponent],
    imports: [CommonModule, CoreModule, AuthModule, ReactiveFormsModule, RouterModule],
})
export class AuthenticationModule {}
