import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { ChangeLogsEntityEnum, Client, Occupant, occupantsPath, Parking, PaymentMethod, Subscription, subscriptionsPath, EnumNames } from '@parkupp/core'
import { ActivatedRoute } from '@angular/router'
import { doc, docData, DocumentData, DocumentReference } from '@angular/fire/firestore'
import { AdminChangeLogService } from 'src/app/services/admin-change-log.service'
import { SubscriptionService } from 'src/app/services/subscription.service'
import { ClientService } from 'src/app/services/client.service'
import { ParkingService } from 'src/app/services/parking.service'
import { map } from 'rxjs'

@Component({
    selector: 'app-admin-view-subscription',
    templateUrl: './admin-view-subscription.component.html',
    styleUrls: ['./admin-view-subscription.component.scss'],
})
export class AdminViewSubscriptionComponent implements OnInit {
    EnumNames = EnumNames
    subscriptionKey: string
    subscription: Subscription
    events: {
        timestamp: Date
        date: string
        title: string
        description: string
    }[] = []
    clientCache: Map<string, Client> = new Map()

    constructor(
        private activatedRoute: ActivatedRoute,
        private adminChangeLogService: AdminChangeLogService,
        private subscriptionService: SubscriptionService,
        private clientService: ClientService,
        private parkingService: ParkingService
    ) {}

    async ngOnInit() {
        this.subscriptionKey = this.activatedRoute.snapshot.params['subscriptionKey']
        const subscription = await this.subscriptionService.getPromise(this.subscriptionKey)
        const subscriptionRef = this.subscriptionService.getDocRef(this.subscriptionKey)

        const promises = []
        promises.push(this.getClient(subscription.clientRef.id))
        promises.push(this.parkingService.getPromise(subscription.parkingRef.id))

        Promise.all(promises).then(([client, parking]) => {
            console.log(client)
            subscription.$client = client as any
            subscription.$parking = parking as any

            const createdAt = subscription.createdAt.toDate()
            this.events.push({
                timestamp: createdAt,
                date: createdAt.toLocaleString(),
                title: 'Subscription Created',
                description: `Subscription created.<br> 
                              Client: <b>${subscription.$client?.firstName} ${subscription.$client?.lastName}</b><br> 
                              Parking: <b>${subscription.$parking?.title}</b>`,
            })
            this.sortEvents()
        })
        // this.paymentMethodService.get(subscription.paymentMethodRef.id).subscribe((data) => {
        //     subscription.$paymentMethod = data as PaymentMethod
        //     subscription.$paymentMethod.$key = subscription.paymentMethodRef.id
        //     this.events.push({
        //         date: subscription.createdAt.toDate().toLocaleString(),
        //         title: 'Payment Method Info',
        //         description: 'Payment method ' + subscription.$paymentMethod?.title + ' added to subscription',
        //     })
        // })

        if (subscription.contractCreationLog) {
            const contractDate = subscription.contractCreationLog.date.toDate()
            this.events.push({
                timestamp: contractDate,
                date: contractDate.toLocaleString(),
                title: 'Agent Info',
                description: '<b>IP:</b> ' + subscription.contractCreationLog.ip + ' <br> <b>Agent:</b> ' + subscription.contractCreationLog.agent,
            })
        }

        const changeLogs = await this.adminChangeLogService.list(ChangeLogsEntityEnum.SUBSCRIPTION, { subscriptionRef: subscriptionRef as any })
        console.log(changeLogs)
        for (const changeLog of changeLogs) {
            const changeDate = changeLog.createdAt.toDate()
            changeLog.$admin = await this.getClient(changeLog.adminRef.id)
            this.events.push({
                timestamp: changeDate,
                date: changeDate.toLocaleString(),
                title: 'Admin Update',
                description: `<b>Admin:</b> ${changeLog.$admin?.firstName} ${changeLog.$admin?.lastName} <br> <b>Comment:</b> ${changeLog.comment}`,
            })
        }

        this.sortEvents()
        console.log(this.events)
    }

    async getClient(clientId: string): Promise<Client> {
        if (this.clientCache.has(clientId)) {
            return this.clientCache.get(clientId)!
        } else {
            const client = await this.clientService.getPromise(clientId)
            this.clientCache.set(clientId, client)
            return client
        }
    }

    sortEvents() {
        this.events = this.events.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
    }
}
