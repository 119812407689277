<admin-app-header></admin-app-header>
<div class="container-fluid">
    <div class="row">
        <div class="col-6">
            <h1 class="">VAT 201 Proforma Invoices</h1>
        </div>

        <div class="col-md-2 ms-auto">
            <button (click)="globalService.exportcsv('all-proforma-invoices')" class="btn btn-info btn-sm text-white float-end px-3 py-1 my-2"><i class="fas fa-file-csv"></i> Export to CSV</button>
        </div>
    </div>
    <div class="content col-lg-12">
        <table class="table table-striped table-hover table-sm" id="all-proforma-invoices">
            <thead>
                <tr>
                    <th>Created</th>
                    <th>Payout</th>
                    <th>Proforma ID</th>
                    <th>Landlord Requirements</th>
                    <th>Invoice Refs</th>
                    <th>Merchant</th>
                    <th>Total Amount</th>
                    <th>Payout Amount</th>
                    <th>Bank Details</th>
                    <th>Payout Batch</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let payout of payouts">
                    <tr>
                        <td>{{ payout.createdAt.toDate() | date: 'dd MMM yyyy' }}</td>
                        <td>
                            <ng-container *ngIf="payout.payoutDate">
                                <span class="badge bg-success text-dark">{{ payout.payoutDate.toDate() | date: 'dd MMM yyyy' }}</span>
                            </ng-container>
                            <ng-container *ngIf="!payout.payoutDate">
                                <span class="badge bg-danger text-light">Unpaid</span>
                            </ng-container>
                        </td>
                        <td>
                            <a [routerLink]="['/admin/payout/', payout.$key]" class="text-decoration-underline"> PFI-{{ payout.payoutNumber }} </a>
                        </td>
                        <td>
                            <ng-container *ngIf="payout && payout.$merchant && payout.$merchant?.payoutDetails && payout.$merchant?.payoutDetails?.paymentOnInvoice !== undefined">
                                <span [class]="EnumNames.getClassPaymentOnInvoice(payout.$merchant!.payoutDetails!.paymentOnInvoice)">
                                    {{ EnumNames.getAdminPaymentOnInvoice(payout.$merchant!.payoutDetails!.paymentOnInvoice) }}
                                </span>
                            </ng-container>
                        </td>
                        <td>{{ payout.invoiceRefs.length }}</td>
                        <td>
                            <a [routerLink]="['/merchant/', payout.$merchant?.$key]" class="text-decoration-underline">
                                {{ payout.$merchant?.companyName }}
                            </a>
                        </td>
                        <td>{{ payout.totalAmount | currency: 'R' }}</td>
                        <td>{{ payout.payoutAmount | currency: 'R' }}</td>
                        <td>
                            <ng-container *ngIf="payout.$merchant?.payoutDetails">
                                <ng-container *ngIf="payout.$merchant?.payoutDetails?.beneficiaryName">
                                    <span class="badge bg-success text-dark">{{ payout.$merchant?.payoutDetails?.beneficiaryName }}</span>
                                </ng-container>
                                <ng-container *ngIf="!payout.$merchant?.payoutDetails?.beneficiaryName">
                                    <span class="badge bg-warning text-dark">{{ payout.$merchant?.payoutDetails?.bank }}: {{ payout.$merchant?.payoutDetails?.accountNumber }} </span>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!payout.$merchant?.payoutDetails">
                                <span class="badge bg-danger text-light">No Bank Details</span>
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="payout.payoutBatchRef">
                                <a [routerLink]="['/admin/payout-batch/', payout.payoutBatchRef.id]" class="text-primary text-decoration-underline">
                                    {{ payout.payoutBatchRef.id }}
                                </a>
                            </ng-container>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
