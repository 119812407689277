import { Component, OnInit } from '@angular/core'
import { collectionData } from '@angular/fire/firestore'
import { collection, orderBy, where } from '@angular/fire/firestore'
import { query } from '@angular/fire/firestore'
import { FirestoreStructure, MerchantUserPrivate } from '@parkupp/core'
import { map } from 'rxjs/operators'
import { AdminService } from 'src/app/services/admin.service'
import { GlobalService } from 'src/app/services/global.service'

@Component({
    selector: 'app-admin-list-merchants-private',
    templateUrl: './admin-list-merchants-private.component.html',
    styleUrls: ['./admin-list-merchants-private.component.scss'],
})
export class AdminListMerchantsPrivateComponent implements OnInit {
    merchants: MerchantUserPrivate[]
    lastSyncedAt: Date
    constructor(private adminSvc: AdminService, public globalService: GlobalService) {}

    ngOnInit(): void {
        this.lastSyncedAt = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 5, 0, 0, 0)
        this.getMerchantsAdmin()
    }

    getMerchantsAdmin(searchTerm?: string) {
        let merchantsPrivateRef = query(collection(this.adminSvc.firestore, FirestoreStructure.MERCHANT_USERS_PRIVATE))

        // if (searchTerm) {
        //     merchantsPrivateRef = query(merchantsPrivateRef, where('searchableField', '>=', searchTerm), where('searchableField', '<=', searchTerm + '\uf8ff'), orderBy('searchableField'))
        // } else {
        merchantsPrivateRef = query(merchantsPrivateRef, orderBy('createdAt', 'desc'))
        // }

        collectionData(merchantsPrivateRef, { idField: 'documentId' })
            .pipe(
                map((merchantsData: any[]) => {
                    return merchantsData.map((merchantData: any) => {
                        return {
                            ...merchantData,
                            $key: merchantData.documentId,
                        }
                    })
                })
            )
            .subscribe((merchants: any[]) => {
                this.merchants = merchants
                console.log(this.merchants)
            })
    }

    callPhone(phoneNumber: string) {
        window.open(`tel:${phoneNumber.replace(/\s+/g, '')}`, '_blank')
    }

    sendWhatsApp(phoneNumber: string) {
        window.open(`https://wa.me/${phoneNumber.replace(/\s+/g, '')}`, '_blank')
    }

    sendEmail(email: string) {
        window.open(`mailto:${email}`, '_blank')
    }
}
