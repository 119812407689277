import { Component, OnInit } from '@angular/core'
import { collection, collectionData, docData, query } from '@angular/fire/firestore'
import { ContractStatus, subscriptionsPath, Subscription, Parking, ParkingType, Client } from '@parkupp/core'
import { AdminService } from '../../../services/admin.service'
import { SubscriptionService } from '../../../services/subscription.service'
import { GlobalService } from '../../../services/global.service'

@Component({
    selector: 'app-admin-list-expired-subscriptions',
    templateUrl: './admin-list-expired-subscriptions.component.html',
    styleUrls: ['./admin-list-expired-subscriptions.component.scss'],
})
export class AdminListExpiredSubscriptionsComponent implements OnInit {
    expiredSubscriptions: Subscription[]

    constructor(public globalService: GlobalService, private adminService: AdminService, private subscriptionService: SubscriptionService) {
        const subscriptionsRef = query(collection(this.adminService.firestore, subscriptionsPath()))
        collectionData(subscriptionsRef, { idField: 'documentId' }).subscribe((documents: any[]) => {
            // Fetch all completed subscriptions
            const completedSubscriptions = documents.filter((documentData: any) => documentData.status === ContractStatus.completed && documentData.clientRef)

            // Fetch all active subscriptions
            const activeSubscriptions = documents.filter((documentData: any) => documentData.status === ContractStatus.active && documentData.clientRef)

            // Get a set of $client.$key values for active subscriptions
            const activeClientKeys = new Set(activeSubscriptions.map((sub: any) => sub.clientRef.id))

            // Filter completed subscriptions to exclude those with active subscriptions for the same $client.$key
            const filteredCompletedSubscriptions = completedSubscriptions.filter((sub: any) => !activeClientKeys.has(sub.clientRef.id))

            this.expiredSubscriptions = []

            filteredCompletedSubscriptions.forEach((documentData) => {
                let subscription = new Subscription(documentData)
                subscription.$key = documentData.documentId

                docData(documentData.clientRef).subscribe((data) => {
                    subscription.$client = new Client(data)
                    subscription.$client.$key = documentData.clientRef.id
                })

                docData(documentData.parkingRef).subscribe((data) => {
                    subscription.$parking = new Parking(data)
                    subscription.$parking.$key = documentData.parkingRef.id
                })

                this.expiredSubscriptions.push(subscription)
            })

            this.expiredSubscriptions = this.expiredSubscriptions.filter((subscription: Subscription) => subscription.$parking?.parkingType !== ParkingType.PARKING_EVENTS)
            this.expiredSubscriptions = this.subscriptionService.sortSubs(this.expiredSubscriptions)
            this.expiredSubscriptions = this.subscriptionService.deduplicateSubscriptionsByClientRef(this.expiredSubscriptions)

            this.adminService.findAndAppendClientPrivate(this.expiredSubscriptions)
        })
    }

    ngOnInit(): void {}
}
