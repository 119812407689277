import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'formatCurrency' })
export class FormatCurrencyPipe implements PipeTransform {
    transform(currency: string): string {
        if (currency && currency.toUpperCase() === 'ZAR') {
            return 'R'
        }
        return currency
    }
}
