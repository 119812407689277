import { Component, OnInit } from '@angular/core'
import { collection, collectionData, orderBy, query } from '@angular/fire/firestore'
import { FirestoreStructure, LogEmail } from '@parkupp/core'
import { AdminService } from '../../../services/admin.service'
import { LogEmailService } from 'src/app/services/log-email.service'

@Component({
    selector: 'app-list-emails-page',
    templateUrl: './list-emails-page.component.html',
    styleUrls: ['./list-emails-page.component.scss'],
})
export class ListEmailsPageComponent implements OnInit {
    logEmails: LogEmail[]

    constructor(private adminService: AdminService, private logEmailService: LogEmailService) {}

    async ngOnInit(): Promise<void> {
        this.logEmails = await this.logEmailService.getAdminOverview()
    }

    async onNeedsReview(logEmail: LogEmail): Promise<void> {
        logEmail.needsReview = !logEmail.needsReview
        await this.logEmailService.updateOverviewDoc(logEmail)
    }
}
