"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WatiTemplates = exports.SubscriptionType = exports.DashboardMetric = exports.PopupMode = exports.BroadLocation = exports.LeadCity = exports.LeadUser = exports.LeadClosedReason = exports.LeadStatus = exports.ChangeLogsEntityEnum = exports.AlertSeverity = exports.SlackChannel = void 0;
var SlackChannel;
(function (SlackChannel) {
    SlackChannel["DEVELOPMENT"] = "development";
    SlackChannel["SYSTEM"] = "system";
    SlackChannel["CRITICAL"] = "critical_alerts";
})(SlackChannel = exports.SlackChannel || (exports.SlackChannel = {}));
var AlertSeverity;
(function (AlertSeverity) {
    AlertSeverity["CRITICAL"] = "critical";
    AlertSeverity["MAJOR"] = "major";
    AlertSeverity["MINOR"] = "minor";
    AlertSeverity["WARNING"] = "warning";
    AlertSeverity["INFO"] = "info";
})(AlertSeverity = exports.AlertSeverity || (exports.AlertSeverity = {}));
var ChangeLogsEntityEnum;
(function (ChangeLogsEntityEnum) {
    ChangeLogsEntityEnum["INVOICE"] = "invoice";
    ChangeLogsEntityEnum["CLIENT"] = "client";
    ChangeLogsEntityEnum["SUBSCRIPTION"] = "subscription";
})(ChangeLogsEntityEnum = exports.ChangeLogsEntityEnum || (exports.ChangeLogsEntityEnum = {}));
var LeadStatus;
(function (LeadStatus) {
    LeadStatus["NEW"] = "New";
    LeadStatus["IN_PROGRESS"] = "In Progress";
    LeadStatus["HOT"] = "Hot";
    LeadStatus["COLD"] = "Cold";
    LeadStatus["CONVERTED"] = "Converted";
    LeadStatus["CLOSED"] = "Closed";
})(LeadStatus = exports.LeadStatus || (exports.LeadStatus = {}));
var LeadClosedReason;
(function (LeadClosedReason) {
    LeadClosedReason["NO_AVAILABILITY"] = "No Availability in Area";
    LeadClosedReason["NO_RESPONSE"] = "No Response";
    LeadClosedReason["DUPLICATE"] = "Duplicate";
    LeadClosedReason["BUDGET_CONSTRAINTS"] = "Budget Constraints";
    LeadClosedReason["TIMING_ISSUES"] = "Timing Issues";
    LeadClosedReason["CHOSE_COMPETITOR"] = "Chose Competitor";
    LeadClosedReason["OTHER"] = "Other";
})(LeadClosedReason = exports.LeadClosedReason || (exports.LeadClosedReason = {}));
var LeadUser;
(function (LeadUser) {
    LeadUser["KWANELE"] = "Kwanele";
    LeadUser["UMAR"] = "Umar";
})(LeadUser = exports.LeadUser || (exports.LeadUser = {}));
var LeadCity;
(function (LeadCity) {
    LeadCity["JOHANNESBURG"] = "Johannesburg";
    LeadCity["CAPE_TOWN"] = "Cape Town";
    LeadCity["DURBAN"] = "Durban";
    LeadCity["PRETORIA"] = "Pretoria";
    LeadCity["PORT_ELIZABETH"] = "Port Elizabeth";
    LeadCity["BLOEMFONTEIN"] = "Bloemfontein";
    LeadCity["EAST_LONDON"] = "East London";
    LeadCity["NELSPRUIT"] = "Nelspruit";
    LeadCity["KIMBERLEY"] = "Kimberley";
    LeadCity["POLOKWANE"] = "Polokwane";
    LeadCity["RUSTENBURG"] = "Rustenburg";
})(LeadCity = exports.LeadCity || (exports.LeadCity = {}));
var BroadLocation;
(function (BroadLocation) {
    BroadLocation["CAPE_TOWN"] = "Cape Town";
    BroadLocation["JOHANNESBURG"] = "Johannesburg";
    BroadLocation["PRETORIA"] = "Pretoria";
    BroadLocation["DURBAN"] = "Durban";
    BroadLocation["GQEBERHA"] = "Gqeberha";
    BroadLocation["BLOEMFONTEIN"] = "Bloemfontein";
    BroadLocation["EAST_LONDON"] = "East London";
    BroadLocation["NELSPRUIT"] = "Nelspruit";
    BroadLocation["KIMBERLEY"] = "Kimberley";
    BroadLocation["POLOKWANE"] = "Polokwane";
    BroadLocation["RUSTENBURG"] = "Rustenburg";
    BroadLocation["OTHER"] = "Other";
})(BroadLocation = exports.BroadLocation || (exports.BroadLocation = {}));
var PopupMode;
(function (PopupMode) {
    PopupMode["CHOOSE_CARD"] = "chooseCard";
    PopupMode["ADD_CARD"] = "addCard";
    PopupMode["OTP_SECURE"] = "otpSecure";
    PopupMode["COMPLETE"] = "complete";
    PopupMode["HOW_IT_WORKS"] = "howItWorks";
})(PopupMode = exports.PopupMode || (exports.PopupMode = {}));
var DashboardMetric;
(function (DashboardMetric) {
    DashboardMetric["SUBSCRIPTION_FUTURE_VALUE"] = "subscriptionFutureValue";
    DashboardMetric["SUBSCRIPTIONS_TOTAL"] = "subscriptionsTotal";
    DashboardMetric["SUBSCRIPTIONS_PAYFAST"] = "subscriptionsPayfast";
    DashboardMetric["SUBSCRIPTIONS_PAYGATE"] = "subscriptionsPaygate";
    DashboardMetric["SUBSCRIPTIONS_EFT"] = "subscriptionsEft";
    DashboardMetric["BAYS_TOTAL"] = "baysTotal";
    DashboardMetric["BAYS_ACTIVE"] = "baysActive";
    DashboardMetric["BAYS_CAPACITY"] = "baysCapacity";
    DashboardMetric["BUILDINGS_TOTAL"] = "buildingsTotal";
    DashboardMetric["BUILDINGS_UNPUBLISHED"] = "buildingsUnpublished";
    DashboardMetric["BUILDINGS_ACTIVE"] = "buildingsActive";
    DashboardMetric["INVOICES_OVERDUE_TOTAL"] = "invoicesOverdueTotal";
    DashboardMetric["INVOICES_OVERDUE_COUNT"] = "invoicesOverdueCount";
    DashboardMetric["INVOICES_NOT_PAID_OUT_TOTAL"] = "invoicesNotPaidOutTotal";
    DashboardMetric["INVOICES_NOT_PAID_OUT_COUNT"] = "invoicesNotPaidOutCount";
    DashboardMetric["USERS"] = "users";
    DashboardMetric["USERS_WITH_PROFILE"] = "usersWithProfile";
    DashboardMetric["USERS_ACTIVE"] = "usersActive";
    DashboardMetric["USERS_NEW"] = "usersNew";
    DashboardMetric["USERS_NEW_WITH_PROFILE"] = "usersNewWithProfile";
    DashboardMetric["SUBSCRIPTIONS_NEW_COUNT"] = "subscriptionsNewCount";
    DashboardMetric["SUBSCRIPTIONS_NEW_VALUE"] = "subscriptionsNewValue";
})(DashboardMetric = exports.DashboardMetric || (exports.DashboardMetric = {}));
var SubscriptionType;
(function (SubscriptionType) {
    SubscriptionType["PAYFAST"] = "payfast";
    SubscriptionType["EFT"] = "eft";
    SubscriptionType["PAYGATE"] = "paygate";
    // Delete these
    SubscriptionType["BILLABLE"] = "billable";
    SubscriptionType["MANUAL"] = "manual";
})(SubscriptionType = exports.SubscriptionType || (exports.SubscriptionType = {}));
var WatiTemplates;
(function (WatiTemplates) {
    WatiTemplates["MERCHANT_PARKING_REQUEST_CREATED"] = "merchant_parking_request_created_v5";
})(WatiTemplates = exports.WatiTemplates || (exports.WatiTemplates = {}));
