import { Component, OnInit } from '@angular/core'
import { collection, collectionData, orderBy, query, where } from '@angular/fire/firestore'
import { FormControl, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AdminClient, FirestoreStructure, LeadClosedReason, LeadStatus, LeadUser } from '@parkupp/core'
import { map, throwIfEmpty } from 'rxjs/operators'
import { AdminService } from 'src/app/services/admin.service'
import { ClientPrivateService } from 'src/app/services/clients-private.service'
import { GlobalService } from 'src/app/services/global.service'
import Swal from 'sweetalert2'
import { AdminClientService } from 'src/app/services/admin-client.service'

@Component({
    selector: 'app-parker-crm',
    templateUrl: './parker-crm.component.html',
    styleUrls: ['./parker-crm.component.scss'],
})
export class ParkerCrm implements OnInit {
    leadStatuses = Object.values(LeadStatus)
    leadUsers = Object.values(LeadUser)
    adminClients: AdminClient[]
    filteredAdminClients: AdminClient[]
    lastSyncedAt: Date
    filterForm: FormGroup = new FormGroup({
        search: new FormControl(''),
        filterEverParked: new FormControl(''),
        filterCurrentSub: new FormControl(''),
        filterFutureSub: new FormControl(''),
        filterLeadStatus: new FormControl(''),
        filterUserResponsible: new FormControl(''),
    })
    view: string
    searchReady: boolean = false

    sortColumn: string = ''
    sortDirection: 'asc' | 'desc' = 'asc'

    constructor(private adminSvc: AdminService, public globalService: GlobalService, private adminClientService: AdminClientService, private router: Router, private route: ActivatedRoute) {
        this.view = this.route.snapshot.params['view']
    }

    ngOnInit(): void {
        const now = new Date()
        this.lastSyncedAt = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 5, 0, 0, 0)
        this.getClientsAdmin()

        this.filterForm.valueChanges.subscribe(() => {
            this.onFilter()
        })
    }

    onFilter() {
        this.getClientsAdmin()
    }

    getClientsAdmin() {
        let clientsPrivateRef = query(collection(this.adminSvc.firestore, FirestoreStructure.ADMIN_CLIENTS))

        const searchTerm = this.filterForm.get('search')?.value
        if (searchTerm) {
            clientsPrivateRef = query(
                clientsPrivateRef,
                where('searchableField', '>=', searchTerm.toLowerCase()),
                where('searchableField', '<=', searchTerm.toLowerCase() + '\uf8ff'),
                orderBy('searchableField')
            )
        } else {
            clientsPrivateRef = query(clientsPrivateRef, orderBy('createdAt', 'desc'))
        }

        const filterUserResponsible = this.filterForm.get('filterUserResponsible')?.value
        const filterEverParked = this.filterForm.get('filterEverParked')?.value
        const filterCurrentSub = this.filterForm.get('filterCurrentSub')?.value
        const filterFutureSub = this.filterForm.get('filterFutureSub')?.value
        const filterLeadStatus = this.filterForm.get('filterLeadStatus')?.value

        if (filterEverParked) {
            clientsPrivateRef = query(clientsPrivateRef, where('hasEverHadActiveOrCompletedSubscription', '==', filterEverParked === 'true'))
        }

        if (filterCurrentSub) {
            clientsPrivateRef = query(clientsPrivateRef, where('hasActiveSubscription', '==', filterCurrentSub === 'true'))
        }

        if (filterFutureSub) {
            clientsPrivateRef = query(clientsPrivateRef, where('hasFutureSubscription', '==', filterFutureSub === 'true'))
        }

        if (filterLeadStatus) {
            clientsPrivateRef = query(clientsPrivateRef, where('leadStatus', '==', filterLeadStatus))
        }

        if (filterUserResponsible) {
            clientsPrivateRef = query(clientsPrivateRef, where('userResponsible', '==', filterUserResponsible))
        }

        collectionData(clientsPrivateRef, { idField: 'documentId' })
            .pipe(
                map((clientsData: any[]) => {
                    return clientsData.map((clientData: any) => {
                        return new AdminClient({
                            ...clientData,
                            $key: clientData.documentId,
                        })
                    })
                })
            )
            .subscribe((clients: any[]) => {
                this.adminClients = clients
                this.filteredAdminClients = clients
                this.searchReady = true
                console.log(this.adminClients)
            })
    }
}
