<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="form-group mb-0 position-relative dropzone-container">
                <input type="file" name="img_logo" [attr.accept]="accept" class="dropzone" (change)="onChange($event)" />
                <div class="dropzone-wrapper" (dragover)="onDragOver()" (dragleave)="onDragLeave()">
                    <div class="dropzone-desc">
                        <i class="glyphicon glyphicon-download-alt"></i>
                        <h4 class="drag-title">Drag and Drop or Click to Browse</h4>
                        <div class="mt-3">
                            <small *ngIf="subtitle">{{ subtitle }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
