"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertSeverity = exports.SlackChannel = void 0;
var SlackChannel;
(function (SlackChannel) {
    SlackChannel["DEVELOPMENT"] = "development";
    SlackChannel["SYSTEM"] = "system";
    SlackChannel["CRITICAL"] = "critical_alerts";
})(SlackChannel = exports.SlackChannel || (exports.SlackChannel = {}));
var AlertSeverity;
(function (AlertSeverity) {
    AlertSeverity["CRITICAL"] = "critical";
    AlertSeverity["MAJOR"] = "major";
    AlertSeverity["MINOR"] = "minor";
    AlertSeverity["WARNING"] = "warning";
    AlertSeverity["INFO"] = "info";
})(AlertSeverity = exports.AlertSeverity || (exports.AlertSeverity = {}));
