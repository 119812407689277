import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'

@Pipe({ name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {
    transform(value: any): string {
        if (value) {
            if (value._seconds) {
                return moment(value._seconds * 1000).format('D MMM YYYY')
            }
            if (value.seconds) {
                return moment(value.seconds * 1000).format('D MMM YYYY')
            }
            return moment(value).format('D MMM YYYY')
        }
        return ''
    }
}
