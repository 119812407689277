import { Injectable } from '@angular/core'
import { addDoc, collection, collectionData, doc, docData, Firestore, getDoc, query, serverTimestamp, setDoc, updateDoc } from '@angular/fire/firestore'
import { AdminClient, FirestoreStructure } from '@parkupp/core'
import { BehaviorSubject, map, Observable, Subject, Subscription } from 'rxjs'
import { DocumentData } from 'rxfire/firestore/interfaces'
import * as firestore from 'firebase/firestore'
import { Auth } from '@angular/fire/auth'
import { AuthenticationService } from './authentication.service'
import { Router } from '@angular/router'

@Injectable({
    providedIn: 'root',
})
export class AdminClientService {
    collection = collection(this.firestore, FirestoreStructure.ADMIN_CLIENTS)

    activeAdminClient: AdminClient | null
    adminClientSubscription: Subscription
    activeAdminClientReady = new Subject()

    private activeAdminClientSubject = new BehaviorSubject<AdminClient | null>(null)
    activeAdminClient$: Observable<AdminClient | null> = this.activeAdminClientSubject.asObservable()

    constructor(public firestore: Firestore, public auth: Auth, private authenticationSvc: AuthenticationService, private router: Router) {
        this.auth.onAuthStateChanged((user) => {
            this.activeAdminClient = null
            if (user?.uid) {
                this.getActiveAdminClient(user?.uid!)
            }
        })
    }

    getDocRef(adminClientKey: string) {
        return doc(this.firestore, `${FirestoreStructure.ADMIN_CLIENTS}/${adminClientKey}`)
    }

    list(): Observable<AdminClient[]> {
        const adminClientsQuery = query(this.collection)
        return collectionData(adminClientsQuery, { idField: '$key' }) as Observable<AdminClient[]>
    }

    get(adminClientId: string): Observable<any> {
        const adminClientDocRef = doc(this.firestore, `${FirestoreStructure.ADMIN_CLIENTS}/${adminClientId}`)
        return docData(adminClientDocRef, { idField: '$key' })
    }

    async getActiveAdminClient(uid: string) {
        if (this.adminClientSubscription) {
            this.adminClientSubscription.unsubscribe()
        }
        const documentRef = doc(this.firestore, FirestoreStructure.ADMIN_CLIENTS, uid)
        const documentSnap = await getDoc(documentRef)

        if (documentSnap.exists()) {
            this.activeAdminClient = documentSnap.data() as AdminClient
            this.activeAdminClient.$key = uid

            this.activeAdminClientReady.next(this.activeAdminClient! as AdminClient)
            this.activeAdminClientSubject.next(this.activeAdminClient! as AdminClient)
        }
    }

    createAdminClient(adminClient: AdminClient, uid: string): Promise<any> {
        adminClient.createdAt = serverTimestamp() as firestore.Timestamp
        adminClient.updatedAt = serverTimestamp() as firestore.Timestamp
        const reference = doc(this.firestore, FirestoreStructure.ADMIN_CLIENTS, uid)
        return setDoc(reference, adminClient.parse())
    }

    update(adminClient: AdminClient): Promise<any> {
        adminClient.updatedAt = serverTimestamp() as firestore.Timestamp
        const reference = doc(this.firestore, FirestoreStructure.ADMIN_CLIENTS, adminClient.$key!)
        return updateDoc(reference, adminClient.parse())
    }
}
