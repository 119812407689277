<ng-container *ngIf="merchant">
    <div class="btn-group" role="group" aria-label="Merchant Profile Tabs">
        <button
            type="button"
            class="btn btn-sm"
            [ngClass]="{ 'btn-outline-secondary': tab !== 'details', 'btn-outline-primary': tab === 'details' }"
            [routerLink]="['/merchant', merchant.$key, 'profile', 'details']"
        >
            Details
        </button>
        <button
            type="button"
            class="btn btn-sm"
            [ngClass]="{ 'btn-outline-secondary': tab !== 'managers', 'btn-outline-primary': tab === 'managers' }"
            [routerLink]="['/merchant', merchant.$key, 'profile', 'managers']"
        >
            Managers
        </button>
        <button
            type="button"
            class="btn btn-sm btn-outline-secondary"
            [ngClass]="{ 'btn-outline-secondary': tab !== 'payout', 'btn-outline-primary': tab === 'payout' }"
            [routerLink]="['/merchant', merchant.$key, 'profile', 'payout']"
        >
            Payout Details
        </button>
        <button
            type="button"
            class="btn btn-sm btn-outline-secondary"
            [ngClass]="{ 'btn-outline-secondary': tab !== 'payout-details-old', 'btn-outline-primary': tab === 'payout-details-old' }"
            [routerLink]="['/merchant', merchant.$key, 'profile', 'payout-details-old']"
        >
            Payout Details (archived)
        </button>
    </div>

    <div *ngIf="tab === 'details'" class="mt-4">
        <app-merchant-profile-form [merchantKey]="merchant.$key!" [dashboardView]="dashboardView" [showHeading]="false"></app-merchant-profile-form>
    </div>
    <div *ngIf="tab === 'managers'" class="mt-4">
        <app-merchant-members-list [merchantKey]="merchant.$key!" [showHeading]="false"></app-merchant-members-list>
    </div>
    <div *ngIf="tab === 'payout'">
        <app-merchant-banking-details-form [merchantKey]="merchant.$key!" [bankDetailsModal]="false"></app-merchant-banking-details-form>
    </div>
    <div *ngIf="tab === 'payout-details-old'">
        <app-merchant-banking-details-old [merchantKey]="merchant.$key!"></app-merchant-banking-details-old>
    </div>
</ng-container>
