<div class="row">
    <div class="col-12">
        <div *ngIf="authService.merchants.length > 0 && authService.activeMerchant" class="dropdown d-grid">
            <button
                *ngIf="authService.merchants.length > 1; else onlyOne"
                class="btn btn-outline-info dropdown-toggle"
                type="button"
                id="merchantDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <strong><i class="fa fa-bars me-3"></i> {{ this.authService.activeMerchant?.companyName }}</strong>
            </button>

            <ng-template #onlyOne>
                <h4 class="text-info text-center">
                    {{ this.authService.activeMerchant?.companyName }}
                </h4>
            </ng-template>

            <ul *ngIf="authService.merchants.length > 1" class="dropdown-menu" aria-labelledby="merchantDropdown">
                <ng-container *ngFor="let merchant of authService.merchants">
                    <li *ngIf="merchant.companyName != authService.activeMerchant.companyName" class="d-grid">
                        <a class="dropdown-item" (click)="authService.setActiveMerchant(merchant)">{{ merchant.companyName }} </a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</div>
<div class="divider mt-3 mb-4"></div>
