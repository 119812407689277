import { Component } from '@angular/core'
import { HealthCheckService } from './services/health-check.service'
import { AuthenticationService } from './services/authentication.service'
import { Meta, Title } from '@angular/platform-browser'
import { OmnisendService } from './services/omnisend.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'parkupp'

    constructor(
        private healthCheckService: HealthCheckService,
        public authService: AuthenticationService,
        private titleService: Title,
        private metaTagService: Meta,
        private omnisendService: OmnisendService
    ) {
        this.authService.auth.onAuthStateChanged((user) => {
            if (user == null) {
                this.authService.signInAnonymously()
            }
        })

        // this.omnisendService.viewPage()
    }

    ngOnInit() {
        this.titleService.setTitle('ParkUpp - Find monthly parking online')
        this.metaTagService.addTags([{ name: 'description', content: 'Find nearby parking spots for your car. Find monthly parking online.' }])
    }
}
