import { Injectable } from '@angular/core'
import {
    AcceptInviteRequest,
    AcceptInviteResponse,
    BankDetails,
    bankingDetailsPath,
    CreateMerchantRequest,
    CreateMerchantResponse,
    FirestoreStructure,
    InviteType,
    Merchant,
    MerchantMember,
    merchantMemberMapPath,
    merchantMembersPath,
    merchantsPath,
    MerchantTenant,
    merchantTenantsPath,
    Subscription,
} from '@parkupp/core'
import { addDoc, collection, collectionData, doc, docData, DocumentReference, Firestore, serverTimestamp, updateDoc } from '@angular/fire/firestore'
import { Observable, take } from 'rxjs'
import { DocumentData } from 'rxfire/firestore/interfaces'
import * as firestore from 'firebase/firestore'
import { Auth } from '@angular/fire/auth'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { AuthenticationService } from './authentication.service'
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class MerchantService {
    collection = collection(this.firestore, FirestoreStructure.MERCHANTS)
    sideMenuCollapsed: boolean = false

    constructor(public firestore: Firestore, public auth: Auth, private http: HttpClient) {}

    getDocRef(merchantKey: string): any {
        return doc(this.firestore, `${FirestoreStructure.MERCHANTS}/${merchantKey}`)
    }

    get(merchantKey: string): Observable<any> {
        const clientDocRef = doc(this.firestore, `${FirestoreStructure.MERCHANTS}/${merchantKey}`)
        return docData(clientDocRef, { idField: '$key' })
    }

    getMerchant(merchantKey: string) {
        return new Promise<any>((resolve, reject) => {
            docData(doc(this.firestore, merchantsPath(), merchantKey)).subscribe((documentData: any) => {
                let merchant = new Merchant(documentData)
                merchant.$key = merchantKey
                resolve(merchant)
            })
        })
    }

    list(): Observable<Merchant[]> {
        const merchantsCollection = collection(this.firestore, merchantsPath())
        return collectionData(merchantsCollection, { idField: 'documentId' }).pipe(
            take(1),
            map((documents: DocumentData[]) =>
                documents
                    .map((merchantData) => {
                        const merchant = new Merchant(merchantData)
                        merchant.$key = merchantData['documentId']
                        return merchant
                    })
                    .filter((merchant) => merchant.companyName !== '')
            )
        )
    }

    listPromise(): Promise<Merchant[]> {
        return new Promise<Merchant[]>((resolve, reject) => {
            this.list().subscribe((merchants) => {
                resolve(merchants)
            })
        })
    }

    createMerchant(createMerchantRequest: CreateMerchantRequest, idToken: string): Observable<CreateMerchantResponse> {
        return this.http.post<any>(`${environment.functionsUrl}/merchants-createMerchant`, createMerchantRequest, AuthenticationService.getHeaders(idToken))
    }

    updateMerchant(fields: { [id: string]: any }, uid: string): Promise<any> {
        fields['updatedAt'] = serverTimestamp() as firestore.Timestamp
        const reference = doc(this.firestore, merchantsPath(), uid)
        return updateDoc(reference, fields)
    }

    createOrUpdateMerchant(merchant: Merchant): Promise<any> {
        merchant.updatedAt = serverTimestamp() as firestore.Timestamp
        if (merchant.$key) {
            const reference = doc(this.firestore, merchantsPath(), merchant.$key)
            return updateDoc(reference, merchant.parse())
        } else {
            merchant.createdAt = serverTimestamp() as firestore.Timestamp
            return addDoc(collection(this.firestore, merchantsPath()), merchant.parse())
        }
    }

    subscribeToMerchant(uid: string): Observable<DocumentData> {
        return docData(doc(this.firestore, merchantsPath(), uid))
    }

    subscribeToMerchantMap(uid: string): Observable<DocumentData[]> {
        return collectionData(collection(this.firestore, merchantMemberMapPath(uid)), { idField: 'documentId' })
    }
    getAllBankDetails(uid: string): Observable<BankDetails[]> {
        return collectionData(collection(this.firestore, bankingDetailsPath(uid)), { idField: 'documentId' }).pipe(
            map((documents: DocumentData[]) =>
                documents.map((documentData) => {
                    const bankDetails = new BankDetails(documentData)
                    bankDetails.$key = documentData['documentId']
                    return bankDetails
                })
            )
        )
    }

    // saveMerchantBankDetails(bankDetails: BankDetails, merchantKey: string): Promise<any> {
    //     // bankDetails.updatedAt = serverTimestamp() as firestore.Timestamp
    //     if (bankDetails.$key) {
    //         const reference = doc(this.firestore, bankingDetailsPath(merchantKey), bankDetails.$key)
    //         return updateDoc(reference, bankDetails.parse())
    //     } else {
    //         // bankDetails.createdAt = serverTimestamp() as firestore.Timestamp
    //         return addDoc(collection(this.firestore, bankingDetailsPath(merchantKey)), bankDetails.parse())
    //     }
    // }

    addMerchantMember(merchantMember: MerchantMember, uid: string): Promise<any> {
        merchantMember.createdAt = serverTimestamp() as firestore.Timestamp
        merchantMember.updatedAt = serverTimestamp() as firestore.Timestamp
        return addDoc(collection(this.firestore, merchantMembersPath(uid)), merchantMember.parse())
    }

    updateMerchantMember(fields: { [id: string]: any }, uid: string, docId: string): Promise<any> {
        fields['updatedAt'] = serverTimestamp() as firestore.Timestamp
        return updateDoc(doc(this.firestore, merchantMembersPath(uid), docId), fields)
    }

    addMerchantTenant(merchantTenant: MerchantTenant, uid: string): Promise<any> {
        merchantTenant.createdAt = serverTimestamp() as firestore.Timestamp
        merchantTenant.updatedAt = serverTimestamp() as firestore.Timestamp
        return addDoc(collection(this.firestore, merchantTenantsPath(uid)), merchantTenant.parse())
    }

    updateMerchantTenant(fields: { [id: string]: any }, uid: string, docId: string): Promise<any> {
        fields['updatedAt'] = serverTimestamp() as firestore.Timestamp
        return updateDoc(doc(this.firestore, merchantTenantsPath(uid), docId), fields)
    }

    deleteMerchantMember(fields: { [id: string]: any }, uid: string, docId: string): Promise<any> {
        fields['updatedAt'] = serverTimestamp() as firestore.Timestamp
        fields['active'] = false
        return updateDoc(doc(this.firestore, merchantMembersPath(uid), docId), fields)
    }

    acceptMerchantInvite(merchantKey: string, uid: string, email: string, token: string, idToken: string, inviteType: InviteType): Observable<AcceptInviteResponse> {
        const acceptMerchantInviteRequest = new AcceptInviteRequest()
        acceptMerchantInviteRequest.inviteType = inviteType
        acceptMerchantInviteRequest.merchantKey = merchantKey
        acceptMerchantInviteRequest.uid = uid
        acceptMerchantInviteRequest.token = token
        acceptMerchantInviteRequest.email = email
        return this.http.post<AcceptInviteResponse>(`${environment.functionsUrl}/merchants-acceptMerchantIMemberInvite`, acceptMerchantInviteRequest, AuthenticationService.getHeaders(idToken))
    }

    populateSubscriptionsArray(ref: any) {
        return new Promise<any>((resolve, reject) => {
            collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
                let subscriptions: Subscription[] = []
                documents.forEach((documentData) => {
                    let subscription = new Subscription(documentData)
                    subscription.$key = documentData.documentId

                    // docData(documentData.clientRef).subscribe((data) => {
                    //     subscription.$client = new Client(data)
                    //     subscription.$client.$key = documentData.clientRef.id
                    // })
                    //
                    // docData(documentData.parkingRef).subscribe((data) => {
                    //     subscription.$parking = new Parking(data)
                    //     subscription.$parking.$key = documentData.parkingRef.id
                    // })

                    // subscription.loadClient(firestore).then(() => {
                    //     console.log(subscription)
                    // })

                    subscriptions.push(subscription)
                })
                resolve(subscriptions)
            })
        })
    }

    toggleSideMenu() {
        this.sideMenuCollapsed = !this.sideMenuCollapsed
    }
}
