<admin-app-header></admin-app-header>
<div class="container-fluid">
    <button (click)="globalService.exportcsv('all-invites')" class="btn btn-info btn-sm text-white float-end px-3 py-1 my-2"><i class="fas fa-file-csv"></i> Export to CSV</button>

    <div class="row header">
        <div class="col-4 p-0">
            <h2 class="mb-0">Admin Merchant Invites List</h2>
        </div>
    </div>
    <div class="content col-lg-12">
        <table class="table table-striped table-hover table-sm" id="all-invites">
            <thead>
                <tr>
                    <th scope="col">Created<br />Date</th>
                    <th scope="col">Profile<br />Created</th>
                    <th scope="col">Access<br />Deleted</th>
                    <th scope="col">Email</th>
                    <th scope="col">Client</th>
                    <th scope="col">Merchant</th>
                    <th scope="col">portfolio Manager</th>
                    <th scope="col">Building Manager</th>
                    <th scope="col">Account Key</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let invite of invites">
                    <tr>
                        <td>
                            {{ invite.createdAt | formatDate }}
                        </td>

                        <td>
                            <span class="badge" [ngClass]="{ 'bg-success': invite.uid, 'bg-danger': !invite.uid }">
                                {{ invite.uid ? 'Yes' : 'No' }}
                            </span>
                        </td>
                        <td>
                            <span class="badge" [ngClass]="{ 'bg-success': !invite.active, 'bg-danger': invite.active }">
                                {{ invite.active ? 'No' : 'Yes' }}
                            </span>
                        </td>
                        <td>
                            {{ invite.email }}
                        </td>
                        <td>
                            <ng-container *ngIf="invite.uid">
                                <a [routerLink]="['/client/', invite.uid]"> Profile </a>
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="invite.merchant">
                                <a [routerLink]="['/merchant/', invite.merchant.$key]">
                                    {{ invite.merchant.companyName || 'Not set' }}
                                </a>
                            </ng-container>
                        </td>
                        <td>
                            <span class="badge" [ngClass]="{ 'bg-success': invite.roles.includes('PORTFOLIO_MANAGER'), 'bg-danger': !invite.roles.includes('PORTFOLIO_MANAGER') }">
                                {{ invite.roles.includes('PORTFOLIO_MANAGER') ? 'Yes' : 'No' }}
                            </span>
                        </td>
                        <td>
                            <span class="badge" [ngClass]="{ 'bg-success': invite.roles.includes('BUILDING_MANAGER'), 'bg-danger': !invite.roles.includes('BUILDING_MANAGER') }">
                                {{ invite.roles.includes('BUILDING_MANAGER') ? 'Yes' : 'No' }}
                            </span>
                        </td>
                        <td>
                            <span class="badge" [ngClass]="{ 'bg-success': invite.roles.includes('ACCOUNT_KEY'), 'bg-danger': !invite.roles.includes('ACCOUNT_KEY') }">
                                {{ invite.roles.includes('ACCOUNT_KEY') ? 'Yes' : 'No' }}
                            </span>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
