import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { AuthenticationService } from '../../../services/authentication.service'
import { Auth } from '@angular/fire/auth'
import { Router } from '@angular/router'

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent extends FormBuilder implements OnInit {
    formGroup: FormGroup

    success: (auth: Auth) => void
    failure: (auth: Auth) => void
    loading: boolean = false
    error: string | null

    constructor(public authService: AuthenticationService, private formBuilder: FormBuilder, private router: Router) {
        super()

        this.formGroup = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        })

        this.success = (auth) => {
            this.loading = false
            this.router.navigate([`/sign-in`]) // TODO check returnUrl and also if profile created or not
        }
        this.failure = (error: any) => {
            this.loading = false
            this.error = error.message
        }
    }

    ngOnInit(): void {}

    resetPassword() {
        this.authService.sendPasswordResetEmail(this.formGroup.value.email).then(this.success).catch(this.failure)
    }
}
