import { Injectable } from '@angular/core'
import { ref, Storage } from '@angular/fire/storage'
import { UploadResult, uploadString } from '@firebase/storage'

@Injectable({
    providedIn: 'root',
})
export class ImageService {
    constructor(private storage: Storage) {}

    uploadImage(croppedImage: string, filePath: string, fileName: string): Promise<UploadResult> {
        return uploadString(ref(this.storage, `${filePath}/${fileName}.jpg`), croppedImage, 'data_url')
    }
}
