import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'stripHtml',
})
export class StripHtmlPipe implements PipeTransform {
    constructor() {}

    public transform(value: any): string {
        if (!value) return value
        return value.replace(/<[^>]*>?/g, '')
    }
}
