import { Component, OnInit } from '@angular/core'
import { AdminChangeLog, AngularRoutes, ChangeLogsEntityEnum, ContractStatus, Invoice, InvoiceStatus, Subscription, SubscriptionType, subscriptionsPath, EnumNames } from '@parkupp/core'
import { collection, collectionData, DocumentData, orderBy, query, Query, serverTimestamp, Timestamp, where } from '@angular/fire/firestore'
import { GlobalService } from '../../../services/global.service'
import { ActivatedRoute, Router } from '@angular/router'
import { AdminService } from '../../../services/admin.service'
import { InvoiceService } from '../../../services/invoice.service'
import * as moment from 'moment/moment'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { ClientService } from 'src/app/services/client.service'
import { AdminChangeLogService } from 'src/app/services/admin-change-log.service'

@Component({
    selector: 'app-admin-list-invoices',
    templateUrl: './admin-list-invoices.component.html',
    styleUrls: ['./admin-list-invoices.component.scss'],
})
export class AdminListInvoicesComponent implements OnInit {
    Invoice = Invoice
    readonly AngularRoutes = AngularRoutes
    EnumNames = EnumNames
    SubscriptionType = SubscriptionType
    subscriptions: Subscription[] = []
    invoices: Invoice[] = []
    InvoiceStatus = InvoiceStatus
    invoiceStates: InvoiceStatus[] = Object.values(InvoiceStatus)

    today: number = moment().toDate().getTime()
    status: InvoiceStatus = InvoiceStatus.DUE
    selectedInvoice: Invoice | null = null

    constructor(
        public globalService: GlobalService,
        private authService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private adminService: AdminService,
        private invoiceService: InvoiceService,
        private clientService: ClientService,
        private adminChangeLogService: AdminChangeLogService
    ) {}

    async ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            if (params['status']) {
                this.status = params['status'] as InvoiceStatus
            }
        })
        const activeContractStatuses: ContractStatus[] = [ContractStatus.paymentInitiated, ContractStatus.active, ContractStatus.bouncing, ContractStatus.renewing]
        const ref = query(collection(this.adminService.firestore, subscriptionsPath()), orderBy('createdAt', 'desc'), where('status', 'in', activeContractStatuses))
        collectionData(ref, { idField: 'documentId' }).subscribe(async (documents: any[]) => {
            this.subscriptions = []
            documents.forEach((documentData) => {
                let subscription = new Subscription(documentData, documentData.documentId)
                this.subscriptions.push(subscription)
            })
            this.invoices = []
            await this.loadInvoices()
        })
    }

    async loadInvoices() {
        const data = await this.invoiceService.list(this.status)
        if (data.length > 0) {
            this.invoices = data.map((doc) => {
                const invoice = new Invoice(doc.data())
                invoice.$key = doc.id
                // Match subscription to the invoice
                invoice.$subscription = this.subscriptions.find((sub) => sub.$key === invoice.subscriptionRef?.id)
                return invoice
            })
        }
    }

    changeStatus(event: any) {
        this.status = event.target.value as InvoiceStatus
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { status: this.status },
            queryParamsHandling: 'merge',
        })
        this.filterInvoices()
    }

    filterInvoices() {
        this.loadInvoices()
    }

    async showContextMenu(event: MouseEvent, invoice: Invoice) {
        this.globalService.contextMenuItems = []
        this.selectedInvoice = invoice
        this.globalService.showContextMenu(event)

        let markAsMenuItems: any = []
        if (invoice.status != InvoiceStatus.PAID) {
            markAsMenuItems.push({
                label: 'Mark as Paid',
                action: () => {
                    this.updateInvoiceStatus(invoice, InvoiceStatus.PAID, 'Marked as paid')
                },
            })
        }

        if (invoice.status != InvoiceStatus.DUE) {
            markAsMenuItems.push({
                label: 'Mark as Due',
                action: () => {
                    this.updateInvoiceStatus(invoice, InvoiceStatus.DUE, 'Marked as due')
                },
            })
        }

        if (invoice.status != InvoiceStatus.REFUNDED) {
            markAsMenuItems.push({
                label: 'Mark as Refunded',
                action: () => {
                    this.updateInvoiceStatus(invoice, InvoiceStatus.REFUNDED, 'Marked as refunded')
                },
            })
        }

        if (invoice.status != InvoiceStatus.CANCELLED) {
            markAsMenuItems.push({
                label: 'Mark as Cancelled',
                action: () => {
                    this.updateInvoiceStatus(invoice, InvoiceStatus.CANCELLED, 'Marked as cancelled')
                },
            })
        }

        if (invoice.status != InvoiceStatus.BREECHED) {
            markAsMenuItems.push({
                label: 'Mark as Breeched',
                action: () => {
                    this.updateInvoiceStatus(invoice, InvoiceStatus.BREECHED, 'Marked as breeched')
                },
            })
        }

        this.globalService.contextMenuItems.push({
            icon: 'fa fa-exchange',
            label: 'Update Status : ',
            subMenuItems: markAsMenuItems,
        })

        let changeTypeMenuItems: any = []
        if (invoice.paymentMethodType != SubscriptionType.PAYFAST) {
            changeTypeMenuItems.push({
                label: 'Change to Payfast',
                action: () => {
                    this.updateInvoiceType(invoice, SubscriptionType.PAYFAST)
                },
            })
        }

        if (invoice.paymentMethodType != SubscriptionType.PAYGATE) {
            changeTypeMenuItems.push({
                label: 'Change to PayGate',
                action: () => {
                    this.updateInvoiceType(invoice, SubscriptionType.PAYGATE)
                },
            })
        }

        if (invoice.paymentMethodType != SubscriptionType.EFT) {
            changeTypeMenuItems.push({
                label: 'Change to EFT',
                action: () => {
                    this.updateInvoiceType(invoice, SubscriptionType.EFT)
                },
            })
        }

        this.globalService.contextMenuItems.push({
            icon: 'fa fa-exchange',
            label: 'Change Payment Method: ',
            subMenuItems: changeTypeMenuItems,
        })
    }

    async updateInvoiceType(invoice: Invoice, type: SubscriptionType) {
        invoice.paymentMethodType = type
        await this.invoiceService.update(invoice)
        this.globalService.hideContextMenu()
        // location.reload()
    }

    async updateInvoiceStatus(invoice: Invoice, status: InvoiceStatus, comment: string) {
        const user = await this.authService.waitForUid()
        const admin = await this.clientService.getClientByUid(user.uid)
        if (admin) {
            const adminRef = this.clientService.getDocRef(admin.$key!)
            const invoiceRef = this.invoiceService.getDocRef(invoice.$key!)

            const changeLog = new AdminChangeLog({
                updatedEntity: ChangeLogsEntityEnum.INVOICE,
                invoiceRef: invoiceRef as any,
                clientRef: adminRef as any,
                comment: `${comment} by ${admin.firstName}`,
                createdAt: serverTimestamp() as Timestamp,
            })
            await this.adminChangeLogService.addOrUpdate(changeLog)

            if (status === InvoiceStatus.PAID) {
                await this.invoiceService.markAsPaid(invoice)
            } else if (status === InvoiceStatus.BREECHED) {
                await this.invoiceService.markAsClientBreeched(invoice)
            } else if (status === InvoiceStatus.REFUNDED) {
                await this.invoiceService.markAsRefunded(invoice)
            } else if (status === InvoiceStatus.CANCELLED) {
                await this.invoiceService.markAsCancelled(invoice)
            } else if (status === InvoiceStatus.DUE) {
                await this.invoiceService.markAsDue(invoice)
            }
        }
        this.globalService.hideContextMenu()
        location.reload()
    }
}
