import { Component, OnInit } from '@angular/core'
import { query } from '@angular/fire/firestore'
import { collection, limit, orderBy } from '@angular/fire/firestore'
import { DashboardMetric } from '@parkupp/core'
import { getDocs } from 'firebase/firestore'
import { AdminService } from 'src/app/services/admin.service'

@Component({
    selector: 'app-admin-line-charts',
    templateUrl: './admin-line-charts.component.html',
    styleUrls: ['./admin-line-charts.component.scss'],
})
export class AdminLineChartsComponent implements OnInit {
    DashboardMetric = DashboardMetric
    dashboardData: any[] = []
    dashboardMetrics: any[] = []

    chartData: any[] = []
    chartsReady = false

    chartVisibility: { [key: string]: boolean } = {}

    constructor(private adminService: AdminService) {}

    async ngOnInit() {
        const data = await this.fetchDashboardData()
        console.log('Fetched data:', data) // Log the fetched data to verify its structure

        this.dashboardMetrics = Object.values(DashboardMetric)
        this.dashboardMetrics.forEach((metric) => {
            const chartData = [this.getChartData(metric, data)]
            const chartTitle = this.getChartTitle(metric)
            const xAxisLabel = 'Day'
            const yAxisLabel = 'Value'
            const colourScheme = this.getColourScheme(metric)

            this.chartData.push({ chartData, chartTitle, xAxisLabel, yAxisLabel, colourScheme, metric })
        })
        this.chartsReady = true
    }

    toggleChart(metric: DashboardMetric) {
        const isVisible = this.isVisible(metric)
        localStorage.setItem(metric, (!isVisible).toString())
    }

    isVisible(metric: DashboardMetric) {
        const isVisible = localStorage.getItem(metric) === 'true'
        return isVisible
    }

    updateChartData() {
        this.chartData = []
        this.dashboardMetrics.forEach((metric) => {
            if (this.isVisible(metric)) {
                const chartData = [this.getChartData(metric, this.dashboardData)]
                const chartTitle = this.getChartTitle(metric)
                const xAxisLabel = 'Day'
                const yAxisLabel = 'Value'
                const colourScheme = this.getColourScheme(metric)

                this.chartData.push({ chartData, chartTitle, xAxisLabel, yAxisLabel, colourScheme })
            }
        })
    }

    async fetchDashboardData() {
        const dailyDashboardDataCollection = collection(this.adminService.firestore, 'dailyDashboardData')
        const q = query(dailyDashboardDataCollection, orderBy('createdAt'), limit(90))
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map((doc) => doc.data())
    }

    getChartData(metric: DashboardMetric, data: any[]) {
        const output = data.map((data) => ({
            name: new Date(data.createdAt.seconds * 1000).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }),
            value: data[metric],
        }))
        return {
            name: metric,
            series: output,
        }
    }

    getChartTitle(metric: DashboardMetric) {
        switch (metric) {
            case DashboardMetric.USERS:
                return 'Total Users'
            case DashboardMetric.SUBSCRIPTION_FUTURE_VALUE:
                return 'Subscription Future Value'
            case DashboardMetric.SUBSCRIPTIONS_TOTAL:
                return 'Subscriptions Total'
            case DashboardMetric.SUBSCRIPTIONS_PAYFAST:
                return 'Subscriptions Payfast'
            case DashboardMetric.SUBSCRIPTIONS_PAYGATE:
                return 'Subscriptions Paygate'
            case DashboardMetric.SUBSCRIPTIONS_EFT:
                return 'Subscriptions EFT'
            case DashboardMetric.BAYS_TOTAL:
                return 'Bays Total'
            case DashboardMetric.BAYS_ACTIVE:
                return 'Bays Active'
            case DashboardMetric.BAYS_CAPACITY:
                return 'Bays Capacity'
            case DashboardMetric.BUILDINGS_TOTAL:
                return 'Buildings Total'
            case DashboardMetric.BUILDINGS_UNPUBLISHED:
                return 'Buildings Unpublished'
            case DashboardMetric.BUILDINGS_ACTIVE:
                return 'Buildings Active'
            case DashboardMetric.INVOICES_OVERDUE_TOTAL:
                return 'Invoices Overdue Total'
            case DashboardMetric.INVOICES_OVERDUE_COUNT:
                return 'Invoices Overdue Count'
            case DashboardMetric.INVOICES_NOT_PAID_OUT_TOTAL:
                return 'Invoices Not Paid Out Total'
            case DashboardMetric.INVOICES_NOT_PAID_OUT_COUNT:
                return 'Invoices Not Paid Out Count'
            case DashboardMetric.USERS_ACTIVE:
                return 'Users Active'
            case DashboardMetric.USERS_NEW:
                return 'Users New'
            case DashboardMetric.USERS_NEW_WITH_PROFILE:
                return 'Users New With Profile'
            case DashboardMetric.SUBSCRIPTIONS_NEW_COUNT:
                return 'Subscriptions New Count'
            case DashboardMetric.SUBSCRIPTIONS_NEW_VALUE:
                return 'Subscriptions New Value'

            case DashboardMetric.USERS_WITH_PROFILE:
                return 'Users With Profile'

            default:
                return metric
        }
    }

    getColourScheme(metric: DashboardMetric) {
        switch (metric) {
            case DashboardMetric.USERS:
                return { domain: ['#5AA454'] }
            case DashboardMetric.SUBSCRIPTIONS_TOTAL:
                return { domain: ['#E44D25'] }
            case DashboardMetric.SUBSCRIPTIONS_PAYFAST:
                return { domain: ['#CFC0BB'] }
            case DashboardMetric.SUBSCRIPTIONS_PAYGATE:
                return { domain: ['#7aa3e5'] }
            case DashboardMetric.SUBSCRIPTIONS_EFT:
                return { domain: ['#a8385d'] }
            case DashboardMetric.BAYS_TOTAL:
                return { domain: ['#FF5733'] }
            case DashboardMetric.BAYS_ACTIVE:
                return { domain: ['#33FF57'] }
            case DashboardMetric.BAYS_CAPACITY:
                return { domain: ['#3357FF'] }
            case DashboardMetric.BUILDINGS_TOTAL:
                return { domain: ['#FF33A1'] }
            case DashboardMetric.BUILDINGS_UNPUBLISHED:
                return { domain: ['#A133FF'] }
            case DashboardMetric.BUILDINGS_ACTIVE:
                return { domain: ['#33FFA1'] }
            case DashboardMetric.INVOICES_OVERDUE_TOTAL:
                return { domain: ['#FFAA33'] }
            case DashboardMetric.INVOICES_OVERDUE_COUNT:
                return { domain: ['#33AAFF'] }
            case DashboardMetric.INVOICES_NOT_PAID_OUT_TOTAL:
                return { domain: ['#AA33FF'] }
            case DashboardMetric.INVOICES_NOT_PAID_OUT_COUNT:
                return { domain: ['#FF33AA'] }
            case DashboardMetric.USERS_ACTIVE:
                return { domain: ['#AAFF33'] }
            case DashboardMetric.USERS_NEW:
                return { domain: ['#33FFAA'] }
            case DashboardMetric.USERS_NEW_WITH_PROFILE:
                return { domain: ['#FF5733'] }
            case DashboardMetric.SUBSCRIPTIONS_NEW_COUNT:
                return { domain: ['#5733FF'] }
            case DashboardMetric.SUBSCRIPTIONS_NEW_VALUE:
                return { domain: ['#FF3357'] }
            case DashboardMetric.USERS_WITH_PROFILE:
                return { domain: ['#57FF33'] }
            default:
                return { domain: ['#3357FF'] }
        }
    }
    formatToK(value: number) {
        if (value >= 1000000) {
            return (value / 1000000).toFixed(0) + 'M'
        } else if (value >= 1000) {
            return (value / 1000).toFixed(0) + 'K'
        }
        return value.toFixed(0)
    }
}
