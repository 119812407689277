"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackingEntriesPath = exports.migrationsPath = exports.emailVerificationTokenPath = exports.blogsPath = exports.logSearchesPath = exports.logEmailsPath = exports.tenantInvitesPath = exports.merchantInvitesPath = exports.adminPath = exports.enquiriesPath = exports.bookingsPath = exports.subscriptionsPath = exports.settlementsPath = exports.paymentsPath = exports.merchantParkingImagesPath = exports.parkingAmenitiesPath = exports.parkingsPath = exports.merchantParkingsPath = exports.bankingDetailsPath = exports.merchantMemberMapPath = exports.merchantTenantsPath = exports.merchantMembersPath = exports.merchantsPath = exports.legacyCredentialsPath = exports.occupantsPath = exports.paymentMethodsPath = exports.clientPrivatePath = exports.clientsPrivatePath = exports.clientsPath = exports.FirestoreStructure = void 0;
class FirestoreStructure {
}
exports.FirestoreStructure = FirestoreStructure;
FirestoreStructure.CLIENTS = 'clients';
FirestoreStructure.CLIENTS_PRIVATE = 'clientsPrivate';
FirestoreStructure.CLIENTS_PAYMENT_METHODS = 'paymentMethods';
FirestoreStructure.CLIENTS_LEGACY_CREDENTIALS = 'legacyCredentials';
FirestoreStructure.CLIENTS_OCCUPANTS = 'occupants';
FirestoreStructure.CLIENTS_INVOICES = 'invoices';
FirestoreStructure.MERCHANTS = 'merchants';
FirestoreStructure.MERCHANTS_BANK_DETAILS = 'bankDetails';
FirestoreStructure.MERCHANTS_MERCHANT_MEMBERS = 'merchantMembers';
FirestoreStructure.MERCHANTS_MERCHANT_MEMBER_MAP = 'merchantMemberMap';
FirestoreStructure.MERCHANTS_PARKINGS = 'parkings';
FirestoreStructure.PARKINGS = 'parkings';
FirestoreStructure.LISTS_PARKING_AMENITIES = 'parkingAmenities';
FirestoreStructure.PAYMENTS = 'payments';
FirestoreStructure.INVOICES = 'invoices';
FirestoreStructure.VARIABLES = 'variables';
FirestoreStructure.SETTLEMENTS = 'settlements';
FirestoreStructure.SUBSCRIPTIONS = 'subscriptions';
FirestoreStructure.BOOKINGS = 'bookings';
FirestoreStructure.ADMINISTRATORS = 'administrators';
FirestoreStructure.MERCHANT_MEMBER_INVITES = 'merchantMemberInvites';
FirestoreStructure.MERCHANT_TENANT_INVITES = 'merchantTenantInvites';
FirestoreStructure.MERCHANTS_MERCHANT_TENANTS = 'merchantTenants';
FirestoreStructure.LOG_EMAILS_PATH = 'logsEmails';
FirestoreStructure.LOG_SEARCHES_PATH = 'logsSearches';
FirestoreStructure.BLOGS_PATH = 'blogs';
FirestoreStructure.ENQUIRIES = 'enquiries';
FirestoreStructure.EMAIL_VERIFICATION_TOKEN = 'emailVerificationToken';
FirestoreStructure.MIGRATIONS = 'migrations';
FirestoreStructure.TRACKING_ENTRIES = 'trackingEntries';
FirestoreStructure.EXCEPTION_ALERTS = 'exceptionAlerts';
function clientsPath() {
    return FirestoreStructure.CLIENTS;
}
exports.clientsPath = clientsPath;
function clientsPrivatePath() {
    return FirestoreStructure.CLIENTS_PRIVATE;
}
exports.clientsPrivatePath = clientsPrivatePath;
function clientPrivatePath(uid) {
    return `${FirestoreStructure.CLIENTS_PRIVATE}/${uid}`;
}
exports.clientPrivatePath = clientPrivatePath;
function paymentMethodsPath(uid) {
    return `${FirestoreStructure.CLIENTS}/${uid}/${FirestoreStructure.CLIENTS_PAYMENT_METHODS}`;
}
exports.paymentMethodsPath = paymentMethodsPath;
function occupantsPath(uid) {
    return `${FirestoreStructure.CLIENTS}/${uid}/${FirestoreStructure.CLIENTS_OCCUPANTS}`;
}
exports.occupantsPath = occupantsPath;
function legacyCredentialsPath(uid) {
    return `${FirestoreStructure.CLIENTS}/${uid}/${FirestoreStructure.CLIENTS_LEGACY_CREDENTIALS}`;
}
exports.legacyCredentialsPath = legacyCredentialsPath;
function merchantsPath() {
    return FirestoreStructure.MERCHANTS;
}
exports.merchantsPath = merchantsPath;
function merchantMembersPath(uid) {
    return `${FirestoreStructure.MERCHANTS}/${uid}/${FirestoreStructure.MERCHANTS_MERCHANT_MEMBERS}`;
}
exports.merchantMembersPath = merchantMembersPath;
function merchantTenantsPath(uid) {
    return `${FirestoreStructure.MERCHANTS}/${uid}/${FirestoreStructure.MERCHANTS_MERCHANT_TENANTS}`;
}
exports.merchantTenantsPath = merchantTenantsPath;
function merchantMemberMapPath(uid) {
    return `${FirestoreStructure.MERCHANTS_MERCHANT_MEMBER_MAP}/${uid}/${FirestoreStructure.MERCHANTS}`;
}
exports.merchantMemberMapPath = merchantMemberMapPath;
function bankingDetailsPath(uid) {
    return `${FirestoreStructure.MERCHANTS}/${uid}/${FirestoreStructure.MERCHANTS_BANK_DETAILS}`;
}
exports.bankingDetailsPath = bankingDetailsPath;
function merchantParkingsPath(uid) {
    return `${FirestoreStructure.MERCHANTS}/${uid}/${FirestoreStructure.MERCHANTS_PARKINGS}`;
}
exports.merchantParkingsPath = merchantParkingsPath;
function parkingsPath() {
    return FirestoreStructure.PARKINGS;
}
exports.parkingsPath = parkingsPath;
function parkingAmenitiesPath(parkingKey) {
    return FirestoreStructure.LISTS_PARKING_AMENITIES;
}
exports.parkingAmenitiesPath = parkingAmenitiesPath;
function merchantParkingImagesPath(uid, parkingKey) {
    return `${FirestoreStructure.MERCHANTS}/${uid}/${FirestoreStructure.MERCHANTS_PARKINGS}/${parkingKey}`;
}
exports.merchantParkingImagesPath = merchantParkingImagesPath;
function paymentsPath() {
    return FirestoreStructure.PAYMENTS;
}
exports.paymentsPath = paymentsPath;
function settlementsPath(paymentKey) {
    return `${FirestoreStructure.PAYMENTS}/${paymentKey}/${FirestoreStructure.SETTLEMENTS}`;
}
exports.settlementsPath = settlementsPath;
function subscriptionsPath() {
    return FirestoreStructure.SUBSCRIPTIONS;
}
exports.subscriptionsPath = subscriptionsPath;
function bookingsPath() {
    return FirestoreStructure.BOOKINGS;
}
exports.bookingsPath = bookingsPath;
function enquiriesPath() {
    return FirestoreStructure.ENQUIRIES;
}
exports.enquiriesPath = enquiriesPath;
function adminPath() {
    return FirestoreStructure.ADMINISTRATORS;
}
exports.adminPath = adminPath;
function merchantInvitesPath() {
    return FirestoreStructure.MERCHANT_MEMBER_INVITES;
}
exports.merchantInvitesPath = merchantInvitesPath;
function tenantInvitesPath() {
    return FirestoreStructure.MERCHANT_TENANT_INVITES;
}
exports.tenantInvitesPath = tenantInvitesPath;
function logEmailsPath() {
    return FirestoreStructure.LOG_EMAILS_PATH;
}
exports.logEmailsPath = logEmailsPath;
function logSearchesPath() {
    return FirestoreStructure.LOG_SEARCHES_PATH;
}
exports.logSearchesPath = logSearchesPath;
function blogsPath() {
    return FirestoreStructure.BLOGS_PATH;
}
exports.blogsPath = blogsPath;
function emailVerificationTokenPath() {
    return FirestoreStructure.EMAIL_VERIFICATION_TOKEN;
}
exports.emailVerificationTokenPath = emailVerificationTokenPath;
function migrationsPath() {
    return FirestoreStructure.MIGRATIONS;
}
exports.migrationsPath = migrationsPath;
function trackingEntriesPath() {
    return FirestoreStructure.TRACKING_ENTRIES;
}
exports.trackingEntriesPath = trackingEntriesPath;
