"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayGateStatusName = void 0;
var PayGateStatusName;
(function (PayGateStatusName) {
    PayGateStatusName["Error"] = "Error";
    PayGateStatusName["Pending"] = "Pending";
    PayGateStatusName["Cancelled"] = "Cancelled";
    PayGateStatusName["Completed"] = "Completed";
    PayGateStatusName["ValidationError"] = "ValidationError";
    PayGateStatusName["ThreeDSecureRedirectRequired"] = "ThreeDSecureRedirectRequired";
    PayGateStatusName["WebRedirectRequired"] = "WebRedirectRequired";
})(PayGateStatusName = exports.PayGateStatusName || (exports.PayGateStatusName = {}));
