"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingEntryType = void 0;
var TrackingEntryType;
(function (TrackingEntryType) {
    TrackingEntryType["VIEW"] = "VIEW";
    TrackingEntryType["IMPRESSION"] = "IMPRESSION";
    TrackingEntryType["CLICK"] = "CLICK";
    TrackingEntryType["CONVERSION"] = "CONVERSION";
})(TrackingEntryType = exports.TrackingEntryType || (exports.TrackingEntryType = {}));
