import { Directive, ElementRef, HostListener, OnInit } from '@angular/core'

@Directive({ selector: '[inputCurrency]' })
export class InputCurrencyDirective implements OnInit {
    private el: any

    constructor(private elementRef: ElementRef) {
        this.el = this.elementRef.nativeElement
    }

    static formatCurrency(x: any) {
        if (isNaN(parseFloat(x))) {
            return '0'
        }
        x = parseFloat(x).toFixed(0)
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }

    ngOnInit() {
        this.el.value = this.el.value.replace(/ /g, '')
        if (!isNaN(parseFloat(this.el.value))) {
            this.el.value = this.transform(this.el.value)
        } else {
            this.el.value = '0'
        }
    }

    @HostListener('focus', ['$event.target.value'])
    onFocus(value: any) {
        if (!isNaN(parseFloat(value))) {
            this.el.value = this.parse(value)
            this.el.select()
        }
    }

    @HostListener('blur', ['$event.target.value'])
    onBlur(value: any) {
        if (!isNaN(parseFloat(value))) {
            this.el.value = this.transform(value)
        } else {
            this.el.value = '0'
        }
    }

    transform(value: any): string {
        return InputCurrencyDirective.formatCurrency(Number(value))
    }

    parse(value: string): number | null {
        if (isNaN(parseFloat(value.replace(/ /g, '')))) {
            return null
        }
        return parseFloat(value.replace(/ /g, ''))
    }
}
