import { Component, OnInit } from '@angular/core'
import { ProvinceData } from './province-data'
import { AdminService } from '../../../services/admin.service'
import * as moment from 'moment'
import { LogSearch } from '@parkupp/core'

@Component({
    selector: 'app-admin-searches-report',
    templateUrl: './admin-searches-report.component.html',
    styleUrls: ['./admin-searches-report.component.scss'],
})
export class AdminSearchesReportComponent implements OnInit {
    single: any
    public view: any = [1000, 400]
    public showXAxis = true
    public showYAxis = true
    public gradient = false
    public showLegend = true
    public showXAxisLabel = true
    public xAxisLabel: 'Years'
    public showYAxisLabel = true
    public yAxisLabel: 'Salary'
    public graphDataChart: any[]
    SA_PROVINCES = ['Eastern Cape', 'Free State', 'Gauteng', 'KwaZulu-Natal', 'Limpopo', 'Mpumalanga', 'North West', 'Northern Cape', 'Western Cape']

    public colorScheme: any = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
    }

    constructor(private adminService: AdminService) {}

    ngOnInit(): void {
        this.adminService.getSearchLogs().then((logs) => {
            this.single = this.processData(logs)
            console.log(this.single)
            // Object.assign(this, { single })
        })
    }

    processData(logs: LogSearch[]): any {
        const momentDateFormat = 'M-YYYY'
        const provinceDataMap = new Map<string, ProvinceData>()

        logs.forEach((search: LogSearch) => {
            const province = search.province
            const monthYear = moment(search.createdAt.toDate().toDateString()).format(momentDateFormat)

            let provinceData = provinceDataMap.get(province)
            if (this.SA_PROVINCES.includes(province)) {
                if (!provinceData) {
                    provinceData = {
                        name: province,
                        series: [],
                    }
                    provinceDataMap.set(province, provinceData)
                }

                const dataPoint = provinceData.series.find((point) => point.name === monthYear)
                if (dataPoint) {
                    dataPoint.value++
                } else {
                    provinceData.series.push({
                        name: monthYear,
                        value: 1,
                    })
                }
            }
        })

        // return Array.from(provinceDataMap.values())
        const sortedData = Array.from(provinceDataMap.values()).map((provinceData) => {
            const sortedSeries = provinceData.series.sort((a, b) => moment(a.name, momentDateFormat).diff(moment(b.name, momentDateFormat)))
            return { ...provinceData, series: sortedSeries }
        })

        return sortedData
    }
}
