<admin-app-header></admin-app-header>
<div class="container-fluid" *ngIf="payout">
    <div class="row">
        <div class="col-6 d-flex flex-column justify-content-between">
            <h1 class="">Proforma: PFI-{{ payout.payoutNumber }}</h1>
            <p>
                Status:
                <ng-container *ngIf="payout.payoutDate">
                    <span class="badge bg-success text-dark">{{ payout.payoutDate.toDate() | date: 'dd MMM yyyy' }}</span>
                </ng-container>
                <ng-container *ngIf="!payout.payoutDate">
                    <span class="badge bg-danger text-light">Unpaid</span>
                </ng-container>
            </p>
        </div>
        <div class="col-6">
            <!-- <button class="btn btn-primary" (click)="completePayoutBatch()">Complete Payout Batch</button> -->
        </div>
    </div>
    <div class="content col-lg-12">
        <table class="table table-striped table-hover table-sm" id="invoices-payouts">
            <thead>
                <tr>
                    <th scope="col">Status</th>
                    <th scope="col">Frequency</th>
                    <!-- <th scope="col">Created<br />Date</th> -->
                    <th scope="col">Due<br />Date</th>
                    <th scope="col">Payment<br />Date</th>
                    <th scope="col">Total</th>
                    <th scope="col">Payout</th>
                    <th scope="col">Fee</th>
                    <th scope="col">Merchant</th>
                    <th scope="col">Parking</th>
                    <th scope="col">Client</th>
                    <th scope="col">Invoice</th>
                    <th scope="col">Bank Details</th>
                    <th scope="col">Bank (OLD)</th>
                    <th scope="col">Proforma</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let invoice of invoices">
                    <tr>
                        <td>
                            <ng-container *ngIf="invoice.paidOut">
                                <span class="badge bg-success text-dark">Paid</span>
                            </ng-container>
                            <ng-container *ngIf="!invoice.paidOut">
                                <span class="badge bg-danger text-light">Not Paid</span>
                            </ng-container>
                        </td>
                        <td>
                            <span
                                [routerLink]="['/merchant/', invoice.$merchant?.$key, 'profile', 'payout']"
                                class="badge"
                                [ngClass]="{
                                    ' bg-success': invoice.$merchant?.payoutDetails?.payoutFrequency === PayoutFrequencyEnum.IMMEDIATE,
                                    ' bg-warning': invoice.$merchant?.payoutDetails?.payoutFrequency === PayoutFrequencyEnum.MONTHLY
                                }"
                            >
                                {{ invoice.$merchant?.payoutDetails?.payoutFrequency }}
                            </span>
                        </td>
                        <!-- <td>
                            <a [routerLink]="['/merchant/', invoice.$merchant?.$key, 'invoice', invoice.$key]">
                                {{ invoice.createdAt | formatDate }}
                            </a>
                        </td> -->
                        <td>
                            {{ invoice.dueDate | formatDate }}
                        </td>
                        <td>
                            {{ invoice.paymentDate | formatDate }}
                        </td>
                        <td>
                            {{ invoice.amountTotalDue | formatRands }}
                        </td>
                        <td>
                            <!-- FIXME: Undo this exception -->
                            {{ invoice.periodFinalPayout | formatRands }}
                        </td>
                        <td>
                            <span *ngIf="invoice.$merchant?.commissionPercentage === 0" class="badge bg-danger">
                                {{ invoice.$merchant?.commissionPercentage }}%
                            </span>
                            <span *ngIf="invoice.$merchant?.commissionPercentage" class="badge bg-warning">
                                {{ invoice.$merchant?.commissionPercentage }}%
                            </span>
                            <span
                                *ngIf="!invoice.$merchant?.commissionPercentage && invoice.$merchant?.commissionPercentage !== 0"
                                class="badge bg-secondary"
                            >
                                15%
                            </span>
                        </td>

                        <td>
                            <a
                                [routerLink]="['/merchant/', invoice.merchantRef.id]"
                                [title]="invoice.$merchant?.companyName"
                                class="text-decoration-underline"
                            >
                                {{ invoice.merchantRef.id | slice: 0:5 }}
                            </a>
                        </td>
                        <td>
                            <a
                                [routerLink]="['/parking/', invoice.$parking?.$key]"
                                [title]="invoice.$parking?.title"
                                class="text-decoration-underline"
                            >
                                {{ invoice.denormParkingTitle | slice: 0:15 }}
                            </a>
                        </td>
                        <td>
                            <a [routerLink]="['/client/', invoice.clientRef.id, 'invoices']" class="text-decoration-underline">
                                {{ invoice.denormClientFirstName || '' }}
                                {{ invoice.denormClientLastName || '' }}
                            </a>
                        </td>
                        <td>
                            <a [routerLink]="['/merchant/', invoice.$merchant?.$key, 'invoice', invoice.$key]" class="text-decoration-underline">
                                INV-{{ invoice.invoiceNumber }}</a
                            >
                        </td>
                        <td>
                            <ng-container *ngIf="invoice.$merchant?.payoutDetails">
                                <ng-container *ngIf="invoice.$merchant?.payoutDetails?.beneficiaryName">
                                    <span class="badge bg-success text-dark">{{ invoice.$merchant?.payoutDetails?.beneficiaryName }}</span>
                                </ng-container>
                                <ng-container
                                    *ngIf="!invoice.$merchant?.payoutDetails?.beneficiaryName && invoice.$merchant?.payoutDetails?.accountNumber"
                                >
                                    <span class="badge bg-warning text-dark"
                                        >{{ invoice.$merchant?.payoutDetails?.bank }}: {{ invoice.$merchant?.payoutDetails?.accountNumber }}
                                    </span>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!invoice.$merchant?.payoutDetails">
                                <span class="badge bg-danger text-light" [routerLink]="['/merchant', invoice.$merchant?.$key, 'profile', 'payout']"
                                    >No Bank Details</span
                                >
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="invoice.$parking?.$bankDetails?.accountHolderName">
                                <a [routerLink]="['/merchant/', invoice.$merchant?.$key, 'profile', 'payout-details-old']" class="badge bg-info">
                                    OLD: {{ invoice.$parking?.$bankDetails?.accountNumber }}
                                </a>
                            </ng-container>
                        </td>
                        <td>
                            <a [routerLink]="['/admin/payout/', invoice.payoutRef?.id]" class="text-decoration-underline">
                                {{ invoice.payoutRef?.id }}
                            </a>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
