import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'phoneWithSpaces',
})
export class PhoneWithSpacesPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return ''
        }

        const cleaned = value.replace(/[^0-9]/g, '')
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

        if (match) {
            return `${match[1]} ${match[2]} ${match[3]}`
        }

        return value
    }
}
