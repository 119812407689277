import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppComponent } from './app.component'
import { RouterModule } from '@angular/router'
import { AppRoutingModule } from './app-routing.module'
import { initializeApp, provideFirebaseApp } from '@angular/fire/app'
import { connectFirestoreEmulator, getFirestore, provideFirestore } from '@angular/fire/firestore'
import { environment } from '../environments/environment'
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics'
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth'
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions'
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule } from '@angular/common/http'
import { ToastrModule } from 'ngx-toastr'
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics'

let resolvePersistenceEnabled: (enabled: boolean) => void

export const persistenceEnabled = new Promise<boolean>((resolve) => {
    resolvePersistenceEnabled = resolve
})

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsKey),
        NgxGoogleAnalyticsRouterModule,
        BrowserAnimationsModule,
        RouterModule,
        AppRoutingModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        // provideFirestore(() => getFirestore()),
        provideFirestore(() => {
            const firestore = getFirestore()
            if (environment.useEmulators) {
                connectFirestoreEmulator(firestore, 'localhost', 8080)
            }
            // enableMultiTabIndexedDbPersistence(firestore).then(
            //     () => resolvePersistenceEnabled(true),
            //     () => resolvePersistenceEnabled(false)
            // )
            return firestore
        }),
        provideAnalytics(() => getAnalytics()),
        provideAuth(() => {
            const auth = getAuth()
            if (environment.useEmulators) {
                connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true })
            }
            return auth
        }),
        provideFunctions(() => {
            const functions = getFunctions()
            if (environment.useEmulators) {
                connectFunctionsEmulator(functions, 'localhost', 5001)
            }
            return functions
        }),
        provideStorage(() => {
            const storage = getStorage()
            if (environment.useEmulators) {
                connectStorageEmulator(storage, 'localhost', 9199)
            }
            return storage
        }),
    ],
    providers: [ScreenTrackingService, UserTrackingService, { provide: 'googleTagManagerId', useValue: environment.googleTagManagerKey }],
    bootstrap: [AppComponent],
    exports: [],
})
export class AppModule {}
