"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractStatus = void 0;
var ContractStatus;
(function (ContractStatus) {
    ContractStatus["new"] = "New";
    ContractStatus["incomplete"] = "Incomplete";
    ContractStatus["paymentInitiated"] = "Payment Initiated";
    ContractStatus["paymentFailed"] = "Payment Failed";
    ContractStatus["clientCancelled"] = "Client Cancelled";
    ContractStatus["merchantRejected"] = "Merchant Rejected";
    ContractStatus["expired"] = "Expired";
    ContractStatus["active"] = "Active";
    ContractStatus["completed"] = "Completed";
    ContractStatus["bouncing"] = "Bouncing";
    ContractStatus["clientBreachedNonPayment"] = "Client Breached Non Payment";
    ContractStatus["merchantBreached"] = "Merchant Breached";
    ContractStatus["mutuallyTerminated"] = "Mutually Terminated";
    ContractStatus["renewing"] = "Renewing";
    ContractStatus["pendingClientApproval"] = "Pending Client Approval";
})(ContractStatus = exports.ContractStatus || (exports.ContractStatus = {}));
