<admin-app-header></admin-app-header>
<br />
<div class="container">
    <h2>Searches by Province Over Time</h2>
    <br />
    <ngx-charts-line-chart
        class="dark"
        [view]="view"
        [scheme]="colorScheme"
        [results]="single"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
    >
    </ngx-charts-line-chart>
</div>
