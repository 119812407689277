"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Administrator = void 0;
class Administrator {
    constructor() {
        this.active = true;
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        const administrator = JSON.parse(JSON.stringify(clone));
        return administrator;
    }
}
exports.Administrator = Administrator;
