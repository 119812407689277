import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import QrScanner from 'qr-scanner'
import { ToastrService } from 'ngx-toastr'
// https://github.com/nimiq/qr-scanner

@Component({
    selector: 'app-admin-scanqr-page',
    templateUrl: './admin-scanqr-page.component.html',
    styleUrls: ['./admin-scanqr-page.component.scss'],
})
export class AdminScanqrPageComponent implements OnInit, AfterViewInit {
    @ViewChild('qrCodeElem') qrCodeElem: ElementRef
    qrScanner: QrScanner
    qrResult: { type: String; key?: String }

    constructor(private toastrService: ToastrService) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.qrScanner = new QrScanner(
            this.qrCodeElem.nativeElement,
            (scannedString) => {
                this.onDecode(scannedString)
            },
            {
                maxScansPerSecond: 2,
                highlightScanRegion: true,
            }
        )
        this.qrScanner.start()
    }

    qrTempPause() {
        this.qrScanner.pause()
        setTimeout(() => {
            this.qrScanner.start()
        }, 2000)
    }

    onDecode(scannedString: any) {
        this.qrTempPause()

        try {
            this.qrResult = JSON.parse(scannedString.data)

            if (this.qrResult.type == 'subscription') {
                window.open('/admin/subscription/' + this.qrResult.key, '_blank')
            } else {
                this.qrFailedResonse()
            }
        } catch (e) {
            this.qrFailedResonse()
        }
    }

    qrFailedResonse() {
        this.toastrService.error('Invalid QR Code')
    }
}
