<div *ngIf="!mobile" class="ticker-container">
    <div #tickerContent class="ticker-content">
        <ng-container *ngFor="let parking of duplicatedItems">
            <div [ngStyle]="{ width: this.width }" [routerLink]="['/parking', parking.$key]" class="ticker-item">
                <div class="row">
                    <div class="col-4 image-container">
                        <ng-container>
                            <img [src]="parking.defaultImageSmall" alt="{{ parking.title }}" class="post-img" />
                        </ng-container>
                    </div>
                    <div class="col-8 card-body">
                        <h5 class="card-title">
                            {{ parking.title || 'Unnamed Parking ' }}
                        </h5>
                        <h6 *ngIf="parking.parkingLocationName" class="card-subtitle mb-2 text-info">{{ parking.parkingLocationName }}</h6>
                        <h6 *ngIf="parking.parkingLocation && !parking.parkingLocationName" class="card-subtitle mb-2 text-info">{{ parking.parkingLocation.city }}</h6>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
