import { AfterViewInit, Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-logo-slider',
    templateUrl: './logo-slider.component.html',
    styleUrls: ['./logo-slider.component.scss'],
})
export class LogoSliderComponent implements OnInit, AfterViewInit {
    customerLogos = [
        { alt: 'Horizon Logo', src: 'assets/images/customer-logos/horizon.webp' },
        { alt: 'Hyprop Logo', src: 'assets/images/customer-logos/hyprop.webp' },
        { alt: 'Investec Logo', src: 'assets/images/customer-logos/investec.webp' },
        { alt: 'Rebosis Logo', src: 'assets/images/customer-logos/rebosis.webp' },
        { alt: 'Redefine Logo', src: 'assets/images/customer-logos/redefine.webp' },
        { alt: 'Consolidated Logo', src: 'assets/images/customer-logos/consolidated.webp' },
        { alt: 'Growthpoint Logo', src: 'assets/images/customer-logos/growthpoint.webp' },
        { alt: 'Mowana Logo', src: 'assets/images/customer-logos/mowana.webp' },
        { alt: 'Urban Logo', src: 'assets/images/customer-logos/urban.webp' },

        { alt: 'Campus Logo', src: 'assets/images/customer-logos/campus.webp' },
        { alt: 'ABT Logo', src: 'assets/images/customer-logos/abt.webp' },
        { alt: 'Admyt Logo', src: 'assets/images/customer-logos/admyt.webp' },
        { alt: 'Advance Logo', src: 'assets/images/customer-logos/advance.webp' },
        { alt: 'Aria Logo', src: 'assets/images/customer-logos/aria.webp' },
        { alt: 'Eris Logo', src: 'assets/images/customer-logos/eris.webp' },
        { alt: 'Excellerate Logo', src: 'assets/images/customer-logos/excellerate.webp' },
        { alt: 'Investicore Logo', src: 'assets/images/customer-logos/investicore.webp' },
        { alt: 'Parket Logo', src: 'assets/images/customer-logos/parket.webp' },
        { alt: 'Regus Logo', src: 'assets/images/customer-logos/regus.webp' },
        { alt: 'Signatura Logo', src: 'assets/images/customer-logos/signatura.webp' },
        { alt: 'SKG Logo', src: 'assets/images/customer-logos/skg.webp' },
        { alt: 'Street Logo', src: 'assets/images/customer-logos/street.webp' },
        { alt: 'Urban Logo', src: 'assets/images/customer-logos/urban.webp' },
    ].sort(() => Math.random() - 0.5)

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        const swiperEl = document.querySelector('.swiper-container-logo-slider') as any
        if (swiperEl) {
            const swiperParams = {
                slidesPerView: 2,

                breakpoints: {
                    768: {
                        slidesPerView: 4,
                    },
                    1024: {
                        slidesPerView: 6,
                    },
                    1500: {
                        slidesPerView: 8,
                    },
                    2000: {
                        slidesPerView: 10,
                    },
                },
                mousewheel: {
                    forceToAxis: true,
                },
                // navigation: true,
                // pagination: true,
                autoplay: {
                    delay: 1000,
                    disableOnInteraction: false,
                },
                speed: 1000,
                // injectStylesUrls: ['assets/swiper.css'],
            }

            Object.assign(swiperEl, swiperParams)

            // and now initialize it
            swiperEl.initialize()
        }
    }
}
