import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { HttpClient } from '@angular/common/http'
import { AuthenticationService } from '../../../services/authentication.service'
import { FormBuilder, FormGroup } from '@angular/forms'

@Component({
    selector: 'app-admin-functions',
    templateUrl: './admin-functions.component.html',
    styleUrls: ['./admin-functions.component.scss'],
})
export class AdminFunctionsComponent implements OnInit {
    formGroup: FormGroup

    constructor(private formBuilder: FormBuilder, private authService: AuthenticationService, private adminService: AdminService, private http: HttpClient) {
        this.formGroup = this.formBuilder.group({
            subscriptionKey: [''],
        })
    }

    ngOnInit(): void {}

    // updateStartDateFormat() {
    //     if (confirm('Are you Sure')) {
    //         const rezf = query(collection(this.adminService.firestore, subscriptionsPath()), orderBy('createdAt', 'desc'), orderBy('nextDebitDate'))
    //         collectionData(rezf, { idField: 'documentId' }).subscribe((documents: any[]) => {
    //             documents.forEach((documentData) => {
    //                 const subscription = new Subscription(documentData)
    //
    //                 // @ts-ignore
    //                 if (subscription.startDate['_seconds']) {
    //                     console.log(subscription.startDate)
    //                     // @ts-ignore
    //                     const fixedStartDate = new Date(subscription.startDate['_seconds'] * 1000)
    //                     updateDoc(doc(this.adminService.firestore, subscriptionsPath(), documentData.documentId), {
    //                         startDate: fixedStartDate,
    //                     })
    //                 }
    //                 if (subscription.startDate['seconds']) {
    //                     // @ts-ignore
    //                     const fixedStartDate = new Date(subscription.startDate['seconds'] * 1000)
    //                     updateDoc(doc(this.adminService.firestore, subscriptionsPath(), documentData.documentId), {
    //                         startDate: fixedStartDate,
    //                     })
    //                 }
    //             })
    //         })
    //     }
    // }

    // updateSubscriptionEndDate() {
    //     if (confirm('Are you Sure')) {
    //         const ref = query(collection(this.adminService.firestore, subscriptionsPath()), orderBy('createdAt', 'desc'), orderBy('nextDebitDate'))
    //         collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
    //             documents.forEach((documentData) => {
    //                 const subscription = new Subscription(documentData)
    //                 const endDate = subscription.startDate.toDate()
    //                 endDate.setMonth(endDate.getMonth() + documentData.period)
    //                 endDate.setDate(endDate.getDate() - 1)
    //                 updateDoc(doc(this.adminService.firestore, subscriptionsPath(), documentData.documentId), {
    //                     endDate: endDate,
    //                 })
    //             })
    //         })
    //     }
    // }
    //
    // addPaidOutToPayments() {
    //     if (confirm('Are you Sure')) {
    //         const ref = query(collection(this.adminService.firestore, paymentsPath()), where('settled', '==', true))
    //
    //         collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
    //             documents.forEach((documentData) => {
    //                 const payment = new Payment(documentData)
    //                 payment.$key = documentData.documentId
    //
    //                 if (documentData.paidOut == undefined) {
    //                     updateDoc(doc(this.adminService.firestore, paymentsPath(), documentData.documentId), {
    //                         paidOut: false,
    //                     })
    //                 }
    //             })
    //         })
    //     }
    // }
}
