<admin-app-header></admin-app-header>
<div class="container-fluid">
    <!-- <button (click)="processSelectedPayments()" class="btn btn-primary btn-sm float-end m-3" type="button">
        Mark selected payments as processed
    </button> -->

    <button (click)="globalService.exportcsv('all-payments')" class="btn btn-info btn-sm float-end px-3 py-1 my-2"><i class="fas fa-file-csv"></i> Export to CSV</button>

    <h1 class="float-start">Daily Payouts (before invoices)</h1>
    <div aria-label="Basic radio toggle button group" class="btn-group btn-group-sm m-2" role="group">
        <input (change)="togglePaid($event)" [checked]="this.showType == 'unpaid'" autocomplete="off" class="btn-check" id="btnradio1" name="status" type="radio" value="unpaid" />
        <label class="btn btn-outline-primary" for="btnradio1">Unpaid</label>

        <input (change)="togglePaid($event)" [checked]="this.showType == 'paid'" autocomplete="off" class="btn-check" id="btnradio2" name="status" type="radio" value="paid" />
        <label class="btn btn-outline-primary" for="btnradio2">Paid</label>
    </div>

    <div class="content col-lg-12">
        <table class="table table-striped table-hover table-sm" id="all-payments">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Status</th>
                    <th scope="col">Date</th>
                    <th scope="col">Total</th>
                    <th scope="col">Payout</th>
                    <th scope="col">Exception</th>
                    <th scope="col">Tenant</th>
                    <th scope="col">Merchant</th>
                    <th scope="col">Parking</th>
                    <th scope="col">Invoice</th>
                    <th scope="col">Bank Details</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let payment of payments">
                    <td>
                        <ng-container *ngIf="!payment.paidOut">
                            <div class="form-check">
                                <input (change)="changeStatus($event)" (mouseenter)="onHover($event)" class="form-check-input" type="checkbox" value="{{ payment.$key }}" />
                            </div>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngIf="payment.paidOut">
                            <span class="badge bg-success text-dark">Paid</span>
                        </ng-container>
                        <ng-container *ngIf="payment.paidOut === false">
                            <span class="badge bg-danger text-light">Not Paid</span>
                        </ng-container>
                    </td>
                    <td>
                        <a [routerLink]="['/merchant/', payment.$merchant?.$key, 'payment', payment.$key]">
                            {{ payment.createdAt | formatDate }}
                        </a>
                    </td>
                    <td>R{{ payment.amountInCents | formatCents }}</td>
                    <td>
                        <!-- FIXME: Undo this exception -->
                        R{{ payment.$subscription?.periodFinalPayout | formatCents }}
                    </td>
                    <td>
                        <ng-container *ngIf="payment.$subscription?.commissionException">
                            <span class="badge bg-info text-light">Exception</span>
                        </ng-container>
                    </td>
                    <td>
                        <a [routerLink]="['/client/', payment.$client?.$key]">
                            {{ payment.$client?.firstName || '' }}
                            {{ payment.$client?.lastName || '' }}
                        </a>
                    </td>
                    <td>
                        <a [routerLink]="['/merchant/', payment.$merchant?.$key]" [title]="payment.$merchant?.companyName">
                            {{ payment.$merchant?.companyName | slice: 0:15 }}
                        </a>
                    </td>
                    <td>
                        <a [routerLink]="['/parking/', payment.$parking?.$key]" [title]="payment.$parking?.title">
                            {{ payment.$parking?.title | slice: 0:15 }}
                        </a>
                    </td>
                    <td>
                        <a [routerLink]="['/merchant/', payment.$merchant?.$key, 'payment', payment.$key]" class="link-primary text-decoration-underline"> View Invoice </a>
                    </td>
                    <td>
                        <ng-container *ngIf="!payment.$parking?.$bankDetails?.accountHolderName">
                            <a [routerLink]="['/merchant/', payment.$merchant?.$key, 'parking', payment.$parking?.$key]" class="link-primary text-decoration-underline"> Add account details </a>
                        </ng-container>
                        <a [title]="payment.$parking?.$bankDetails?.accountHolderName">
                            {{ payment.$parking?.$bankDetails?.accountHolderName | truncate: [35, '...'] }}
                        </a>
                        <ng-container *ngIf="payment.$parking?.$bankDetails?.bank"> ({{ payment.$parking?.$bankDetails?.bank }}) </ng-container>
                        -
                        {{ payment.$parking?.$bankDetails?.accountNumber }}
                    </td>
                    <td>
                        <a (click)="markAsRefunded(payment)" class="badge bg-danger">Mark as refunded</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
