export const environment = {
    useEmulators: false,
    production: false,
    firebase: {
        projectId: 'parkupp-test',
        appId: '1:333093056409:web:ae12ffd8dbfa91e4caad08',
        storageBucket: 'parkupp-test.appspot.com',
        apiKey: 'AIzaSyA5jkzjLDU-H0xVV9JQ46qtZ4kZqr3d3dQ',
        authDomain: 'parkupp-test.firebaseapp.com',
        messagingSenderId: '333093056409',
        locationId: 'europe-west',
    },
    host: 'https://staging.parkupp.co.za',
    functionsUrl: 'https://us-central1-parkupp-test.cloudfunctions.net',
    googleMapsApiKey: 'AIzaSyDqxESx_0G_ecZiHbVf81C65alhv9qb2ig',
    googleAnalyticsKey: 'UA-226129700-1',
    googleTagManagerKey: 'GTM-5PFLFJM',
    omnisendAccountId: '631b85c048c164e108e0c276',
    payfastUrl: 'https://sandbox.payfast.co.za/eng/process',
}
