"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationSuggestion = void 0;
class LocationSuggestion {
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        const locationSuggestion = JSON.parse(JSON.stringify(clone));
        locationSuggestion.createdAt = this.createdAt;
        locationSuggestion.updatedAt = this.updatedAt;
        return;
    }
}
exports.LocationSuggestion = LocationSuggestion;
