import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { environment } from '../../../../../environments/environment'

@Component({
    selector: 'app-payfast-payment-form',
    templateUrl: './payfast-payment-form.component.html',
    styleUrls: ['./payfast-payment-form.component.scss'],
})
export class PayfastPaymentFormComponent implements OnInit, AfterViewInit {
    @Input() paymentData: any
    payfast_url = environment.payfastUrl
    formDataArray: { key: string; value: any }[] = []

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        if (this.paymentData) {
            this.formDataArray = Object.entries(this.paymentData).map(([key, value]) => ({
                key,
                value,
            }))
            this.cdr.detectChanges() // Manually trigger change detection
        }
    }
}
