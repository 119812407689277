<div class="row" id="actions-container">
    <div class="row">
        <h2>Click on the buttons below to print or pay for the invoice</h2>
    </div>
    <div class="col-12 col-md-6">
        <button (click)="downloadPdf()" class="pdf-button btn btn-md btn-primary text-white" type="button">
            <i class="fa-solid fa-file-pdf"></i>
            Generate PDF
        </button>
        &nbsp;
        <button (click)="printPage()" class="print-button btn btn-md btn-secondary text-white" type="button">
            <i class="fa-solid fa-print"></i>
            Print Invoice
        </button>
    </div>
</div>
<div id="outer-container" *ngIf="payout">
    <div id="print-container">
        <div class="row">
            <div class="col-6">
                <h1 class="mb-3">Proforma Invoice</h1>
                <div>Proforma: PFI-{{ payout.payoutNumber }}</div>
                <div>
                    Status:
                    <span *ngIf="payout.status === PayoutStatusEnum.PAID" class="badge text-dark bg-success">Paid Out</span>
                    <span *ngIf="payout.status === PayoutStatusEnum.DUE" class="badge text-dark bg-warning">Unpaid</span>
                </div>
                <div>Company: {{ payout.$merchant?.companyName || '' }}</div>
                <div>Created Date: {{ payout.createdAt.toDate() | date: 'dd MMM yyyy' }}</div>
                <div *ngIf="payout.payoutDate">Payout Date: {{ payout.payoutDate.toDate() | date: 'dd MMM yyyy' }}</div>

                <div *ngIf="payout.$merchant?.companyRegistration">Company Registration: {{ payout.$merchant?.companyRegistration }}</div>
                <div *ngIf="payout.$merchant?.vatNumber">VAT Number: {{ payout.$merchant?.vatNumber }}</div>
                <div *ngIf="payout.$merchant?.phone">Phone: {{ payout.$merchant?.phone?.internationalNumber }}</div>

                <!-- <h4 class="mt-3">Parker Details</h4> -->
                <!-- <div>Name: {{ payout.denormClientFirstName }} {{ payout.denormClientLastName }}</div> -->
            </div>

            <div class="col-6 text-end">
                <img class="logo-image mb-3" src="/assets/images/home/logo-blue-250.png" />
                <div class="company-info">Registered Name: The Upps (Pty) Ltd</div>
                <div class="company-info">Registration Number: 2022/43971/07</div>
                <div class="company-info">VAT number: 4620313298</div>
                <div class="company-info">32 Kloof St, Gardens, Cape Town, 8001, South Africa</div>
            </div>
        </div>

        <br />
        <div class="row">
            <div class="content col-lg-12">
                <table class="table" id="invoices-payouts">
                    <thead>
                        <tr>
                            <th scope="col">Invoice</th>
                            <!-- <th scope="col">Status</th> -->
                            <th scope="col">Due Date</th>
                            <!-- <th scope="col">Fee</th> -->
                            <th scope="col">Client</th>
                            <th scope="col">Parking</th>
                            <th scope="col">Total</th>
                            <th scope="col">Payout</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let invoice of payout.$invoices">
                            <tr>
                                <td>
                                    <a [routerLink]="['/merchant/', invoice.$merchant?.$key, 'invoice', invoice.$key]" class="text-decoration-underline"> INV-{{ invoice.invoiceNumber }}</a>
                                </td>
                                <!-- <td>
                                    <ng-container *ngIf="invoice.paidOut">
                                        <span class="badge bg-success text-dark">Paid</span>
                                    </ng-container>
                                    <ng-container *ngIf="!invoice.paidOut">
                                        <span class="badge bg-danger text-light">Not Paid</span>
                                    </ng-container>
                                </td> -->
                                <td>
                                    {{ invoice.dueDate.toDate() | date: 'dd MMM yyyy' }}
                                </td>

                                <!-- <td>
                                    <span *ngIf="invoice.$merchant?.commissionPercentage === 0" class="badge bg-danger"> {{ invoice.$merchant?.commissionPercentage }}% </span>
                                    <span *ngIf="invoice.$merchant?.commissionPercentage" class="badge bg-warning"> {{ invoice.$merchant?.commissionPercentage }}% </span>
                                    <span *ngIf="!invoice.$merchant?.commissionPercentage && invoice.$merchant?.commissionPercentage !== 0" class="badge bg-secondary"> 15% </span>
                                </td> -->
                                <td>
                                    {{ invoice.denormClientFirstName || '' }}
                                    {{ invoice.denormClientLastName || '' }}
                                </td>
                                <td>
                                    {{ invoice.denormParkingTitle | slice: 0:15 }}
                                </td>

                                <td>
                                    {{ invoice.amountTotalDue | currency: 'R' }}
                                </td>
                                <td>
                                    <!-- FIXME: Undo this exception -->
                                    {{ invoice.periodFinalPayout | currency: 'R' }}
                                </td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td colspan="4" class="text-end"><strong>Total Incl. VAT:</strong></td>
                            <td>
                                <strong>{{ payout.totalAmount | currency: 'R' }}</strong>
                            </td>
                            <td>
                                <strong>{{ payout.payoutAmount | currency: 'R' }}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
