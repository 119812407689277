import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { HttpClient } from '@angular/common/http'
import { collection, collectionData, doc, docData, DocumentData, limit, orderBy, Query, query, updateDoc, where } from '@angular/fire/firestore'
import { BankDetails, Client, Merchant, Parking, Payment, paymentsPath, Subscription } from '@parkupp/core'
import * as moment from 'moment'
import { GlobalService } from 'src/app/services/global.service'

@Component({
    selector: 'app-admin-list-payouts-page',
    templateUrl: './admin-list-payouts-page.component.html',
    styleUrls: ['./admin-list-payouts-page.component.scss'],
})
export class AdminListPayoutsPageComponent implements OnInit {
    payments: Payment[]
    selectedPayments: [] = []
    mouseDown = false
    showType: string = 'paid'
    private refUnpaid: Query<DocumentData>
    private refPaid: Query<DocumentData>

    constructor(private adminService: AdminService, private http: HttpClient, public globalService: GlobalService) {
        // This is the date that we switch over to invoices
        const cutOffDate = moment('2024-06-12').toDate()
        const startDate = moment('2024-01-01').toDate()

        this.refUnpaid = query(
            collection(this.adminService.firestore, paymentsPath()),
            orderBy('createdAt', 'desc'),
            where('paidOut', '==', false),
            where('settled', '==', true),
            where('createdAt', '<', cutOffDate)
        )
        this.refPaid = query(
            collection(this.adminService.firestore, paymentsPath()),
            orderBy('createdAt', 'desc'),
            where('paidOut', '==', true),
            where('settled', '==', true),
            where('createdAt', '<', cutOffDate),
            where('createdAt', '>', startDate)
        )

        this.getPayments(this.refPaid)

        document.body.addEventListener('mousedown', () => {
            this.mouseDown = !this.mouseDown
            document.body.addEventListener('mouseup', () => {})
            this.mouseDown = !this.mouseDown
        })
    }

    getPayments(ref: Query<DocumentData>) {
        collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
            let payments: Payment[] = []
            documents.forEach((documentData) => {
                let payment = new Payment(documentData)
                payment.$key = documentData.documentId

                if (documentData.clientRef) {
                    docData(documentData.clientRef).subscribe((data) => {
                        payment.$client = new Client(data)
                        payment.$client.$key = documentData.clientRef.id
                    })
                }
                if (documentData.subscriptionRef) {
                    docData(documentData.subscriptionRef).subscribe((data) => {
                        payment.$subscription = new Subscription(data)
                        payment.$subscription.$key = documentData.subscriptionRef.id

                        if (data['merchantRef']) {
                            docData(data['merchantRef']).subscribe((dataInner: any) => {
                                payment.$subscription!.$merchant = new Merchant(dataInner)
                            })
                        }
                    })
                }
                if (documentData.parkingRef) {
                    docData(documentData.parkingRef).subscribe((parkingDocData) => {
                        payment.$parking = new Parking(parkingDocData)
                        payment.$parking.$key = documentData.parkingRef.id

                        if (payment.$parking && parkingDocData['bankDetailsRef']) {
                            docData(parkingDocData['bankDetailsRef']).subscribe((bankDetailsDocData) => {
                                payment.$parking!.$bankDetails = new BankDetails(bankDetailsDocData)
                                payment.$parking!.$bankDetails.$key = parkingDocData['bankDetailsRef']
                            })
                        }
                    })
                }
                if (documentData.merchantRef) {
                    docData(documentData.merchantRef).subscribe((data) => {
                        payment.$merchant = new Merchant(data)
                        payment.$merchant.$key = documentData.merchantRef.id
                    })
                }

                if (!payment.refunded) {
                    payments.push(payment)
                }
            })
            this.payments = payments
        })
    }

    togglePaid(e: any) {
        console.log(e.target.value)
        if (e?.target?.value === 'paid') {
            console.log('here')
            this.getPayments(this.refPaid)
        } else {
            this.getPayments(this.refUnpaid)
        }
    }

    ngOnInit(): void {}

    processSelectedPayments() {
        this.selectedPayments.forEach((paymentKey) => {
            const payment = this.payments.find((payment) => paymentKey === payment.$key)

            if (payment) {
                updateDoc(doc(this.adminService.firestore, paymentsPath(), payment.$key!), { paidOut: true }).then(() => {
                    console.log(payment.$key + ' Updated')
                })
            }
        })
    }

    changeStatus(event: any) {
        const paymentKey = event.target.defaultValue
        const isChecked = (<HTMLInputElement>event.target).checked
        if (isChecked) {
            this.addItem(this.selectedPayments, paymentKey)
        } else {
            this.removeItem(this.selectedPayments, paymentKey)
        }
    }

    addItem(arr: any, item: string) {
        this.removeItem(arr, item)
        arr.push(item)
    }

    removeItem<T>(arr: Array<T>, value: T): Array<T> {
        const index = arr.indexOf(value)
        if (index > -1) {
            arr.splice(index, 1)
        }
        return arr
    }

    onHover(event: any) {
        const paymentKey = event.target.defaultValue
        const isChecked = (<HTMLInputElement>event.target).checked
        if (!isChecked && this.mouseDown) {
            this.addItem(this.selectedPayments, paymentKey)
            event.target.checked = true
        }
    }

    markAsRefunded(payment: Payment) {
        if (window.confirm('Are you sure you want to mark this payment as refunded?')) {
            updateDoc(doc(this.adminService.firestore, paymentsPath(), payment.$key!), {
                refunded: true,
                comments: 'Marked as refunded',
            }).then(() => {
                console.log(payment.$key + ' Updated')
            })
        }
        return false
    }
}
