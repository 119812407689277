import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Invoice, Merchant, Payout, PayoutFrequencyEnum, PayoutStatusEnum } from '@parkupp/core'
import { InvoiceService } from 'src/app/services/invoice.service'
import { MerchantService } from 'src/app/services/merchant.service'
import { PayoutService } from 'src/app/services/payout.service'

@Component({
    selector: 'app-merchant-proforma-invoice-detail',
    templateUrl: './merchant-proforma-invoice-detail.component.html',
    styleUrls: ['./merchant-proforma-invoice-detail.component.scss'],
})
export class MerchantProformaInvoiceDetailComponent implements OnInit {
    PayoutFrequencyEnum = PayoutFrequencyEnum
    PayoutStatusEnum = PayoutStatusEnum
    payoutKey: string
    merchantKey: string
    payout: Payout
    invoices: Invoice[]
    html2PdfAdded: any
    invoice: any
    changeDetectorRef: any
    constructor(private payoutService: PayoutService, private route: ActivatedRoute, private merchantService: MerchantService, private invoiceService: InvoiceService) {}

    async ngOnInit(): Promise<void> {
        this.payoutKey = this.route.snapshot.params['payoutKey']
        this.merchantKey = this.route.snapshot.params['merchantKey']

        this.payout = await this.payoutService.getPayout(this.payoutKey)
        this.payout.$merchant = await this.merchantService.getMerchant(this.merchantKey)
        const payoutRef = this.payoutService.getDocRef(this.payoutKey) as any
        this.payout.$invoices = await this.invoiceService.getInvoicesByPayout(payoutRef)
        this.payout.$invoices.forEach(async (invoice) => {
            if (invoice.merchantRef) {
                invoice.$merchant = await this.merchantService.getMerchant(invoice.merchantRef.id)
            }
        })
        console.log(this.payout.$invoices)
    }

    downloadPdf() {
        if (!this.html2PdfAdded) {
            this.html2PdfAdded = true
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = '//cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js'
            script.onload = () => {
                this.generatePDF()
            }
            document.body.appendChild(script)
        } else {
            this.generatePDF()
        }
    }

    generatePDF() {
        // @ts-ignore
        if (typeof html2pdf !== 'function') {
            setTimeout(() => {
                this.generatePDF()
            }, 100)
            return
        }

        const element = document.getElementById('print-container')
        // @ts-ignore
        html2pdf()
            .set({
                margin: 10,
                filename: `PFI-${this.payout.payoutNumber}.pdf`,
                html2canvas: {
                    scale: 1,
                    useCORS: true,
                },
            })
            .from(element)
            .save()
            .then(() => {
                this.changeDetectorRef.detectChanges()
            })
    }

    printPage() {
        window.print()
    }
}
