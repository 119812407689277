<admin-app-header></admin-app-header>

<div *ngIf="invoices; else loading">
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col-md-2">
                <h2 class="float-start d-inline-block">VAT 201 Invoices</h2>
            </div>
            <!-- <div class="col-md-8">
                <form [formGroup]="form" (ngSubmit)="filterInvoices()" class="d-flex align-items-end col-md-8">
                    <div class="col-md-3">
                        <label for="fromDate" class="form-label mb-0">From Date</label>
                        <input type="date" id="fromDate" class="form-control" formControlName="fromDate" />
                    </div>
                    <div class="col-md-3">
                        <label for="toDate" class="form-label mb-0">To Date</label>
                        <input type="date" id="toDate" class="form-control" formControlName="toDate" />
                    </div>
                    <div class="col-md-2 d-flex align-items-end">
                        <button (click)="filterInvoices()" class="btn btn-primary">Filter</button>
                    </div>
                </form>
            </div> -->
            <div class="col-md-2 ms-auto">
                <button (click)="globalService.exportcsv('all-invoices')" class="btn btn-info btn-sm text-white float-end px-3 py-1 my-2"><i class="fas fa-file-csv"></i> Export to CSV</button>
            </div>
        </div>

        <div class="content col-lg-12">
            <table class="table table-striped table-hover table-sm" id="all-invoices">
                <thead>
                    <tr>
                        <th scope="col">Created Date</th>
                        <th scope="col">Payment Method</th>
                        <th scope="col">Due Date</th>
                        <th scope="col">Invoice Status</th>
                        <th scope="col">Payout Status</th>
                        <th scope="col">Invoice</th>
                        <th scope="col">Portfolio</th>
                        <th scope="col">Parking</th>
                        <th scope="col">Client</th>
                        <th scope="col">Bays</th>
                        <th scope="col">Total</th>
                        <!--                    <th scope="col">Attempts</th>-->
                        <th scope="col">Reference</th>
                        <th scope="col">Subscription</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let invoice of invoices">
                        <tr>
                            <td>
                                <a [routerLink]="['/client', invoice.clientRef.id, AngularRoutes.INVOICE, invoice.$key]">
                                    {{ invoice.createdAt | formatDate }}
                                </a>
                            </td>
                            <td>
                                <span *ngIf="invoice.$subscription?.paymentMethodRef" class="badge bg-blue"> PayGate </span>
                                <span *ngIf="!invoice.$subscription?.paymentMethodRef" class="badge bg-secondary"> Manual </span>
                            </td>
                            <td>
                                {{ invoice.dueDate | formatDate }}
                            </td>

                            <td>
                                <span [ngClass]="invoice.getStatusClass()" class="badge"> {{ invoice.status }} </span>&nbsp;

                                <span *ngIf="invoice.paygateAttempts" class="badge bg-danger"> Fail: {{ invoice.paygateAttempts }} </span>
                            </td>
                            <td>
                                <ng-container *ngIf="!invoice.payoutRef">
                                    <span *ngIf="!invoice.paidOut" class="badge bg-warning"> Not Paid Out </span>
                                </ng-container>

                                <ng-container *ngIf="invoice.payoutRef">
                                    <span [routerLink]="['/admin', 'payout', invoice.payoutRef.id]" class="badge bg-success">
                                        {{ invoice.payoutRef.id | slice: 0:5 }}
                                    </span>
                                </ng-container>
                            </td>
                            <td>
                                <a [routerLink]="['/', AngularRoutes.client, invoice.clientRef.id, AngularRoutes.INVOICE, invoice.$key]" class="text-decoration-underline">
                                    INV-{{ invoice.invoiceNumber }}
                                </a>
                            </td>

                            <td>
                                <a [routerLink]="['/', AngularRoutes.MERCHANT, invoice.merchantRef.id]" class="text-decoration-underline">
                                    {{ invoice.denormMerchantCompanyName }}
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/', AngularRoutes.parking, invoice.parkingRef.id]" class="text-decoration-underline">
                                    {{ invoice.denormParkingTitle | truncate: [50] }}
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/', AngularRoutes.client, invoice.clientRef.id, 'invoices']" class="text-decoration-underline">
                                    {{ invoice.denormClientFirstName }} {{ invoice.denormClientLastName }}
                                </a>
                            </td>
                            <td>
                                {{ invoice.baysBooked }}
                            </td>
                            <td>
                                {{ invoice.amountTotalDue | formatRands }}
                            </td>

                            <td>
                                {{ invoice.reference }}
                            </td>
                            <td>
                                <a [routerLink]="['/admin', 'subscription', invoice.subscriptionRef?.id]" class="text-decoration-underline">
                                    {{ invoice.subscriptionRef?.id | slice: 0:5 }}
                                </a>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
<ng-template #loading>
    <p>Loading invoices...</p>
</ng-template>
