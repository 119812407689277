import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { InvoiceStatus, Merchant, Parking, parkingsPath, ParkingStatus, SubscriptionType, EnumNames } from '@parkupp/core'
import { collection, collectionData, doc, orderBy, query, Timestamp, updateDoc } from '@angular/fire/firestore'
import { GlobalService } from 'src/app/services/global.service'
import { environment } from 'src/environments/environment'
import { Router } from '@angular/router'
import { ParkingService } from 'src/app/services/parking.service'
import { MerchantService } from 'src/app/services/merchant.service'

@Component({
    selector: 'app-admin-list-parkings-page',
    templateUrl: './admin-list-parkings-page.component.html',
    styleUrls: ['./admin-list-parkings-page.component.scss'],
})
export class AdminListParkingsPageComponent implements OnInit {
    EnumNames = EnumNames
    parkings: Parking[]
    parkingStatus = ParkingStatus
    selectedParking: Parking
    parkingStatuses = Object.values(ParkingStatus)
    merchants: Merchant[]
    filterByStatus: ParkingStatus = ParkingStatus.PUBLISHED

    constructor(private adminSvc: AdminService, private globalService: GlobalService, private router: Router, private parkingService: ParkingService, private merchantService: MerchantService) {}

    async ngOnInit() {
        this.merchants = await this.merchantService.listPromise()

        await this.fetchParkings()

        this.parkings.forEach((parking) => {
            if (parking.merchantRef) {
                parking.$merchant = this.merchants.find((merchant) => merchant.$key === parking.merchantRef.id)
            }
        })

        this.parkings.sort((a, b) => b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime())
    }

    updateStatusFilter(event: Event) {
        this.filterByStatus = (event.target as HTMLSelectElement).value as ParkingStatus
        this.fetchParkings()
    }

    async fetchParkings() {
        this.parkings = await this.parkingService.listPromise({ status: this.filterByStatus })
    }

    approveParking(key: any) {
        return new Promise<any>((resolve) => {
            const reference = doc(this.adminSvc.firestore, parkingsPath(), key)
            updateDoc(reference, {
                parkingStatus: ParkingStatus.PUBLISHED,
            })
        })
    }

    async showContextMenu(event: MouseEvent, parking: Parking) {
        this.globalService.contextMenuItems = []
        this.selectedParking = parking
        this.globalService.showContextMenu(event)
        this.globalService.contextMenuItems.push({
            icon: 'fa fa-eye',
            label: 'View Parking',
            action: () => this.router.navigate(['/parking', parking.$key]),
        })
        this.globalService.contextMenuItems.push({
            icon: 'fa fa-edit',
            label: 'Edit Parking',
            action: () => this.router.navigate(['/merchant', parking.merchantRef?.id, 'parking', parking.$key]),
        })
        this.globalService.contextMenuItems.push({
            icon: 'fa fa-link',
            label: 'Copy PayFast Special Link',
            action: () => this.copyPayFastSpecialLink(),
        })

        this.globalService.contextMenuItems.push({
            icon: 'fa fa-link',
            label: 'Copy EFT Special Link',
            action: () => this.copyEftSpecialLink(),
        })

        const leadSubMenuItems: any = this.parkingStatuses.map((status) => {
            return {
                label: status,
                action: () => this.updateParkingStatus(parking, status as ParkingStatus),
            }
        })

        this.globalService.contextMenuItems.push({
            label: 'Update Parking Status',
            icon: 'fa fa-file',
            subMenuItems: leadSubMenuItems,
        })
    }

    copyPayFastSpecialLink() {
        const url = `${environment.host}/parking/${this.selectedParking.$key}?allowPaymentType=${SubscriptionType.PAYFAST}`
        navigator.clipboard.writeText(url).then(() => {
            console.log('URL copied to clipboard:', url)
        })
    }

    copyEftSpecialLink() {
        const url = `${environment.host}/parking/${this.selectedParking.$key}?allowPaymentType=${SubscriptionType.EFT}`
        navigator.clipboard.writeText(url).then(() => {
            console.log('URL copied to clipboard:', url)
        })
    }

    updateParkingStatus(parking: Parking, status: ParkingStatus) {
        parking.parkingStatus = status
        this.parkingService.update(parking.merchantRef.id, parking)
    }

    getDaysAgo(date: any) {
        return (new Date().getTime() - new Date(date.toDate()).getTime()) / (1000 * 3600 * 24)
    }
}
