"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyValuePair = void 0;
class KeyValuePair {
    constructor(value, key) {
        this.value = value;
        this.key = key;
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        return JSON.parse(JSON.stringify(clone));
    }
}
exports.KeyValuePair = KeyValuePair;
