import { Injectable } from '@angular/core'
import { collection, collectionData, deleteDoc, doc, docData, Firestore, query } from '@angular/fire/firestore'
import { Administrator, adminPath, blogsPath, Client, clientsPrivatePath, LogSearch, logSearchesPath, Subscription, subscriptionsPath } from '@parkupp/core'
import { firstValueFrom } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    constructor(public firestore: Firestore) {}

    getAdmins(uid: string) {
        return new Promise<Administrator>((resolve) => {
            docData(doc(this.firestore, adminPath(), uid)).subscribe(
                (document) => {
                    if (document) {
                        let administrator: Administrator = <Administrator>document
                        administrator.$key = uid
                        resolve(administrator)
                    } else {
                        resolve({} as any)
                    }
                },
                (error) => {
                    resolve({} as any)
                }
            )
        })
    }

    getSubscription(documentId: string) {
        return new Promise<Subscription>((resolve) => {
            docData(doc(this.firestore, subscriptionsPath(), documentId)).subscribe((document: any) => {
                let subscription: Subscription = <Subscription>document
                subscription.$key = documentId
                resolve(subscription)
            })
        })
    }

    // const ref = query(collection(this.firestore, 'users'), where('firstName', '==', 'Test 11'))
    //     collectionData(ref).subscribe((queryResults) => {
    //     console.log(queryResults)
    // })

    listSubscriptions() {
        let subscriptions: Subscription[] = []
        return new Promise<Subscription[]>((resolve) => {})
    }

    deleteSubscription(key: string) {
        // Not commonly used, use soft deletes rather
        return new Promise<any>((resolve) => {
            const reference = doc(this.firestore, subscriptionsPath(), key)
            deleteDoc(reference)
                .then(() => {
                    resolve({ status: 'success' })
                })
                .catch((error) => {
                    resolve({
                        status: 'failed',
                        message: error,
                    })
                })
        })
    }

    deleteBlog(key: string) {
        // Not commonly used, use soft deletes rather
        return new Promise<any>((resolve) => {
            const reference = doc(this.firestore, blogsPath(), key)
            deleteDoc(reference)
                .then(() => {
                    resolve({ status: 'success' })
                })
                .catch((error) => {
                    resolve({
                        status: 'failed',
                        message: error,
                    })
                })
        })
    }

    getSearchLogs() {
        return new Promise<LogSearch[]>((resolve) => {
            const subscription = collectionData(collection(this.firestore, logSearchesPath()), { idField: 'documentId' }).subscribe((documents: any[]) => {
                subscription.unsubscribe()
                var LogSearches: LogSearch[] = []
                documents.forEach((logSearchData) => {
                    let LogSearch = logSearchData as LogSearch
                    LogSearches.push(LogSearch)
                })
                resolve(LogSearches)
            })
        })
    }

    async findAndAppendClientPrivate(expiringSubscriptions: Subscription[]) {
        const clientsPrivateRef = query(collection(this.firestore, clientsPrivatePath()))

        const clientsPrivateData = await firstValueFrom(collectionData(clientsPrivateRef, { idField: 'documentId' }))

        if (clientsPrivateData) {
            const clientsPrivateMap = new Map(clientsPrivateData.map((clientPrivate: any) => [clientPrivate.documentId, clientPrivate]))

            expiringSubscriptions.forEach((subscription) => {
                if (subscription.$client) {
                    // Add this check to prevent accessing properties of undefined
                    const clientPrivate = clientsPrivateMap.get(subscription.$client.$key)

                    if (clientPrivate) {
                        const updatedClient = {
                            ...subscription.$client,
                            $email: clientPrivate.email, // Only include the email property
                            $phoneNumber: clientPrivate.phoneNumber, // Only include the phoneNumber property
                        }

                        subscription.$client = Object.assign(new Client(), updatedClient)
                    }
                }
            })
        }
    }
}
