import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
    { path: '', loadChildren: () => import('./shared/shared-routing.module').then((m) => m.SharedRoutingModule) },
    { path: 'admin', loadChildren: () => import('./admin/admin-routing.module').then((m) => m.AdminRoutingModule) },
    { path: 'client', loadChildren: () => import('./client/client-routing.module').then((m) => m.ClientRoutingModule) },
    {
        path: 'merchant',
        loadChildren: () => import('./merchant/merchant-routing.module').then((m) => m.MerchantRoutingModule),
    },
    { path: '**', redirectTo: '/' },
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
