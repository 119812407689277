import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { AuthenticationService } from '../../../services/authentication.service'
import { ActivatedRoute, Router } from '@angular/router'
import { TabsetComponent } from 'ngx-bootstrap/tabs'
import { Merchant } from '@parkupp/core'

@Component({
    selector: 'app-merchant-profile-tabs',
    templateUrl: './merchant-profile-tabs.component.html',
    styleUrls: ['./merchant-profile-tabs.component.scss'],
})
export class MerchantProfileTabsComponent implements OnInit {
    @Input() merchant: Merchant

    dashboardView: boolean
    tab: any

    constructor(private activatedRoute: ActivatedRoute, public authService: AuthenticationService, public router: Router) {
        this.activatedRoute.params.subscribe((params) => {
            if (params['tab']) {
                this.tab = params['tab']
            } else {
                this.tab = 'details'
            }
            console.log('Tab parameter:', this.tab)
        })
        this.dashboardView = true
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        if (this.tab) {
            if (this.tab === 'managers') {
                const tabButton = document.querySelector('#tabs li:nth-child(2) a')
                if (tabButton) {
                    // @ts-ignore
                    tabButton.click()
                }
            }
            if (this.tab === 'payout') {
                const tabButton = document.querySelector('#tabs li:nth-child(3) a')
                if (tabButton) {
                    // @ts-ignore
                    tabButton.click()
                }
            }
        }
    }
}
