"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminChangeLog = void 0;
class AdminChangeLog {
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        const output = JSON.parse(JSON.stringify(clone));
        if (this.clientRef) {
            output.clientRef = this.clientRef;
        }
        if (this.invoiceRef) {
            output.invoiceRef = this.invoiceRef;
        }
        if (this.subscriptionRef) {
            output.subscriptionRef = this.subscriptionRef;
        }
        if (this.adminRef) {
            output.adminRef = this.adminRef;
        }
        if (this.merchantRef) {
            output.merchantRef = this.merchantRef;
        }
        output.createdAt = this.createdAt;
        return output;
    }
}
exports.AdminChangeLog = AdminChangeLog;
