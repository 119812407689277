<div class="container-fluid">
    <div *ngFor="let bankDetail of bankDetailsList" class="row">
        <div class="col-md-12">
            <div class="card mt-3 w-100">
                <div class="card-body">
                    <p class="card-text"><strong>Account Holder Name:</strong> {{ bankDetail.accountHolderName }}</p>
                    <p class="card-text"><strong>Bank:</strong> {{ bankDetail.bank }}</p>
                    <p class="card-text"><strong>Account Number:</strong> {{ bankDetail.accountNumber }}</p>
                    <p class="card-text"><strong>Billing Address:</strong> {{ bankDetail.billingAddressStreetNumber }}</p>
                    <p class="card-text"><strong>Street Address:</strong> {{ bankDetail.billingAddressStreetAddress }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
