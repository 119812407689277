<div class="d-flex justify-content-center" style="height: 100vh">
    <form [formGroup]="editParkerForm" (ngSubmit)="onSubmit()" class="w-50" *ngIf="adminClient">
        <div>
            <h1>Editing: {{ adminClient.firstName }} {{ adminClient.lastName }} ({{ adminClient.companyName }})</h1>
        </div>
        <div class="mb-3">
            <label for="numberOfBays" class="form-label">Number of Bays</label>
            <input type="number" id="numberOfBays" class="form-control" formControlName="numberOfBays" />
        </div>

        <div class="mb-3">
            <label for="city" class="form-label">City</label>
            <input list="cities" id="city" class="form-control" formControlName="city" />
            <datalist id="cities">
                <option *ngFor="let city of southAfricanCities" [value]="city">{{ city }}</option>
            </datalist>
        </div>

        <div class="mb-3">
            <label for="userResponsible" class="form-label">User Responsible</label>
            <select id="userResponsible" class="form-control" formControlName="userResponsible">
                <option *ngFor="let user of leadUsers" [value]="user">{{ user }}</option>
            </select>
        </div>

        <div class="mb-3">
            <label for="leadStatus" class="form-label">Lead Status</label>
            <select id="leadStatus" class="form-control" formControlName="leadStatus">
                <option *ngFor="let status of leadStatuses" [value]="status">{{ status }}</option>
            </select>
        </div>

        <div class="mb-3" *ngIf="editParkerForm.get('leadStatus')?.value === LeadStatus.CLOSED">
            <label for="leadClosedReason" class="form-label">Closed Reason</label>
            <select id="leadClosedReason" class="form-control" formControlName="leadClosedReason">
                <option *ngFor="let reason of leadClosedReasons" [value]="reason">{{ reason }}</option>
            </select>
        </div>
        <button type="submit" class="btn btn-primary">Save</button>
    </form>
</div>
