import { Component, OnInit } from '@angular/core'
import { collection, collectionData, orderBy, query, serverTimestamp } from '@angular/fire/firestore'
import { BankDetails, Merchant, merchantsPath, Parking } from '@parkupp/core'
import { AdminService } from '../../../services/admin.service'
import * as firestore from 'firebase/firestore'
import { MerchantService } from '../../../services/merchant.service'
import { ParkingService } from '../../../services/parking.service'

@Component({
    selector: 'app-admin-list-merchants-page',
    templateUrl: './admin-list-merchants-page.component.html',
    styleUrls: ['./admin-list-merchants-page.component.scss'],
})
export class AdminListMerchantsPageComponent implements OnInit {
    merchants: Merchant[]
    isCreateMerchant = false
    isAdminCreateMerchant = false

    constructor(private adminService: AdminService, private merchantService: MerchantService, private parkingService: ParkingService) {
        const ref = query(collection(this.adminService.firestore, merchantsPath()), orderBy('createdAt', 'desc'))

        this.parkingService.list().subscribe((parkings: Parking[]) => {
            collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
                let merchants: Merchant[] = []
                documents.forEach((documentData) => {
                    let merchant = new Merchant(documentData)

                    merchant.$key = documentData.documentId

                    merchant.$parkings = parkings.filter((parking) => parking.merchantRef?.id === merchant.$key)

                    // Fetch the `bankDetails` subcollection
                    const bankDetailsRef = collection(this.adminService.firestore, `${merchantsPath()}/${merchant.$key}/bankDetails`)
                    collectionData(bankDetailsRef).subscribe((bankDetails) => {
                        if (bankDetails.length > 0) {
                            merchant.$bankDetails = bankDetails.map((bankDetail) => new BankDetails(bankDetail))
                        }
                    })

                    if (!merchant.deletedAt) {
                        merchants.push(merchant)
                    }
                })
                this.merchants = merchants
            })
        })
    }

    ngOnInit(): void {}

    createMerchant() {
        this.isAdminCreateMerchant = !this.isAdminCreateMerchant
    }

    async deleteMerchant(merchant: Merchant) {
        if (confirm('Are you sure you want to cancel this request. This cannot be undone.')) {
            merchant.deletedAt = serverTimestamp() as firestore.Timestamp
            await this.merchantService.createOrUpdateMerchant(merchant)
            window.location.reload()
        }
    }
}
