<admin-app-header></admin-app-header>
<div class="container-fluid" *ngIf="events && events.length > 0">
    <div class="container">
        <div class="col-md-12 col-sm-12 col-xs-12 mt-5">
            <h4 class="text-center">Subscription Events Timeline</h4>
            <div class="timeline-container">
                <div class="timeline-wrapper">
                    <div class="timeline">
                        <div *ngFor="let event of events; let i = index" class="timeline-item" [ngClass]="{ 'timeline-item-left': i % 2 === 0, 'timeline-item-right': i % 2 === 1 }">
                            <div class="timeline-dot"></div>
                            <div class="timeline-content">
                                <div class="timeline-headline mb-0">{{ event.title }}</div>
                                <div class="timeline-date mb-2">{{ event.date }}</div>
                                <div class="timeline-text" [innerHTML]="event.description"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
