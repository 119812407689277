<admin-app-header></admin-app-header>
<div class="container mt-5">
    <div class="row">
        <div class="col-md-6 offset-md-3 col-12">
            <h1 class="mt-4">Deputize</h1>
            <p class="my-3">Allows you to log in as another user</p>
            <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="needs-validation">
                <div class="form-group mb-3">
                    <label>User Email</label>
                    <input type="email" name="email" id="email" formControlName="email" placeholder="Email" class="form-control" />
                    <div invalidMessage="email" class="invalid-feedback">{{ formGroup.get('email') | validationErrors }}</div>
                </div>
                <button type="submit" [disabled]="loading" class="btn btn-primary"><i *ngIf="loading" class="fa fa-circle-notch fa-spin"></i><span *ngIf="!loading">Submit</span></button>
            </form>
        </div>
    </div>
</div>
