import { Component, OnInit } from '@angular/core'
import { BackendService } from 'src/app/services/backend.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
@Component({
    selector: 'app-admin-force-void-payment',
    templateUrl: './admin-force-void-payment.component.html',
    styleUrls: ['./admin-force-void-payment.component.scss'],
})
export class AdminForceVoidPaymentComponent implements OnInit {
    formGroup: FormGroup
    response: any
    loading: boolean = false

    constructor(private backendService: BackendService, private formBuilder: FormBuilder) {
        this.formGroup = this.formBuilder.group({
            paymentKey: ['', Validators.required],
        })
    }

    ngOnInit(): void {}

    onSubmit() {
        this.loading = true
        const paymentKey = this.formGroup.value.paymentKey

        this.backendService.httpVoidRequestManually(paymentKey).then((response) => {
            console.log(response)
            this.response = response
            this.loading = false
            this.formGroup.reset()
        })
    }
}
