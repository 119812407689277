import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'simpleTime',
    pure: true,
})
export class SimpleTimePipe implements PipeTransform {
    transform(value: any): string {
        value = Number(value)
        if (value < 60) {
            return `${value} min`
        } else if (value < 1440) {
            return `${(value / 60).toFixed(1)} hours`
        } else {
            return `${(value / 1440).toFixed(1)} days`
        }
    }
}
