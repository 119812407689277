"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankDetails = void 0;
class BankDetails {
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        return JSON.parse(JSON.stringify(clone));
    }
}
exports.BankDetails = BankDetails;
