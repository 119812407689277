"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayGateClient = void 0;
class PayGateClient {
    constructor(source) {
        this.title = '';
        if (source) {
            Object.assign(this, source);
        }
        if (source?.mobile) {
            this.mobile = source.mobile;
            this.telephone = source.mobile;
        }
    }
}
exports.PayGateClient = PayGateClient;
