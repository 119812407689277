<admin-app-header></admin-app-header>
<div class="container-fluid">
    <h1>Functions</h1>

    <form [formGroup]="formGroup" class="needs-validation" novalidate>
        <div class="content col-lg-12">
            <!--            <a (click)="addPaidOutToPayments()" class="btn btn-danger"> Add paid out to all payments </a>-->
            <!--            <br />-->
            <!--            <br />-->
            <!--            <a (click)="updateStartDateFormat()" class="btn btn-primary"> Update start date to correct format </a>-->
            <!--            <br />-->
            <!--            <br />-->
            <!--            <a (click)="updateSubscriptionEndDate()" class="btn btn-primary"> Update subscription end date </a>-->
            <!--            <br />-->
            <!--            <br />-->

            <!--            <a (click)="populatePayGateClient()" class="btn btn-primary"> populatePayGateClient </a>-->

            <!--            <br />-->
            <!--            <br />-->
            <!--            <input class="form-control" formControlName="subscriptionKey" id="subscriptionKey" name="subscriptionKey" placeholder="subscriptionKey" type="text" />-->
            <!--            <a (click)="subTestRenew()" class="btn btn-primary"> subTestRenew </a>-->
        </div>
    </form>
</div>
