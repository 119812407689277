import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { AgmMap, AgmMarker, MapsAPILoader } from '@agm/core'
import { LogSearch, Parking, ParkingLocation, ParkingType, Term } from '@parkupp/core'
import { geohashForLocation } from 'geofire-common'
import { LatLngBounds } from '@agm/core/services/google-maps-types'
import { FormatCentsPipe } from '../pipes/format-cents.pipe'
import { ClientService } from '../../../services/client.service'
import { GlobalService } from 'src/app/services/global.service'

@Component({
    selector: 'app-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit {
    @Input() autoCompleteInputElement: any
    @Input() parkingType: ParkingType | null
    @Input() parkingLocation: ParkingLocation
    @Input() zoom = 5
    @Input() mapHeight: string
    @Input() cameraLat = -29.320829
    @Input() cameraLng = 24.237754
    @Input() scrollWheel = false
    @Input() onlyCity = false
    @Input() parking: Parking
    @Input() showPriceAsMarker = false
    @Output() onLocationChanged = new EventEmitter<ParkingLocation>()
    @Output() onBoundsChanged = new EventEmitter<LatLngBounds>()
    @Output() onMarkerSelected = new EventEmitter<string>()
    @ViewChild(AgmMap) map: AgmMap

    latLng: LatLngBounds
    parkings: Parking[] = []
    displayParkings: Parking[] = []
    label: any = {}
    term: Term | null
    url: any
    iconUrlMap: { [id: string]: any } = {}
    zIndexMap: { [id: string]: number } = {}
    selectedParkingKey: string
    hoverParkingKey: string | null
    nativeMap: google.maps.Map
    minZoom = 13 // Example minimum zoom level
    maxZoom = 16 // Example maximum zoom level

    constructor(private mapsAPILoader: MapsAPILoader, private changeDetectorRef: ChangeDetectorRef, private clientSvc: ClientService, private globalService: GlobalService) {
        this.cameraLat = -29.320829
        this.cameraLng = 24.237754
        const savedCoords = this.globalService.getCookie('agm_saved_coords')
        if (savedCoords) {
            this.cameraLat = JSON.parse(savedCoords).lat
            this.cameraLng = JSON.parse(savedCoords).lng
        }
        const zoomLevel = this.globalService.getCookie('agm_zoom_level')
        if (zoomLevel) {
            setTimeout(() => {
                this.zoom = parseFloat(zoomLevel)
            })
        }
    }

    ngOnInit(): void {
        if (this.parking && this.parking.parkingLocation) {
            this.parkingLocation = this.parking.parkingLocation
            this.cameraLat = this.parking.parkingLocation.latitude
            this.cameraLng = this.parking.parkingLocation.longitude
        } else {
            this.parkingLocation = new ParkingLocation()
        }
        this.changeDetectorRef.detectChanges()
        this.autocomplete()
    }

    autocomplete(): void {
        setTimeout(() => {
            this.mapsAPILoader.load().then(() => {
                const element: any = this.autoCompleteInputElement
                if (element) {
                    let autoComplete: any
                    if (this.onlyCity) {
                        autoComplete = new google.maps.places.Autocomplete(element, {
                            // types: ['(cities)'],
                            types: [],
                            componentRestrictions: { country: 'za' },
                        })
                    } else {
                        autoComplete = new google.maps.places.Autocomplete(element, {
                            types: ['address'],
                            componentRestrictions: { country: 'za' },
                        })
                    }
                    google.maps.event.addListener(autoComplete, 'place_changed', () => {
                        const place = autoComplete.getPlace()
                        this.logClientSearch(place)
                        console.log('searched place', place, place.geometry.viewport)
                        if (place.geometry && place.geometry.location) {
                            let parkingLocationString = place.formatted_address
                            this.parkingLocation.longitude = place.geometry.location.lng()
                            this.parkingLocation.latitude = place.geometry.location.lat()
                            let streetNumber = parkingLocationString?.split(' ')[0]
                            let streetAddress = parkingLocationString?.split(', ')[0]
                            streetAddress = streetAddress?.slice(streetNumber?.length)
                            this.parkingLocation.streetNumber = streetNumber
                            this.parkingLocation.streetAddress = streetAddress
                            this.parkingLocation.suburb = parkingLocationString?.split(', ')[1]
                            this.parkingLocation.city = parkingLocationString?.split(', ')[2]!
                            this.parkingLocation.postalCode = parkingLocationString?.split(', ')[3]
                            this.parkingLocation.country = parkingLocationString?.split(', ')[4]!
                            this.updateMapLocation(place.geometry.location.lat(), place.geometry.location.lng())
                            this.parkingLocation.geoHash = geohashForLocation([this.parkingLocation.latitude, this.parkingLocation.longitude])
                            this.onLocationChanged.emit(this.parkingLocation)

                            if (place.geometry.viewport) {
                                this.nativeMap.fitBounds(place.geometry.viewport)

                                let currentZoom = this.nativeMap.getZoom()
                                if (currentZoom > this.maxZoom) {
                                    this.zoom = this.maxZoom
                                } else if (currentZoom < this.minZoom) {
                                    this.zoom = this.minZoom
                                }
                            } else {
                                this.zoom = 14 // Default zoom if viewport is not available
                            }

                            if (this.zoom > 16) {
                                this.zoom = 16
                            }

                            this.changeDetectorRef.detectChanges()
                            if (this.parking) {
                                this.parking.parkingLocation = this.parkingLocation
                            }
                        }
                    })
                }
                if (this.parkingLocation && this.parkingLocation.latitude && this.parkingLocation.longitude) {
                    this.updateMapLocation(this.parkingLocation.latitude, this.parkingLocation.longitude)
                    this.zoom = 14
                }
            })
        }, 500)
    }

    onBoundsChange(event: LatLngBounds) {
        this.latLng = event
    }

    onIdle() {
        this.globalService.setCookie({
            name: 'agm_saved_coords',
            value: JSON.stringify(this.latLng.getCenter()),
            expireDays: 30,
        })
        this.onBoundsChanged.emit(this.latLng)
    }

    updateMapLocation(lat: number, lng: number) {
        this.parkingLocation.latitude = lat
        this.parkingLocation.longitude = lng
        this.cameraLat = lat
        this.cameraLng = lng
        this.changeDetectorRef.detectChanges()
    }

    generateMarkers(parkings: Parking[], term: Term | null) {
        this.parkings = parkings
        this.term = term
        this.refreshMarkers()
    }

    refreshMarkers() {
        let parkingsList: Parking[] = []
        for (const parking of this.parkings) {
            parking.plans?.map((plan) => {
                if (plan.term == this.term) {
                    parkingsList.push(parking)
                }
            })
            if (this.showPriceAsMarker) {
                let displayAmount

                if (this.getFeeInCents(parking) != 0) {
                    displayAmount = 'R ' + FormatCentsPipe.formatCurrency(this.getFeeInCents(parking))
                } else {
                    displayAmount = 'TBA'
                }

                if (displayAmount) {
                    const blob: any = new Blob([this.getMarkerSvg(displayAmount, parking.$key!)], { type: 'image/svg+xml' }) as any
                    this.iconUrlMap[parking.$key!] = URL.createObjectURL(blob)
                }
            }
            this.zIndexMap[parking.$key!] = 1
            if (parking.$key == this.selectedParkingKey) {
                this.zIndexMap[parking.$key] = 2
            }
            if (parking.$key == this.hoverParkingKey) {
                this.zIndexMap[parking.$key] = 3
            }
        }
        this.displayParkings = parkingsList
    }

    getFeeInCents(parking: Parking): number | null {
        if (!parking.plans || parking.plans.length == 0) {
            return null
        }
        for (const plan of parking.plans) {
            if (plan.term === this.term) {
                return plan.feeInCents
            }
        }
        return null
    }

    onMarkerClick(agmMarker: AgmMarker) {
        const parkingKey: string = agmMarker.title
        this.onMarkerSelected.emit(parkingKey)
        this.selectedParkingKey = parkingKey
        this.refreshMarkers()
    }

    setHoverParkingKey(hoverParkingKey: string | null) {
        this.hoverParkingKey = hoverParkingKey
        this.refreshMarkers()
    }

    getMarkerSvg(displayAmount: string, parkingKey: string): string {
        let backgroundColor = '#FFFFFF'
        let color = '#222222'
        if (this.hoverParkingKey && this.hoverParkingKey == parkingKey) {
            backgroundColor = '#222222'
            color = '#FFFFFF'
        }
        if (this.selectedParkingKey && this.selectedParkingKey == parkingKey) {
            backgroundColor = '#29B9A0'
            color = '#FFFFFF'
        }
        if (displayAmount.length > 5) {
            return `<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="93" height="28" viewBox="0 0 93 28" style="enable-background:new 0 0 93 28;" xml:space="preserve"><style type="text/css">.st0{fill:${backgroundColor};}  .st1{fill:none;stroke:${color};}  .st2{font-family:\'Arial-BoldMT\';fill: ${color};}  .st3{font-size:14px;}</style><g><path class="st0" d="M14,0h65c5.5,0,10,4.5,10,10l0,0c0,5.5-4.5,10-10,10H14C8.5,20,4,15.5,4,10l0,0C4,4.5,8.5,0,14,0z"/><path class="st1" d="M14,0.5h65c5.2,0,9.5,4.3,9.5,9.5l0,0c0,5.2-4.3,9.5-9.5,9.5H14c-5.2,0-9.5-4.3-9.5-9.5l0,0C4.5,4.8,8.8,0.5,14,0.5z"/></g><text transform="matrix(1 0 0 1 18.697 15.4651)" class="st2 st3">${displayAmount}</text></svg>`
        }
        if (displayAmount.length == 5) {
            return `<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="93" height="28" viewBox="0 0 157.5 51.6" style="enable-background:new 0 0 157.5 51.6;" xml:space="preserve"><style type="text/css">.st0{fill:${backgroundColor};}  .st1{fill:none;stroke:${color};}  .st2{font-family:'Arial-BoldMT';fill: ${color};}  .st3{font-size:29.0738px;}</style><g><path class="st0" d="M17.6,0h121.3c10.3,0,18.7,9.3,18.7,20.8l0,0c0,11.5-8.4,20.8-18.7,20.8H17.6C7.3,41.5-1,32.2-1,20.8l0,0C-1,9.3,7.3,0,17.6,0z"/><path class="st1" d="M17.6,1h121.3c9.8,0,17.7,8.8,17.7,19.7l0,0c0,10.9-7.9,19.7-17.7,19.7H17.6c-9.8,0-17.7-8.8-17.7-19.7l0,0C-0.1,9.9,7.8,1,17.6,1z"/></g><text transform="matrix(1 0 0 1 29.485 32.1166)" class="st2 st3">${displayAmount}</text></svg>`
        }
        if (displayAmount.length == 4 || displayAmount.length == 3) {
            return `<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="93" height="28" viewBox="0 0 132.2 51.6" style="enable-background:new 0 0 132.2 51.6;" xml:space="preserve"><style type="text/css">.st0{fill:${backgroundColor};}  .st1{fill:none;stroke:${color};}  .st2{font-family:'Arial-BoldMT';fill: ${color};}  .st3{font-size:29.0738px;}</style><g><path class="st0" d="M14.6,0h101.9c8.7,0,15.7,9.3,15.7,20.8l0,0c0,11.5-7,20.8-15.7,20.8H14.6C6,41.5-1,32.2-1,20.8l0,0C-1,9.3,6,0,14.6,0z"/><path class="st1" d="M14.6,1h101.9c8.2,0,14.9,8.8,14.9,19.7l0,0c0,10.9-6.7,19.7-14.9,19.7H14.6c-8.2,0-14.9-8.8-14.9-19.7l0,0C-0.3,9.9,6.4,1,14.6,1z"/></g><text transform="matrix(1 0 0 1 29.485 32.1166)" class="st2 st3">${displayAmount}</text></svg>`
        }
        return `<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="93" height="28" viewBox="0 0 116.6 51.6" style="enable-background:new 0 0 116.6 51.6;" xml:space="preserve"><style type="text/css">.st0{fill:${backgroundColor};}  .st1{fill:none;stroke:${color};}  .st2{font-family:'Arial-BoldMT';fill: ${color};}  .st3{font-size:29.0738px;}</style><g><path class="st0" d="M12.9,0h90.5c7.7,0,13.9,9.3,13.9,20.8l0,0c0,11.5-6.2,20.8-13.9,20.8H12.9C5.2,41.5-1,32.2-1,20.8l0,0C-1,9.3,5.2,0,12.9,0z"/><path class="st1" d="M12.9,1h90.5c7.3,0,13.2,8.8,13.2,19.7l0,0c0,10.9-5.9,19.7-13.2,19.7H12.9c-7.3,0-13.2-8.8-13.2-19.7l0,0C-0.3,9.9,5.6,1,12.9,1z"/></g><text transform="matrix(1 0 0 1 29.485 32.1166)" class="st2 st3">${displayAmount}</text></svg>`
    }

    getMarkerValue(parking: Parking, term: string | null): number {
        let value: number = 0
        if (parking.plans) {
            parking.plans.forEach((plan) => {
                if (plan.term == term) {
                    value = plan.feeInCents
                }
            })
        }
        return value
    }

    logClientSearch(googlePlace: any) {
        const logSearch = new LogSearch()
        if (googlePlace.formatted_address) {
            logSearch.city = googlePlace.address_components[0].long_name
            if (googlePlace.address_components[2] && googlePlace.address_components[2].long_name) {
                logSearch.province = googlePlace.address_components[2].long_name
            }
            if (googlePlace.address_components[3] && googlePlace.address_components[3].long_name) {
                logSearch.country = googlePlace.address_components[3].long_name
            }
            logSearch.latitude = googlePlace.geometry.location.lat()
            logSearch.longitude = googlePlace.geometry.location.lng()
            if (this.parkingType) {
                logSearch.parkingType = this.parkingType
            }
            this.clientSvc.logClientSearch(logSearch)
        }
    }

    handleZoomChange(zoomLevel: number) {
        this.globalService.setCookie({
            name: 'agm_zoom_level',
            value: zoomLevel,
            expireDays: 30,
        })
    }

    onMapReady(map: google.maps.Map) {
        this.nativeMap = map
    }
}
