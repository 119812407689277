"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Merchant = void 0;
class Merchant {
    constructor(source, key) {
        this.$merchantTenants = [];
        this.bookuppEnabled = false;
        this.logoUrl = '';
        if (source) {
            Object.assign(this, source);
        }
        if (key) {
            this.$key = key;
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        delete clone.$parkings;
        delete clone.$subscriptions;
        delete clone.$bankDetails;
        delete clone.$merchantMembers;
        delete clone.$merchantTenants;
        const merchant = JSON.parse(JSON.stringify(clone));
        merchant.createdAt = this.createdAt;
        merchant.updatedAt = this.updatedAt;
        return merchant;
    }
}
exports.Merchant = Merchant;
