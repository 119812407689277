import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AdminDashboardPageComponent } from './admin-dashboard-page/admin-dashboard-page.component'
import { AdminHeaderComponent } from '../header/admin-header.component'
import { RouterModule } from '@angular/router'
import { AdminListSubscriptionsPageComponent } from './admin-list-subscriptions-page/admin-list-subscriptions-page.component'
import { AdminViewSubscriptionComponent } from './admin-view-subscription/admin-view-subscription.component'
import { CoreModule } from '../../shared/core/core.module'
import { AdminListParkingsPageComponent } from './admin-list-parkings-page/admin-list-parkings-page.component'
import { AdminListMerchantsPageComponent } from './admin-list-merchants-page/admin-list-merchants-page.component'
import { AdminListPaymentsPageComponent } from './admin-list-payments-page/admin-list-payments-page.component'
import { ListEmailsPageComponent } from './list-emails-page/list-emails-page.component'
import { AdminListSettlementsPageComponent } from './admin-list-settlements-page/admin-list-settlements-page.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AdminBlogListComponent } from './admin-blog-list/admin-blog-list.component'
import { AdminBlogCreateComponent } from './admin-blog-create-page/admin-blog-create.component'
import { MediaModule } from '../../shared/media/media.module'
import { AdminFunctionsComponent } from './admin-functions/admin-functions.component'
import { NgxChartsModule } from '@swimlane/ngx-charts'
import { AdminScanqrPageComponent } from './admin-scanqr-page/admin-scanqr-page.component'
import { QRCodeModule } from 'angularx-qrcode'
import { AdminGenerateqrPageComponent } from './admin-generateqr-page/admin-generateqr-page.component'
import { AdminViewPaymentPageComponent } from './admin-view-payment-page/admin-view-payment-page.component'
import { AdminListSearchesPageComponent } from './admin-list-searches-page/admin-list-searches-page.component'
import { AdminSubscriptionCreateComponent } from './admin-subscription-create/admin-subscription-create.component'
import { ClientSubscriptionsModule } from '../../client/client-subscriptions/client-subscriptions.module'
import { AdminListPayoutsPageComponent } from './admin-list-payouts-page/admin-list-payouts-page.component'
import { MerchantProfileModule } from '../../merchant/merchant-profile/merchant-profile.module'
import { AdminDeputizePageComponent } from './admin-deputize-page/admin-deputize-page.component'
import { AdminSearchesReportComponent } from './admin-searches-report/admin-searches-report.component'
import { AdminSearchesTableComponent } from './admin-searches-table/admin-searches-table.component'
import { TypeaheadModule } from 'ngx-bootstrap/typeahead'
import { AdminListExpiredSubscriptionsComponent } from './admin-list-expired-subscriptions/admin-list-expired-subscriptions.component'
import { AdminReconPageComponent } from './admin-recon-page/admin-recon-page.component'
import { AdminAllManualComponent } from './admin-all-manual/admin-all-manual.component'
import { CompaniesByClientComponent } from './reports/companies-by-client/companies-by-client.component'
import { AdminListInvoicesComponent } from './admin-list-invoices/admin-list-invoices.component'
import { AdminCreateInvoiceComponent } from './invoices/admin-create-invoice/admin-create-invoice.component'
import { AdminListExceptionsComponent } from './admin-list-exceptions/admin-list-exceptions.component'
import { AdminListClientsComponent } from './admin-list-clients/admin-list-clients.component'
import { AdminViewExceptionsComponent } from './admin-view-exceptions/admin-view-exceptions.component'
import { AdminInvoicePayoutsComponent } from './admin-invoice-payouts/admin-invoice-payouts.component'
import { AdminSubscriptionManualCreateComponent } from './admin-subscription-manual-create/admin-subscription-manual-create.component'
import { AdminListLeadsComponent } from './admin-list-leads/admin-list-leads.component'
import { AdminListPayoutsComponent } from './admin-list-payouts/admin-list-payouts.component'
import { AdminListPayoutBatchesComponent } from './admin-list-payout-batches/admin-list-payout-batches.component'
import { AdminPayoutBatchDetailsComponent } from './admin-payout-batch-details/admin-payout-batch-details.component'
import { AdminPayoutDetailsComponent } from './admin-payout-details/admin-payout-details.component'
import { AdminEmailDetailsComponent } from './admin-email-details/admin-email-details.component'
import { AdminListMerchantsPrivateComponent } from './admin-list-merchants-private/admin-list-merchants-private.component'
import { AdminListMerchantsMemberInvitesComponent } from './admin-list-merchants-member-invites/admin-list-merchants-member-invites.component'
import { EditParkerComponent } from './crm/edit-parker/edit-parker.component'
import { ParkerCrm } from './crm/parker-crm/parker-crm.component';
import { ParkerTableComponent } from './crm/parker-table/parker-table.component';
import { ParkerStatsComponent } from './crm/parker-stats/parker-stats.component';
import { AdminLineChartsComponent } from './admin-line-charts/admin-line-charts.component';
import { AdminForceVoidPaymentComponent } from './admin-force-void-payment/admin-force-void-payment.component'

@NgModule({
    declarations: [
        AdminDashboardPageComponent,
        AdminHeaderComponent,
        AdminListClientsComponent,
        AdminListSubscriptionsPageComponent,
        AdminViewSubscriptionComponent,
        AdminListParkingsPageComponent,
        AdminListMerchantsPageComponent,
        AdminListPaymentsPageComponent,
        ListEmailsPageComponent,
        AdminListSettlementsPageComponent,
        AdminBlogCreateComponent,
        AdminBlogListComponent,
        AdminFunctionsComponent,
        AdminScanqrPageComponent,
        AdminGenerateqrPageComponent,
        AdminViewPaymentPageComponent,
        AdminListSearchesPageComponent,
        AdminSubscriptionCreateComponent,
        AdminListPayoutsPageComponent,
        AdminDeputizePageComponent,
        AdminSearchesReportComponent,
        AdminSearchesTableComponent,
        AdminListExpiredSubscriptionsComponent,
        AdminReconPageComponent,
        AdminAllManualComponent,
        CompaniesByClientComponent,
        AdminListInvoicesComponent,
        AdminCreateInvoiceComponent,
        AdminListExceptionsComponent,
        AdminViewExceptionsComponent,
        AdminInvoicePayoutsComponent,
        AdminSubscriptionManualCreateComponent,
        AdminListLeadsComponent,
        AdminListPayoutsComponent,
        AdminListPayoutBatchesComponent,
        AdminPayoutBatchDetailsComponent,
        AdminPayoutDetailsComponent,
        AdminEmailDetailsComponent,
        ParkerCrm,
        AdminListMerchantsPrivateComponent,
        AdminListMerchantsMemberInvitesComponent,
        EditParkerComponent,
        ParkerTableComponent,
        ParkerStatsComponent,
        AdminLineChartsComponent,
        AdminForceVoidPaymentComponent,
    ],
    exports: [AdminHeaderComponent],
    imports: [RouterModule, CommonModule, CoreModule, ReactiveFormsModule, MediaModule, NgxChartsModule, QRCodeModule, ClientSubscriptionsModule, MerchantProfileModule, TypeaheadModule, FormsModule],
})
export class AdminPagesModule {}
