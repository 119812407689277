<agm-map
    [latitude]="cameraLat"
    [longitude]="cameraLng"
    [zoom]="zoom"
    [scrollwheel]="scrollWheel"
    [streetViewControl]="false"
    (boundsChange)="onBoundsChange($event)"
    (idle)="onIdle()"
    style="height: {{ mapHeight }}"
    (zoomChange)="handleZoomChange($event)"
    (mapReady)="onMapReady($event)"
>
    <ng-container *ngIf="parking && parking.parkingLocation">
        <agm-marker [latitude]="parking.parkingLocation.latitude" [longitude]="parking.parkingLocation.longitude" [title]="parking.$key!!" (markerClick)="onMarkerClick($event)"></agm-marker>
    </ng-container>
    <ng-container *ngFor="let parking of displayParkings">
        <ng-container *ngIf="parking.parkingLocation">
            <agm-marker
                *ngIf="iconUrlMap[parking.$key!]"
                [latitude]="parking.parkingLocation.latitude"
                [title]="parking.$key!!"
                [zIndex]="zIndexMap[parking.$key!]"
                [longitude]="parking.parkingLocation.longitude"
                [iconUrl]="iconUrlMap[parking.$key!]"
                (markerClick)="onMarkerClick($event)"
            ></agm-marker>
        </ng-container>
    </ng-container>
</agm-map>
