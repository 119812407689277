import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { collection, collectionData, docData, limit, orderBy, query, where } from '@angular/fire/firestore'
import { Client, Payment, paymentsPath } from '@parkupp/core'
import { HttpClient } from '@angular/common/http'
import { ClientService } from '../../../services/client.service'
import { BackendService } from 'src/app/services/backend.service'

@Component({
    selector: 'app-admin-list-payments-page',
    templateUrl: './admin-list-payments-page.component.html',
    styleUrls: ['./admin-list-payments-page.component.scss'],
})
export class AdminListPaymentsPageComponent implements OnInit {
    payments: Payment[]
    private clientCache: Map<string, Client> = new Map()

    constructor(private adminService: AdminService, private http: HttpClient, private clientService: ClientService, private backendService: BackendService) {
        const ref = query(
            collection(this.adminService.firestore, paymentsPath()),
            where('voided', '==', false),
            where('settled', '==', false),
            where('paymentFail', '==', false),
            orderBy('createdAt', 'desc'),
            limit(100)
        )
        collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
            let payments: Payment[] = []
            documents.forEach((documentData) => {
                let payment = new Payment(documentData)
                payment.$key = documentData.documentId

                if (documentData.clientRef) {
                    const clientId = documentData.clientRef.id
                    if (this.clientCache.has(clientId)) {
                        payment.$client = this.clientCache.get(clientId)
                    } else {
                        this.clientService.getPromise(clientId).then((client) => {
                            payment.$client = client
                            this.clientCache.set(clientId, client)
                        })
                    }
                }

                payments.push(payment)
            })
            this.payments = payments
        })
    }

    ngOnInit(): void {}

    async voidPayment(paymentKey: string) {
        if (confirm('Are you sure you want to void this payment?')) {
            await this.backendService.httpVoidRequestManually(paymentKey)
            this.payments = this.payments.filter((payment) => payment.$key !== paymentKey)
        }
    }
}
