<footer [ngClass]="bgClass" class="pt-sm-5">
    <div class="container py-3">
        <div class="row">
            <div class="col-sm-6 col-md-4">
                <ng-container>
                    <!--                    <img src="{{globalService.whitelabelLogoUrl}}" class="whitelabel-logo-image">-->
                    <!--                    <br>-->
                    <!--                    <br>-->
                    <h6 class="mb-0">Book your parking in association with</h6>
                    <img class="logo-footer-small mb-3" src="/assets/images/home/footer-logo.svg" />
                </ng-container>

                <div class="company-info">Registered Name: The Upps (Pty) Ltd</div>
                <div class="company-info">Registration Number: 2022/43971/07</div>
                <div class="company-info">VAT number: 4620313298</div>
                <!--                                    <div class="company-info">Date of Registration: 01-07-2023</div>-->
                <div class="company-info">32 Kloof St, Gardens, Cape Town, 8001, South Africa</div>
            </div>
            <div class="col-12 col-md-4">
                <h6>Get In Touch</h6>
                <div>
                    <a class="" href="mailto:info&#64;parkupp.co.za">info&#64;parkupp.co.za</a>
                    <br />
                    <br />
                </div>
                <div class="d-none d-md-block">
                    <img class="payment-image-lg" src="/assets/images/footer/payment-logos.png" />
                </div>
            </div>
            <div class="col-sm-6 col-md-4 my-5 my-md-0">
                <h6>Links:</h6>
                <a [routerLink]="['/about-us']">About Us</a><br />
                <a [routerLink]="['/how-it-works']">How it Works</a><br />
                <a [routerLink]="['/ettiquette']">Etiquette</a><br />
                <a [routerLink]="['/safety-and-trust']">Safety and Trust</a><br />
                <a [routerLink]="['/terms-and-conditions']">Terms and Conditions</a><br />
                <a [routerLink]="['/privacy']">Privacy Policy</a><br />
                <a [routerLink]="['/data-deletion']">Data Deletion Policy</a><br />
                <br />
                <br />
                <div class="d-block d-sm-none">
                    <img class="payment-image-lg" src="/assets/images/footer/payment-logos.png" />
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="own-risk col-12 text-center text-sm mb-2">PARKING AT YOUR OWN RISK</div>
            <div class="col-12 text-center text-sm">All rights reserved ©</div>
        </div>
    </div>
    <div class="container py-4"></div>
</footer>
