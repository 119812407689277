<admin-app-header></admin-app-header>
<div class="container-fluid">
    <div class="row">
        <div class="col-6">
            <h1 class="">3. Payout Batches</h1>
        </div>
        <div class="col-3"></div>
        <div class="col-3">
            <!--    <button (click)="globalService.exportcsv('invoices-payouts')" class="btn btn-info btn-sm text-white float-end px-3 py-1 my-2"><i class="fas fa-file-csv"></i> Export to Excel</button>-->
        </div>
    </div>
    <div class="content col-lg-12">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th>Payout Batch ID</th>
                    <th>Created</th>
                    <th>Payout Status</th>
                    <th>Merchants</th>
                    <th>Invoices</th>
                    <th>Total Amount</th>
                    <th>Payout Amount</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let payoutBatch of payoutBatches">
                    <tr>
                        <td>
                            <a [routerLink]="['/admin/payout-batch', payoutBatch.$key]" class="text-decoration-underline">
                                BATCH-{{ payoutBatch.payoutBatchNumber }}
                            </a>
                        </td>
                        <td>{{ payoutBatch.createdAt.toDate() | date: 'dd MMM yyyy' }}</td>
                        <td>
                            <ng-container *ngIf="payoutBatch.payoutDate">
                                <span class="badge bg-success text-dark">
                                    Paid:
                                    {{ payoutBatch.payoutDate | formatDate }}
                                </span>
                            </ng-container>
                            <ng-container *ngIf="!payoutBatch.payoutDate">
                                <span class="badge bg-danger text-light">Unpaid</span>
                            </ng-container>
                        </td>
                        <td>{{ payoutBatch.merchantRefs.length }}</td>
                        <td>{{ payoutBatch.invoiceRefs.length }}</td>
                        <td>{{ payoutBatch.totalAmount | currency: 'R' }}</td>
                        <td>{{ payoutBatch.payoutAmount | currency: 'R' }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
