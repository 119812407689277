"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayGateTransactionStatus = void 0;
var PayGateTransactionStatus;
(function (PayGateTransactionStatus) {
    PayGateTransactionStatus[PayGateTransactionStatus["Not Done"] = 0] = "Not Done";
    PayGateTransactionStatus[PayGateTransactionStatus["Approved"] = 1] = "Approved";
    PayGateTransactionStatus[PayGateTransactionStatus["Declined"] = 2] = "Declined";
    PayGateTransactionStatus[PayGateTransactionStatus["Cancelled"] = 3] = "Cancelled";
    PayGateTransactionStatus[PayGateTransactionStatus["User Cancelled"] = 4] = "User Cancelled";
    PayGateTransactionStatus[PayGateTransactionStatus["Received by PayGate"] = 5] = "Received by PayGate";
    PayGateTransactionStatus[PayGateTransactionStatus["Settlement Voided"] = 7] = "Settlement Voided";
})(PayGateTransactionStatus = exports.PayGateTransactionStatus || (exports.PayGateTransactionStatus = {}));
