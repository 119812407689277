import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { AuthenticationService } from '../../../services/authentication.service'
import { Router } from '@angular/router'

@Component({
    selector: 'app-admin-deputize-page',
    templateUrl: './admin-deputize-page.component.html',
    styleUrls: ['./admin-deputize-page.component.scss'],
})
export class AdminDeputizePageComponent implements OnInit {
    error: string | null
    loading = false
    formGroup: FormGroup

    constructor(public authService: AuthenticationService, private router: Router, private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        })
    }

    onSubmit() {
        if (this.formGroup.valid) {
            this.formGroup.get('email')?.disable()
            this.loading = true

            const email = this.formGroup.value.email.toLowerCase()
            this.authService.user.getIdToken(true).then((idToken) => {
                this.authService.createCustomToken(email, idToken).subscribe((response) => {
                    this.authService.signInWithCustomToken(response.token).then((userCredential) => {
                        if (userCredential.user) {
                            this.router.navigate(['/'])
                            this.formGroup.get('email')?.enable()
                            this.loading = false
                        }
                    })
                })
            })
        }
    }
}
