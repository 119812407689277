import { Component, OnInit } from '@angular/core'
import { collection, collectionData, orderBy, query } from '@angular/fire/firestore'
import { Blog, blogsPath } from '@parkupp/core'
import { AdminService } from '../../../services/admin.service'

@Component({
    selector: 'app-admin-blog-list',
    templateUrl: './admin-blog-list.component.html',
    styleUrls: ['./admin-blog-list.component.scss'],
})
export class AdminBlogListComponent implements OnInit {
    blogs: Blog[]

    constructor(private adminService: AdminService) {
        const ref = query(collection(this.adminService.firestore, blogsPath()), orderBy('createdAt', 'desc'))
        collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.blogs = []
            documents.forEach((documentData) => {
                let blog = new Blog(documentData)
                blog.$key = documentData.documentId

                this.blogs.push(blog)
            })
        })
    }

    ngOnInit(): void {}

    deleteBlog(blog: Blog) {
        if (confirm('Are you sure you want to cancel this request. This cannot be undone.')) {
            this.adminService.deleteBlog(blog.$key!).then((response) => {
                if (response.status === 'success') {
                    // let index = this.payments.findIndex((x) => x.$key == key)
                    // if (index) {
                    //     this.payments.splice(index)
                    // }
                }
            })
        }
    }
}
