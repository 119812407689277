import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { EnumNames, Payout, PayoutBatch, PayoutBatchStatusEnum, PayoutStatusEnum } from '@parkupp/core'
import { doc, serverTimestamp, Timestamp, writeBatch } from 'firebase/firestore'
import { GlobalService } from 'src/app/services/global.service'
import { PayoutBatchService } from 'src/app/services/payout-batch.service'
import { PayoutService } from 'src/app/services/payout.service'
import Swal from 'sweetalert2'
@Component({
    selector: 'app-proforma-list',
    templateUrl: './proforma-list.component.html',
    styleUrls: ['./proforma-list.component.scss'],
})
export class ProformaListComponent implements OnInit {
    EnumNames = EnumNames
    payouts: Payout[] = []
    selectedPayouts: Payout[] = []

    constructor(private payoutService: PayoutService, private payoutBatchService: PayoutBatchService, private router: Router, public globalService: GlobalService) {}

    async ngOnInit() {
        this.payouts = await this.payoutService.getProformaPayouts()
        console.log(this.payouts)
    }
}
