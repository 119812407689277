import { Component, OnInit } from '@angular/core'
import { BankDetails, FirestoreStructure, Invoice, InvoiceStatus, Merchant, Parking, Payout, PayoutFrequencyEnum, PayoutStatusEnum, Subscription } from '@parkupp/core'
import { collection, collectionData, doc, docData, DocumentData, limit, orderBy, query, Query, updateDoc, where, DocumentReference, writeBatch } from '@angular/fire/firestore'
import { AdminService } from '../../../services/admin.service'
import { HttpClient } from '@angular/common/http'
import * as moment from 'moment/moment'
import { InvoiceService } from '../../../services/invoice.service'
import { GlobalService } from '../../../services/global.service'
import { PayoutService } from 'src/app/services/payout.service'
import { MerchantService } from 'src/app/services/merchant.service'
import { Router } from '@angular/router'

@Component({
    selector: 'app-admin-invoice-payouts',
    templateUrl: './admin-invoice-payouts.component.html',
    styleUrls: ['./admin-invoice-payouts.component.scss'],
})
export class AdminInvoicePayoutsComponent implements OnInit {
    PayoutFrequencyEnum = PayoutFrequencyEnum
    invoices: Invoice[]
    filteredInvoices: Invoice[] = []
    selectedInvoices: Invoice[] = []
    mouseDown = false
    showType: string = 'unpaid'
    refUnpaid: Query<DocumentData>
    refPaid: Query<DocumentData>
    includeMonthlyMerchants = false
    includeBlankBankDetails = false
    busyCreatingPayouts = false

    constructor(
        public globalService: GlobalService,
        private adminService: AdminService,
        private http: HttpClient,
        private invoiceService: InvoiceService,
        private payoutService: PayoutService,
        private merchantService: MerchantService,
        private router: Router
    ) {}

    async ngOnInit() {
        const today = moment()
        const dayOfMonth = today.date()
        if (dayOfMonth >= 1 && dayOfMonth <= 5) {
            this.includeMonthlyMerchants = true
        }

        this.refUnpaid = query(
            collection(this.adminService.firestore, FirestoreStructure.INVOICES),
            orderBy('merchantRef', 'desc'),
            where('paidOut', '==', false),
            where('status', '==', InvoiceStatus.PAID)
        )

        await this.getInvoices(this.refUnpaid)
        this.applyFilters()
    }
    applyFilters() {
        this.filteredInvoices = this.invoices
        if (!this.includeMonthlyMerchants) {
            this.filteredInvoices = this.filteredInvoices.filter((invoice) => invoice.$merchant?.payoutDetails?.payoutFrequency == PayoutFrequencyEnum.IMMEDIATE)
        }
        if (!this.includeBlankBankDetails) {
            this.filteredInvoices = this.filteredInvoices.filter((invoice) => invoice.$merchant?.payoutDetails?.accountNumber)
        }
    }
    getInvoices(ref: any): Promise<void> {
        return new Promise((resolve, reject) => {
            collectionData(ref, { idField: 'documentId' }).subscribe({
                next: (documents: any[]) => {
                    let invoices: Invoice[] = []
                    let subscriptionCount = 0
                    let completedSubscriptions = 0

                    const checkCompletion = () => {
                        if (completedSubscriptions === subscriptionCount) {
                            this.invoices = invoices
                            resolve()
                        }
                    }

                    documents.forEach((documentData) => {
                        let invoice = new Invoice(documentData)
                        invoice.$key = documentData.documentId

                        if (documentData.subscriptionRef) {
                            subscriptionCount++
                            docData(documentData.subscriptionRef).subscribe({
                                next: (data) => {
                                    invoice.$subscription = new Subscription(data)
                                    invoice.$subscription.$key = documentData.subscriptionRef.id

                                    if (data['merchantRef']) {
                                        subscriptionCount++
                                        docData(data['merchantRef']).subscribe({
                                            next: (dataInner: any) => {
                                                invoice.$subscription!.$merchant = new Merchant(dataInner)
                                                completedSubscriptions++
                                                checkCompletion()
                                            },
                                            error: reject,
                                        })
                                    }
                                    completedSubscriptions++
                                    checkCompletion()
                                },
                                error: reject,
                            })
                        }

                        if (documentData.parkingRef) {
                            subscriptionCount++
                            docData(documentData.parkingRef).subscribe({
                                next: (parkingDocData) => {
                                    invoice.$parking = new Parking(parkingDocData)
                                    invoice.$parking.$key = documentData.parkingRef.id

                                    if (invoice.$parking && parkingDocData['bankDetailsRef']) {
                                        subscriptionCount++
                                        docData(parkingDocData['bankDetailsRef']).subscribe({
                                            next: (bankDetailsDocData) => {
                                                invoice.$parking!.$bankDetails = new BankDetails(bankDetailsDocData)
                                                invoice.$parking!.$bankDetails.$key = parkingDocData['bankDetailsRef']
                                                completedSubscriptions++
                                                checkCompletion()
                                            },
                                            error: reject,
                                        })
                                    }
                                    completedSubscriptions++
                                    checkCompletion()
                                },
                                error: reject,
                            })
                        }

                        if (documentData.merchantRef) {
                            subscriptionCount++
                            docData(documentData.merchantRef).subscribe({
                                next: (data) => {
                                    invoice.$merchant = new Merchant(data)
                                    invoice.$merchant.$key = documentData.merchantRef.id

                                    if (!invoice.$merchant?.payoutDetails) {
                                        invoice.$merchant.payoutDetails = new BankDetails()
                                    }
                                    completedSubscriptions++
                                    checkCompletion()
                                },
                                error: reject,
                            })
                        }

                        invoices.push(invoice)
                    })

                    checkCompletion()
                },
                error: reject,
            })
        })
    }

    togglePaid(e: any) {
        console.log(e.target.value)
        if (e?.target?.value === 'paid') {
            console.log('here')
            this.getInvoices(this.refPaid)
        } else {
            this.getInvoices(this.refUnpaid)
        }
    }

    async createPayouts() {
        this.busyCreatingPayouts = true
        const merchantPayouts: { [key: string]: any[] } = {}

        // Loop through all selected invoices
        this.selectedInvoices.forEach((invoice: Invoice) => {
            const merchantKey = invoice.merchantRef.id

            // Initialize the merchant key if it doesn't exist
            if (!merchantPayouts[merchantKey]) {
                merchantPayouts[merchantKey] = []
            }

            // Add the invoice reference to the merchant's payout list
            merchantPayouts[merchantKey].push(invoice)
        })

        // Loop through all merchantRefs and create a payout record per merchantRef
        for (const merchantKey in merchantPayouts) {
            if (merchantPayouts.hasOwnProperty(merchantKey)) {
                const payoutInvoices = merchantPayouts[merchantKey]
                const merchantRef = this.merchantService.getDocRef(merchantKey) as any
                const payout = new Payout({
                    merchantRef: merchantRef,
                    invoiceRefs: payoutInvoices.map((invoice: Invoice) => {
                        return doc(this.adminService.firestore, `${FirestoreStructure.INVOICES}/${invoice.$key}`) as any
                    }),
                    totalAmount: payoutInvoices.reduce((acc, invoice) => acc + invoice.amountTotalDue, 0),
                    payoutAmount: payoutInvoices.reduce((acc, invoice) => acc + invoice.periodFinalPayout, 0),
                    status: PayoutStatusEnum.DUE,
                })

                // Save the payout record
                const payoutRef = await this.payoutService.createOrUpdate(payout)

                // Update each invoice with the payoutRef in a batch
                const updateInvoicesBatch = writeBatch(this.adminService.firestore)
                for (const invoice of payoutInvoices) {
                    invoice.payoutRef = payoutRef
                    const invoiceRef = this.invoiceService.getDocRef(invoice.$key)
                    updateInvoicesBatch.update(invoiceRef, { payoutRef: payoutRef })
                }
                await updateInvoicesBatch.commit()
            }
        }

        this.busyCreatingPayouts = false
        this.selectedInvoices = []
        // this.router.navigate(['/admin/payouts'])
    }

    changeStatus(event: any) {
        const invoiceKey = event.target.defaultValue
        const isChecked = (<HTMLInputElement>event.target).checked
        if (isChecked) {
            this.addItem(this.selectedInvoices, invoiceKey)
        } else {
            this.removeItem(this.selectedInvoices, invoiceKey)
        }
    }

    addItem(arr: any, item: string) {
        this.removeItem(arr, item)
        arr.push(item)
    }

    removeItem<T>(arr: Array<T>, value: T): Array<T> {
        const index = arr.indexOf(value)
        if (index > -1) {
            arr.splice(index, 1)
        }
        return arr
    }

    onHover(event: any) {
        const invoiceKey = event.target.defaultValue
        const isChecked = (<HTMLInputElement>event.target).checked
        if (!isChecked && this.mouseDown) {
            this.addItem(this.selectedInvoices, invoiceKey)
            event.target.checked = true
        }
    }

    toggleSelected(invoice: any): void {
        if (!this.selectedInvoices.includes(invoice)) {
            this.selectedInvoices.push(invoice)
        } else {
            this.selectedInvoices = this.selectedInvoices.filter((inv) => inv !== invoice)
        }
    }

    onHoverWithMouseDown(event: MouseEvent, invoice: Invoice): void {
        if (event.buttons === 1) {
            this.toggleSelected(invoice)
        }
    }

    getTotalAmount(): number {
        return this.selectedInvoices.reduce((acc, invoice) => acc + invoice.periodFinalPayout, 0)
    }

    getMerchantsSelected(): number {
        return new Set(this.selectedInvoices.map((invoice) => invoice.merchantRef.id)).size
    }

    showAllInvoices() {
        this.filteredInvoices = this.invoices
    }
}
