<admin-app-header></admin-app-header>
<div class="container-fluid">
    <div class="row my-2">
        <div class="col-md-2">
            <h2>Buildings / Parking lots</h2>
        </div>
        <div class="col-md-2">
            <select id="statusFilter" class="form-control" [(ngModel)]="filterByStatus" (change)="updateStatusFilter($event)">
                <option *ngFor="let status of parkingStatuses" [value]="status">{{ status }}</option>
            </select>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="content col-lg-12">
        <table class="table table-striped table-hover table-sm">
            <thead>
                <tr>
                    <th scope="col">Created</th>
                    <th scope="col">Type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Name</th>
                    <th scope="col">Merchant</th>
                    <th scope="col">Bays</th>
                    <th scope="col">Bank</th>
                    <th scope="col">Address</th>
                    <th scope="col">Use Policy</th>
                    <th scope="col">Lease Agreement</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="parkings">
                    <ng-container *ngFor="let parking of parkings">
                        <tr (contextmenu)="showContextMenu($event, parking)" [ngClass]="{ selected: selectedParking === parking }">
                            <td>
                                {{ parking.createdAt | formatDate }}
                            </td>
                            <td>
                                <span class="badge" [ngClass]="EnumNames.getParkingTypeClass(parking.parkingType)">
                                    {{ EnumNames.getParkingTypeNiceName(parking.parkingType) }}
                                </span>
                            </td>
                            <td>
                                <a>
                                    <span *ngIf="parking.parkingStatus == parkingStatus.DISABLED" class="badge bg-danger text-dark">Disabled</span>
                                    <span *ngIf="parking.parkingStatus == parkingStatus.PUBLISHED" class="badge bg-success text-dark">Published</span>
                                    <span *ngIf="parking.parkingStatus == parkingStatus.NEW" class="badge bg-warning text-dark">Draft</span>
                                    <span *ngIf="parking.parkingStatus == parkingStatus.PENDING" class="badge bg-warning text-dark">Pending</span>
                                </a>
                            </td>
                            <td>
                                <a [routerLink]="['/parking', parking.$key]">
                                    {{ parking.title || '' }}
                                    <span *ngIf="!parking.title" class="undefined">Undefined</span>
                                    <ng-container *ngIf="parking.updatedAt && this.getDaysAgo(parking.updatedAt) <= 30">
                                        <span *ngIf="parking.updatedAt" class="badge bg-warning text-dark ms-2">Updated {{ parking.updatedAt | daysAgo }}</span>
                                    </ng-container>
                                </a>
                            </td>
                            <td>
                                <a *ngIf="parking.merchantRef" [routerLink]="['/merchant/', parking.merchantRef.id]">
                                    <span [ngClass]="{ undefined: !parking.denormMerchantCompanyName }">
                                        {{ parking.denormMerchantCompanyName || 'Not set' }}
                                    </span>
                                </a>
                            </td>
                            <td>
                                <ng-container *ngIf="parking.numberOfBays"><i class="fa-solid fa-car"></i> {{ parking.numberOfBays }}</ng-container>
                            </td>
                            <td [ngClass]="{ 'bg-danger': !parking.$merchant || !parking.$merchant.payoutDetails }">
                                <ng-container *ngIf="parking.$merchant && parking.$merchant.payoutDetails">
                                    <i class="fa-solid fa-building-columns"></i> {{ parking.$merchant.payoutDetails.bank }}
                                </ng-container>
                            </td>
                            <td [ngClass]="{ 'bg-danger': !parking.parkingLocation?.streetNumber }">
                                <ng-container *ngIf="parking.parkingLocation?.streetNumber"><i class="fa-solid fa-location-dot"></i> {{ parking.parkingLocation.city }}</ng-container>
                            </td>
                            <!--                    <td>-->
                            <!--                        <ng-container *ngIf="parking.parkingStatus != parkingStatus.PUBLISHED">-->
                            <!--                            <a class="link-primary" (click)="approveParking(parking.$key)">-->
                            <!--                                Publish-->
                            <!--                            </a>-->
                            <!--                        </ng-container>-->
                            <!--                    </td>-->
                            <td [ngClass]="{ 'bg-danger': !parking.usePolicyUrl }">
                                <ng-container *ngIf="parking.usePolicyUrl">
                                    <a class="text-decoration-underline" [href]="parking.usePolicyUrl" target="_blank">View</a>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngIf="parking.leaseAgreementUrl">
                                    <a class="text-decoration-underline" [href]="parking.leaseAgreementUrl" target="_blank">View</a>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
