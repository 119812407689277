<admin-app-header></admin-app-header>

<div class="container-fluid">
    <button (click)="globalService.exportcsv('all-merchants')" class="btn btn-info btn-sm text-white float-end px-3 py-1 my-2"><i class="fas fa-file-csv"></i> Export to CSV</button>
    <div class="row header">
        <div class="col-4 p-0">
            <h2 class="mb-0">Admin Merchant Users List</h2>
            <small>Last synced at {{ lastSyncedAt | date: 'dd MMMM HH:mm' }}</small>
        </div>
    </div>
    <div class="content col-lg-12">
        <table class="table table-striped table-hover table-sm" id="all-merchants">
            <thead>
                <tr>
                    <th scope="col">Created</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">portfolio Manager</th>
                    <th scope="col">Building Manager</th>
                    <th scope="col">Account Key</th>
                    <th scope="col">Merchant Company Name</th>
                    <th scope="col">Company Registration</th>
                    <th scope="col">VAT Number</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let merchant of merchants">
                    <tr>
                        <td>
                            {{ merchant.createdAt | formatDate }}
                        </td>
                        <td>
                            <a [routerLink]="['/merchant/', merchant.clientRef.id]">
                                <span [ngClass]="{ undefined: !merchant.firstName }">{{ merchant.firstName || 'Not set' }}</span>
                                {{ merchant.lastName }}
                            </a>
                        </td>
                        <td>
                            {{ merchant.email }}
                            <a class="px-1" (click)="sendEmail(merchant.email)">
                                <i class="fa fa-envelope" style="color: #007bff"></i>
                            </a>
                        </td>
                        <td>
                            {{ merchant.phoneNumber }}
                            <a class="px-1" (click)="callPhone(merchant.phoneNumber)">
                                <i class="fa fa-phone" style="color: #d00000"></i>
                            </a>
                            <a class="px-1" (click)="sendWhatsApp(merchant.phoneNumber)">
                                <i class="fab fa-whatsapp-square" style="color: #25d366"></i>
                            </a>
                        </td>
                        <td>
                            <span class="badge" [ngClass]="{ 'bg-success': merchant.roles.includes('PORTFOLIO_MANAGER'), 'bg-danger': !merchant.roles.includes('PORTFOLIO_MANAGER') }">
                                {{ merchant.roles.includes('PORTFOLIO_MANAGER') ? 'Yes' : 'No' }}
                            </span>
                        </td>
                        <td>
                            <span class="badge" [ngClass]="{ 'bg-success': merchant.roles.includes('BUILDING_MANAGER'), 'bg-danger': !merchant.roles.includes('BUILDING_MANAGER') }">
                                {{ merchant.roles.includes('BUILDING_MANAGER') ? 'Yes' : 'No' }}
                            </span>
                        </td>
                        <td>
                            <span class="badge" [ngClass]="{ 'bg-success': merchant.roles.includes('ACCOUNT_KEY'), 'bg-danger': !merchant.roles.includes('ACCOUNT_KEY') }">
                                {{ merchant.roles.includes('ACCOUNT_KEY') ? 'Yes' : 'No' }}
                            </span>
                        </td>
                        <td>
                            <a [routerLink]="['/merchant/', merchant.merchantRef.id]">
                                {{ merchant.merchantCompanyName }}
                            </a>
                        </td>
                        <td>
                            {{ merchant.companyRegistration }}
                        </td>
                        <td>
                            {{ merchant.vatNumber }}
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
