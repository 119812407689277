import { Injectable } from '@angular/core'
import { Timestamp } from '@angular/fire/firestore'
import { NavigationStart, Router } from '@angular/router'
import Swal from 'sweetalert2'
import { AuthenticationService } from './authentication.service'

@Injectable({
    providedIn: 'root',
})
export class GlobalService {
    static globalSideMenuCollapsed: boolean = true
    routes: any = {}
    subdomain: string
    whitelabeled: boolean
    whitelabelLogoUrl: string
    whitelabelColour: string
    whitelabelBannerUrl: string
    whitelabelBuilding: string
    whitelabelManager: string
    whitelabelHeading: string
    whitelabelMerchant: string
    whitelabelName: string
    whitelabelHeaderColour: string
    whitelabelHeaderLight = false
    contextMenuVisible: boolean
    contextMenuItems: any[] = []
    contextMenuX: number
    contextMenuY: number

    constructor(private router: Router, private authenticationService: AuthenticationService) {
        // Listen to routing events
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                GlobalService.globalSideMenuCollapsed = true
            }
        })

        this.routes.parking = `/parking/`

        this.whitelabeled = false

        const domainParts = location.hostname.split('.')
        if (domainParts) {
            this.subdomain = domainParts[0]
            // this.subdomain = 'docklands'

            if (this.subdomain === 'redefine') {
                this.whitelabeled = true
                this.whitelabelLogoUrl = 'assets/whitelabel/redefine/logo.png'
                this.whitelabelColour = '#ed1c24'
                this.whitelabelName = 'redefine'
                this.whitelabelHeading = 'Redefine - Parking for people'
                this.whitelabelHeaderColour = '#f7f7f7'
                this.whitelabelHeaderLight = true
            }

            if (this.subdomain === 'spire') {
                this.whitelabeled = true
                this.whitelabelLogoUrl = 'assets/whitelabel/spire/logo.png'
                this.whitelabelColour = '#0da676'
                this.whitelabelHeaderColour = '#0da676'
                this.whitelabelMerchant = 'WtUfbAg95cQQSCBtnQQUBJ9O4WU2'
                this.whitelabelHeading = 'Parkings Managed By Spire'
            }

            if (this.subdomain === 'cityprop') {
                this.whitelabeled = true
                this.whitelabelLogoUrl = 'assets/whitelabel/cityprop/logo.svg'
                this.whitelabelColour = '#06223E'
                this.whitelabelHeaderColour = '#06223E'
                this.whitelabelBannerUrl = 'assets/whitelabel/cityprop/banner.png'
                this.whitelabelBuilding = 'City Property'
                this.whitelabelHeading = 'Parkings in City Property Buildings'
            }

            if (this.subdomain === 'advance') {
                this.whitelabeled = true
                this.whitelabelLogoUrl = 'assets/whitelabel/advance/logo.svg'
                this.whitelabelColour = '#650058'
                this.whitelabelHeaderColour = '#650058'
                this.whitelabelManager = 'Advance on point solutions'
                this.whitelabelHeading = 'Parkings Managed By Advance On Point Solutions'
            }

            if (this.subdomain === 'admyt') {
                this.whitelabeled = true
                this.whitelabelLogoUrl = 'assets/whitelabel/admyt/logo.png'
                this.whitelabelColour = '#FCD004'
                this.whitelabelHeaderColour = '#FCD004'
                this.whitelabelHeading = 'Parkings With Admyt Access Control'
            }

            if (this.subdomain === 'docklands') {
                this.whitelabeled = true
                this.whitelabelLogoUrl = 'assets/whitelabel/docklands/logo.svg'
                this.whitelabelColour = '#266ec2'
                this.whitelabelHeaderColour = '#266ec2'
                this.whitelabelManager = 'Docklands Ventures'
                this.whitelabelHeading = 'Driving Innovative Thinking Across Real Estate Markets'
            }
        }
    }

    static verticalMenuClass() {
        if (GlobalService.globalSideMenuCollapsed) {
            return 'side-menu-collapsed'
        }
        return 'side-menu-expanded'
    }

    goHome() {
        if (this.whitelabeled) {
            // this.router.navigate(['/showcase'])
            window.location.href = '/showcase'
        } else {
            window.location.href = '/'
        }
    }

    public getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';')
        let caLen: number = ca.length
        let cookieName = `${name}=`
        let c: string

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '')
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length)
            }
        }
        return ''
    }

    public deleteCookie(cookieName: string) {
        this.setCookie({ name: cookieName, value: '', expireDays: -1 })
    }

    /**
     * Expires default 1 day
     * If params.session is set and true expires is not added
     * If params.path is not set or value is not greater than 0 its default value will be root "/"
     * Secure flag can be activated only with https implemented
     * Examples of usage:
     * {service instance}.setCookie({name:'token',value:'abcd12345', session:true }); <- This cookie will not expire
     * {service instance}.setCookie({name:'userName',value:'John Doe', secure:true }); <- If page is not https then secure will not apply
     * {service instance}.setCookie({name:'niceCar', value:'red', expireDays:10 }); <- For all this examples if path is not provided default will be root
     */
    public setCookie(params: any) {
        let d: Date = new Date()
        d.setTime(d.getTime() + (params.expireDays ? params.expireDays : 1) * 24 * 60 * 60 * 1000)
        document.cookie =
            (params.name ? params.name : '') +
            '=' +
            (params.value ? params.value : '') +
            ';' +
            (params.session && params.session == true ? '' : 'expires=' + d.toUTCString() + ';') +
            'path=' +
            (params.path && params.path.length > 0 ? params.path : '/') +
            ';' +
            (location.protocol === 'https:' && params.secure && params.secure == true ? 'secure' : '')
    }

    addScript(source: string, id: string) {
        const newElement = document.createElement('script'),
            existingElement = document.getElementsByTagName('script')[0]
        newElement.async = true
        newElement.src = source
        newElement.id = id
        existingElement.parentNode?.insertBefore(newElement, existingElement)
    }

    exportcsv(tableId: string): void {
        let csv = ''
        const table = document.getElementById(tableId)
        const tr = table!.children[0].children[0] as any
        if (tr) {
            for (let i = 0; i < tr.children.length; i++) {
                const th = tr.children[i]
                const exclude = th.getAttribute('exclude')
                if (exclude !== 'true') {
                    csv += th.innerText + ','
                }
            }
            csv = csv.substring(0, csv.length - 1) + '\n'

            const tbody = table!.children[1] as any
            for (let i = 0; i < tbody.children.length; i++) {
                for (let j = 0; j < tbody.children[i].children.length; j++) {
                    const td = tbody.children[i].children[j]
                    if (tr.children[j]) {
                        const exclude = tr.children[j].getAttribute('exclude')
                        if (exclude !== 'true') {
                            csv += td.innerText + ','
                        }
                    }
                }
                csv = csv.substring(0, csv.length - 1) + '\n'
            }

            const date = new Date().toLocaleDateString('en-CA')
            const hiddenElement = document.createElement('a')
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
            hiddenElement.target = '_blank'
            hiddenElement.download = `${date} ParkUpp Export ${tableId}.csv`
            hiddenElement.click()
        }
    }

    toggleSideMenu() {
        GlobalService.globalSideMenuCollapsed = !GlobalService.globalSideMenuCollapsed
    }

    convertImageMapToJSON(imageMapHTML: string) {
        const parser = new DOMParser()
        const doc = parser.parseFromString(imageMapHTML, 'text/html')
        const imageMap = doc.querySelector('map[name]') as any
        const areas = imageMap.querySelectorAll('area')
        const jsonResult: any = []

        areas.forEach((area: any) => {
            const bayNumber = area.getAttribute('title')
            const coords = area.getAttribute('coords').split(',').map(Number)
            const x1 = coords[0]
            const y1 = coords[1]
            const x2 = coords[2]
            const y2 = coords[3]
            const width = Math.abs(x2 - x1)
            const height = Math.abs(y2 - y1)

            jsonResult.push({
                bayNumber,
                x: x1.toFixed(2),
                y: y1.toFixed(2),
                width: width.toFixed(2),
                height: height.toFixed(2),
            })
        })

        return jsonResult
    }

    serialize(object: any): any {
        const clone = { ...object } // Shallow copy of the object

        // Iterate over the properties of the object
        for (const key in clone) {
            // Remove properties that start with '$'
            if (clone.hasOwnProperty(key) && key.startsWith('$')) {
                delete clone[key]
                continue
            }

            // Check if the property is a Firestore Timestamp and handle it appropriately
            if (clone[key] instanceof Timestamp) {
                clone[key] = object[key]
                continue
            }

            // Check if the property is empty or undefined
            if (clone[key] === undefined) {
                delete clone[key]
                continue
            }

            // Preserve properties that end with 'ref' or 'refs' and are not empty
            if (key.endsWith('ref') || key.endsWith('refs') || (object[key] !== undefined && object[key] !== null && object[key] !== '')) {
                clone[key] = object[key]
            }
        }

        console.log('Serialized', clone)

        return clone
    }

    async moreInfoMerchantPortfolioConsolidation() {
        await Swal.fire({
            title: 'More Info',
            text: 'The Portfolio Consolidation feature on ParkUpp allows you to organize various businesses or groups of buildings into separate portfolios. This functionality enables you to categorize payout information, buildings, tenants, and other relevant details according to different business entities. While the use of separate portfolios is optional, it is particularly beneficial if you require different managers to oversee specific aspects of your profile. Utilize this feature as needed to streamline your management process.',
        })
    }

    async genericInfoModal(text: string) {
        await Swal.fire({ title: 'More Info', text })
    }

    showContextMenu(event?: MouseEvent) {
        this.contextMenuVisible = true
        if (event) {
            this.contextMenuX = event.pageX
            this.contextMenuY = event.pageY
            event.preventDefault()
        }
    }

    hideContextMenu() {
        this.contextMenuVisible = false
    }

    async redirectToRegister() {
        await this.router.navigate([`/sign-up`], {
            queryParams: { returnUrl: this.router.url },
        })
        return
    }

    static async getPublicIP() {
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        return data.ip
    }
}
