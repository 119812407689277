"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParkingLocation = void 0;
class ParkingLocation {
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
    get googleMapsUrl() {
        return `https://www.google.com/maps?q=${this.latitude},${this.longitude}`;
    }
    get fullAddress() {
        let address = '';
        if (this.streetNumber) {
            address = this.streetNumber + ' ';
        }
        if (this.streetAddress) {
            address = address + this.streetAddress + ' ';
        }
        if (this.suburb) {
            address = address + this.suburb + ' ';
        }
        if (this.city) {
            address = address + this.city;
        }
        return address;
    }
    get shortAddress() {
        let address = '';
        if (this.suburb) {
            address = address + this.suburb + ' ';
        }
        if (this.city) {
            address = address + this.city + ' ';
        }
        if (this.country) {
            address = address + this.country;
        }
        return address;
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        return JSON.parse(JSON.stringify(clone));
    }
}
exports.ParkingLocation = ParkingLocation;
