<div class="container-fluid">
    <div class="row g-3">
        <form [formGroup]="formGroup" (ngSubmit)="save()">
            <!-- <div class="row">
                <div class="col-12">
                    <h3>Account Details</h3>
                </div>
            </div> -->
            <div class="row">
                <div class="form-group col-md-6 mb-4">
                    <label>
                        Payout Frequency <span class="text-danger">*</span>
                        <i class="fa fa-info-circle" (click)="globalService.genericInfoModal(this.payoutFrequenciesHelpText)"></i>
                    </label>
                    <select id="payoutFrequency" formControlName="payoutFrequency" class="form-control" required>
                        <option *ngFor="let frequency of payoutFrequencies" [value]="frequency">{{ EnumNames.getPayoutFrequency(frequency) }}</option>
                    </select>
                    <div invalidMessage="payoutFrequency" class="invalid-feedback">{{ formGroup.get('payoutFrequency') | validationErrors }}</div>
                </div>
                <div class="form-group col-md-6 mb-4">
                    <label>
                        Payment on invoice <span class="text-danger">*</span>
                        <i class="fa fa-info-circle" (click)="globalService.genericInfoModal(this.paymentOnInvoiceHelpText)"></i>
                    </label>
                    <select id="paymentOnInvoice" formControlName="paymentOnInvoice" class="form-control" required>
                        <option *ngFor="let paymentOnInvoice of paymentOnInvoices" [value]="paymentOnInvoice">
                            {{ EnumNames.getPaymentOnInvoice(paymentOnInvoice) }}
                        </option>
                    </select>
                    <div invalidMessage="paymentOnInvoice" class="invalid-feedback">{{ formGroup.get('paymentOnInvoice') | validationErrors }}</div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 mb-4">
                    <label>Account Holder <span class="text-danger">*</span></label>
                    <input
                        type="text"
                        name="accountHolderName"
                        id="accountHolderName"
                        formControlName="accountHolderName"
                        placeholder="Account Holder"
                        class="form-control"
                        required
                    />
                    <div invalidMessage="accountHolderName" class="invalid-feedback">{{ formGroup.get('accountHolderName') | validationErrors }}</div>
                </div>
                <div class="form-group col-md-6 mb-4">
                    <label>Account Number <span class="text-danger">*</span></label>
                    <input
                        type="text"
                        name="accountNumber"
                        id="accountNumber"
                        formControlName="accountNumber"
                        placeholder="Account Number"
                        class="form-control"
                        required
                    />
                    <div invalidMessage="accountNumber" class="invalid-feedback">{{ formGroup.get('accountNumber') | validationErrors }}</div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 mb-4">
                    <label>Bank <span class="text-danger">*</span></label>
                    <ng-select
                        id="bank"
                        formControlName="bank"
                        class="form-control border-0 p-0 mt-2"
                        required
                        [items]="banks"
                        bindLabel="bank"
                        bindValue="bank"
                    >
                    </ng-select>
                    <div invalidMessage="bank" class="invalid-feedback">{{ formGroup.get('bank') | validationErrors }}</div>
                </div>
                <div class="form-group col-md-6 mb-4">
                    <label>Account Type <span class="text-danger">*</span></label>
                    <ng-select
                        id="accountType"
                        formControlName="accountType"
                        class="form-control border-0 p-0 mt-2"
                        required
                        [items]="accountTypes"
                        bindLabel="accountType"
                        bindValue="accountType"
                    >
                    </ng-select>
                    <div invalidMessage="accountType" class="invalid-feedback">{{ formGroup.get('accountType') | validationErrors }}</div>
                </div>
            </div>
            <div *ngIf="authenticationService.isAdmin" class="form-group mb-4 admin-only">
                <label>Beneficiary Name</label>
                <input
                    class="form-control"
                    formControlName="beneficiaryName"
                    id="beneficiaryName"
                    name="beneficiaryName"
                    placeholder="Beneficiary Name"
                    type="text"
                />
            </div>
            <!-- <div class="row">
                <div class="col-12">
                    <h3>Billing Address</h3>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12 mb-4">
                    <label>Country</label>
                    <ng-select
                        id="billingAddressCountry"
                        formControlName="billingAddressCountry"
                        class="form-control border-0 p-0 mt-2"
                        required
                        [items]="countries"
                        bindLabel="billingAddressCountry"
                        bindValue="billingAddressCountry"
                    >
                    </ng-select>
                    <div invalidMessage="billingAddressCountry" class="invalid-feedback">{{ formGroup.get('billingAddressCountry') | validationErrors }}</div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 mb-4">
                    <label>Street Number</label>
                    <input
                        type="text"
                        name="billingAddressStreetNumber"
                        id="billingAddressStreetNumber"
                        formControlName="billingAddressStreetNumber"
                        placeholder="Street Number"
                        class="form-control"
                        required
                    />
                    <div invalidMessage="billingAddressStreetNumber" class="invalid-feedback">{{ formGroup.get('billingAddressStreetNumber') | validationErrors }}</div>
                </div>
                <div class="form-group col-md-6 mb-4">
                    <label>Street Address</label>
                    <input
                        type="text"
                        name="billingAddressStreetAddress"
                        id="billingAddressStreetAddress"
                        formControlName="billingAddressStreetAddress"
                        placeholder="Street Address"
                        class="form-control"
                        required
                    />
                    <div invalidMessage="billingAddressStreetAddress" class="invalid-feedback">{{ formGroup.get('billingAddressStreetAddress') | validationErrors }}</div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 mb-4">
                    <label>Suburb</label>
                    <input type="text" name="billingAddressSuburb" id="billingAddressSuburb" formControlName="billingAddressSuburb" placeholder="Suburb" class="form-control" required />
                    <div invalidMessage="billingAddressSuburb" class="invalid-feedback">{{ formGroup.get('billingAddressSuburb') | validationErrors }}</div>
                </div>
                <div class="form-group col-md-6 mb-4">
                    <label>City</label>
                    <input type="text" name="billingAddressCity" id="billingAddressCity" formControlName="billingAddressCity" placeholder="City" class="form-control" required />
                    <div invalidMessage="billingAddressCity" class="invalid-feedback">{{ formGroup.get('billingAddressCity') | validationErrors }}</div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 mb-4">
                    <label>Province</label>
                    <input type="text" name="billingAddressProvince" id="billingAddressProvince" formControlName="billingAddressProvince" placeholder="Province" class="form-control" required />
                    <div invalidMessage="billingAddressProvince" class="invalid-feedback">{{ formGroup.get('billingAddressProvince') | validationErrors }}</div>
                </div>
                <div class="form-group col-md-6 mb-4">
                    <label>Postal Code</label>
                    <input
                        type="text"
                        name="billingAddressPostalCode"
                        id="billingAddressPostalCode"
                        formControlName="billingAddressPostalCode"
                        placeholder="Postal Code"
                        class="form-control"
                        required
                    />
                    <div invalidMessage="billingAddressPostalCode" class="invalid-feedback">{{ formGroup.get('billingAddressPostalCode') | validationErrors }}</div>
                </div>
            </div> -->
            <div *ngIf="!valid && formGroup.invalid" class="alert alert-info">Please complete required fields</div>
            <div *ngIf="error" class="alert alert-warning">
                {{ error }}
            </div>
            <button
                [disabled]="loading || formGroup.invalid"
                type="submit"
                class="btn btn-primary mb-3"
                [ngClass]="{ 'btn-secondary': formGroup.invalid, 'btn-primary': formGroup.valid }"
            >
                <ng-container *ngIf="loading"> <i class="fa fa-circle-notch fa-spin"></i> Updating... </ng-container>
                <ng-container *ngIf="!loading">
                    <span>Save</span>
                </ng-container>
            </button>
        </form>
    </div>
</div>
