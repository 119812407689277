"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayGateResultCode = void 0;
var PayGateResultCode;
(function (PayGateResultCode) {
    PayGateResultCode[PayGateResultCode["Call for Approval"] = 900001] = "Call for Approval";
    PayGateResultCode[PayGateResultCode["Card Expired"] = 900002] = "Card Expired";
    PayGateResultCode[PayGateResultCode["Insufficient Funds"] = 900003] = "Insufficient Funds";
    PayGateResultCode[PayGateResultCode["Invalid Card Number"] = 900004] = "Invalid Card Number";
    PayGateResultCode[PayGateResultCode["Bank Interface Timeout"] = 900005] = "Bank Interface Timeout";
    PayGateResultCode[PayGateResultCode["Invalid Card"] = 900006] = "Invalid Card";
    PayGateResultCode[PayGateResultCode["Declined"] = 900007] = "Declined";
    PayGateResultCode[PayGateResultCode["Lost Card"] = 900009] = "Lost Card";
    PayGateResultCode[PayGateResultCode["Invalid Card Length"] = 900010] = "Invalid Card Length";
    PayGateResultCode[PayGateResultCode["Suspected Fraud"] = 900011] = "Suspected Fraud";
    PayGateResultCode[PayGateResultCode["Card Reported as Stolen"] = 900012] = "Card Reported as Stolen";
    PayGateResultCode[PayGateResultCode["Restricted Card"] = 900013] = "Restricted Card";
    PayGateResultCode[PayGateResultCode["Excessive Card Usage"] = 900014] = "Excessive Card Usage";
    PayGateResultCode[PayGateResultCode["Card Blacklisted"] = 900015] = "Card Blacklisted";
    PayGateResultCode[PayGateResultCode["Declined authentication failed"] = 900207] = "Declined authentication failed";
    PayGateResultCode[PayGateResultCode["Auth Declined"] = 990020] = "Auth Declined";
    PayGateResultCode[PayGateResultCode["3D Secure Lookup Timeout"] = 900210] = "3D Secure Lookup Timeout";
    PayGateResultCode[PayGateResultCode["Invalid expiry date"] = 991001] = "Invalid expiry date";
    PayGateResultCode[PayGateResultCode["Invalid Amount"] = 991002] = "Invalid Amount";
    PayGateResultCode[PayGateResultCode["Auth Done"] = 990017] = "Auth Done";
    // Undocumented Result Codes
    PayGateResultCode[PayGateResultCode["Transaction Settled"] = 990004] = "Transaction Settled";
    PayGateResultCode[PayGateResultCode["Settlement record already exists"] = 202] = "Settlement record already exists";
    PayGateResultCode[PayGateResultCode["Transaction Voided"] = 990026] = "Transaction Voided";
    PayGateResultCode[PayGateResultCode["Transaction Already Voided"] = 401] = "Transaction Already Voided";
    PayGateResultCode[PayGateResultCode["Card type not supported"] = 104] = "Card type not supported";
    PayGateResultCode[PayGateResultCode["notProvided"] = 105] = "notProvided";
})(PayGateResultCode = exports.PayGateResultCode || (exports.PayGateResultCode = {}));
