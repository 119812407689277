"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegacyCredentials = void 0;
class LegacyCredentials {
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        return JSON.parse(JSON.stringify(clone));
    }
}
exports.LegacyCredentials = LegacyCredentials;
