import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core'
import { parkingsPath, Parking, ParkingStatus, ParkingType } from '@parkupp/core'
import { ParkingService } from '../../../services/parking.service'
import { collection, collectionData, limit, orderBy, query, where } from '@angular/fire/firestore'

@Component({
    selector: 'app-ticker-tape',
    templateUrl: './ticker-tape.component.html',
    styleUrls: ['./ticker-tape.component.scss'],
})
export class TickerTapeComponent implements AfterViewInit {
    @ViewChild('tickerContent') tickerContent: ElementRef

    duplicatedItems: Parking[] = []
    limitValue = 10
    mobile: boolean = false
    width = '20vw'

    constructor(private parkingService: ParkingService) {
        if (window.screen.width <= 1512) {
            this.width = '25vw'
        }
        if (window.screen.width <= 1000) {
            this.width = '40vw'
        }
        if (window.screen.width <= 768) {
            // 768px portrait
            this.width = '80vw'
            this.mobile = true
        }

        const parkingsQuery = query(
            collection(this.parkingService.firestore, parkingsPath()),
            where('parkingStatus', '==', ParkingStatus.PUBLISHED),
            where('parkingType', '==', ParkingType.STANDARD),
            orderBy('createdAt', 'desc'),
            limit(this.limitValue)
        )

        const subscription = collectionData(parkingsQuery, { idField: 'documentId' }).subscribe((documents: any[]) => {
            subscription.unsubscribe()

            const fetchedParkings: Parking[] = documents
                .map((parkingData) => {
                    let parking = new Parking(parkingData)
                    parking.$key = parkingData.documentId
                    return parking
                })
                .filter((parking) => parking.merchantRef)

            this.duplicatedItems = [...fetchedParkings, ...fetchedParkings]
        })
    }

    ngAfterViewInit(): void {
        if (!this.mobile) {
            const element = this.tickerContent.nativeElement
            const animation = element.animate([{ transform: 'translateX(0)' }, { transform: 'translateX(-20%)' }], {
                duration: 20000,
                iterations: Infinity,
            })
        }
    }
}
