import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MerchantService } from '../../../services/merchant.service'
import {
    AccountType,
    Bank,
    BankDetails,
    bankingDetailsPath,
    Country,
    EnumNames,
    Merchant,
    PaymentOnInvoiceEnum,
    PayoutFrequencyEnum,
} from '@parkupp/core'
import { ActivatedRoute, Router } from '@angular/router'
import { doc, docSnapshots } from '@angular/fire/firestore'
import { Subscription } from 'rxjs'
import { GlobalService } from 'src/app/services/global.service'
import { AuthenticationService } from 'src/app/services/authentication.service'

@Component({
    selector: 'app-merchant-banking-details-form',
    templateUrl: './merchant-banking-details-form.component.html',
    styleUrls: ['./merchant-banking-details-form.component.scss'],
})
export class MerchantBankingDetailsFormComponent implements OnInit {
    EnumNames = EnumNames
    @Input() merchantKey: string
    @Input() bankDetailsModal: boolean
    @Output() onSaved = new EventEmitter<BankDetails>()
    bankDetailsKey: string
    formGroup: FormGroup
    valid: boolean = true
    merchant: Merchant
    loading: boolean = false
    error: string | null
    accountTypes: AccountType[]
    banks: Bank[]
    countries: Country[]
    bankDetailsSubscription: Subscription
    returnUrl: string
    bankDetailsArray: BankDetails[] = []
    bankDetails: BankDetails
    // payoutFrequencies: { key: string; value: PayoutFrequencyEnum }[] = Object.keys(PayoutFrequencyEnum).map((key) => ({ key, value: PayoutFrequencyEnum[key as keyof typeof PayoutFrequencyEnum] }))
    payoutFrequencies: PayoutFrequencyEnum[] = Object.values(PayoutFrequencyEnum)
    payoutFrequenciesHelpText: string =
        'For monthly payouts, funds will be disbursed on the first of the month or as close to it as possible. For immediate payouts, you can expect payment within two to three days of the date when the payer can process the payment.'
    paymentOnInvoices: PaymentOnInvoiceEnum[] = Object.values(PaymentOnInvoiceEnum)
    paymentOnInvoiceHelpText: string =
        'ParkUpp makes the payment on generation of the proforma. If you want ParkUpp to wait for invoice before making the payment, select "ParkUpp waits for invoice before making the payment".'

    constructor(
        private formBuilder: FormBuilder,
        private merchantService: MerchantService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public globalService: GlobalService,
        public authenticationService: AuthenticationService
    ) {
        this.banks = Object.values(Bank)
        this.accountTypes = Object.values(AccountType)
        this.countries = Object.values(Country)

        this.formGroup = this.formBuilder.group({
            bank: ['', [Validators.required]],
            accountType: ['', [Validators.required]],
            accountHolderName: ['', [Validators.required]],
            accountNumber: ['', [Validators.required]],
            payoutFrequency: [PayoutFrequencyEnum.IMMEDIATE, [Validators.required]],
            paymentOnInvoice: [PaymentOnInvoiceEnum.NONE, [Validators.required]],
            beneficiaryName: [''],
        })
    }

    ngOnInit(): void {
        this.merchantService.getMerchant(this.merchantKey).then((merchant: Merchant) => {
            this.merchant = merchant

            if (this.merchant.payoutDetails) {
                // this.bankDetails = new BankDetails(this.merchant.payoutDetails)
                this.formGroup.patchValue(this.merchant.payoutDetails)
            } else {
                this.bankDetails = new BankDetails()
            }
        })
    }

    async save() {
        this.loading = true
        if (this.formGroup.valid) {
            this.merchant.payoutDetails = new BankDetails({ ...this.bankDetails, ...this.formGroup.value })

            await this.merchantService.createOrUpdateMerchant(this.merchant)

            this.loading = false
        }
    }
}
