<!-- PayfastPaymentFormComponent Template -->
<form action="{{ payfast_url }}" method="post">
    <!-- Use *ngFor to iterate over formDataArray and create inputs -->
    <ng-container *ngFor="let item of formDataArray">
        <input *ngIf="item.value !== ''" [name]="item.key" [value]="item.value" type="hidden" />
    </ng-container>

    <button class="payfast-pay-now" type="submit">
        <i class="far fa-credit-card"></i> Pay with PayFast
    </button>

    
</form>
