"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Payment = void 0;
class Payment {
    constructor(source, key) {
        this.paymentFail = false;
        this.settled = false;
        this.voided = false;
        this.paidOut = false;
        this.refunded = false;
        this.comments = '';
        if (source) {
            Object.assign(this, source);
        }
        if (key) {
            this.$key = key;
        }
    }
    get amountMinusCommission() {
        return this.amountInCents - this.amountInCents * 0.15;
    }
    get formatCentsToRand() {
        const rand = (this.amountInCents / 100).toFixed(2);
        return `R${rand}`;
    }
    get status() {
        if (this.paymentFail) {
            return 'Failed';
        }
        if (this.settled) {
            return 'Settled';
        }
        if (this.voided) {
            return 'Refunded';
        }
        return 'Funds Held';
    }
    get statusBadgeColour() {
        if (this.status == 'Settled') {
            return 'bg-success text-dark';
        }
        if (this.status == 'Funds Held') {
            return 'bg-warning text-dark';
        }
        if (this.status == 'Refunded' || this.status == 'Failed') {
            return 'bg-danger';
        }
        return 'bg-warning text-dark ';
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        delete clone.$merchant;
        delete clone.$client;
        delete clone.$parking;
        delete clone.$subscription;
        delete clone.$invoice;
        const payment = JSON.parse(JSON.stringify(clone));
        if (this.merchantRef) {
            payment.merchantRef = this.merchantRef;
        }
        if (this.clientRef) {
            payment.clientRef = this.clientRef;
        }
        if (this.parkingRef) {
            payment.parkingRef = this.parkingRef;
        }
        if (this.subscriptionRef) {
            payment.subscriptionRef = this.subscriptionRef;
        }
        if (this.settlement) {
            payment.settlement = this.settlement.parse();
        }
        if (this.invoiceRef) {
            payment.invoiceRef = this.invoiceRef;
        }
        payment.createdAt = this.createdAt;
        payment.updatedAt = this.updatedAt;
        return payment;
    }
}
exports.Payment = Payment;
