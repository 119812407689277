"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayGateCard = void 0;
class PayGateCard {
    constructor(source) {
        this.budgetPeriod = 0;
        if (source) {
            Object.assign(this, source);
        }
    }
}
exports.PayGateCard = PayGateCard;
