import { Component, OnInit } from '@angular/core'
import { collection, collectionData, limit, orderBy, query } from '@angular/fire/firestore'
import { Client, ClientPrivate, clientsPath, clientsPrivatePath } from '@parkupp/core'
import { AdminService } from '../../../services/admin.service'
import { HttpClient } from '@angular/common/http'
import { AuthenticationService } from '../../../services/authentication.service'
import { Auth } from '@angular/fire/auth'
import { ToastrService } from 'ngx-toastr'
import { FormBuilder, FormGroup } from '@angular/forms'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Router } from '@angular/router'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { GlobalService } from '../../../services/global.service'

@Component({
    selector: 'app-admin-list-clients',
    templateUrl: './admin-list-clients.component.html',
    styleUrls: ['./admin-list-clients.component.scss'],
})
export class AdminListClientsComponent implements OnInit {
    formGroup: FormGroup
    clients: Client[]
    clientsPrivate: ClientPrivate[]
    clientsMap: { [id: string]: ClientPrivate }
    modalRef?: BsModalRef
    loading = false
    clientKey: string

    constructor(
        public globalService: GlobalService,
        public auth: Auth,
        private adminSvc: AdminService,
        private router: Router,
        private http: HttpClient,
        private toastrService: ToastrService,
        private formBuilder: FormBuilder,
        private authService: AuthenticationService,
        private modalService: BsModalService
    ) {
        this.formGroup = this.formBuilder.group({
            password: [''],
        })
    }

    ngOnInit(): void {
        this.getClientsWithPrivateInfo()
    }

    getClientsWithPrivateInfo() {
        const clientsRef = query(collection(this.adminSvc.firestore, clientsPath()), orderBy('createdAt', 'desc'), limit(2000))
        const clientsPrivateRef = query(collection(this.adminSvc.firestore, clientsPrivatePath()), orderBy('createdAt', 'desc'))

        combineLatest([collectionData(clientsRef, { idField: 'documentId' }), collectionData(clientsPrivateRef, { idField: 'documentId' })])
            .pipe(
                map(([clientsData, clientsPrivateData]) => {
                    const clientsPrivateMap = new Map(clientsPrivateData.map((clientPrivate: any) => [clientPrivate.documentId, clientPrivate]))

                    return clientsData.map((clientData: any) => {
                        const clientPrivate = clientsPrivateMap.get(clientData.documentId)

                        if (clientPrivate) {
                            return {
                                ...clientData,
                                // ...clientPrivate,
                                $key: clientData.documentId,
                                $email: clientPrivate.email,
                                $phoneNumber: clientPrivate.phoneNumber,
                            }
                        }

                        return {
                            ...clientData,
                            $key: clientData.documentId,
                        }
                    })
                })
            )
            .subscribe((clients: any[]) => {
                this.clients = clients
            })
    }

    closeSaveFormModal() {
        this.modalRef?.hide()
    }

    openModal(template: any, clientKey: string) {
        this.clientKey = clientKey
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' })
    }

    onSubmit() {
        this.loading = true
        this.changeClientPassword(this.clientKey)
    }

    changeClientPassword(clientKey: any) {
        this.authService.user.getIdToken(true).then((idToken) => {
            this.authService.changeClientPassword(clientKey, this.formGroup.value.password, idToken).subscribe((response) => {
                if (response.successful) {
                    this.loading = false
                    this.closeSaveFormModal()
                    this.toastrService.success('', response.displayMessage)
                } else {
                    this.loading = false
                    this.toastrService.error('Error', response.error)
                }
            })
        })
    }

    deputize(client: Client) {
        const email = this.clientsMap[client.$key!].email
        this.authService.user.getIdToken(true).then((idToken) => {
            console.log(idToken)
            this.authService.createCustomToken(email, idToken).subscribe((response) => {
                this.authService.signInWithCustomToken(response.token).then((userCredential) => {
                    if (userCredential.user) {
                        this.router.navigate(['/'])
                    }
                })
            })
        })
    }

    lastLoginwithin60Days(client: Client) {
        const lastLogin = client.lastLogin
        const sixtyDaysAgo = new Date(Date.now() - 60 * 24 * 60 * 60 * 1000)
        return lastLogin && lastLogin.toDate() > sixtyDaysAgo
    }
}
