<admin-app-header></admin-app-header>
<div class="container-fluid">
    <div class="content col-lg-12">
        <div class="row">
            <ng-container *ngIf="logEmail">
                <div class="col-12 col-md-6">
                    <h1>Email Details</h1>
                    <div class="email-header">
                        <div><strong>From:</strong> ParkUpp</div>
                        <div><strong>To:</strong> {{ logEmail.toEmails.join(', ') }}</div>
                        <div><strong>Subject:</strong> {{ logEmail.subject }}</div>
                    </div>
                    <div class="email-content">
                        <div [innerHTML]="logEmail.content"></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
