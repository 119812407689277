import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DragDropComponent } from './drag-drop/drag-drop.component'
import { ImageCropperModule } from 'ngx-image-cropper'
import { ParkuppImageCropperComponent } from './image-cropper/parkupp-image-cropper.component'
import { ModalModule } from 'ngx-bootstrap/modal'

@NgModule({
    declarations: [DragDropComponent, ParkuppImageCropperComponent],
    imports: [CommonModule, ImageCropperModule, ModalModule.forRoot()],
    exports: [DragDropComponent, ParkuppImageCropperComponent],
})
export class MediaModule {}
