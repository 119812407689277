<div class="content col-lg-12">
    <table class="table table-striped table-hover table-sm" id="all-clients">
        <thead>
            <tr>
                <th scope="col" (click)="sortData('createdAt')">Created</th>
                <th scope="col" (click)="sortData('auth')">Auth</th>
                <th scope="col" (click)="sortData('status')">Status</th>
                <th scope="col" (click)="sortData('leadStatus')">Lead Status</th>
                <th scope="col" (click)="sortData('leadClosedReason')">Closed Reason</th>
                <th scope="col" (click)="sortData('userResponsible')">User</th>
                <th scope="col" (click)="sortData('numberOfBays')">Bays</th>
                <th scope="col" (click)="sortData('city')">City</th>
                <th scope="col" (click)="sortData('firstName')">Name</th>
                <th scope="col" (click)="sortData('companyName')">Company</th>
                <th scope="col" (click)="sortData('email')">Email</th>
                <th scope="col" (click)="sortData('phoneNumber')">Phone</th>
                <th scope="col" (click)="sortData('breachedSubscriptionsCount')">Breached Subs</th>
                <th scope="col" (click)="sortData('completedSubscriptionsCount')">Completed Subs</th>
                <th scope="col" (click)="sortData('activeSubscriptionExpiry')">Current Sub</th>
                <th scope="col" (click)="sortData('hasFutureSubscription')">Future Sub</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let adminClient of filteredAdminClients; let i = index">
                <tr (contextmenu)="onContextMenu($event, adminClient)">
                    <td>
                        {{ adminClient.createdAt | formatDate }}
                    </td>
                    <td>
                        <span *ngIf="adminClient.noAuth" class="badge bg-danger"> None </span>
                        <ng-container *ngIf="!adminClient.noAuth">
                            <span *ngFor="let authType of adminClient.authTypes" class="">
                                <i *ngIf="authType === 'phone'" class="me-1 fas fa-phone"></i>
                                <i *ngIf="authType === 'gmail'" class="me-1 fab fa-google"></i>
                                <i *ngIf="authType === 'facebook'" class="me-1 fab fa-facebook-f"></i>
                                <i *ngIf="authType === 'email'" class="me-1 fas fa-envelope"></i>
                            </span>
                        </ng-container>
                    </td>
                    <td>
                        <span [ngClass]="adminClient.hasEverHadActiveOrCompletedSubscription ? 'bg-success' : 'bg-danger'" class="badge">
                            {{ adminClient.hasEverHadActiveOrCompletedSubscription ? 'Parker' : 'Contact' }}
                        </span>
                    </td>
                    <td>
                        <ng-container *ngIf="adminClient.leadStatus">
                            <span [ngClass]="adminClient.getLeadStatusClass()" class="badge">
                                {{ adminClient.leadStatus }}
                            </span>
                        </ng-container>
                    </td>
                    <td>
                        <span *ngIf="adminClient.leadClosedReason">
                            {{ adminClient.leadClosedReason }}
                        </span>
                    </td>
                    <td>
                        <span *ngIf="adminClient.userResponsible" class="badge user-{{ adminClient.userResponsible.toLowerCase() }}">
                            {{ adminClient.userResponsible }}
                        </span>
                    </td>
                    <td>
                        {{ adminClient.numberOfBays }}
                    </td>
                    <td>
                        {{ adminClient.city }}
                    </td>
                    <td>
                        <a [routerLink]="['/client/', adminClient.$key]" class="text-decoration-underline">
                            <span [ngClass]="{ undefined: !adminClient.firstName }">{{ adminClient.firstName || 'Not set' }}</span>
                            {{ adminClient.lastName }}
                        </a>
                    </td>
                    <td>
                        <span title=" {{ adminClient.companyName }}">
                            {{ adminClient.companyName | truncate: [30, '...'] }}
                        </span>
                    </td>
                    <td>
                        <ng-container *ngIf="adminClient.email">
                            {{ adminClient.email }}
                            <a (click)="sendEmail(adminClient.email)" class="px-1">
                                <i class="fa fa-envelope" style="color: #007bff"></i>
                            </a>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngIf="adminClient.phoneNumber">
                            {{ adminClient.phoneNumber }}
                            <a (click)="callPhone(adminClient.phoneNumber)" class="px-1">
                                <i class="fa fa-phone" style="color: #d00000"></i>
                            </a>
                            <a (click)="sendWhatsApp(adminClient.phoneNumber)" class="px-1">
                                <i class="fab fa-whatsapp-square" style="color: #25d366"></i>
                            </a>
                        </ng-container>
                    </td>
                    <td>
                        {{ adminClient.breachedSubscriptionsCount }}
                    </td>
                    <td>
                        {{ adminClient.completedSubscriptionsCount }}
                    </td>
                    <td>
                        <span [ngClass]="adminClient.hasActiveSubscription ? 'bg-success' : 'bg-danger'" class="badge">
                            {{ adminClient.hasActiveSubscription ? (adminClient.activeSubscriptionExpiry | daysUntil) : 'No' }}
                        </span>
                    </td>
                    <td>
                        <span [ngClass]="adminClient.hasFutureSubscription ? 'bg-success' : 'bg-danger'" class="badge">
                            {{ adminClient.hasFutureSubscription ? 'Yes' : 'No' }}
                        </span>
                    </td>
                    <!-- <td>
                        {{ adminClient.totalSpend | currency: 'R' }}
                    </td> -->
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
