"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OmnisendProduct = void 0;
class OmnisendProduct {
    // variantTitle: 'Container for mojo - aliuminium/black',
    // weight: 1000,
    // categoryIDs: ['cat1', 'cat1-23'],
    // tags: ['containers']
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
}
exports.OmnisendProduct = OmnisendProduct;
