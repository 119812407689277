import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { addDoc, collection, doc, docData, serverTimestamp, Timestamp, updateDoc } from '@angular/fire/firestore'
import { Client, ContractStatus, Merchant, merchantsPath, Parking, parkingsPath, Plan, Subscription, subscriptionsPath, SubscriptionType, Term } from '@parkupp/core'
import { AdminService } from '../../../services/admin.service'
import * as moment from 'moment'
import * as firestore from 'firebase/firestore'
import { ActivatedRoute, Router } from '@angular/router'
import { MerchantService } from '../../../services/merchant.service'
import { ParkingService } from '../../../services/parking.service'
import { ClientService } from '../../../services/client.service'
import { SubscriptionService } from '../../../services/subscription.service'
import Swal from 'sweetalert2'
import { AdminChangeLog, ChangeLogsEntityEnum } from '@parkupp/core'
import { AdminChangeLogService } from 'src/app/services/admin-change-log.service'
import { AuthenticationService } from 'src/app/services/authentication.service'
@Component({
    selector: 'app-admin-subscription-manual-create',
    templateUrl: './admin-subscription-manual-create.component.html',
    styleUrls: ['./admin-subscription-manual-create.component.scss'],
})
export class AdminSubscriptionManualCreateComponent implements OnInit {
    subscriptionKey: string
    formData: FormGroup
    formLoading: boolean = false
    subscription: Subscription
    merchantLookup: Merchant[]
    parkingLookup: Parking[]
    clientLookup: any[]

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private adminService: AdminService,
        private activatedRoute: ActivatedRoute,
        private merchantService: MerchantService,
        private parkingService: ParkingService,
        private clientService: ClientService,
        private subscriptionService: SubscriptionService,
        private adminChangeLogService: AdminChangeLogService,
        private authService: AuthenticationService
    ) {
        this.subscriptionKey = this.activatedRoute.snapshot.params['subscriptionKey']

        this.formData = this.formBuilder.group({
            startDate: [moment().add(1, 'M').startOf('month').format('yyyy-MM-DD'), []],
            endDate: [moment().add(1, 'M').endOf('month').format('yyyy-MM-DD'), []],
            baysBooked: [1, []],
            feeInRands: ['', []],
            contractLength: [1, []],
            merchantName: [''],
            merchantKey: ['', [Validators.required]],
            parkingName: [''],
            parkingKey: ['', [Validators.required]],
            clientName: [''],
            clientKey: ['', [Validators.required]],
        })

        this.formData.controls['contractLength'].valueChanges.subscribe((value) => this.formChanges({ contractLength: value }))
        this.formData.controls['startDate'].valueChanges.subscribe((value) => this.formChanges({ startDate: value }))

        if (this.subscriptionKey) {
            this.getEditingSubscription(this.subscriptionKey)
        } else {
            this.subscription = new Subscription()
        }

        this.merchantService.list().subscribe((merchants) => {
            this.merchantLookup = merchants
        })

        this.clientService.list().subscribe((clients) => {
            this.clientLookup = clients.map((client) => ({
                ...client,
                searchField: `${client.firstName} ${client.lastName} ${client.companyName}`,
            }))
        })
    }

    getParkings(merchantKey: string) {
        const merchantRef = doc(this.adminService.firestore, merchantsPath(), merchantKey)
        this.parkingService.listByMerchantRef(merchantRef).subscribe((parkings) => {
            this.parkingLookup = parkings
        })
    }

    getEditingSubscription(subscriptionKey: string) {
        docData(doc(this.adminService.firestore, subscriptionsPath(), subscriptionKey)).subscribe((documentData: any) => {
            this.subscription = new Subscription(documentData)
            this.subscription.$key = subscriptionKey

            this.formData.patchValue({
                startDate: moment(this.subscription.startDate.toDate()).format('yyyy-MM-DD'),
                endDate: moment(this.subscription.endDate.toDate()).format('yyyy-MM-DD'),
                baysBooked: this.subscription.baysBooked,
                feeInRands: this.subscription.plan.feeInCents / 100,
                contractLength: this.subscription.period,
            })

            if (this.subscription.clientRef) {
                this.formData.controls['clientKey'].setValue(this.subscription.clientRef.id)
            }

            if (this.subscription.merchantRef) {
                this.formData.controls['merchantKey'].setValue(this.subscription.merchantRef.id)
                this.getParkings(this.subscription.merchantRef.id)
            }

            if (this.subscription.parkingRef) {
                this.formData.controls['parkingKey'].setValue(this.subscription.parkingRef.id)
            }
        })
    }

    ngOnInit(): void {}

    formChanges(changes: { contractLength?: number; startDate?: number }) {
        let contractLength = changes.contractLength ? changes.contractLength : this.formData.value.contractLength
        let startDate = changes.startDate ? changes.startDate : this.formData.value.startDate

        if (changes.contractLength || changes.startDate) {
            this.updateEndDate(Term.MONTHLY, startDate, contractLength)
        }
    }

    updateEndDate(term: string, startDate: Date, contractLength: any) {
        let lengthUnit = 'M'
        if (term == Term.MONTHLY) {
            lengthUnit = 'M'
        }
        if (term == Term.DAILY) {
            lengthUnit = 'd'
        }
        if (term == Term.ONCEOFF) {
            lengthUnit = 'd'
        }

        const newEndDate = moment(startDate).add(contractLength, lengthUnit).subtract(1, 'day')
        this.formData.controls['endDate'].setValue(newEndDate.format('yyyy-MM-DD'))
    }

    async onSubmit() {
        this.formLoading = true

        if (!this.subscription.$key) {
            this.subscription.createdAt = serverTimestamp() as firestore.Timestamp
            this.subscription.updatedAt = serverTimestamp() as firestore.Timestamp
        } else {
            this.logSubscriptionUpdate()
        }

        const plan = new Plan({
            feeInCents: this.formData.value.feeInRands * 100,
            term: Term.MONTHLY,
        })

        if (!this.subscription.type) {
            this.subscription.type = SubscriptionType.EFT
        }

        this.subscription.baysBooked = this.formData.value.baysBooked
        this.subscription.startDate = firestore.Timestamp.fromDate(new Date(this.formData.value.startDate))
        this.subscription.endDate = firestore.Timestamp.fromDate(new Date(this.formData.value.endDate))
        this.subscription.status = ContractStatus.active
        this.subscription.plan = plan
        this.subscription.period = this.formData.value.contractLength
        this.subscription.manualParkingName = this.formData.value.manualParkingName

        if (this.formData.value.clientKey) {
            const clientRef = this.clientService.getDocRef(this.formData.value.clientKey)
            this.subscription.clientRef = clientRef as any

            const client = await this.clientService.getClientByUid(this.formData.value.clientKey)
            if (client) {
                this.subscription.denormClientFirstName = client.firstName
                this.subscription.denormClientLastName = client.lastName
            }
        }

        if (this.formData.value.merchantKey) {
            const merchantRef = this.merchantService.getDocRef(this.formData.value.merchantKey)
            this.subscription.merchantRef = merchantRef as any

            const merchant: Merchant = await this.merchantService.getMerchant(this.formData.value.merchantKey)
            this.subscription.denormMerchantCompanyName = merchant.companyName
        }

        if (this.formData.value.parkingKey) {
            const parkingRef = this.parkingService.getDocRef(this.formData.value.parkingKey)
            this.subscription.parkingRef = parkingRef as any

            const parking: Parking = await this.parkingService.getStatic(this.formData.value.parkingKey)
            this.subscription.denormParkingTitle = parking.title
        }

        if (this.subscription.$key) {
            updateDoc(doc(this.adminService.firestore, subscriptionsPath(), this.subscription.$key), this.subscription.parse()).then(() => {
                this.formLoading = false
                this.router.navigate([`/admin/subscriptions`])
                return
            })
        } else {
            addDoc(collection(this.adminService.firestore, subscriptionsPath()), this.subscription.parse()).then((docRef) => {
                this.formLoading = false
                this.router.navigate([`/admin/subscriptions`])
                return
            })
        }
    }

    async logSubscriptionUpdate() {
        const changedFieldsString = this.getChangedFieldsString(this.subscription, this.formData.value)
        console.log(changedFieldsString)
        const user = await this.authService.waitForUid()
        const admin = await this.clientService.getClientByUid(user.uid)
        if (admin) {
            const adminRef = this.clientService.getDocRef(admin.$key!)
            const subscriptionRef = this.subscriptionService.getDocRef(this.subscription.$key!)

            const changeLog = new AdminChangeLog({
                updatedEntity: ChangeLogsEntityEnum.SUBSCRIPTION,
                subscriptionRef: subscriptionRef as any,
                adminRef: adminRef as any,
                comment: `Subscription edited by ${admin.firstName}, fields: ${changedFieldsString}`,
                createdAt: serverTimestamp() as Timestamp,
            })
            await this.adminChangeLogService.addOrUpdate(changeLog)
        }
    }

    getChangedFieldsString(subscription: Subscription, formData: any) {
        console.log(subscription, formData)
        const changedFields: any[] = []
        if (subscription.baysBooked != formData.baysBooked) {
            changedFields.push({ field: 'baysBooked', from: subscription.baysBooked, to: formData.baysBooked })
        }
        if (subscription.plan) {
            if (subscription.plan.feeInCents != formData.feeInRands * 100) {
                changedFields.push({ field: 'feeInRands', from: subscription.plan.feeInCents / 100, to: formData.feeInRands })
            }
        }
        return JSON.stringify(changedFields)
    }

    onMerchantSelect(selectedMerchant: Merchant): void {
        if (selectedMerchant) {
            this.formData.controls['merchantKey'].setValue(selectedMerchant.$key)
            this.getParkings(selectedMerchant.$key!)
        }
        this.formData.controls['parkingName'].setValue('')
        this.formData.controls['parkingKey'].setValue('')
    }

    onParkingSelect(event: any): void {
        if (event) {
            const parkingKey = event.target.value
            this.formData.controls['parkingKey'].setValue(parkingKey)
        }
    }

    onClientSelect(selectedClient: Client): void {
        if (selectedClient) {
            this.formData.controls['clientKey'].setValue(selectedClient.$key)
        }
    }

    isMerchantSelected(): boolean {
        return this.formData.value.merchantKey !== ''
    }
}
