"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogSearch = void 0;
class LogSearch {
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        clone.createdAt = this.createdAt;
        return clone;
    }
}
exports.LogSearch = LogSearch;
