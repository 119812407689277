import { Component, OnInit } from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { Client, ContractStatus, Merchant, Parking, Subscription, subscriptionsPath } from '@parkupp/core'
import { collection, collectionData, docData, DocumentData, orderBy, Query, query } from '@angular/fire/firestore'
import { ActivatedRoute, Router } from '@angular/router'
import { GlobalService } from '../../../services/global.service'

@Component({
    selector: 'app-admin-all-manual',
    templateUrl: './admin-all-manual.component.html',
    styleUrls: ['./admin-all-manual.component.scss'],
})
export class AdminAllManualComponent implements OnInit {
    subscriptions: Subscription[]
    inactiveContractStatuses: ContractStatus[]
    ref: Query<DocumentData>
    status: string
    today: number

    constructor(public globalService: GlobalService, private route: ActivatedRoute, private router: Router, private adminService: AdminService) {
        this.today = new Date().getTime()
        this.route.queryParams.subscribe((params) => {
            if (params['status']) {
                this.status = params['status']
            } else if (localStorage.getItem('adminListSubscriptionsStatus')) {
                this.status = localStorage.getItem('adminListSubscriptionsStatus') as string
            } else {
                this.status = 'active'
            }

            localStorage.setItem('adminListSubscriptionsStatus', this.status)
            this.router.navigate([], { queryParams: { status: this.status } })
            const ref = query(collection(this.adminService.firestore, subscriptionsPath()), orderBy('manualClientName', 'desc'))
            this.updateDocsFromCollectionRef(ref)
        })
    }

    ngOnInit(): void {}

    updateDocsFromCollectionRef(ref: Query<DocumentData>) {
        collectionData(ref, { idField: 'documentId' }).subscribe((documents: any[]) => {
            this.subscriptions = []
            documents.forEach((documentData) => {
                let subscription = new Subscription(documentData)
                subscription.$key = documentData.documentId

                docData(documentData.parkingRef).subscribe((data) => {
                    subscription.$parking = new Parking(data)
                    subscription.$parking.$key = documentData.parkingRef.id
                })
                docData(documentData.clientRef).subscribe((data) => {
                    subscription.$client = new Client(data)
                    subscription.$client.$key = documentData.clientRef.id
                })
                docData(documentData.merchantRef).subscribe((data) => {
                    subscription.$merchant = new Merchant(data)
                    subscription.$merchant.$key = documentData.merchantRef.id
                })
                this.subscriptions.push(subscription)
            })
        })
    }
}
