import { Injectable } from '@angular/core'
import { collection, collectionData, deleteDoc, doc, docData, Firestore, setDoc, updateDoc } from '@angular/fire/firestore'
import { Client } from '@parkupp/core'
import { Auth } from '@angular/fire/auth'
import * as moment from 'moment'
import { HttpClient } from '@angular/common/http'

@Injectable({
    providedIn: 'root',
})
export class HealthCheckService {
    // https://www.bezkoder.com/angular-13-firestore-crud-angularfirestore/

    constructor(private firestore: Firestore, private auth: Auth, private http: HttpClient) {}

    getCollections() {
        collectionData(collection(this.firestore, 'users')).subscribe((documents) => {
            console.log(documents)
        })
    }

    getDocument() {
        const uid = '1'
        docData(doc(this.firestore, 'users', uid)).subscribe((document: any) => {
            let client: Client = document
            client.$key = uid
            console.log(client)
        })
    }

    saveDocument() {
        const uid = '3'
        let client: Client = new Client()
        client.firstName = 'Test 11'
        const reference = doc(this.firestore, `users`, uid)
        setDoc(reference, JSON.parse(JSON.stringify(client)))
            .then(() => {
                console.log('SAVED')
            })
            .catch((error) => {
                console.error(error)
            })
    }

    updateDocument() {
        const uid = '3'
        const reference = doc(this.firestore, `users`, uid)
        updateDoc(reference, {
            lastName: 'New Name',
        })
            .then(() => {
                console.log('Update')
            })
            .catch((error) => {
                console.error(error)
            })
    }

    deleteDocument() {
        // Not commonly used, use soft deletes rather
        const uid = '3'
        const reference = doc(this.firestore, `users`, uid)
        deleteDoc(reference)
            .then(() => {
                console.log('Update')
            })
            .catch((error) => {
                console.error(error)
            })
    }

    softDelete() {
        const uid = '3'
        const reference = doc(this.firestore, `users`, uid)
        updateDoc(reference, {
            deletedAt: moment().valueOf(),
        })
            .then(() => {
                console.log('Deleted')
            })
            .catch((error) => {
                console.error(error)
            })
    }
}
