<app-header></app-header>

<ng-container *ngIf="merchantKey">
    <div class="side-menu">
        <app-merchant-vertical-menu></app-merchant-vertical-menu>
    </div>

    <div class="side-menu-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6">
                    <h1 class="">Proforma Invoices</h1>
                </div>
                <div class="col-3"></div>
                <div class="col-3"></div>
            </div>
            <div *ngIf="!merchant.payoutDetails?.accountNumber" class="alert alert-danger" role="alert">
                <b>Payout Unavailable</b>
                <br />
                We are unable to process payouts until payout details are updated.
                <br />
                <a [routerLink]="['/merchant', merchantKey, 'profile', 'payout']" class="text-danger text-decoration-underline">Click here to update your payout details</a>
            </div>
            <div class="content col-lg-12">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Created</th>
                            <th>Payout</th>
                            <th>Proforma</th>
                            <th>Invoices</th>
                            <th>Total Amount</th>
                            <th>Payout Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let payout of payouts">
                            <tr>
                                <td>{{ payout.createdAt.toDate() | date: 'dd MMM yyyy' }}</td>
                                <td>
                                    <ng-container *ngIf="payout.payoutDate">
                                        <span class="badge bg-success text-dark">{{ payout.payoutDate.toDate() | date: 'dd MMM yyyy' }}</span>
                                    </ng-container>
                                    <ng-container *ngIf="!payout.payoutDate">
                                        <span class="badge bg-danger text-light">Unpaid</span>
                                    </ng-container>
                                </td>
                                <td>
                                    <a [routerLink]="['/merchant', this.merchantKey, 'proforma-invoice', payout.$key]" class="text-decoration-underline"> PFI-{{ payout.payoutNumber }} </a>
                                </td>
                                <td>{{ payout.invoiceRefs.length }}</td>
                                <td>{{ payout.totalAmount | currency: 'R' }}</td>
                                <td>{{ payout.payoutAmount | currency: 'R' }}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-container>

<app-footer></app-footer>
