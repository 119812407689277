"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserDetails = void 0;
class UserDetails {
    constructor(source) {
        if (source) {
            Object.assign(this, source);
        }
    }
}
exports.UserDetails = UserDetails;
