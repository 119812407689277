<nav class="navbar navbar-expand-lg navbar-dark bg-dark p-0">
    <div class="container-fluid">
        <div class="navbar-header">
            <a class="navbar-brand" href="/Home">ParkUpp</a>
        </div>

        <button
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            class="navbar-toggler d-block d-md-none"
            data-bs-target="#navbarSupportedContent"
            data-bs-toggle="collapse"
            type="button"
        >
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a [routerLink]="['/admin']" aria-current="page" class="nav-link" routerLinkActive="active">Dashboard</a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/admin/line-charts']" aria-current="page" class="nav-link" routerLinkActive="active">Line Charts</a>
                </li>
                <li class="nav-item dropdown">
                    <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button">Landlords</a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/admin/merchants']" class="dropdown-item">Portfolios</a></li>
                        <li><a [routerLink]="['/admin/parkings']" class="dropdown-item">Buildings / Parking lots</a></li>
                        <li><a [routerLink]="['/admin/merchant-users-private']" class="dropdown-item">Merchant Members</a></li>
                        <li><a [routerLink]="['/admin/merchant-member-invites']" class="dropdown-item">Merchant Member Invites</a></li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button">Parkers</a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/admin/parker-crm/table']" class="dropdown-item">Parker CRM Data</a></li>
                        <li><a [routerLink]="['/admin/parker-crm/stats']" class="dropdown-item">Parker CRM Stats</a></li>
                        <li><a [routerLink]="['/admin/clients']" class="dropdown-item">Latest 2000 Parkers</a></li>
                        <li><a [routerLink]="['/admin/leads']" class="dropdown-item">Leads</a></li>
                    </ul>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/admin/subscriptions']" class="nav-link">Subscriptions</a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/admin/invoices']" aria-current="page" class="nav-link" routerLinkActive="active"> Invoices </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/admin/emails']" aria-current="page" class="nav-link" routerLinkActive="active"> Emails </a>
                </li>
                <!--                <li class="nav-item">-->
                <!--                    <a [routerLink]="['/admin/payments']" aria-current="page" class="nav-link" routerLinkActive="active">Payments</a>-->
                <!--                </li>-->

                <li class="nav-item dropdown">
                    <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button"> Accounts </a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/admin/invoice-payouts']" class="dropdown-item">1. Invoice Payouts </a></li>
                        <li><a [routerLink]="['/admin/payouts']" class="dropdown-item">2. Proforma Invoices</a></li>
                        <li><a [routerLink]="['/admin/payout-batches']" class="dropdown-item">3. Payout Batches</a></li>
                        <li><hr class="dropdown-divider" /></li>

                        <li><a [routerLink]="['/admin/recon']" class="dropdown-item">Income Recon</a></li>
                    </ul>
                </li>

                <li class="nav-item dropdown">
                    <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button"> VAT 201 </a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/admin/invoices-list']" class="dropdown-item">Invoices</a></li>
                        <li><a [routerLink]="['/admin/proforma-invoices-list']" class="dropdown-item">Proforma Invoices</a></li>
                        <li><a [routerLink]="['/admin/old-invoices-list']" class="dropdown-item">Old Invoice System</a></li>
                    </ul>
                </li>

                <li class="nav-item dropdown">
                    <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button"> More </a>
                    <ul class="dropdown-menu">
                        <!--                        <li><a [routerLink]="['/admin/all-manual-subs']" class="dropdown-item">All Manual Subs</a></li>-->
                        <li><a [routerLink]="['/admin/settlements']" class="dropdown-item">Settlements</a></li>
                        <!--                        <li><a [routerLink]="['/admin/emails']" class="dropdown-item">Emails</a></li>-->
                        <li><a [routerLink]="['/admin/searches']" class="dropdown-item">Searches</a></li>
                        <li><a [routerLink]="['/admin/deputize']" class="dropdown-item">Deputize</a></li>
                        <li><a [routerLink]="['/admin/scan']" class="dropdown-item">Scan QR</a></li>

                        <!--                        <li><hr class="dropdown-divider"></li>-->
                    </ul>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/admin/blog']" class="nav-link">Blog</a>
                </li>
                <li class="nav-item dropdown">
                    <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button"> Reports </a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/admin/searches-report']" class="dropdown-item">Searches by Province</a></li>
                        <li><a [routerLink]="['/admin/searches-table']" class="dropdown-item">Searches by City</a></li>
                        <li><a [routerLink]="['/admin/companies-by-client']" class="dropdown-item">Companies by client</a></li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button"> Dev </a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/admin/exceptions']" class="dropdown-item">Exceptions</a></li>
                        <li><a [routerLink]="['/admin/list-payments']" class="dropdown-item">Possible Held Payments</a></li>
                        <li><a [routerLink]="['/admin/void-payment']" class="dropdown-item">Force Void Payment</a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>

<div class="context-menu" id="context-menu" *ngIf="globalService.contextMenuVisible" [style.left.px]="globalService.contextMenuX" [style.top.px]="globalService.contextMenuY">
    <ul class="context-menu-list">
        <li class="context-menu-item" *ngFor="let item of globalService.contextMenuItems" (click)="item.action()">
            <a> <i *ngIf="item.icon" [class]="item.icon"></i> {{ item.label }}</a>
            <ul *ngIf="item.subMenuItems" class="context-submenu-list d-none">
                <li class="context-submenu-item" *ngFor="let subItem of item.subMenuItems" (click)="subItem.action()"><i *ngIf="subItem.icon" [class]="subItem.icon"></i> {{ subItem.label }}</li>
            </ul>
        </li>
    </ul>
</div>
