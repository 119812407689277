import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { EnumNames, Payout, PayoutBatch, PayoutBatchStatusEnum, PayoutStatusEnum } from '@parkupp/core'
import { doc, serverTimestamp, Timestamp, writeBatch } from 'firebase/firestore'
import { PayoutBatchService } from 'src/app/services/payout-batch.service'
import { PayoutService } from 'src/app/services/payout.service'
import Swal from 'sweetalert2'
@Component({
    selector: 'app-admin-list-payouts',
    templateUrl: './admin-list-payouts.component.html',
    styleUrls: ['./admin-list-payouts.component.scss'],
})
export class AdminListPayoutsComponent implements OnInit {
    EnumNames = EnumNames
    payouts: Payout[] = []
    selectedPayouts: Payout[] = []

    constructor(private payoutService: PayoutService, private payoutBatchService: PayoutBatchService, private router: Router) {}

    async ngOnInit() {
        this.payouts = await this.payoutService.getUnbatchedPayouts()
    }

    toggleSelected(payout: Payout): void {
        console.log(payout, this.selectedPayouts)
        // if (payout.$merchant?.payoutDetails) {
        if (!this.selectedPayouts.includes(payout)) {
            this.selectedPayouts.push(payout)
        } else {
            this.selectedPayouts = this.selectedPayouts.filter((tempPayout: Payout) => tempPayout.$key !== payout.$key)
        }
        // }

        // if (!this.selectedInvoices.includes(invoice)) {
        //     this.selectedInvoices.push(invoice)
        // } else {
        //     this.selectedInvoices = this.selectedInvoices.filter((inv) => inv !== invoice)
        // }
    }

    onHoverWithMouseDown(event: MouseEvent, payout: Payout): void {
        if (event.buttons === 1) {
            this.toggleSelected(payout)
        }
    }

    async createPayoutBatch() {
        const payoutBatch = new PayoutBatch()
        payoutBatch.payoutRefs = this.selectedPayouts.map((payout) => doc(this.payoutService.collection, payout.$key) as any)
        payoutBatch.status = PayoutBatchStatusEnum.DUE
        payoutBatch.totalAmount = this.selectedPayouts.reduce((acc, payout: Payout) => acc + payout.totalAmount, 0)
        payoutBatch.payoutAmount = this.selectedPayouts.reduce((acc, payout: Payout) => acc + payout.payoutAmount, 0)
        payoutBatch.merchantRefs = this.selectedPayouts.map((payout) => payout.merchantRef)
        payoutBatch.invoiceRefs = this.selectedPayouts.flatMap((payout) => payout.invoiceRefs)
        const payoutBatchRef = await this.payoutBatchService.createOrUpdate(payoutBatch)

        const updatePayoutsBatch = writeBatch(this.payoutService.firestore)

        for (const payout of this.selectedPayouts) {
            payout.payoutBatchRef = payoutBatchRef as any
            payout.status = PayoutStatusEnum.BATCHED
            const payoutRef = this.payoutService.getDocRef(payout.$key!)
            updatePayoutsBatch.update(payoutRef, payout.parse())
        }
        await updatePayoutsBatch.commit()

        this.router.navigate(['/admin', 'payout-batch', payoutBatchRef.id])
    }
}
