"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MerchantTenant = void 0;
class MerchantTenant {
    constructor(source) {
        this.active = true;
        if (source) {
            Object.assign(this, source);
        }
    }
    parse() {
        const clone = JSON.parse(JSON.stringify(this));
        delete clone.$key;
        delete clone.$client;
        const merchantTenant = JSON.parse(JSON.stringify(clone));
        if (this.merchantRef)
            merchantTenant.merchantRef = this.merchantRef;
        merchantTenant.createdAt = this.createdAt;
        merchantTenant.updatedAt = this.updatedAt;
        return merchantTenant;
    }
}
exports.MerchantTenant = MerchantTenant;
