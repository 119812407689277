"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parkingLeaseAgreementPath = exports.parkingServiceAgreementPath = exports.blogImagePath = exports.parkingImagePath = exports.merchantImagePath = exports.clientImagePath = exports.StorageStructure = void 0;
class StorageStructure {
}
exports.StorageStructure = StorageStructure;
StorageStructure.IMAGES = 'images';
StorageStructure.IMAGES_ORIGINAL = 'original';
StorageStructure.IMAGES_1920 = '1920';
StorageStructure.IMAGES_1280 = '1280';
StorageStructure.IMAGES_512 = '512';
StorageStructure.IMAGES_256 = '256';
StorageStructure.IMAGES_ORIGINAL_CLIENTS = 'clients';
StorageStructure.IMAGES_ORIGINAL_MERCHANTS = 'merchants';
StorageStructure.IMAGES_ORIGINAL_PARKINGS = 'parkings';
StorageStructure.IMAGES_ORIGINAL_BLOGS = 'blogs';
StorageStructure.SERVICE_AGREEMENTS = 'service-agreements';
StorageStructure.LEASE_AGREEMENTS = 'lease-agreements';
function clientImagePath(uid) {
    return `${StorageStructure.IMAGES}/${StorageStructure.IMAGES_ORIGINAL}/${StorageStructure.IMAGES_ORIGINAL_CLIENTS}/${uid}`;
}
exports.clientImagePath = clientImagePath;
function merchantImagePath(uid) {
    return `${StorageStructure.IMAGES}/${StorageStructure.IMAGES_ORIGINAL}/${StorageStructure.IMAGES_ORIGINAL_MERCHANTS}/${uid}`;
}
exports.merchantImagePath = merchantImagePath;
function parkingImagePath(uid, parkingKey, imageKey) {
    return `${StorageStructure.IMAGES}/${StorageStructure.IMAGES_ORIGINAL}/${StorageStructure.IMAGES_ORIGINAL_PARKINGS}/${uid}/${parkingKey}/${imageKey}`;
}
exports.parkingImagePath = parkingImagePath;
function blogImagePath(uid) {
    return `${StorageStructure.IMAGES}/${StorageStructure.IMAGES_ORIGINAL}/${StorageStructure.IMAGES_ORIGINAL_BLOGS}/${uid}`;
}
exports.blogImagePath = blogImagePath;
function parkingServiceAgreementPath(uid, parkingKey) {
    return `${StorageStructure.SERVICE_AGREEMENTS}/${uid}/${parkingKey}`;
}
exports.parkingServiceAgreementPath = parkingServiceAgreementPath;
function parkingLeaseAgreementPath(uid, parkingKey) {
    return `${StorageStructure.LEASE_AGREEMENTS}/${uid}/${parkingKey}`;
}
exports.parkingLeaseAgreementPath = parkingLeaseAgreementPath;
