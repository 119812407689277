<div class="container-fluid">
    <div class="row">
        <div *ngIf="showHeading" class="col-12">
            <h1 *ngIf="!dashboardView" class="mb-4">Create a Portfolio</h1>
            <h2 *ngIf="dashboardView" class="mb-4">Merchant Details</h2>
        </div>
        <div class="col-12">
            <form (ngSubmit)="save()" [formGroup]="formGroup" class="needs-validation" novalidate>
                <div class="form-group mb-4">
                    <label>Who's listing?</label>
                    <input class="form-control" formControlName="companyName" id="companyName" name="companyName" placeholder="Company / Owner Name" required type="text" />
                    <div class="invalid-feedback" invalidMessage="companyName">{{ formGroup.get('companyName') | validationErrors }}</div>
                </div>
                <div class="form-group mb-4">
                    <label>Company Registration <small>(Optional)</small></label>
                    <input class="form-control" formControlName="companyRegistration" id="companyRegistration" name="companyRegistration" placeholder="Company Registration" type="text" />
                </div>
                <div class="form-group mb-4">
                    <label>Vat Number <small>(Optional)</small></label>
                    <input class="form-control" formControlName="vatNumber" id="vatNumber" name="vatNumber" placeholder="Vat Number" type="text" />
                </div>
                <div *ngIf="!dashboardView && isAdminCreateMerchant" class="form-group mb-4 admin-only">
                    <label>Email</label>
                    <input class="form-control" formControlName="email" id="email" name="email" placeholder="Email" required type="text" />
                    <div class="invalid-feedback" invalidMessage="email">{{ formGroup.get('email') | validationErrors }}</div>
                </div>
                <div *ngIf="!dashboardView && isAdminCreateMerchant" class="form-group mb-4 admin-only">
                    <p>ReserveUpp Settings</p>
                    <div>
                        <input class="form-check-input" formControlName="bookuppEnabled" id="bookuppEnabled" name="bookuppEnabled" type="checkbox" />&nbsp;
                        <label class="form-check-label" for="bookuppEnabled">Activate ReserveUpp</label>
                    </div>
                </div>

                <div *ngIf="authenticationService.isAdmin" class="form-group mb-4 admin-only">
                    <label>ParkUpp Fee Exception</label>
                    <input class="form-control" formControlName="commissionPercentage" id="commissionPercentage" name="commissionPercentage" type="number" />
                </div>
                <div class="form-group mb-4">
                    <label>Parking Contact Number</label>
                    <div class="input-group">
                        <ngx-intl-tel-input
                            [cssClass]="'form-control'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [numberFormat]="PhoneNumberFormat.National"
                            [phoneValidation]="true"
                            [preferredCountries]="preferredCountries"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [searchCountryFlag]="true"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="CountryISO.SouthAfrica"
                            [separateDialCode]="separateDialCode"
                            formControlName="phone"
                            required
                        >
                        </ngx-intl-tel-input>
                    </div>
                    <div class="invalid-feedback" invalidMessage="phone">{{ formGroup.get('phone') | validationErrors }}</div>
                </div>

                <div *ngIf="dashboardView" class="form-group mb-4">
                    <label>Company Emails:</label>
                    <ng-container *ngFor="let merchantMember of merchantMembers">
                        <div *ngIf="merchantMember.active">
                            <input [disabled]="true" class="form-control" placeholder="{{ merchantMember.email }}" type="text" />
                        </div>
                    </ng-container>
                    <div class="form-text" id="emailHelp">Add managers to add emails</div>
                </div>
                <div *ngIf="!valid && formGroup.invalid" class="alert alert-info">Please complete required fields</div>
                <button [disabled]="loading" class="btn btn-primary mb-3" type="submit"><i *ngIf="loading" class="fa fa-circle-notch fa-spin"></i><span *ngIf="!loading">Save</span></button>
                <div *ngIf="error" class="alert alert-warning">
                    {{ error }}
                </div>
            </form>
        </div>
    </div>
</div>
