import { Injectable } from '@angular/core'
import { Firestore, query } from '@angular/fire/firestore'
import { Functions, httpsCallable } from '@angular/fire/functions'
import { doc, DocumentReference, getDoc, orderBy, setDoc, updateDoc } from 'firebase/firestore'
import { where } from 'firebase/firestore'
import { FirebaseFunctions, Payout, FirestoreStructure, LogEmail } from '@parkupp/core'
import { collection } from '@angular/fire/firestore'
import { getDocs } from 'firebase/firestore'

@Injectable({
    providedIn: 'root',
})
export class LogEmailService {
    collection = collection(this.firestore, FirestoreStructure.LOG_EMAILS)
    constructor(private functions: Functions, public firestore: Firestore) {}

    getDocRef(key: string) {
        return doc(this.collection, key)
    }

    async getLogEmail(logEmailDocRef: DocumentReference) {
        const docSnap = await getDoc(logEmailDocRef)
        const logEmail = new LogEmail(docSnap.data() as Partial<LogEmail>)
        logEmail.$key = docSnap.id
        return logEmail
    }

    async listByMerchant(merchantDocRef: DocumentReference) {
        const q = query(this.collection, where('merchantRef', '==', merchantDocRef), orderBy('createdAt', 'desc'))
        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map((doc) => {
            const logEmail = new LogEmail(doc.data())
            logEmail.$key = doc.id
            return logEmail
        })
    }

    async getAdminOverview() {
        const ref = query(collection(this.firestore, FirestoreStructure.LOG_EMAILS_OVERVIEW), orderBy('createdAt', 'desc'))
        const querySnapshot = await getDocs(ref)
        return querySnapshot.docs.map((doc) => {
            const logEmail = new LogEmail(doc.data())
            logEmail.$key = doc.id
            return logEmail
        })
    }

    async updateOverviewDoc(logEmail: LogEmail) {
        const reference = doc(this.firestore, FirestoreStructure.LOG_EMAILS_OVERVIEW, logEmail.$key)
        return updateDoc(reference, logEmail.parse())
    }
}
